import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as vendorActions  from '../../../actions/vendorActions';
import * as formStateActions  from '../../../actions/formStateActions';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import _ from 'lodash';

/************************************
Note on UI for Qualtrics vendors:

We need to be able to query the VendorAccount table in slip-api to get the correct token. Traditionally we've done that by using the AccountName column. We've had accounts called "Silverlink Twilio," "Silverlink Sendgrid," and now "WES Qualtrics User" and "WES Qualtrics Admin."

Unfortunately one of the calls to Qualtrics's legacy API needed the actual account name of the Qualtrics account. Our infrastructure had already been built around "WES Qualtrics User" and "WES Qualtrics Admin."

The code here represents the temporary solution. We label AccountName as "Account Type" when you're adding/editing a Qualtrics vendor account. We label a new field which is saved in a JSON blob as "Vendor Account." The field in the JSON blob is called qualtricsAccount.

After we move to SQL Server 2016, we'll be able to query JSON in a SQL call. We'll then add booleans "isQualtricsUser" and isQualtricsAdmin" to the JSON blob, and we'll query those from slip-api. We'll then be able to use the AccountName field to store the actual account name.
**************************************/

class VendorForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentSelectedTypeCode: {
				TypeCode: null
			},
			formError: null
		};

		this._isMounted = false;

		this.typeCodeChanged = this.typeCodeChanged.bind(this);
		this.takeDownForm    = this.takeDownForm.bind(this);
		this.setFormErrorMessage     = this.setFormErrorMessage.bind(this);
	}

	componentDidMount() {
		let obj = this.props.object;
		if (obj && obj.VendorAccountID) {
			this.props.actions.loadVendorShortCodes(this.props.object.VendorAccountID).then(data => {
				;
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading short codes: " + msg);
			});
			this.props.actions.loadVendorEmailDomains(obj.VendorAccountID).catch(errObject => {
				var msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the vendor email domains: " + msg);
			});
		}
		this.props.actions.loadVendorTypecodes().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the vendor typecodes: " + msg);
		});
	}

	componentWillUnmount() {
		;
	}

	setFormErrorMessage(errorMessage)
	{
		this.setState({
			formError: errorMessage
		});
	}

	componentDidUpdate(prevProps) {
		window.scrollTo(0, 0)
	}

	takeDownForm(successMessage) {
		window.scrollTo(0, 0);
	}

	_onChange() {
		// repaint the form when data loads
		if (this._isMounted) {
			this.forceUpdate();
		}
	}

	extractJSONFields(text) {
		try {
			return JSON.parse(text);
		} catch(err) {
			return {};
		}
	}

	typeCodeChanged(event, fieldChanges) {
		this.setState({
			currentSelectedTypeCode: _.extend(this.state.currentSelectedTypeCode, fieldChanges),
			formError: null
		});
	}

	render() {
		let shortCodeTagTextFunction = function(obj) {
			return obj.ShortCode;
		};
		let emailDomainTagTextFunction = function(obj) {
			return obj.EmailDomain;
		};

		let object = this.props.object;
		let donotModify = true;
		let jsonObj = this.extractJSONFields(object.MetadataJSON);
		object = _.extend(object, jsonObj);
		let shortCodeEmaildomainUI = [];

		let vendorTypeCode = this.state.currentSelectedTypeCode.VendorTypeCode;
		if (!vendorTypeCode)
			vendorTypeCode = object.VendorTypeCode;
		if (!vendorTypeCode) // On a vendor create
			vendorTypeCode = "Email";
		if (this.props.readOnly) {
			let assignedList, keyField, labelText, tagTextFunction;

			if (vendorTypeCode === "SMS")
			{
				labelText = "Short Codes";
				assignedList = this.props.vendorShortcodes;
				keyField = "ShortCode";
				tagTextFunction = shortCodeTagTextFunction;
			}
			else if (vendorTypeCode === "Email")
			{
				labelText = "Email Domains";
				assignedList = this.props.vendorEmaildomains;
				keyField = "EmailDomain";
				tagTextFunction = emailDomainTagTextFunction;
			}
			if (assignedList)
			{
				shortCodeEmaildomainUI.push(
						<div className="form-group row">
							<label className="col-form-label col-md-2" style={{ lineHeight: "16px"}}>{labelText}</label>
							<div className="form-control-plaintext col-md-10" >
								<FormField.AssignedTagList
												readOnly = { this.props.readOnly }
												keyField = {keyField}
												sortField = {keyField}
												tagText={ tagTextFunction }
												objects = { assignedList } >
								</FormField.AssignedTagList>
							</div>
						</div>
					);
				}
		} else  {
			donotModify = false;
		}

		let asidUI = [], messagingServiceSidUI = [], quatricsAccountUI = [];
		if (vendorTypeCode === "SMS")
		{
			asidUI.push(
				<FormField.Input fieldid="asid"  width="9" label="asid" labelWidth="2" required = {true} fieldvalue={object.asid} />
			);
			messagingServiceSidUI.push(
				<FormField.Input fieldid="MessagingServiceSid"  width="9" label="Messaging ServiceSid" labelWidth="2" fieldvalue={object.MessagingServiceSid} />
			);
		}
		let accountnameLabel;
		// Please see the note at the top of the page to find out more about qualtricsAccount
		if (vendorTypeCode === "WebProg")
		{
			quatricsAccountUI.push(
				<FormField.Input fieldid="qualtricsAccount"  width="9" label="Vendor Account" labelWidth="2" required = {true} fieldvalue={object.qualtricsAccount} />
			);
			accountnameLabel = "Account Type"
		}
		else
			accountnameLabel = "Account Name";

		let typeCodes = this.props.vendorTypecodes;

		//set up default value
		object.VendorTypeCode = vendorTypeCode;

		return (
			<Form {...this.props} width="800" height="580" >
				<FormField.Input dontModify= {donotModify} fieldid="VendorName" maxLength={32}  width="5" label="Vendor Name" labelWidth="2" required = {true} fieldvalue={object.VendorName} />
				<FormField.Select label="Type Code" width="2" labelWidth="2"
								  fieldid="VendorTypeCode"
								  fieldName="VendorTypeCode"
								  optionid="VendorTypeCode"
								  noCaseChange= {true}
								  fieldvalue={ vendorTypeCode }
								  options={ typeCodes }
								  localFieldChangeCallback={ this.typeCodeChanged } errorMessage={this.state.formError} />

				<FormField.Input fieldid="AccountName" width="9" maxLength={256}  label={accountnameLabel} labelWidth="2" required = {(vendorTypeCode === "WebProg")} fieldvalue={object.AccountName} />
				{quatricsAccountUI}
				{asidUI}
				<FormField.Input fieldid="authToken"  width="9" label="authToken" labelWidth="2" required = {true} fieldvalue={object.authToken}/>
				{messagingServiceSidUI}
				{shortCodeEmaildomainUI}

			</Form>
		);
	}
}

function mapStateToProps(state, ownProps) {
	// FIREFOX BUG WORKAROUND
	// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
	// It doesn't like the number 1 as a computed key in an object, even once it's stringified
	return {
		currentForm: state.formStateReducer.currentForm,
		vendorShortcodes: state.vendorReducer.vendorShortcodes['ID_' + ownProps.object.VendorAccountID],
		vendorEmaildomains: state.vendorReducer.vendorEmaildomains['ID_' + ownProps.object.VendorAccountID],
		vendorTypecodes: state.vendorReducer.vendorTypecodes
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, vendorActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorForm)
