import * as types from './actionTypes';
import api from './api';

export function loadApiClients() {
    return function (dispatch) {
        return api({
            method: 'get',
            url: '/auth/apiclients/',
        }).then(apiclients => {
            dispatch({type: types.GET_API_CLIENTS_SUCCESS, payload: apiclients});
        }).catch(error => {
            throw(error);
        });
    };
}

export function loadApiClient(apiclientId) {
    return function (dispatch) {
        return api({
            method: 'get',
            url: `/auth/apiclients/${apiclientId}`
        }).then(response => {
            dispatch({type: types.GET_SINGLE_API_CLIENT_SUCCESS, payload: response});
        }).catch(error => {
            throw(error);
        });
    };
}

export function updateApiClientRelationship(id, apiclient) {
    return function (dispatch) {
        return api({
            method: 'post',
            url: `/auth/apiclients/${id}/relationship`,
            data: apiclient
        }).catch(error => {
            throw(error);
        });

    };
}

export function updateApiClient(apiclient) {
    return function (dispatch) {
        return api({
            method: 'post',
            url: `/auth/apiclients/${apiclient.login_id}`,
            data: apiclient
        }).then(response => {
            dispatch({type: types.UPDATE_API_CLIENT, payload: apiclient});
        }).catch(error => {
            throw(error);
        });
    };
}

export function addApiClient(apiclient) {
    return function (dispatch) {
        return api({
            method: 'post',
            url: `/auth/apiclients`,
            data: apiclient
        }).catch(error => {
            throw(error);
        });
    };
}

export function deleteApiClient(apiclient) {
    return function(dispatch) {
        return api({
            method: 'delete',
            url: `auth/apiclients/${apiclient.id}`,
            data: apiclient
        }).catch(error => {
            throw(error);
        });
    };
}

export function changePassword(apiclient) {
    return function (dispatch) {
        return api({
            method: 'post',
            url: '/auth/password',
            data: apiclient
        }).catch(error => {
            throw(error);
        })
    }
}