import * as types from './actionTypes';
import api from './api';

export function loadApiLinks() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/api-link-service/api-link',
		}).then(response => {
			dispatch({type: types.GET_APILINKS_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadApiLinkDetails(api_link_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/api-link-service/api-link/${api_link_id}`
		}).then(response => {
			dispatch({type: types.GET_APILINK_SUCCESS, payload: response, id: api_link_id});
		}).catch(error => {
			throw(error);
		});
	};
}

export function resetApiLinkDetails() {
	return function(dispatch) {
		dispatch({type: types.RESET_APILINK_SUCCESS, payload: {}});
	};
}

export function loadApiLinkInUse(api_link_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/api-link-service/in-use/${api_link_id}`
		}).then(response => {
			dispatch({type: types.GET_APILINK_INUSE, payload: response, id: api_link_id});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadApiLinkAccounts(api_link_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/api-link-service/api-link/${api_link_id}/accounts`
		}).then(response => {
			dispatch({type: types.GET_APILINK_ACCOUNTS_SUCCESS, payload: response, id: api_link_id});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadApiLinkAttributes(api_link_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/api-link-service/api-link-attributes/${api_link_id}`
		}).then(response => {
			dispatch({type: types.GET_APILINK_ATTRIBUTES_SUCCESS, payload: response, id: api_link_id});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addApiLinkAccount(api_link_id, account_id) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: `/api-link-service/api-link/${api_link_id}/accounts/${account_id}`
		}).then(response => {
			dispatch({type: types.ADD_APILINK_ACCOUNT_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function removeApiLinkAccount(api_link_id, account_id) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/api-link-service/api-link/${api_link_id}/accounts/${account_id}`
		}).then(response => {
			dispatch({type: types.REMOVE_APILINK_ACCOUNT_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addApiLink(api_link_name, api_link_description, api_link_url, api_link_verb, api_link_enabled, api_link_authentication_method, api_link_authentication_detail) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: '/api-link-service/api-link',
			data: {
				api_link_name: api_link_name,
				api_link_description: api_link_description,
				api_link_verb: api_link_verb,
				api_link_url: api_link_url,
				api_link_enabled: api_link_enabled,
				api_link_authentication_method_id: api_link_authentication_method,
				api_link_authentication_detail: api_link_authentication_detail
			}
		}).then(response => {
			return response;
		}).catch(error => {
			throw(error);
		});
	};
}

export function updateApiLink(api_link_id, api_link_name, api_link_description, api_link_url, api_link_verb, api_link_enabled, api_link_authentication_method, api_link_authentication_detail) {
	return function(dispatch) {
		return api({
			method: 'put',
			url: `/api-link-service/api-link/${api_link_id}`,
			data: {
				api_link_name: api_link_name,
				api_link_description: api_link_description,
				api_link_verb: api_link_verb,
				api_link_url: api_link_url,
				api_link_enabled: api_link_enabled,
				api_link_authentication_method_id: api_link_authentication_method,
				api_link_authentication_detail: api_link_authentication_detail
			}
		}).then(response => {
			dispatch({type: types.UPDATE_APILINK_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function updateApiLinkAttributes(api_link_id, api_link_attributes) {
	return function(dispatch) {
		return api({
			method: 'put',
			url: `/api-link-service/api-link-attributes/${api_link_id}`,
			data: {
				api_link_attributes: api_link_attributes
			}
		}).then(response => {
			dispatch({type: types.UPDATE_APILINK_ATTRIBUTES_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function deleteApiLink(api_link_id) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/api-link-service/api-link/${api_link_id}`
		}).then(response => {
			dispatch({type: types.DELETE_APILINK_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadApiLinkDataTypes() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/api-link-service/data-types'
		}).then(response => {
			dispatch({type: types.GET_APILINK_DATA_TYPES_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadApiLinkAuthMethods() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/api-link-service/auth-methods'
		}).then(response => {
			dispatch({type: types.GET_APILINK_AUTH_METHODS_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function cloneApiLink(api_link_id) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: `/api-link-service/api-link/clone/${api_link_id}`
		}).then(response => {
			dispatch({type: types.CLONE_APILINK_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}
