import React from 'react';
import {useDispatch, shallowEqual, useSelector} from 'react-redux';
import Form from '../../common/Form';
import * as DashboardActions from '../../../actions/dashboardActions';
import MuTable from '../../common/MuTable';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import DownloadIcon from '@material-ui/icons/GetApp';
import KeyboardReturn from '@material-ui/icons/ExitToApp';
import Link from '@material-ui/core/Link';
import MuDataBox from '../../common/MuDataBox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import { lighten, makeStyles } from '@material-ui/core/styles';
import ReactSelect  from "react-select";
import _  from 'lodash'
import DownloadLink from "react-download-link";
import ContentWrap from "../../admin/users/Users";

const IMPORT_TYPES  =
    [
        {value: 'all',label:'All Import Types'},
        {value: 'import_recipient',label:'Recipient'},
        {value: 'import_group',label:'Group' },
        {value: 'import_healthlink',label:'Healthlink' },
        {value: 'import_healthcast_direct',label:'Healthcast Direct' },
        {value: 'import_comm_link_program',label:'Comm Link Program' },
        {value: 'import_campaign',label:'Campaign' },
        {value: 'import_website',label:'Website' },
        {value: 'import_data_donotcall',label:'Data Donnotcall' },
        {value: 'import_audio_library',label:'Audio Library' },
        {value: 'import_form_data',label:'Form Data' },
        {value: 'import_recipient_sweep',label:'Recipient Sweep' },
        {value: 'import_conditional_sweep',label:'Conditional Sweep' }
    ];

//here are all the styles for a simple height change
const targetHeight = 30;
const customSelect2Styles = {
    control: base => ({
        ...base,
        minHeight: 'initial',
        marginRight: 10
    }),
    valueContainer: base => ({
        ...base,
        height: `${targetHeight - 1 - 1}px`,
        padding: '0 8px',
    }),
    clearIndicator: base => ({
        ...base,
        padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
    }),
    indicatorSeparator: base => ({
        ...base,
        marginTop: 1,
        marginBottom: 1
    })
};
const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    dataBlockSpace: {
        marginTop: theme.spacing(3)
    },
    accountList: {
        paddingLeft: 5,
        color: '#777777'
    },
    topMenus: {
        '& a': {
            fontSize: 14,
            color: '#003c44'
        },
        '& button': {
            fontSize: 14,
            color: '#003c44',
            marginRight: 5
        },
        '& svg': {
            color: '#777777'
        }
    }
}));

const ImportMetricColumns = [
    {id: 'account_id', align: "left", disablePadding: true, label: 'Account', width: "50px", tooltip: "account_name"},
    {id: 'file_name', align: "left", disablePadding: false, label: 'File Name', width: "180px"},
    {id: 'import_type_label', align: "left", disablePadding: false, label: 'Import Type', width: "80px"},
    {id: 'rows_accept', type: 'number', align: "left", disablePadding: false, label: 'Accepted', width: "60px"},
    {id: 'rows_imported', type: 'number', align: "left", disablePadding: false, label: 'Imported', width: "60px"},
    {id: 'users_swept', type: 'number', align: "left", disablePadding: false, label: 'Swept', width: "60px"},
    {id: 'rows_reject', type: 'number', align: "left", disablePadding: false, label: 'Rejected', width: "60px"},
    {id: 'rows_reject_rate', align: "left", disablePadding: false, label: 'Rejected %', width: "70px", showAfterValue: '%'},
    {id: 'duration', align: "left", disablePadding: false, label: 'Duration (hh:mm)', width: "50px"},
    {id: 'status', align: "left", disablePadding: false, label: 'Status', width: "60px"}
];

const callMetricsHeader1 = [
    {
        id: 'call_status', align: "center", disablePadding: true, label: 'Status', width: "30px", rowSpan: 2,
        showSortIcon: false,
        showAsTrafficLight: [{color:'green', value: 'In-Progress'},{color:'yellow', value: 'Paused'}]
    },
    {id: 'account_id', align: "left", disablePadding: true, label: 'Account', width: "45px", rowSpan: 2, tooltip: "account_name"},
    {id: 'program_name', align: "left", disablePadding: true, label: 'Program', width: "170px", rowSpan: 2},
    {id: 'call_id', align: "left", disablePadding: true, label: 'Call ID', width: "45px", rowSpan: 2},
    {id: 'throttle_setting', align: "left", disablePadding: true, label: 'Throttle Setting', width: "35px", rowSpan: 2},
    {id: 'ob_called', type: 'number', align: "left", disablePadding: true, label: 'Outbound', width: "50px", rowSpan: 2},
    {id: 'ib_called', type: 'number', align: "left", disablePadding: true, label: 'Inbound', width: "40px", rowSpan: 2},
    {
        id: null,
        align: "center",
        disablePadding: true,
        label: 'Remaining',
        width: "160px",
        colSpan: 3,
        groupNumber: 1
    },
    {
        id: 'total_recipients',
        type: 'number',
        align: "left",
        disablePadding: false,
        label: 'Total Recipients',
        width: "50px",
        rowSpan: 2
    },
    {id: 'recipients_swept', type: 'number', align: "left", disablePadding: false, label: 'Swept', width: "40px", rowSpan: 2},
    {id: 'transfer_count', type: 'number', align: "left", disablePadding: false, label: 'Transfers', width: "50px", rowSpan: 2},
    {id: 'transfer_rate', align: "left", disablePadding: false, label: 'Transfers %', width: "60px", rowSpan: 2, showAfterValue: '%'},
];

const callMetricsHeader2 = [
    {id: 'recipient_remaining_att_1', type: 'number', align: "center", disablePadding: false, label: 'Att 1', width: "40px", groupNumber: 1},
    {id: 'recipient_remaining_att_2', type: 'number', align: "center", disablePadding: false, label: 'Att 2', width: "40px", groupNumber: 1},
    {id: 'recipient_remaining_att_3', type: 'number', align: "center", disablePadding: false, label: 'Att 3', width: "40px", groupNumber: 1},
];

function sumUp(objectArray, fieldName) {
    return objectArray.reduce(function (accumulator, currentValue) {
        return accumulator + parseInt(isNaN(currentValue[fieldName]) ? 0: currentValue[fieldName]);
    }, 0)
}

function createImportSummaryData(importDataMetrics) {
    const totalReceived = sumUp(importDataMetrics, 'rows_received');
    const totalAccepted = sumUp(importDataMetrics, 'rows_accept');
    const totalImported = sumUp(importDataMetrics, 'rows_imported');
    const totalReject = sumUp(importDataMetrics, 'rows_reject');
    let rejectRate = 0;
    if (totalReceived !=0) {
        rejectRate = (1.0 * totalReject) / totalReceived;
        rejectRate = Math.round(rejectRate * 10000)/100;
    }
    return [
        {
            name: 'Files Imported',
            type: 'number',
            value_data: importDataMetrics.length,
            percent_data: null
        },
        {
            name: 'Total Recipients Accepted',
            type: 'number',
            value_data: totalAccepted,
            percent_data: null
        },
        {
            name: 'Total Recipients Imported',
            type: 'number',
            value_data: totalImported,
            percent_data: null
        },
        {
            name: 'Total Recipients Rejected',
            value_data: totalReject,
            percent_data: rejectRate
        }
    ]
}

function createCallProgramsSummaryData(callProgramsMetric) {
    const totalRecipients = sumUp(callProgramsMetric, 'total_recipients');
    const recipientsAtt1 = sumUp(callProgramsMetric, 'recipient_remaining_att_1');
    const recipientsAtt2 = sumUp(callProgramsMetric, 'recipient_remaining_att_2');
    const recipientsAtt3 = sumUp(callProgramsMetric, 'recipient_remaining_att_3');
    const recipientsSwept = sumUp(callProgramsMetric, 'recipients_swept') ;
    const recipientsTransfers = sumUp(callProgramsMetric, 'transfer_count');
    const totalCallsMade = sumUp(callProgramsMetric, 'total_calls_made_cnt');
    let recipientsTransferRate = 0.00;
    if (totalCallsMade !=0) {
        recipientsTransferRate = (1.0 * recipientsTransfers) / totalCallsMade;
        recipientsTransferRate = Math.round(recipientsTransferRate * 10000)/100;
    }

    return [
        {
            name: 'Recipients Called',
            type: 'number',
            value_data: totalRecipients,
            percent_data: null
        },
        {
            name: 'Remaining - Att 1',
            type: 'number',
            value_data: recipientsAtt1,
            percent_data: null
        },
        {
            name: 'Remaining - Att 2',
            value_data: recipientsAtt2,
            percent_data: null
        },
        {
            name: 'Remaining - Att 3',
            type: 'number',
            value_data: recipientsAtt3,
            percent_data: null
        },
        {
            name: 'Recipients Swept',
            type: 'number',
            value_data: recipientsSwept,
            percent_data: null
        },
        {
            name: 'Transfers',
            value_data: recipientsTransfers,
            percent_data: recipientsTransferRate
        }
    ]
}

function AccountList ({account_ids}) {
    const classes = useStyles();
    return (
            <Grid
                className={classes.accountList}
                container
                direction="row"
                alignItems="baseline"
            >
                <Box fontWeight="light" fontWeight="fontWeightLight">
                    {`Accounts: ${account_ids}`}
                </Box>
            </Grid>
    )
}

function RefreshDownloadBackMenus({faSpinClassName, handleRefreshClick, handleDownloadClick,cancelCallback }) {
    const classes = useStyles();
    const current_datetime = new Date()
    const formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate() + "@" + current_datetime.getHours() + ":" + current_datetime.getMinutes() + ":" + current_datetime.getSeconds()

    return (
        <div  className ={classes.topMenus}>
            <Link
                component="button"
                variant="body2"
                onClick={cancelCallback}
            >
                {<KeyboardReturn/>} Cancel&nbsp;&nbsp;&nbsp;
            </Link>
            <Link
                component="button"
                variant="body2"
                onClick={handleDownloadClick}
            >
                {<DownloadIcon/>}
                <DownloadLink
                    style={{textDecoration: 'none'}}
                    label="Download CSV&nbsp;&nbsp;"
                    filename= {`Dashboard-${formatted_date}.csv`}
                    exportFile={handleDownloadClick}
                >
                </DownloadLink>
            </Link>

            <Link
                component="button"
                variant="body2"
                onClick={handleRefreshClick}>
                {<RefreshIcon
                    className={`fa fa-circle-o-notch ${faSpinClassName}`}/>} Refresh&nbsp;&nbsp;
            </Link>
        </div>
    )
}

const convertToDisplayName = (str)=> {
    let o = _.find(IMPORT_TYPES, {value: str})
    if (o)
        return  o.label;
    else
        return `Not Supported Type: (${str})`;
}

function ImportMetrics({ImportMetricColumns, dashboardMetrics, selectedImportType, handleImportTypeChange} ) {
    const classes = useStyles();
    if (!dashboardMetrics)
        return (null);
    const importType = selectedImportType.value;
    let importDataMetrics = dashboardMetrics[0];
    if (importType !== 'all') {
        importDataMetrics = _.filter(importDataMetrics, {import_type: importType})
    }
    // construct display label for import type
    importDataMetrics =  importDataMetrics.map((item)=>{
        item.import_type_label = convertToDisplayName(item.import_type);
        return item;
    });
    let importSummaryData = createImportSummaryData(importDataMetrics);

    return (
        <div className={classes.dataBlockSpace}>
            <table width="100%">
                <tr>
                    <td width="90%">
                        <Typography variant="h6" id="tableTitle" gutterBottom={true}>
                            {"Imports Today"}
                        </Typography>
                    </td>
                    <td>
                        <div style={{width: 180, marginBottom: 10}}>
                            <ReactSelect
                                key={`importsTypes`}
                                className="basic-single"
                                classNamePrefix="select"
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isRtl={false}
                                isSearchable={true}
                                name="importsTypes"
                                isMulti={false}
                                value =  {selectedImportType}
                                options={IMPORT_TYPES}
                                onChange ={handleImportTypeChange}
                                styles={customSelect2Styles}
                            />
                        </div>
                    </td>
                </tr>
            </table>

            <MuDataBox title={"Imports Today"} rows={importSummaryData}/>
            <MuTable headCells={ImportMetricColumns} rows={ importDataMetrics}/>
        </div>
    )
}

function CallProgramsMetrics({callMetricsHeader1,callMetricsHeader12, dashboardMetrics, selectedProgramTimeValue, handleChange} ) {
    const classes = useStyles();

    if (!dashboardMetrics)
        return (null)
    const callProgramsMetric = dashboardMetrics[1];
    const programsSummaryData = createCallProgramsSummaryData(callProgramsMetric);
    return (
        <div className={classes.dataBlockSpace}>
            <table width="100%">
                <tr>
                    <td width="90%">
                        <Typography variant="h6" id="tableTitle">
                            {"Call Programs"}
                        </Typography>
                    </td>
                    <td>
                        <div style={{display: 'inline-block', whiteSpace: 'nowrap', verticalAlign: 'middle'}}>
                            <Radio name="CallMetricsTime"
                                   checked={selectedProgramTimeValue === 'today'}
                                   value="today"
                                   color="primary"
                                   onChange={handleChange}
                                   inputProps={{ 'aria-label': 'today' }}
                                   label="Today"
                            />Today<span>&nbsp;&nbsp;&nbsp;</span>
                            <Radio name="CallMetricsTime"
                                   checked={selectedProgramTimeValue === 'alltime'}
                                   value="alltime"
                                   color="primary"
                                   onChange={handleChange}
                                   inputProps={{ 'aria-label': 'alltime' }}
                                   label="All Time"
                            />All Time
                        </div>
                    </td>
                </tr>
            </table>
            <MuDataBox title={"Call Programs Metrics"} rows={programsSummaryData}/>
            <MuTable headCells={callMetricsHeader1} headCells2={callMetricsHeader2}
                     rows={callProgramsMetric}/>
        </div>
    )
}

function exportSummaryData(SummaryData) {
    let fileContent = '';
    fileContent += SummaryData.map((item)=> {
        if (item.percent_data !== null) {
            return  `${item.name},${item.name} %`;
        } else {
            return  `${item.name}`;
        }

    }).join(',') + '\n';

    fileContent += SummaryData.map((item)=> {
        if (item.percent_data !== null) {
            return  `${item.value_data},${item.percent_data} %`;
        } else {
            return item.value_data;
        }
    }).join(',') + '\n\n';

    return fileContent;
}

export default function ViewDashboardReport(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {object, divProps,  cancelCallback} = props;
    const [errorMessage, setErrorMessage] = React.useState();
    const [successMessage, setSuccessMessage] = React.useState();
    const [selectedProgramTimeValue, setSelectedProgramTimeValue] = React.useState('today');
    const [selectedImportType, setSelectedImportType] = React.useState(IMPORT_TYPES[0]);
    const [faSpinClassName, setFaSpinClassName] = React.useState('');

    // global states stored in Redux
    const {dashboardMetrics, currentForm} = useSelector(state => ({
        dashboardMetrics: state.dashboardReducer.dashboardMetrics,
        currentForm: state.formStateReducer.currentForm
    }), shallowEqual);

    const setFormMessage = (successMessage, errorMessage) => {
        setErrorMessage(errorMessage);
        setSuccessMessage();
    }

    const loadData = (type) => {
        setFaSpinClassName('fa-spin');
        dispatch(DashboardActions.loadDashboardMetrics(object.engagement_dashboard_id, type)).then(response => {
            setTimeout(function () {
                setFaSpinClassName('');
            }, 500);
        }).catch(errObject => {
            let msg = errObject.message || errObject;
            setFormMessage(null, "An error occurred loading Dashboard report data. " + msg);
        });
    }

    React.useEffect(() => {
        loadData(selectedProgramTimeValue);
    }, []);

    const handleRefreshClick = () => {
        loadData(selectedProgramTimeValue);
    }


    const handleDownloadClick = () => {
        let fileContent = `# Name: ${object.engagement_dashboard_name}\n`;
        fileContent += `# Accounts: ${object.account_ids}\n\n`;

        const current_datetime = new Date()
        const formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate() + " " + current_datetime.getHours() + ":" + current_datetime.getMinutes() + ":" + current_datetime.getSeconds()

        fileContent += `# Imports Today\n`;
        fileContent += `# Import Type: ${selectedImportType.label}\n`;
        fileContent += `# Run At: ${formatted_date}\n`;
        let filteredImportData = dashboardMetrics[0];

        if (selectedImportType.value !='all')
            filteredImportData = _.filter(dashboardMetrics[0], {import_type: selectedImportType.value});

        // construct display label for import type
        filteredImportData =  filteredImportData.map((item)=>{
            item.import_type_label = convertToDisplayName(item.import_type);
            return item;
        });

        fileContent += `# Import Summary\n`;
        const importSummaryData = createImportSummaryData(filteredImportData);
        fileContent += exportSummaryData(importSummaryData);

        fileContent += `# Import Details\n`;
        fileContent +=ImportMetricColumns.map( function(item) { return item.label}).join(',') + '\n';
        fileContent += filteredImportData.map((item)=>{
            return ImportMetricColumns.map((hdr)=> {
                return `${item[hdr.id]}${hdr.showAfterValue!==undefined && hdr.showAfterValue!== null?hdr.showAfterValue:''}`;
            }).join(',')
        }).join('\n');

        fileContent += `\n\n# Call Programs\n`;
        fileContent += `# Report Type: ${selectedProgramTimeValue}\n`;
        fileContent += `# Call Programs Summary\n`;

        const programsSummaryData = createCallProgramsSummaryData(dashboardMetrics[1]);
        fileContent += exportSummaryData(programsSummaryData);

        let callHdrObjects = [];
        callMetricsHeader1.forEach((item)=> {
            if (item.groupNumber)
                callMetricsHeader2.forEach((hdr2)=>{
                    let hdrClone = {...hdr2};
                    if (hdrClone.groupNumber === item.groupNumber)
                        hdrClone.label = `${item.label} - ${hdrClone.label}`;
                        callHdrObjects.push(hdrClone);
                })
            else
                callHdrObjects.push(item);
        });

        fileContent += `# Call Programs Details\n`;
        fileContent += callHdrObjects.map( function(item) { return item.label}).join(',') + '\n';
        fileContent += dashboardMetrics[1].map((item)=>{
            return callHdrObjects.map((hdr)=> {
                return `${item[hdr.id]}${hdr.showAfterValue!==undefined && hdr.showAfterValue!== null?hdr.showAfterValue:''}`;
            }).join(',')
        }).join('\n');

        return fileContent;
    };

    const handleChange = (event) => {
        setSelectedProgramTimeValue(event.target.value);
        loadData(event.target.value);
    };

    const handleImportTypeChange = (event) => {
        setSelectedImportType(event);
    };

    return (
        <Form width="96%" height="580" readOnly={true}
               successMessage={successMessage}
               errorMessage={errorMessage}
               cancelCallback={cancelCallback}
        >
            <h4 className="page-header">{object.engagement_dashboard_name}</h4>

            <RefreshDownloadBackMenus
                faSpinClassName = {faSpinClassName}
                handleRefreshClick = {handleRefreshClick}
                handleDownloadClick = {handleDownloadClick}
                cancelCallback = {cancelCallback}
            />

            <AccountList account_ids={object.account_ids}/>

            <ImportMetrics
                ImportMetricColumns={ImportMetricColumns}
                dashboardMetrics = {dashboardMetrics}
                selectedImportType = {selectedImportType}
                handleImportTypeChange = {handleImportTypeChange}
            />

            < CallProgramsMetrics
                callMetricsHeader1 = {callMetricsHeader1}
                callMetricsHeader2 = {callMetricsHeader2}
                dashboardMetrics = {dashboardMetrics}
                selectedProgramTimeValue = {selectedProgramTimeValue}
                handleChange ={handleChange}
            />

        </Form>
    )
}
