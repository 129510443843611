import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as formStateActions  from '../../../actions/formStateActions';
import * as cacheActions  from '../../../actions/cacheActions';
import ContentWrap from '../../common/ContentWrap';
import Form from '../../common/Form';
import _ from 'lodash';

class CacheManagement  extends Component {
	constructor(props) {
		super(props);

		this.state = {
			successMessage: null,
			errorMessage: null
		};

		this.refreshCache = this.refreshCache.bind(this);
	}

	setFormState(formType, errorMessage, successMessage, id) {
		if (this.props.formChangeHandler) {
			this.props.formChangeHandler(formType, errorMessage, successMessage, id);
		}
	}

	refreshCache(args) {
		this.props.actions.refreshCache().then(data => {
			this.setState({
				successMessage: "Cache has been refreshed",
				errorMessage: null
			});
		}).catch(errObject => {
			let msg = errObject.message;
			this.setState({
				successMessage: null,
				errorMessage: "An error occurred refreshing the cache: " + msg
			});
		});
	}

	render() {
		let message;
		if (this.props.errorMessage) {
			message = <div className="alert alert-danger">{this.props.errorMessage}</div>;
		} else if (this.props.successMessage) {
			message = <div className="alert alert-success">{this.props.successMessage}</div>;
		} else {
			message = null;
		}

		return (
			<ContentWrap title="Refresh Metadata Cache" successMessage={this.state.successMessage} errorMessage={this.state.errorMessage} noInline={true}>
				<div style={{maxWidth: 1000}}>
					<div>{ message }</div>
					<div style={{ fontSize: 16, fontWeight: "bold", marginTop: 12 }}>Do you want to refresh the metadata cache?</div>
					<br />
					<span style={{ fontSize: 16, marginTop: 12, display: "inline-block" }}>This will not disturb ongoing sessions, unless resources they use (such as APIs or Roles) have been modified or deleted.</span>
					<div style={{ marginTop: 30 }}>
						<button className="btn btn-primary btn-sm" style={{ float: "right" }} id="refreshCacheButton" onClick={ this.refreshCache }>Yes, refresh cache</button>
					</div>
				</div>
			</ContentWrap>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, cacheActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CacheManagement)
