import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as transformationActions  from '../../../actions/transformationActions';
import * as formStateActions  from '../../../actions/formStateActions';
import DataTable  from '../../common/DataTable';
import SimpleForm from '../../common/SimpleForm';
import FormField from '../../common/FormField';
import FormFieldWrapper from '../../common/FormFieldWrapper';
import {isSimpleInteger} from '../../common/Utils';
import _ from 'lodash';

const SYNTHETIC_ID_PREFIX = ":sid:"
const SYNTHETIC_ID_SPLITTER = "_:sid:"
const SYNTHETIC_ID_DB_PREFIX = ":sid:db:"
const SYNTHETIC_ID_NEW_PREFIX = ":sid:new:"
const SYNTHETIC_ID_KEY = "_synthetic_id";

const OUTPUT_TYPE_STRING = "String";
const OUTPUT_TYPE_INTEGER = "Integer";
const OUTPUT_TYPE_OPTIONS = [
	{id: OUTPUT_TYPE_INTEGER, output_type: OUTPUT_TYPE_INTEGER},
	{id: OUTPUT_TYPE_STRING, output_type: OUTPUT_TYPE_STRING}
]

const MAP_FROM_COL_DEFINITION = {
	name: "Map From", type: "textInput", id: "_synthetic_id",
	width: 255, textInputWidth: 11, maxLength: 100,
	required: true, noempty: true,
	noaftertag: true, used: 1
}
const MAP_TO_COL_DEFINITION = {
	name: "Map To", type: "textInput", id: "_synthetic_id",
	width: 255, textInputWidth: 11, maxLength: 100,
	required: true, noempty: true,
	noaftertag: true, used: 1
}

const MAP_TO_COL_DEFINITION_INTEGER = _.assign({}, MAP_TO_COL_DEFINITION, {type: "integer"})

function mappingTableColumnDefinitions(details) {
	if (details && details.output_type === OUTPUT_TYPE_INTEGER) {
		return {map_from: MAP_FROM_COL_DEFINITION, map_to: MAP_TO_COL_DEFINITION_INTEGER}
	}
	return {map_from: MAP_FROM_COL_DEFINITION, map_to: MAP_TO_COL_DEFINITION}
}

function plugSyntheticId(mapping) {
	if (!mapping || SYNTHETIC_ID_KEY in mapping) {
		return mapping;
	}
	return _.assign({}, mapping, {[SYNTHETIC_ID_KEY]: SYNTHETIC_ID_DB_PREFIX + mapping.mapping_id});
}

function unplugSyntheticId(mapping) {
	if (!mapping) {
		return mapping;
	}
	return _.omit(mapping, SYNTHETIC_ID_KEY);
}

function withSyntheticId(mappings) {
	if (!mappings) {
		return mappings;
	}
	return _.map(mappings, plugSyntheticId);
}

function withDefaults(details) {
	if (!details) {
		return details;
	}
	if (!details.output_type) {
		return _.assign({}, details, {output_type: OUTPUT_TYPE_STRING});
	}
	return details;
}

class TransformationMappingForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			details: withDefaults(props.details),
			mappings: withSyntheticId(this.props.mappings),
			modified: false,
			newMappingIdSeq: 1,
			successMessage: props.currentForm.successMessage,
			formErrorMessage: props.currentForm.errorMessage
		};

		this.componentWidth = 1000;
		this.componentHeight = 580;

		this.setFormSuccessMessage = this.setFormSuccessMessage.bind(this);
		this.setFormErrorMessage = this.setFormErrorMessage.bind(this);
		this.setFormConfirmMessage = this.setFormConfirmMessage.bind(this);
		this.resetFormMessages = this.resetFormMessages.bind(this);

		this.onDetailChange = this.onDetailChange.bind(this);
		this.onMappingChange = this.onMappingChange.bind(this);

		this.addMappingRow = this.addMappingRow.bind(this);
		this.confirmRemoveMappingRow = this.confirmRemoveMappingRow.bind(this);
		this.removeMappingRow = this.removeMappingRow.bind(this);

		this.confirmCancelCallback = this.confirmCancelCallback.bind(this);
		this.cancelCallback = this.cancelCallback.bind(this);
		this.saveCallback = this.saveCallback.bind(this);
	}

	setFormSuccessMessage(successMessage) {
		this.setState({
			successMessage: successMessage,
			formErrorMessage: null,
			formConfirmMessage: null
		});
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			successMessage: null,
			formErrorMessage: errorMessage,
			formConfirmMessage: null
		});
	}

	setFormConfirmMessage(confirmMessage) {
		this.setState({
			successMessage: null,
			formErrorMessage: null,
			formConfirmMessage: confirmMessage
		});
	}

	resetFormMessages() {
		this.setState({
			successMessage: null,
			formErrorMessage: null,
			formConfirmMessage: null
		});
	}

	onDetailChange(event, change) {
		this.setState((state, props) => (
			{
				details: _.assign({}, state.details, change),
				modified: true,
				successMessage: null,
				formErrorMessage: null,
				formConfirmMessage: null
			}
		));
	}

	onMappingChange(event, change) {
		const finalChange = {};
		let syntheticId;
		for (const key in change) {
			let [fieldName, partialSyntheticId] = key.split(SYNTHETIC_ID_SPLITTER);
			finalChange[fieldName] = change[key];
			syntheticId = SYNTHETIC_ID_PREFIX + partialSyntheticId;
		}
		this.setState((state, props) => (
			{
				mappings: _.map(state.mappings, item => {
					if (item[SYNTHETIC_ID_KEY] === syntheticId) {
						return _.assign({}, item, finalChange);
					}
					return item;
				}),
				modified: true,
				successMessage: null,
				formErrorMessage: null,
				formConfirmMessage: null
			}
		));
	}

	addMappingRow() {
		this.setState((state, props) => (
			{
				mappings: _.concat(state.mappings,
					{
						[SYNTHETIC_ID_KEY]: SYNTHETIC_ID_NEW_PREFIX + state.newMappingIdSeq,
						mapping_id: 0,
						map_from: "",
						map_to: ""
					}
				),
				modified: true,
				newMappingIdSeq: state.newMappingIdSeq + 1
			}
		));
	}

	confirmRemoveMappingRow(event) {
		if (!event.target) {
			this.setFormErrorMessage("I'm sorry, there was an error trying to delete the mapping");
			window.scrollTo(0, 0);
			return;
		}
		let syntheticId = event.target.getAttribute("id");
		let mapping = _.find(this.state.mappings, item => item[SYNTHETIC_ID_KEY] === syntheticId);
		if (!mapping) {
			this.setFormErrorMessage("I'm sorry, there was an error trying to delete the mapping");
			window.scrollTo(0, 0);
			return;
		}
		if (mapping.mapping_id === 0 && !mapping.map_from && !mapping.map_to) {
			this.removeMappingRow(syntheticId);
			return;
		}
		let confirmMessage = `Are you sure you want to delete the mapping (${mapping.map_from || "<blank>"} => ${mapping.map_to || "<blank>"})?`;
		let formConfirmMessage = (
			<div>
				<span>{confirmMessage}</span>
				<button onClick={() => this.removeMappingRow(syntheticId)}>Yes</button>
				<button onClick={this.resetFormMessages}>No</button>
			</div>
		);
		this.setFormConfirmMessage(formConfirmMessage);
		window.scrollTo(0, 0);
	}

	removeMappingRow(syntheticId) {
		this.setState((state, props) => (
			{
				mappings: _.reject(state.mappings, item => item[SYNTHETIC_ID_KEY] === syntheticId),
				modified: true,
				successMessage: 'Successfully removed the mapping',
				formErrorMessage: null,
				formConfirmMessage: null
			}
		));
	}

	confirmCancelCallback() {
		if (this.state.modified) {
			let formConfirmMessage = (
				<div>
					<span>Are you sure you want to leave the Data Transformation page? You've made unsaved changes.</span>
					<button onClick={this.cancelCallback}>Yes</button>
					<button onClick={this.resetFormMessages}>No</button>
				</div>
			);
			this.setFormConfirmMessage(formConfirmMessage);
			window.scrollTo(0, 0);
			return;
		} else {
			this.cancelCallback();
		}
	}

	cancelCallback() {
		if (this.props.cancelCallback) {
			this.props.cancelCallback(true);
		}
	}

	validateDetails() {
		const details = this.state.details;
		if (!details || !details.name) {
			this.setFormErrorMessage("Name cannot be empty");
			document.getElementById("name").focus();
			return false;
		}
		return true;
	}

	validateMappings() {
		const output_type = this.state.details.output_type;
		const mappings = this.state.mappings;
		let errMsg, errField, errMapping;
		for (let idx in mappings) {
			let mapping = mappings[idx];
			if (!mapping.map_from) {
				errMsg = "Map From cannot be empty";
				errField = "map_from";
				errMapping = mapping;
				break;
			}
			if (!mapping.map_to) {
				errMsg = "Map To cannot be empty";
				errField = "map_to";
				errMapping = mapping;
				break;
			}
			if (output_type == OUTPUT_TYPE_INTEGER && !isSimpleInteger(mapping.map_to)) {
				errMsg = "Map To must be an integer";
				errField = "map_to";
				errMapping = mapping;
				break;
			}
		}
		if (errMsg) {
			this.setFormErrorMessage(errMsg);
			const htmlElem = errField && errMapping ? document.getElementById(errField + "_" + errMapping[SYNTHETIC_ID_KEY]) : null;
			if (htmlElem) {
				htmlElem.focus();
			} else {
				window.scrollTo(0, 0);
			}
			return false;
		}
		return true;
	}

	validate() {
		return this.validateDetails() && this.validateMappings();
	}

	saveCallback() {
		const validateResult = this.validate();
		if (!validateResult) {
			return;
		}
		let detailsSavePromise;
		if (!this.state.details.api_link_transform_rule_id) {
			detailsSavePromise = this.props.actions
				.addCustomTransformation(this.state.details)
				.then(response => response.api_link_transform_rule_id);
		} else {
			detailsSavePromise = this.props.actions
				.updateCustomTransformation(this.state.details)
				.then(response => this.state.details.api_link_transform_rule_id);
		}
		detailsSavePromise
			.then(api_link_transform_rule_id => {
				let mappings = _.map(this.state.mappings, unplugSyntheticId);
				return this.props.actions
					.updateCustomTransformationMappings(api_link_transform_rule_id, mappings)
					.then(response => api_link_transform_rule_id);
			}).then(api_link_transform_rule_id => {
				if (this.props.onSave) {
					this.props.onSave(api_link_transform_rule_id, "Successfully saved Transformation details");
				} else {
					this.cancelCallback();
				}
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred saving Transformation details: " + msg);
				window.scrollTo(0, 0);
			});
	}

	componentDidMount() {
		if (this.props.api_link_transform_rule_id > 0) {
			if (!this.state.details) {
				this.props.actions
					.loadCustomTransformationDetails(this.props.api_link_transform_rule_id)
					.catch(errObject => {
						let msg = errObject.message || errObject;
						this.setFormErrorMessage(`An error occurred loading Transformation details: ${msg}`);
					});
			}
			if (!this.state.mappings) {
				this.props.actions
					.loadCustomTransformationMappings(this.props.api_link_transform_rule_id)
					.catch(errObject => {
						let msg = errObject.message || errObject;
						this.setFormErrorMessage(`An error occurred loading Transformation mappings: ${msg}`);
					});
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.api_link_transform_rule_id > 0) {
			let newState = {};
			if (!this.state.details && this.props.details) {
				newState.details = withDefaults(this.props.details);
			}
			if (!this.state.mappings && this.props.mappings) {
				newState.mappings = withSyntheticId(this.props.mappings);
			}
			if (!_.isEmpty(newState)) {
				this.setState(newState);
			}
		}
	}

	componentWillUnmount() {
		this.props.actions.resetTransformationDetails();
	}

	render() {
		let details = this.state.details || {};
		let inUse = !!details.in_use;
		let hasAllData = this.state.details && this.state.mappings;
		let readOnly = this.props.readOnly || inUse || !hasAllData;
		let inputWidth = 7;
		let labelWidth = 4;
		let disableSort = !readOnly;
		return (
			<div>
				<SimpleForm title={this.props.title} currentForm={this.props.currentForm}
					readOnly={readOnly}
					width={this.componentWidth} height={this.componentHeight}
					successMessage={this.state.successMessage}
					errorMessage={this.state.formErrorMessage}
					confirmMessage={this.state.formConfirmMessage}
					cancelCallback={this.confirmCancelCallback}
					saveCallback={this.state.details ? this.saveCallback: null}
					saveButtonLabel="Save">

					{
						!inUse ? null :
							<div class="alert alert-info" role="alert">
								This Transformation is in use
							</div>
					}

					<FormField.Input fieldid="name" fieldName="name"
						fieldvalue={details.name}
						maxLength={500} width={inputWidth}
						label="Name" labelWidth={labelWidth}
						readOnly={readOnly} noempty={true} required={true} noAfterTag={true}
						fieldchangecallback={this.onDetailChange} />
					<FormField.TextArea fieldid="description" fieldName="description"
						fieldvalue={details.description}
						maxLength={1000} width={7} rows={3}
						label="Description" labelWidth={labelWidth}
						readOnly={readOnly} noAfterTag={true}
						fieldchangecallback={this.onDetailChange} />
					<FormField.Select fieldid="output_type" fieldName="output_type"
						fieldvalue={details.output_type || OUTPUT_TYPE_STRING}
						options={OUTPUT_TYPE_OPTIONS} optionid="id"
						label="Output Data Type" width="2" labelWidth={labelWidth}
						readOnly={readOnly} noempty={true} required={true} noCaseChange={true}
						fieldchangecallback={this.onDetailChange} />

					<br /><br />
					<h5 className="page-header" style={{"display": "block"}}>Mappings</h5>
					<FormFieldWrapper.DataTableForm
						readOnly={readOnly}
						id={"_synthetic_id"}
						columns={mappingTableColumnDefinitions(details)}
						dataItems={this.state.mappings}
						initialSortKey="fake_key"
						addRowCallback={readOnly ? null : this.addMappingRow}
						removeRowCallback={this.confirmRemoveMappingRow}
						fieldchangecallback={this.onMappingChange} />
				</SimpleForm>
			</div>
		)
	}
}

function mapStateToProps(state, ownProps) {
	let transformRuleId = ownProps.api_link_transform_rule_id;
	let details, mappings;
	if (transformRuleId) {
		let key = 'ID_' + transformRuleId;
		details = state.transformationReducer.customTransformationDetails[key];
		mappings = state.transformationReducer.customTransformationMappings[key];
	} else {
		details = {
			api_link_transform_rule_id: 0,
			name: "",
			description: "",
			output_type: OUTPUT_TYPE_STRING
		};
		mappings = [];
	}

	return {
		currentForm: state.formStateReducer.currentForm,
		details: details,
		mappings: mappings
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, transformationActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TransformationMappingForm)
