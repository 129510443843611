import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as emaildomainActions  from '../../../actions/emaildomainActions';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import _ from 'lodash';

class EmailDomainForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			formError: null,
			filterText: '',
			initialSortKey: 'account_name'
		};
	}

	componentDidMount() {
		let obj = this.props.object;
		if (obj && obj.email_domain_id) {
			this.props.actions.loadEmailDomainAccounts(obj.email_domain_id).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the email domain accounts: " + msg);
			});
		}
	}

	componentWillUnmount() {
		;
	}

	_onChange() {
		// repaint the form when data loads
		if (this.isMounted()) {
			this.forceUpdate();
		}
	}

	viewAssignedAccounts() {
		let accountTagTextFunction = function(obj) {
			return obj.account_name + ' (' + obj.account_id + ')';
		};
		let assignedAccounts = this.props.emaildomainAccounts;
		let aryAssignedAccounts = _.values(assignedAccounts);

		if (this.props.readOnly) {
			return (
				<div className="form-group row">
					<label className="col-form-label col-md-2" style={{lineHeight: "16px"}}>Accounts</label>
					<div className="form-control-plaintext col-md-10">
						<FormField.AssignedTagList
							objects={ aryAssignedAccounts }
							keyField="account_id"
							tagText={ accountTagTextFunction }
							sortField="account_name"
							readOnly={this.props.readOnly}
							emptyMessage="No account assigned"/>
					</div>
				</div>
			);
		}

		return null;
	}

	render() {
		let defaultDomainValue = (this.props.object && "email_domain_url" in this.props.object) ? this.props.object.email_domain_url : "";
		return (
			<Form {...this.props} width="1000" height="580" >
				<FormField.Input dontModify= {this.props.readOnly} fieldid="email_domain_url" fieldvalue={ defaultDomainValue } width="6" maxLength={255} label="Email Domain" labelWidth="2" maxWidth = "20" required = {true} />
					{ this.viewAssignedAccounts() }
			</Form>
		);
	}
}

function mapStateToProps(state, ownProps) {
	// FIREFOX BUG WORKAROUND
	// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
	// It doesn't like the number 1 as a computed key in an object, even once it's stringified
	return {
		emaildomainAccounts: state.emailDomainReducer.accountEmaildomains['ID_' + ownProps.object.email_domain_id]
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, emaildomainActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailDomainForm)
