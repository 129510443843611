import axios from 'axios';
import * as constants from '../constants';

//export default appHistory;

// // Add a request interceptor
axios.interceptors.request.use(function (config) {
    config.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem('atk');
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

const  delete_cookie = function(name) {
    sessionStorage.removeItem('atk');
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
// // Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response.data;
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});

 axios.defaults.baseURL = constants.baseURL;

/**
 * Create an Axios Client with defaults
 */
// const client = axios.create({
//     baseURL: "https://api.slc.silverlink.com"
// });
//

/**
 * Request Wrapper with default success/error actions
 */
const axiosApi = function(options) {

    const onSuccess = function(response) {
        if(response.data === 401) {
            //<Redirect to="/login" push />
            delete_cookie("atk");
           return Promise.reject(response.data.data.error + " " + response.data.data.message);
        } else {
            return response.data;
        }
    }

    const onError = function(error) {
        console.error('Request Failed:', error.config);

        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            console.error('Status:',  error.response.status);
            console.error('Data:',    error.response.data);
            console.error('Headers:', error.response.headers);

        } else {
            // Something else happened while setting up the request
            // triggered the error
            console.error('Error Message:', error.message);
        }


        if(error.response.data.code === 401) {
            delete_cookie("atk");
        }
        return Promise.reject(error.response.data || {message: error.message});
    }

    return axios(options)
        .then(onSuccess)
        .catch(onError);
}

export default axiosApi;