import React from 'react';
import PropTypes from 'prop-types';
import { lighten,withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutline from '@material-ui/icons/HelpOutlineRounded';
import {numberWithCommas} from '../common/Utils'

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    dataValue: {
        flex: '1 1 100%',
        fontSize: 30,
        fontWeight: 'bold',
        color: '#000000',
        verticalAlign: 'center'
    },
    dataTitle: {
        fontSize: 14,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 15,
        paddingTop: 10
    },
}));

const styleForIcon = {
    width: '2.5vw',
    height: '2.5vh',
    color: "grey"
};

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export default function MuDataBox(props) {
    const classes = useToolbarStyles();
    const {title, rows} = props;

    return (
            <div style={{width: '100%'}}>
                <Box key={title} display="flex" flexWrap={rows.length>6? "wrap":"nowrap"} justifyContent="flex-start" m={0} p={0} bgcolor="background.paper">
                    {rows.map((row, index) => {
                        return (
                            <Box key={row.name}  className={classes.dataTitle} width={1/(rows.length >6 ? 6.3 : 3)} bgcolor="#f2f2f2">
                                {row.name}
                                <Typography className={classes.dataValue} variant="h6" id="tableTitle">
                                    {(row.type === 'number')? numberWithCommas(row.value_data): row.value_data} {(row.percent_data !== null)?<span fontSize="5" style={{verticalAlign: 'middle'}}>({row.percent_data}%)</span>:null}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            </div>
    );
};

MuDataBox.propTypes = {
    title: PropTypes.string,
    rows: PropTypes.array.isRequired
};

