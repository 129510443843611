import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as DashboardActions  from '../../../actions/dashboardActions';
import * as formStateActions  from '../../../actions/formStateActions';
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';
import NewDashboard  from './NewDashboard';
import ViewDashboardReport from './ViewDashboardReport';
import ConfirmForm from '../../common/ConfirmForm';
import _ from 'lodash';

class DashboardListingPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filterText: '',
			initialSortKey: 'Created Date',
			errorMessage: null,
			successMessage: null,
			currentForm: null,
			currentPage: 1,
			rowsPerPage: 50
		};

		/*
			These binds are used to access the functions in Dashboards.js from sub forms.
			For example:
			Suppose you add an API Link. Now you want to relist the front page.
			Without the calls below, you couldn't access the takeDownForm function
			from the addDashboardCallback with passing it along to the sub form.
			Here we just bind the parent form context to that function.
		*/
		this.handleUserInput = this.handleUserInput.bind(this);
		this.setFormState = this.setFormState.bind(this);
		this.cancelForm = this.cancelForm.bind(this);
		this.takeDownForm = this.takeDownForm.bind(this);
		this.deleteDashboardCallback = this.deleteDashboardCallback.bind(this);
		this.newDashboardClicked = this.newDashboardClicked.bind(this);
		this.clone = this.clone.bind(this);
	}

	getColumns() {
		return {
			engagement_dashboard_id : { name: "Id", used:0, width: 160, required: false },
			engagement_dashboard_name: { name: "Name", used: 1, width: 180, click_action: "View", required: true },
			description:	 { name: "Description", used: 1, width: 180, type: "String", values: [1] },
			account_ids: { name: "Accounts", used: 1, width: 200, required: false },
			created_date_time: { name: "Created Date", used: 1, width: 60, required: false }
		};
	}

	getFieldIds() {
		return _.keys(this.getColumns());
	}

	getRequiredFieldIds() {
		return _.keys(_.pickBy(this.getColumns(), 'required'));
	}

	handleUserInput(filterText) {
		this.setState({
			filterText: filterText
		});
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			currentForm: nextProps.currentForm
		});
	}

	componentDidMount() {
		this.props.actions.loadDashboards().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the dashboard " + msg);
		});
	}

	componentWillUnmount() {
	}

	_onChange() {
		this.setState({
			errorMessage: null
		});
	}

	newDashboardClicked() {
		this.setFormState("insert-Dashboard", null,null,null);
	}

	deleteDashboardCallback(fields) {
		this.props.actions.deleteDashboard(fields.engagement_dashboard_id).then(data => {
			this.takeDownForm(null);
			this.props.actions.loadDashboards().then(data => {
				this.setFormSuccessMessage(`Successfully deleted engagement dashboard`);
			}).catch(errObject => {
				this.takeDownForm(null);
				this.setFormErrorMessage("An error occurred loading the dashboard please try again");
			});
		}).catch(errObject => {
			this.setFormErrorMessage("An error occurred deleting the dashboard please try again");
		});
	}

	setFormState(formType, errorMessage, successMessage, id) {
		if (this.props.actions.formChangeHandler)
			this.props.actions.formChangeHandler(formType, errorMessage, successMessage, id);
			this.setState({
				errorMessage: null,
				successMessage: null
			});
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			successMessage: null,
			errorMessage: errorMessage
		});
	}

	setFormSuccessMessage(successMessage) {
		this.setState({
			successMessage: successMessage,
			errorMessage: null
		});
	}

	takeDownForm(successMessage) {
		this.setFormState(null, null, successMessage, null);
		window.scrollTo(0, 0);
	}

	clearErrorMessage() {
		this.setState({
			errorMessage: null
		});
	}

	cancelForm() {
		this.takeDownForm(null);
		this.clearErrorMessage();
	}
	clone(errorMessage, successMessage, id)
	{
		this.props.actions.cloneDashboard(id).then(data => {
			this.props.actions.loadDashboards().then(data => {
				this.setFormSuccessMessage(`Successfully cloned the Engagement Dashboard`);
			}).catch(errObject => {
				this.takeDownForm(null);
				this.setFormErrorMessage("An error occurred loading the Engagement Dashboards please try again");
			});
		}).catch(errObject => {
			this.setFormErrorMessage("An error occurred cloning the Engagement Dashboard please try again");
		});
	}

	render() {
		let currentForm, object;
		let allDashboards = this.props.Dashboards;
		if (!allDashboards) {
			return null;
		}

		let errMsg = this.state.errorMessage;
		let successMsg = this.state.successMessage;

		let addNewDashboardTitle;
		let buttonList;

		addNewDashboardTitle = "New Dashboard";
		buttonList = [	{ label: "View", icon: "info", callback: _.wrap("show-Dashboard", this.setFormState) },
						{ label: "Edit", icon: "edit", callback: _.wrap("edit-Dashboard", this.setFormState) },
						{ label: "Clone", icon: "clone", callback: this.clone },
						{ label: "Delete", icon: "trash", callback: _.wrap("delete-Dashboard", this.setFormState) }
					];

		switch (this.props.currentForm && this.props.currentForm.formType) {
			case "insert-Dashboard":
				object = {engagement_dashboard_id: 0};
				currentForm = <NewDashboard title="Create a new Dashboard" readOnly={false} object={object}
									errorMessage={errMsg}
									cancelCallback={this.cancelForm}/>;
				break;
			case "show-Dashboard":
				object = allDashboards ? _.find(allDashboards, {engagement_dashboard_id: this.props.currentForm.currentId}) : {};
				if (object) {
					currentForm = <ViewDashboardReport hideTitle= {true} readOnly={true} object={object}
													   errorMessage={errMsg}
													   cancelCallback={this.cancelForm}/>;
				}
				break;
			case "edit-Dashboard":
				object = allDashboards ? _.find(allDashboards, {engagement_dashboard_id: this.props.currentForm.currentId}) : {};
				if (object) {
					currentForm = <NewDashboard title={`Edit Dashboard: ${object.engagement_dashboard_name}`} readOnly={false} object={object}
										cancelCallback={this.cancelForm}/>;
				}
				break;
			case "delete-Dashboard":
				object = allDashboards ? _.find(allDashboards, {engagement_dashboard_id: this.props.currentForm.currentId}) : {};
				currentForm = <ConfirmForm title="Delete Dashboard" text={`Are you sure you want to permanently remove dashboard ${object.engagement_dashboard_name} ?`}
										   args={object} errorMessage={errMsg}
										   confirmCallback={this.deleteDashboardCallback} cancelCallback={this.cancelForm}/>;
				break;
		}

		return (
			<ContentWrap title={this.props.title || "Engagement Dashboards"} successMessage={this.state.successMessage} errorMessage={this.state.errorMessage} form={currentForm}>
				<div className="search">
					<TableSearch
						onUserInput={ this.handleUserInput }
						filterText={ this.state.filterText }>
					</TableSearch>
					<div className="float-right form-inline">
						<button className="btn btn-primary btn-sm searchBtn" id="add_new_dashboard" title="Add Dashboard" onClick={this.newDashboardClicked}>{addNewDashboardTitle}</button>
					</div>
				</div>
				<div className="clearfix"></div>
				<DataTable
					id="engagement_dashboard_id"
					columns={ this.getColumns() }
					dataItems={ allDashboards }
					rowsPerPage={ this.state.rowsPerPage }
					filterText={ this.state.filterText }
					initialSortKey={ this.state.initialSortKey }
					buttons={ buttonList }>
					ascending={false}
				</DataTable>
			</ContentWrap>

		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		Dashboards: state.dashboardReducer.dashboards,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, DashboardActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardListingPage)
