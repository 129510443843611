import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as vendorActions  from '../../../actions/vendorActions';
import * as emaildomainActions  from '../../../actions/emaildomainActions';
import * as formStateActions  from '../../../actions/formStateActions';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import _ from 'lodash';

class VendorEmaildomainForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentVendorAccountToAdd: {EmailDomainId: null},
			applications: null,
			applicationErrorMessage: null,
			errorMessage: null,
			successMessage: null
		};

		this._isMounted = false;

		this.removeVendorEmailDomain = this.removeVendorEmailDomain.bind(this);
		this.addVendorEmailDomain    = this.addVendorEmailDomain.bind(this);
		this.accountChange           = this.accountChange.bind(this);
		this.takeDownForm            = this.takeDownForm.bind(this);
		this.setFormErrorMessage     = this.setFormErrorMessage.bind(this);
		this.cancelButtonCallback = this.cancelButtonCallback.bind(this);
	}

	componentDidMount() {
		this.props.actions.loadVendorEmailDomains(this.props.object.VendorAccountID).catch(errObject => {
			var msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the vendor email domains: " + msg);
		});
		this.props.actions.loadAvailableEmailDomains().catch(errObject => {
			var msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the available email domains: " + msg);
		});
	}

	componentWillUnmount() {
		;
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			applicationErrorMessage: null,
			errorMessage: errorMessage,
			successMessage: null
		});
	}

	_onChange() {
		if (this.isMounted()) {
			this.setState({
				formError: null
			});
		}
	}

	_onVendorAccountChange() {
	}

	takeDownForm(successMessage) {
		window.scrollTo(0, 0);
	}

	removeVendorEmailDomain(email_domain) {
		var data = {};

		let vendorAccountId = this.props.object.VendorAccountID;
		this.props.actions.removeVendorEmailDomain(vendorAccountId, email_domain).then(data => {
			this.props.actions.loadVendorEmailDomains(this.props.object.VendorAccountID).then(data => {
				this.setState({
					currentVendorAccountToAdd: {
						EmailDomainId: null
					},
					applicationErrorMessage: null,
					errorMessage: null,
					successMessage: `Vendor email domain successfully removed`
				});
				this.takeDownForm(null);
			}).catch(errObject => {
				var msg = errObject.message;
				this.setState({
					applicationErrorMessage: null,
					errorMessage: "An error occurred loading email account domains: " + msg
				});
				this.takeDownForm(null);
			});
			this.props.actions.loadAvailableEmailDomains().catch(errObject => {
				var msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the available email domains: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setState({
				applicationErrorMessage: null,
				errorMessage: "An error occurred adding a vendor email domain: " + msg
			});
		});
	}

	addVendorEmailDomain() {
		let email_domain_id = this.state.currentVendorAccountToAdd.EmailDomainId;
		let vendor_account_id = this.props.object.VendorAccountID;

		var data = {};

		if(!this.state.currentVendorAccountToAdd.EmailDomainId) {
			this.setState({
				applicationErrorMessage: null,
				errorMessage: "Please select a valid email domain first."
			});
			return;
		}

		this.props.actions.addVendorEmailDomain(vendor_account_id, email_domain_id).then(data => {
			this.props.actions.loadVendorEmailDomains(vendor_account_id).then(data => {
				this.takeDownForm(null);
				this.setState({
					currentVendorAccountToAdd: {
						EmailDomainId: null
					},
					applicationErrorMessage: null,
					errorMessage: null,
					successMessage: `Vendor email domain successfully added`
				});
			}).catch(errObject => {
				this.takeDownForm(null);
				var msg = errObject.message;
				this.setState({
					applicationErrorMessage: null,
					errorMessage: "An error occurred loading email domains: " + msg
				});
			});
			this.props.actions.loadAvailableEmailDomains().catch(errObject => {
				var msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the available email domains: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setState({
				applicationErrorMessage: null,
				errorMessage: "An error occurred adding a vendor email domain: " + msg
			});
		});
	}

	cancelButtonCallback()
	{
		this.props.actions.loadVendors().then(successObject => {
			if (this.props.cancelCallback)
				this.props.cancelCallback();
		}).catch(errObject => {
			if (this.props.cancelCallback)
				this.props.cancelCallback();
		});
	}

	accountChange(event, fieldChanges) {
		this.setState({
			currentVendorAccountToAdd: _.extend(this.state.currentVendorAccountToAdd, fieldChanges),
			applicationErrorMessage: null,
			errorMessage: null
		});
	}

	renderAvailable() {
		var availableEmailDomains = this.props.availableEmaildomains;
		var addedEmailDomains = this.props.vendorEmaildomains;
		var aryAddEmailDomains = _.values(addedEmailDomains);

		var tagTextFunction = function(obj) {
			return obj.EmailDomain;
		};

		var availableVendorAccounts = _.filter(availableEmailDomains, function (o) {
			var idx = _.findIndex(aryAddEmailDomains, {EmailDomain: o.EmailDomain});
			return idx == -1;
		}) ;

		var availableData = {
				label: "Email Domain",
				fieldid: "EmailDomainId",
				fieldName: "EmailDomainURL",
				optionid: "EmailDomainId",
				fieldvalue:  this.state.currentVendorAccountToAdd.EmailDomainId ,
				options:  availableVendorAccounts ,
				localFieldChangeCallback:  this.accountChange,
				errorMessage: this.state.applicationErrorMessage,
				assinedCb: this.addVendorEmailDomain
		};
		return (
		<div className="form-group row">
			<label className="col-form-label col-md-2" style={{ lineHeight: "16px"}}>Email Domains</label>
			<div className="form-control-plaintext col-md-10" >
				<FormField.AssignedTagList
					readOnly = { this.props.readOnly }
					keyField = "EmailDomain"
					sortField ="EmailDomain"
					tagText={ tagTextFunction }
					objects = { aryAddEmailDomains }
					removeCallback={ this.removeVendorEmailDomain }
					emptyMessage="No email domain assigned"
					buttonIDPrefix={ "vendor_emaildomain" }
					available = {availableData}>
				</FormField.AssignedTagList>
			</div>
		</div>
		);
	}

	render() {
		var self = this;
		var object = this.props.object;

		return (
			<Form {...this.props} width="800" height="580" cancelCallback={this.cancelButtonCallback} cancelButtonLabel="Done" successMessage={ this.state.successMessage } errorMessage={ this.state.errorMessage }>
				<FormField.Input fieldid="VendorName" width="5" label="Vendor Name" labelWidth="2" dontModify="true" />
				<FormField.Input fieldid="VendorTypeCode" width="5" label="Type Code" labelWidth="2" dontModify="true" />
				<FormField.Input fieldid="AccountName" width="5" label="Account Name" labelWidth="2" dontModify="true" />
				{ this.renderAvailable() }
			</Form>
		);
	}
}


function mapStateToProps(state, ownProps) {
	// FIREFOX BUG WORKAROUND
	// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
	// It doesn't like the number 1 as a computed key in an object, even once it's stringified
	return {
		currentForm: state.formStateReducer.currentForm,
		emaildomains: state.emailDomainReducer.emaildomains,
		availableEmaildomains: state.emailDomainReducer.availableEmaildomains,
		vendorEmaildomains: state.vendorReducer.vendorEmaildomains['ID_' + ownProps.object.VendorAccountID]
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, vendorActions, emaildomainActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorEmaildomainForm)
