import React, { Component } from 'react';
import validator from 'validator';
import {isSimpleInteger} from './Utils'
import _ from 'lodash';

	// for text input fields that aren't just type="text"
var inputTypeMap = {
	"digits": "text",
	"zip": "text",
	"state": "text",
	"integer": "text"
};

var reservedWords = ["select", "insert", "delete", "update", "drop", "alter", "create"];

var formatDate = function(dateString) {
	return dateString.replace(/^(\d\d\d\d-\d\d-\d\d)T/,"$1 ").replace(/^([\d-]* [\d:.]*)Z$/,'$1');
};

class Validator extends Component {
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: null,
			letterCnt: null
		};
	}

	validateType(valueType, value) {
		var msg;
		var lc_fn;
		var lc_ln;

		if (value && valueType === "email") {
		  msg = validator.isEmail(value.trim()) ? null : 'Not a valid email address';
		} else if (value && valueType === "first_name") {
			msg = validator.isAlpha(value.trim()) ? null : 'First Name must be letters';
			if (!msg && valueType === "first_name") {
			  lc_fn = value.trim().toLowerCase();
			  if (reservedWords.indexOf(lc_fn) > -1) {
				msg = 'First Name cannot be reserved word';
			  }
			}
		} else if (value && valueType === "last_name") {
			msg = validator.isAlpha(value.trim()) ? null : 'Last Name must be letters';
			if (!msg && valueType === "last_name") {
			  lc_ln = value.trim().toLowerCase();
			  if (reservedWords.indexOf(lc_ln) > -1) {
				msg = 'Last Name cannot be reserved word';
			  }
			}
		} else if (value && valueType === "zip") {
			msg = /^[0-9]{5}(-[0-9]{4})?$/.test(value) ? null : 'Not a valid Zip code';
		} else if (value && valueType === "state_code") {
			msg = /^[A-Za-z][A-Za-z]$/.test(value) ? null : 'State codes must be two letters';
		} else if (value && valueType === "country_code") {
			msg = /^[A-Za-z][A-Za-z]$/.test(value) ? null : 'Country codes must be two letters';
		} else if (value && valueType === "password") {

		  msg = window.getPasswordError(value);
		} else if (value && valueType === "integer") {
			msg = isSimpleInteger(value) ? null : 'Must be an integer';
		}

		return msg;
	}

	validateEmpty(fieldname, value)
	{
		let msg;
		if (!value)
			msg = `${fieldname} can't be empty`;
		return msg;
	}

	validateChars(value, charArray)
	{
		let msg;
		for (let x = 0, charArrayLen = charArray.length; x < charArrayLen; x++)
		{
			let regEx = new RegExp(charArray[x], 'g');
			if (value.match(regEx) !== null)
				msg = "Input field has invalid characters";
		}
		return msg;
	}
}


class Input extends Validator {
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: null,
			letterCnt: null
		};
		this.textChange = this.textChange.bind(this);
	}

	renderErrorBlock() {
		if (!this.state.errorMessage) return null;
		var labelWidth = this.props.labelWidth || 3;
		return (
			<span className={"error-block col-md-offset-"+labelWidth} style={{clear:"both"}}>{this.state.errorMessage}</span>
		);
	}

	renderHelpBlock() {
		if (!this.props.help || this.props.readOnly) return null;
		var labelWidth = this.props.labelWidth || 3;
		return (
			<span className={"form-text col-md-offset-"+labelWidth} style={{clear:"both"}}>{this.props.help}</span>
		);
	}

	textChange(event) {
		var value = event.target.value;
		var inputType = this.props.type;
		var msg = this.validateType(inputType, value);
		if (this.props.noempty && !msg)
			msg = this.validateEmpty("Input field", value);
		if (this.props.invalidChars && !msg)
			msg = this.validateChars(value, this.props.invalidChars);
		var len = null;
		if(value != null ) {
			len = value.length;
		}
		this.setState({ errorMessage: msg  , letterCnt: len});
		var objectChanges = {};
		objectChanges[this.props.fieldid] = value;
		// this callback is for the specific form
		if (this.props.localFieldChangeCallback) {
			this.props.localFieldChangeCallback(null, objectChanges);
		}
		// this callback is for the global form object
		if (this.props.fieldchangecallback) {
			this.props.fieldchangecallback(null, objectChanges);
		}
	}

	renderControl() {
		var fieldName = this.props.fieldName;
		var dataType = this.props.type || 'string';
		var width = this.props.width || 4;
		var typeString = inputTypeMap[this.props.type] || this.props.type || "text";
		var labelWidth = this.props.labelWidth || 3;
		var maxWidth = this.props.maxWidth || 255;
		var groupClass = this.props.singleline ? 'form-control-inline' : 'form-group row';
		var isRequired = this.props.required && !this.props.readOnly;
		var fieldvalue = this.props.fieldvalue;
		var autocompleteProp, dataTag, afterTag, letterCount;
		var maxLength = this.props.maxLength;

		if (dataType === "date") {
			fieldvalue = formatDate(fieldvalue);
		}

		let valueObj = {};
		if ('defaultFieldValue' in this.props)
			valueObj.defaultValue = this.props.defaultFieldValue;
		else if ('fieldvalue' in this.props)
			valueObj.value = fieldvalue || "";

		let className = 'slform-control col-md-'+width;
		let readOnlyState = false;
		if (this.props.readOnly || this.props.dontModify) {
			readOnlyState = true;
			className += ' form-control-plaintext';
			let skipAfterTag = ("noAfterTag" in this.props && !this.props.noAfterTag);
			if (!skipAfterTag)
				afterTag = (<hr />);
		}
		autocompleteProp = this.props.autoComplete || 'on';

		// The spread operator is the only way to selectively add params
		// Even if we have a value={null}, it'll prevent us from editing a text input
		// Additionally, for some reason fieldvalue shows up even if we set defaultFieldValue and not fieldvalue
		dataTag = (
			<input type={typeString} className={className} ref={fieldName} id={this.props.fieldid||fieldName} key={"input-" + fieldName}
				onChange={this.textChange}
				width={maxWidth}
				maxLength = {maxLength}
				{...valueObj}
				autoComplete={autocompleteProp}
				placeholder={this.props.placeholder || ''}
				readOnly={readOnlyState} />
		);

		if (this.state.errorMessage) {
			groupClass += ' has-error';
		}

		if(this.props.countLetter && this.props.fieldvalue != null) {
			var len = this.props.fieldvalue.length;
			if(this.state.letterCnt != null) {
				len = this.state.letterCnt;
			}
			letterCount = (<span className={'badge col-form-label'}>{ len }</span>);
		}
		// for a discussion of value and defaultValue, controlled and uncontrolled components,
		// see https://facebook.github.io/react/docs/forms.html#interactive-props
		return (
			  <div className={groupClass} key={"control-" + fieldName}>
				{ this.props.label ? <label className={'control-label col-md-' + labelWidth} htmlFor={fieldName}>{this.props.label}</label> : null }
				{ dataTag }
				{ letterCount}
				{ isRequired ? <span className="required-mark">*</span> : null }
				{ this.renderErrorBlock() }
				{ this.renderHelpBlock() }
				{ afterTag }
			  </div>
		);
	}

	renderPartOfSingleLine() {
		return (
			  <div className="form-inline sl-form-inline">
			  	{this.renderControl()}
			  </div>
		);
	}

	render() {
		if (this.props.singleline) {
			return this.renderPartOfSingleLine();
		} else {
			return this.renderControl();
		}
	}
}

class TextArea extends Validator {
	constructor(props) {
		super(props);

		this.state = {"val": null};
		this.textAreaChange = this.textAreaChange.bind(this);
	}

	renderErrorBlock() {
		if (!this.state.errorMessage) return null;
		var labelWidth = this.props.labelWidth || 3;
		return (
			<span className={"error-block col-md-offset-"+labelWidth} style={{clear:"both"}}>{this.state.errorMessage}</span>
		);
	}

	textAreaChange(event) {
		var value = event.target.value;
		var len = null;
		if(value != null ) {
			len = value.length;
		}

		let msg = null;
		if (this.props.noempty)
			msg = this.validateEmpty("Textarea", value);
		if (this.props.invalidChars)
			msg = this.validateChars(value, this.props.invalidChars);

		this.setState({ errorMessage: msg, letterCnt: len});
		var objectChanges = {};
		objectChanges[this.props.fieldid] = value;
		// this callback is for the specific form
		if (this.props.localFieldChangeCallback) {
			this.props.localFieldChangeCallback(null, objectChanges);
		}
		// this callback is for the global form object
		if (this.props.fieldchangecallback) {
			this.props.fieldchangecallback(null, objectChanges);
		}
	}

	render() {
		var fieldName = this.props.fieldName || this.props.fieldid;
		var width = this.props.width || 4;
		var cols = this.props.cols || 3;
		var rows = this.props.rows || 3;
		var labelWidth = this.props.labelWidth || 3;
		var isRequired = this.props.required && !this.props.readOnly;
		var dataTag;
		let className;
		let groupClass = this.props.singleline ? 'form-control-inline' : 'form-group row';
		if (this.props.width)
			className = "col-md-" + width;

		let valueObj = {};
		if ("defaultValue" in this.props)
			valueObj.defaultValue = this.props.defaultValue ? this.props.defaultValue.toString() : "";
		else
			valueObj.value = this.props.fieldvalue ? this.props.fieldvalue : "";

		let readOnlyState = false;
		let afterTag;
		if (this.props.readOnly)
		{
			readOnlyState = true;
			className += ' form-control-plaintext';
			let skipAfterTag = ("noAfterTag" in this.props && !this.props.noAfterTag);
			if (!skipAfterTag)
				afterTag = (<hr />);
		}

		let fieldKey = (fieldName + (this.props.fieldKey ? ("_" + this.props.fieldKey) : ""));

		dataTag = (
			<textarea className={className} ref={fieldName} cols={cols} rows={rows} id={fieldName}
					key={"textarea-" + fieldName + "-" + fieldKey}
					onChange={this.textAreaChange}
					{...valueObj}
					readOnly={readOnlyState}
					placeholder={this.props.placeholder||""}
					maxLength={this.props.maxLength} />
		);

		return (
			<div className={groupClass}>
				{this.props.label ? <label className={"col-form-label col-md-" + labelWidth} htmlFor={fieldName}>{this.props.label}</label> : null}
				{ dataTag }
				{ isRequired ? <span className="required-mark">*</span> : null }
				{ this.renderErrorBlock() }
				{ afterTag }
			</div>
		);
	}
}

// FormField.Select takes an "options" prop that's a javascript object;
// the key is the option value and the value is the display string for the option
class Select extends Validator {
	constructor(props) {
		super(props);

		this.state = {
			val: null,
			errorMessage: this.props.errorMessage
		};
		this.valueChange = this.valueChange.bind(this);
		this.renderOption = this.renderOption.bind(this);
	}

	valueChange(event) {
		var value = event.target.value;
		var objectChanges = {};
		if (this.props.fieldid) {
			objectChanges[this.props.fieldid] = value;
			// For uncontrolled React components, we need to store the current value in state.
			// Otherwise, the only way you can get it is via document.getElementById, which is anti-pattern for react
			// Controlled React components get a new props.fieldvalue every time a value is changed, so no need to store the value in props.
			// See https://reactjs.org/docs/uncontrolled-components.html for more about uncontrolled components
			if ("defaultFieldValue" in this.props)
			{
				this.setState({
					val: value
				});
			}
		}
		// this callback is for the specific form
		if (this.props.localFieldChangeCallback) {
			this.props.localFieldChangeCallback(null, objectChanges);
		}
		// this callback is for the global form object
		if (this.props.fieldchangecallback) {
			this.props.fieldchangecallback(event, objectChanges);
		}
	}

	renderOption(object) {
		var id = object[this.props.optionid || 'id'];
		if (_.isString(id)) {
			var old = id;
			id = this.props.noCaseChange === true ? id : id.toLowerCase();
		}
		return (
			<option key={id} value={id}>{object[this.props.fieldName]}</option>
		);
	}

	 renderErrorBlock() {
		var errorMessage = this.state.errorMessage || this.props.errorMessage;
		if (this.props.noempty)
		{
			let val = this.props.fieldvalue;
			// Note that with Uncontrolled React Components, we need to store the changed value in state in order to access it.
			// Otherwise, the only way to get it is via document.getElementById, which may be anti-pattern for React.
			// Uncontrolled Components are those which use defaultValue, defaultChecked, etc. instead of value and checked.
			// See https://reactjs.org/docs/uncontrolled-components.html for more about uncontrolled components
			if (!val && "defaultFieldValue" in this.props && this.state.val !== null) // if it's using defaultValue and you've changed the initial value
				val = this.state.val;
			errorMessage = this.validateEmpty("Select box", val);
		}
		if (!errorMessage) return null;
		var labelWidth = this.props.labelWidth || 3;
		return (
			<span className={"error-block col-md-offset-"+labelWidth} style={{clear:"both"}}>{errorMessage}</span>
		);
	}

	render() {
		var fieldName = this.props.fieldName;
		var width = this.props.width || 4;
		var labelWidth = this.props.labelWidth || 3;
		var isRequired = this.props.required && !this.props.readOnly;
		var keycount = 1;
		var groupClass = this.props.singleline ? 'form-control-inline' : 'form-group row';
		var sortFunction = (!fieldName) ? _.identity : function(val, key, collection) {
			return val[fieldName];
		};
		var value = this.props.fieldvalue;
		var old =value;
		if (this.state.errorMessage || this.props.errorMessage) {
			groupClass += ' has-error';
		}

		var dataTag, afterTag, noChoice;
		value = value ? value.toString() : value;
		let valueObj = {};
		if ("defaultFieldValue" in this.props)
			valueObj.defaultValue = this.props.defaultFieldValue ? this.props.defaultFieldValue.toString() : null;
		else if (value)
			valueObj.value = this.props.noCaseChange === true ? value : value.toLowerCase();

		if (this.props.readOnly)
		{
			let readOnlyVal = ("defaultFieldValue" in this.props) ? valueObj.defaultValue : valueObj.value;
			let displayVal;
			// If you want to show the value instead of the ID in read-only mode
			if (this.props.findReadOnlyValue)
			{
				if (readOnlyVal)
				{
					for (let x = 0, len = this.props.options.length; x < len; x++)
					{
						if (this.props.options[x][this.props.optionid || 'id'].toString() === readOnlyVal.toString())
						{
							displayVal = this.props.options[x][fieldName];
							break;
						}
					}
				}
			}
			else
				displayVal = readOnlyVal;
			dataTag = (<p className={"form-control-plaintext col-md-" + width} ref={fieldName} >{displayVal}</p>);
			afterTag = (<hr />);
		} else {
			if (this.props.noChoice) {
				noChoice = <option key="__nochoice__" value="">{this.props.noChoice}</option>;
			}

			let fieldKey = (fieldName + (this.props.fieldKey ? ("_" + this.props.fieldKey) : ""));
			dataTag = (
				<select className={"col-md-"+width} ref={fieldName} id={this.props.fieldid||fieldName} key={fieldKey}
					{...valueObj}
					onChange={this.valueChange}>
					{noChoice}
					{_.map(_.sortBy(_.values(this.props.options), sortFunction), this.renderOption)}
				</select>
			);
		}
		return (
			<div className={groupClass}>
				{ this.props.label ? <label className={"col-form-label col-md-" + labelWidth} htmlFor={fieldName}>{this.props.label}</label> : null}
				{ dataTag }
				{ isRequired ? <span className="required-mark">*</span> : null }
				{ this.renderErrorBlock() }
				{ afterTag }
			</div>
		);
	}
}


class Checkbox extends Validator {
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: null
		};
		this.valueChange = this.valueChange.bind(this);
	}

	valueChange(event) {
		var value = event.target.checked;
		var objectChanges = {};
		if (this.props.fieldid) {
			objectChanges[this.props.fieldid] = value;
		}
		// this callback is for the specific form
		if (this.props.localFieldChangeCallback) {
			this.props.localFieldChangeCallback(null, objectChanges);
		}
		// this callback is for the global form object
		if (this.props.fieldchangecallback) {
			this.props.fieldchangecallback(event, objectChanges);
		}
	}

	 renderErrorBlock() {
		if (!this.state.errorMessage) return null;
		var labelWidth = this.props.labelWidth || 3;
		return (
			<span className={"error-block col-md-offset-"+labelWidth} style={{clear:"both"}}>{this.state.errorMessage}</span>
		);
	}

	renderHelpBlock() {
		if (!this.props.help || this.props.readOnly) return null;
		var labelWidth = this.props.labelWidth || 3;
		return (
			<span className={"form-text col-md-offset-"+labelWidth} style={{clear:"both"}}>{this.props.help}</span>
		);
	}

	render() {
		var object = this.props.object;
		var fieldName = this.props.fieldName;
		var labelWidth = this.props.labelWidth || 3;
		let checkboxVal = "defaultFieldValue" in this.props ? this.props.defaultFieldValue : this.props.fieldvalue;
		let valueLabel = this.props.valueLabels ? (checkboxVal ? this.props.valueLabels[1] : this.props.valueLabels[0]) : null;
		var dataTag;
		let valueObj = {};
		if ("defaultFieldValue" in this.props)
			valueObj.defaultChecked = !!checkboxVal;
		else
			valueObj.checked = !!checkboxVal;

		if (this.props.readOnly) {
			dataTag = (
				<p className={"form-control-plaintext col-md-" + labelWidth} ref={fieldName} >{valueLabel}</p>
			);
		} else {
			dataTag = (
				<div className="checkbox" key={"checkbox-" + (this.props.fieldid||fieldName) + "-" + checkboxVal}>
					<label style={{ marginTop: 4 }}>
						<input type="checkbox" style={{ marginTop: -8 }} onChange={this.valueChange} {...valueObj} ref={fieldName} id={this.props.fieldid||fieldName} />
						{ this.props.hideLabel ? null : valueLabel }
					</label>
				</div>
			);
		}
		return (
			<div className="form-group row" key={"control-" + fieldName}>
				<label className={'col-form-label col-md-' + labelWidth} htmlFor={fieldName}>{ this.props.label }</label>
				{ dataTag }
				{ this.renderErrorBlock() }
				{ this.renderHelpBlock() }
			</div>
		);
	}
}


class Radio extends Validator {
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: null
		};
		this.valueChange = this.valueChange.bind(this);
	}

	valueChange(event) {
		var value = event.target.value;
		var objectChanges = {};
		if (this.props.fieldid) {
			objectChanges[this.props.fieldid] = value;
		}
		// this callback is for the specific form
		if (this.props.localFieldChangeCallback) {
			this.props.localFieldChangeCallback(null, objectChanges);
		}
		// this callback is for the global form object
		if (this.props.fieldchangecallback) {
			this.props.fieldchangecallback(event, objectChanges);
		}
	}

	render() {
		let object = this.props.object;
		let fieldName = this.props.fieldName;
		let labelWidth = this.props.labelWidth || 3;
		let dataTag;

		if (this.props.readOnly) {
			let readOnlyVal;
			for (let x = 0, len = this.props.values.length; x < len; x++)
			{
				if (this.props.values[x].value === this.props.fieldvalue)
					readOnlyVal = this.props.values[x].name;
			}
			dataTag = (<p className={"form-control-plaintext col-md-" + labelWidth} ref={fieldName} >{readOnlyVal}</p>);
		} else {
			dataTag = this.props.values.map((radioObj) =>
				<div className={"form-check form-check-inline"}>
					<input class="form-check-input" type="radio" name={this.props.fieldid} id={this.props.fieldid + "_" + radioObj.value} value={radioObj.value} defaultChecked={this.props.fieldvalue.toString() === radioObj.value.toString()} onChange={this.valueChange} />
					<label className={"form-check-label"} style={{"font-weight": "normal"}} for={this.props.fieldid + "_" + radioObj.value}>{radioObj.name}</label>
				</div>
			);

			// Please note that the bootstrap classes are not behaving as expected.
			// Adding the class form-check-inline above should've caused this to be inline.
			// Alas that didn't work. Who knows whether there's some conflict with some style somewhere.
			// Adding this flex style on top of bootstrap's classes made it work.
			if (this.props.inline)
			{
				dataTag = <div style={{display: "flex", flexDirection: 'row'}}>{dataTag}</div>;
			}
		}
		return (
			<div className="form-group" key={"control-" + fieldName}>
				{ dataTag }
			</div>
		);
	}
}


class TagList extends Validator {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		var fieldName = this.props.sortField;
		var sortFunction = (!fieldName) ? _.identity : function(val, key, collection) {
			return val[fieldName];
		};

		var self = this;
		var objectList = self.props.objects;
		var renderList = [];
		if (objectList && objectList.length > 0) {
			objectList = _.sortBy(_.values(objectList), sortFunction);
			_.each(objectList, function(obj, idx) {
				var tagText = self.props.tagText(obj);
				var key = self.props.keyField;
				var tagEnd;
				var disabled = (obj.disabled_ind && obj.disabled_ind === 1);

				if (self.props.removeCallback && !self.props.readOnly && !disabled) {
					tagEnd = (<span className="sl-tag-end" title="Remove" onClick={ _.wrap(obj[self.props.keyField], self.props.removeCallback) }>x</span>);
				} else {
					tagEnd = (<span className="sl-tag-end">&nbsp;</span>);
				}
				var disabledClass ='';
				if(disabled) {
					disabledClass = 'sl-disabled'
				}
				renderList.push(<div key={"acct-" + idx} className="sl-tag-holder">
									<span className={"sl-tag " + disabledClass}>{ tagText }</span>
									{ tagEnd }
								</div>);
			});
			return (
				<div className="sl-tag-list">
					{ renderList }
				</div>
			);
		} else {
			return (
				<p style={{ fontStyle: "italic", marginLeft: -5 }}>{ self.props.emptyMessage }</p>
			);
		}
	}
}


// expects an object property that is a four-element array, each element being from 0..255
class IPAddress extends Validator {
	constructor(props) {
		super(props);

		this.state = {
			currentValue: this.props.object || [0,0,0,0]
		};
		this.valueChange = this.valueChange.bind(this);
	}

	valueChange(position, event) {
		var currentValue = _.clone(this.props.object || [0,0,0,0]);
		currentValue[position] = event.target.value;
		var objectChanges = {};
		if (this.props.fieldid) {
			objectChanges[this.props.fieldid] = currentValue;
		}
		if (this.props.onChange) {
			this.props.onChange(event, objectChanges);
		}
	}

	renderOctetSelect(obj, position) {
		var fieldName = this.props.fieldName || this.props.fieldid;
		var width = this.props.width || 4;
		var arr = [];
		var octet = obj[position];
		var optionFunc = function(i) {
			return (<option key={'oct' + position + '-' + i} value={i}>{i}</option>);
		};

		for (var i=0; i < 256; i++) {
			arr.push(i);
		}
		return (<select style={{ width: 60 }} id={fieldName+'-'+position} key={fieldName+'-'+position} value={octet||0}
						onChange={_.wrap(position, this.valueChange)}>
			{ _.map(arr, optionFunc) }
		</select>);
	}

	render() {
		var fieldName = this.props.fieldName;
		var width = this.props.width || 4;
		var labelWidth = this.props.labelWidth || 3;
		var isRequired = this.props.required && !this.props.readOnly;
		var keycount = 1;
		var obj = this.props.object || [0,0,0,0];
		var addressString;
		var dataTag, afterTag;

		if (this.props.readOnly) {
			if (this.props.object) {
				addressString = obj[0] + '.' + obj[1] + '.' + obj[2] + '.' + obj[3];
			} else {
				addressString = '<em>No address</em>';
			}
			dataTag = (<p className={"form-control-plaintext col-md-" + width} ref={fieldName}>{addressString}</p>);
			afterTag = (<hr />);
		} else {
			dataTag = (
				<div className={"form-control-inline row"}>
		{ this.renderOctetSelect(obj, 0) }<strong> . </strong>{ this.renderOctetSelect(obj, 1) }<strong> . </strong>{ this.renderOctetSelect(obj, 2) }<strong> . </strong>{ this.renderOctetSelect(obj, 3) }
				</div>
			);
		}
		return (
			<div className={"form-group row row"}>
				<label className={"col-form-label col-md-" + labelWidth} style={{ marginRight: 8 }} htmlFor={fieldName+"-0"}>{this.props.label}</label>
				{ dataTag }
				{ isRequired ? <span className="required-mark">*</span> : null }
				{ afterTag }
			</div>
		);
	}
}

class SingleLine extends Validator {
	constructor(props) {
		super(props);

		this.state = {};
	}

	renderChildren() {
		var formid = this.props.formid;
		var readOnly = this.props.readOnly;
		var fieldchangecallback = this.props.fieldchangecallback;

		return React.Children.map(this.props.children, function (child) {
			var extraChildProps = {
					formid: formid,
					readOnly: readOnly,
					singleline: true,
					currentObject: this.props.fieldvalue,
					fieldchangecallback: fieldchangecallback
				};
			var object = this.props.object || this.props.fieldvalue;
			if (object) {
				if (child.props.fieldid) {
					_.extend(extraChildProps, { fieldvalue: object[child.props.fieldid] });
				} else {
					_.extend(extraChildProps, { fieldvalue: object });
				}
			}
			return React.cloneElement(child, extraChildProps);
		}.bind(this));
	}

	render() {
		return (
			<div className="row form-control-row">
				{ this.renderChildren() }
				{ this.props.readOnly ? <hr /> : null }
			</div>
		);
	}
}


class Box extends Validator {
	constructor(props) {
		super(props);

		this.state = {};
	}

	renderChildren () {
		var formid = this.props.formid;
		var readOnly = this.props.readOnly;
		var fieldchangecallback = this.props.fieldchangecallback;

		return React.Children.map(this.props.children, function (child) {
			var extraChildProps = {
				formid: formid,
				readOnly: readOnly,
				fieldchangecallback: fieldchangecallback
			};
			if (this.props.fieldvalue && child.props.fieldid) {
				_.extend(extraChildProps, { fieldvalue: this.props.fieldvalue[child.props.fieldid] });
			}
			return React.cloneElement(child, extraChildProps);
		}.bind(this));
	}

	render() {
		if (this.props.hide) {
			return null;
		}
		return (
			<div className="sl-control-box">
			{ this.renderChildren() }
			</div>
		);
	}
}

class AssignedTagList extends Validator {
	constructor(props) {
		super(props);

		this.state = {
			SelectedData: {SelectedId: null},
			applicationErrorMessage: null,
		};
	}

	render() {
		var available = [];
		let IDAttributeObj = {};
		if (this.props.buttonIDPrefix)
			IDAttributeObj["id"] = this.props.buttonIDPrefix + "_assignbutton";
		if(this.props.available != null ) {
			available.push(
				<div className ="card-heading">
					<div>
						<button className="btn btn-primary btn-sm" style={{ marginRight: 12 }} {...IDAttributeObj} onClick={ this.props.available.assinedCb }>Assign</button>
						<Select width="6" labelWidth="3" noChoice="=== Please choose a value ==="
										  label= {this.props.available.label}
										  fieldid={this.props.available.fieldid}
										  fieldName= {this.props.available.fieldName}
										  optionid= {this.props.available.optionid}
										  fieldvalue={this.props.available.fieldvalue }
										  options={this.props.available.options}
										  localFieldChangeCallback={ this.props.available.localFieldChangeCallback }
										  errorMessage={ this.props.available.localFieldChangeCallback} />
					</div>
				</div>
			);
		}
		return (
				<div className = "card">
					<div className ="card-body" style={{overflowY: "auto"}}>
						{available}
						<div  className = "form-control-plaintext col-md-12" style={{ maxHeight: "120px",  marginLeft: -4 }}>
							<TagList objects={ this.props.objects }
											   keyField={this.props.keyField}
											   tagText={ this.props.tagText }
											   sortField={this.props.sortField}
											   readOnly={this.props.readOnly}
											   removeCallback={ this.props.removeCallback }
											   emptyMessage="No data assigned"/>
						</div>
					</div>
				</div>
		);
	}
}

export default {ValidateType: Validator, Input: Input, TextArea: TextArea, Select: Select, Checkbox: Checkbox, Radio: Radio, TagList: TagList, IPAddress: IPAddress, SingleLine: SingleLine, Box: Box, AssignedTagList: AssignedTagList};
