import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as accountActions  from '../../../actions/accountActions';
import * as formStateActions  from '../../../actions/formStateActions';
import * as shortcodeActions  from '../../../actions/shortcodeActions';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import _ from 'lodash';

class ShortCodeAccountForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentAccountToAdd: {account_id: null},
			applications: null,
			applicationErrorMessage: null,
			formErrorMessage: null,
			formSuccessMessage: null
		};

		this.accountChange = this.accountChange.bind(this);
		this.addShortCodeAccount = this.addShortCodeAccount.bind(this);
		this.removeVendorShortCode = this.removeVendorShortCode.bind(this);
		this.setFormErrorMessage = this.setFormErrorMessage.bind(this);
		this.setFormSuccessMessage = this.setFormSuccessMessage.bind(this);
	}

	componentDidMount() {
		this.props.actions.loadAccounts().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the activate accounts: " + msg);
		});
		this.props.actions.loadShortCodeAccounts(this.props.object.ShortCodeId).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the vendor typecodes: " + msg);
		});
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			errorMessage: errorMessage,
			successMessage: null
		});
	}

	setFormSuccessMessage(successMessage) {
		this.setState({
			errorMessage: null,
			successMessage: successMessage
		});
	}

	componentWillUnmount() {
		;
	}

	_onChange() {
		if (this.isMounted()) {
			this.setState({
				formError: null
			});
		}
	}

	_onVendorAccountChange() {
	}

	removeVendorShortCode(account_id) {
		let short_code_id = this.props.object.ShortCodeId;
		this.props.actions.removeShortCodeAccount(short_code_id, account_id).then(data => {
			this.props.actions.loadShortCodeAccounts(short_code_id).then(data => {
				this.setFormSuccessMessage(`Short code account ${account_id} successfully removed`);
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the short code accounts: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred adding the short code accounts: " + msg);
		});
	}

	addShortCodeAccount() {
		let short_code_id = this.props.object.ShortCodeId;
		let account_id = this.state.currentAccountToAdd.account_id;
		this.props.actions.addShortCodeAccount(short_code_id, account_id).then(data => {
			this.props.actions.loadShortCodeAccounts(short_code_id).then(data => {
				this.setFormSuccessMessage(`Short code account ${account_id} successfully added`);
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the short code accounts: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred adding the short code accounts: " + msg);
		});
	}

	applyNameChange() {
		return true;
	}

	accountChange(event, fieldChanges) {
		this.setState({
			currentAccountToAdd: _.extend(this.state.currentAccountToAdd, fieldChanges),
			applicationErrorMessage: null,
			formErrorMessage: null
		});
	}


	renderAvailable() {
		var accountTagTextFunction = function(obj) {
			return obj.account_name + ' (' + obj.account_id + ')';
		};
		var allAccounts = this.props.accounts;
		var addedAccounts = this.props.shortcodeAccounts;
		var aryAddAccounts = _.values(addedAccounts);
		var availableVendorAccounts = _.filter(allAccounts, function (o) {
			var idx = _.findIndex(aryAddAccounts, {account_id: o.account_id});
			return idx == -1;
		}) ;
		var availableData = {
			label: "Account",
			fieldid: "account_id",
			fieldName: "account_name",
			optionid: "account_id",
			fieldvalue:  this.state.currentAccountToAdd.account_id,
			options:  availableVendorAccounts ,
			localFieldChangeCallback:  this.accountChange,
			errorMessage: this.state.applicationErrorMessage,
			assinedCb: this.addShortCodeAccount
		};

		return (
			<div className="form-group row">
				<label className="col-form-label col-md-2" style={{ lineHeight: "16px"}}>Accounts</label>
				<div className="form-control-plaintext col-md-10" >
					<FormField.AssignedTagList
						objects={ aryAddAccounts }
						keyField="account_id"
						tagText={ accountTagTextFunction }
						sortField = "account_name"
						readOnly = {this.props.readOnly}
						emptyMessage="No account assigned"
						removeCallback={ this.removeVendorShortCode }
						buttonIDPrefix={ "shortcode" }
						available = {availableData} />
				</div>
			</div>
		);
	}

	render() {
		var self = this;
		var object = this.props.object;

		return (
			<Form {...this.props} width="800" height="580" cancelButtonLabel="Done" successMessage={ this.state.successMessage } errorMessage={ this.state.errorMessage }>
				<FormField.Input dontModify= {true} fieldid="ShortCode" width="2" label="Short Code" labelWidth="2"  />
				<FormField.Input dontModify= {true} fieldid="ShortCodeDesc" width="9"  label="Description" labelWidth="2" />

				{ this.renderAvailable()}
			</Form>
		);
	}
}


function mapStateToProps(state, ownProps) {
	return {
		accounts: state.accountReducer.accounts,
		shortcodeAccounts:  state.shortcodeReducer.shortcodeAccounts['ID_' + ownProps.object.ShortCodeId],
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, accountActions, shortcodeActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ShortCodeAccountForm)
