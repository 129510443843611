import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as formStateActions from '../../../actions/formStateActions';
import * as apiClientActions from '../../../actions/apiClientActions';
import * as attributeActions from '../../../actions/attributeActions';
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable from '../../common/DataTable';
import ApiClientForm from './ApiClientForms';
import ChangePassword from './ChangePassword';
import AssignApiClient from './AssignApiClient';
import AssignAttribute from './AssignAttribute';
import FormField from '../../common/FormField';
import _ from 'lodash';
import ConfirmForm from '../../common/ConfirmForm';
const SlinkAuth = window.SlinkAuth;

class ApiClients extends Component {
    constructor(props) {
        super(props);

        this.setFormState(null);

        this.state = {
            filterText: '',
            initialSortKey: 'email',
            formError: null,
            formSuccess: null,
            currentForm: null,
            currentPage: 1,
            rowsPerPage: 50
        };

        this.handleUserInput = this.handleUserInput.bind(this);
        this.addApiClientCallback = this.addApiClientCallback.bind(this);
        this.setFormState = this.setFormState.bind(this);
        this.cancelForm = this.cancelForm.bind(this);
        this.takeDownForm = this.takeDownForm.bind(this);
        this.deleteApiClientCallback = this.deleteApiClientCallback.bind(this);
        this.attributeAssignment = this.attributeAssignment.bind(this);
        this.apiClientAssignment = this.apiClientAssignment.bind(this);
        this.changePasswordCallback = this.changePasswordCallback.bind(this);
        this.updateApiClientCallback = this.updateApiClientCallback.bind(this);
        this.newApiClientClicked = this.newApiClientClicked.bind(this);
        this.setFormErrorMessage = this.setFormErrorMessage.bind(this);
    }

    getColumns() {
        return {
            email: {name: "Email", used: 1, width: 320, click_action: "View", required: true},
            first_name: {name: "First Name", used: 1, width: 200, required: true},
            last_name: {name: "Last Name", used: 1, width:180, required: true},
            enabled: {name: "Status", used: 1, width: 80, type: "boolean", valueLabels: ["Disabled", "Enabled"]}
        };
    }

    getFieldIds() {
        return _.keys(this.getColumns());
    }

    getRequiredFieldIds() {
        return _.keys(_.pickBy(this.getColumns(), 'required'));
    }

    handleUserInput(filterText) {
        this.setState({
            filterText: filterText
        });
    }

    componentWillMount() {
        this.setFormState(null, null, null, null);
    }

    /*
    componentWillReceiveProps(nextProps) {
        this.setState({
            currentForm: nextProps.currentForm
        });
    }
    */


    componentDidMount() {
        this.props.actions.loadApiClients().catch(errObject => {
            var msg = errObject.message || errObject;
            if (msg === "Conflict") {
                msg = "Check that a user with that name does not already exist."
            }
            this.setFormErrorMessage("An error occurred loading the user. " + msg);
        });
    }

    componentWillUnmount() {
        ;
    }

    _onChange() {
        ;
    }

    validateApiClientFields(fields) {
        var msg;

        if (!fields.email || fields.email.trim() === "") {
            msg = "Email address is required";
        } else if (!msg) {
            msg = FormField.ValidateType.prototype.validateType('email', fields.email);
        }

        if (!msg && (!fields.first_name || fields.first_name.trim() === "")) {
            msg = "First Name is required";
        } else if (!msg) {
            msg = FormField.ValidateType.prototype.validateType('first_name', fields.first_name);
        }

        if (!msg && (!fields.last_name || fields.last_name.trim() === "")) {
            msg = "Last Name is required";
        } else if (!msg) {
            msg = FormField.ValidateType.prototype.validateType('last_name', fields.last_name);
        }

        if (!msg && fields.zip) {
            msg = FormField.ValidateType.prototype.validateType('zip', fields.zip);
        }

        if (!msg && fields.state_code) {
            msg = FormField.ValidateType.prototype.validateType('state_code', fields.state_code);
        }

        if (!msg && fields.country_code) {
            msg = FormField.ValidateType.prototype.validateType('country_code', fields.country_code);
        }

        if (!msg && fields.city) {
            msg = /^[A-Za-z, ]*/.test(fields.city) ? null : 'Not a valid city';
        }

        return msg;
    }

    addApiClientCallback(fields) {
        var missingFields = _.difference(this.getRequiredFieldIds(), _.keys(fields));
        if (!_.isEmpty(missingFields)) {
            this.setFormErrorMessage("The following fields are required: " + missingFields.join(', '));
            return;
        }

        var msg = this.validateApiClientFields(fields);
        if (msg) {
            this.setFormErrorMessage(msg);
            return;
        }

        var allFields = _.extend(fields, {
            password: fields.pwd1,
            deleted_ind: 0,
            create_login_id: 1,
            change_pw_next_login_ind: 0,
            country_code: 'US'

        });

        if (!fields.account_group_id) {
            this.setFormErrorMessage("Please select an account group for the user");
            return;
        }
        this.standardApiClientInsert(allFields);
    }

    standardApiClientInsert(allFields) {
        this.props.actions.addApiClient(allFields).then(data => {
            this.props.actions.loadApiClients().then(response => {
              this.props.actions.formChangeHandler(null, null, `API Client ${allFields.email} created.`, null);
            }).catch(errObject => {
              this.props.actions.formChangeHandler(null, null, `API Client ${allFields.email} created.`, null);
            });
        }).catch(returnObject => {
            var msg = returnObject.message;
            if (msg && msg.indexOf('onflict') != -1) {
                msg = 'A login with that email may already exist';
            }
            this.setFormErrorMessage("An error occurred when creating the user. " + msg);
        });
    }

    apiClientAssignProperties(apiClientId, app_code, role_type_code, account_group_id, organization_type_code, attribute_id, callback) {
        var settings = {};
        if (app_code && role_type_code) {
            settings.app_code = app_code;
            settings.role_type_code = role_type_code;
        }
        if (account_group_id) {
            settings.account_group_id = account_group_id;
        }
        if (account_group_id) {
            settings.organization_type_code = organization_type_code;
        }
        if (!_.isEmpty(settings)) {
            settings.action = "add";
            this.props.actions.updateApiClientRelationship(apiClientId, settings).then(data => {
                callback(null, data);
            }).catch(errObject => {
                var msg = errObject.message;
                if (msg && msg.indexOf('cannot insert duplicate entry' != -1)) {
                    msg = 'The entry already exists';
                }
                callback(msg);
            })
        }
    }

    updateApiClientCallback(fields) {
        // Need to set all null fields to empty quote for slip-api server
        for (let fieldName in fields)
        {
            if (fields[fieldName] === null)
                fields[fieldName] = "";
        }

        var missingFields = _.difference(this.getRequiredFieldIds(), _.keys(fields));
        if (!_.isEmpty(missingFields)) {
            this.setFormErrorMessage("The following fields are required: " + missingFields.join(', '));
            return;
        }

        var msg = this.validateApiClientFields(fields);
        if (msg) {
            this.setFormErrorMessage(msg);
            return;
        }

        if (!fields.login_id) {
            this.setFormErrorMessage("ID field is missing");
            return;
        }

        var allFields = _.extend(fields, {
            deleted_ind: 0,
            creator_login_id: 1,
            change_pw_next_login_ind: 0,
            country_code: 'US'
        });
        if (fields.pwd1) {
            _.extend(allFields, {password: fields.pwd1});
        }

        this.props.actions.updateApiClient(allFields).then(response => {
            this.props.actions.formChangeHandler(null, null, null);
            this.takeDownForm("API Client \"" + fields.email + "\" updated.")
        }).catch(errObject => {
            var msg = errObject.message;
            if (msg === "Conflict") {
                msg = "Check that an api client with that name does not already exist."
            }
            this.setFormErrorMessage("An error occurred updating the api client. " + msg);
        });
    }

    changePasswordCallback(fields) {
        if (fields.pwd1 || fields.pwd2) {
            if (fields.pwd1 != fields.pwd2) {
                this.setFormErrorMessage("The password fields do not match");
                return;
            }
        } else {
            this.setFormErrorMessage("The password fields are required");
            return;
        }

        var allFields = _.extend(fields, {
            action: 'update',
            password: fields.pwd1,
            email: fields.email
        });

        this.props.actions.changePassword(allFields).then(data => {
            this.takeDownForm("Password for api client \"" + fields.emails + "\" changed");
        }).catch(errObject => {
            var msg = errObject.message;
            this.setFormErrorMessage("An error occurred updating the api client. " + msg);
        });
    }

    deleteApiClientCallback(apiClientId) {
        this.props.actions.deleteApiClient({id: apiClientId}).then(data => {
            this.props.actions.loadApiClients().catch(errObject => {
                var msg = errObject.message || errObject;
                if (msg === "Conflict") {
                    msg = "Check that an api client with that name does not already exist."
                }
                this.setFormErrorMessage("An error occurred loading the api client. " + msg);
            });
            this.takeDownForm("API Client deleted.");
        }).catch(errOject => {
            this.setFormErrorMessage("There was a problem deleting the api client. " + errOject.message);
        })
    }

    attributeAssignment(fields) {
        var apiClientId = fields.login_id;
        var attributeId = parseInt(fields.attribute_id);
        var attributeValue = fields.attribute_value;

        this.props.actions.addLoginAttributes(apiClientId, attributeId, attributeValue).then(data => {
            this.props.actions.loadLoginAttributes(apiClientId).then(data => {
                this.setFormSuccessMessage("API Client \"" + fields.email + "\" updated with attribute: \"" + attributeValue + "\".");
            });
        }).catch(errObject => {
            this.setFormErrorMessage("There was a problem assigning the attribute. " + errObject.message);
        })
    }

    apiClientAssignment(fields) {
        var agId = fields.account_group_id;
        var appCode = fields.application_type_code;
        var roleCode = fields.role_type_code;

        if ((appCode && !roleCode) || (roleCode && !appCode)) {
            this.setFormErrorMessage("You must specify both an application and a role or neither");
            return;
        }

        this.props.actions.updateApiClientRelationship(fields.login_id, {
            action: "add",
            app_code: (appCode && roleCode ? appCode : undefined),
            role_type_code: (appCode && roleCode ? roleCode : undefined),
            account_group_id: agId
        }).then(data => {
            this.props.actions.loadApiClient(fields.login_id).then(data => {
                this.setFormSuccessMessage("API Client \"" + fields.email + "\" updated.");
            }).catch(errObject => {
                var msg = errObject.message || errObject;

                if (msg === "Conflict") {
                    msg = "Check that an api client with that name does not already exist."
                }
                this.setFormErrorMessage("An error occurred updating the api client. " + msg);
            });
        }).catch(errObject => {
            this.setFormErrorMessage("An error occurred updating the user. " + errObject.message);
        });
    }

    setFormState(formType, errorMessage, successMessage, id) {
        //console.log('setFormState formtype', formType);
        if (this.props.actions.formChangeHandler) {
            this.props.actions.formChangeHandler(formType, errorMessage, successMessage, id);
        }
    }

    setFormErrorMessage(errorMessage) {
        window.scrollTo(0, 0);
        this.props.actions.setErrMsg(errorMessage);
    }

    setFormSuccessMessage(successMessage) {
        window.scrollTo(0, 0);
        this.props.actions.setSuccessMsg(successMessage);
    }

    takeDownForm(successMessage) {
        this.setFormState(null, null, successMessage, null);
        window.scrollTo(0, 0);
    }

    cancelForm() {
        this.takeDownForm(null);
    }

    newApiClientClicked() {
        this.setFormState("insert", null, null, null);
    }

    render() {
        var logins = this.props.apiClients;
        var errMsg = null;
        var successMsg = null;

        if (!logins) {
            return null;
        }

        if(this.props.currentForm && this.props.currentForm.errorMessage) {
            errMsg = this.props.currentForm.errorMessage;
        }
        if(this.props.currentForm && this.props.currentForm.successMessage) {
            successMsg = this.props.currentForm.successMessage;
        }

        var currentForm, object;
        var addNewApiClientTitle;
        var buttonList;
        var isSislUser = (this.props.usertype === "SISL");

        addNewApiClientTitle = "Add new API Client";
        buttonList =[
            {label: "View", icon: "info", callback: _.wrap("show", this.setFormState)},
            {label: "Edit", icon: "edit", callback: _.wrap("edit", this.setFormState)},
            {label: "Password", icon: "key", callback: _.wrap("changepassword", this.setFormState)},
            {label: "Assign", icon: "plus", callback: _.wrap("assign", this.setFormState)},
            {label: "Attributes", icon: "id-card", callback: _.wrap("attribute", this.setFormState)},
            {label: "Delete", icon: "trash", callback: _.wrap("delete", this.setFormState)}
        ];

        //console.log('api client currentForm', this.props.currentForm);
        switch (this.props.currentForm && this.props.currentForm.formType) {
            case "insert":
                object = {};
                currentForm = <ApiClientForm title={addNewApiClientTitle} object={object} errorMessage={errMsg}
                                        showAccountGroupDropdown={!isSislUser} hidepwd={isSislUser}
                                        submitCallback={this.addApiClientCallback} cancelCallback={this.cancelForm}/>;
                break;

            case "edit":
                object = logins ? _.find(logins, {login_id: this.props.currentForm.currentId}) : {};
                currentForm = <ApiClientForm title="Edit API Client" hidepwd={true} object={object} errorMessage={errMsg}
                                        submitCallback={this.updateApiClientCallback} cancelCallback={this.cancelForm}/>;
                break;

            case "show":
                object = logins ? _.find(logins, {login_id: this.props.currentForm.currentId}) : {};
                currentForm = <ApiClientForm title="API Client Details" readOnly={true} hidepwd={true} object={object}
                                        errorMessage={errMsg} showAccountGroupDropdown={!isSislUser}
                                        cancelCallback={this.cancelForm}/>;
                break;

            case "changepassword":
                object = logins ? _.find(logins, {login_id: this.props.currentForm.currentId}) : {};
                currentForm = <ChangePassword title="Change Password" object={object} errorMessage={errMsg}
                                              submitCallback={this.changePasswordCallback} cancelCallback={this.cancelForm}/>;
                break;

            case "assign":
                object = logins ? _.find(logins, {login_id: this.props.currentForm.currentId}) : {};
                //object = logins ? logins[this.props.currentId] : {};
                currentForm = <AssignApiClient title="API Client Assignment" object={object} errorMessage={errMsg} successMessage={successMsg}
                                          submitCallback={this.apiClientAssignment} cancelCallback={this.cancelForm}/>;
                break;

            case "attribute":
                object = logins ? _.find(logins, {login_id: this.props.currentForm.currentId}) : {};
                currentForm = <AssignAttribute title="Attribute Assignment" object={object} errorMessage={errMsg} successMessage={successMsg}
                                               text="Assign Attributes to this API Client"
                                               submitCallback={this.attributeAssignment} cancelCallback={this.cancelForm}/>;
                break;

            case "delete":
                object = logins ? _.find(logins, {login_id: this.props.currentForm.currentId}) : {};
                currentForm = <ConfirmForm title="Delete API Client"
                                           text={"Are you sure you want to permanently remove API Client " + object.email + " ?"}
                                           args={this.props.currentForm.currentId}
                                           confirmCallback={this.deleteApiClientCallback} cancelCallback={this.cancelForm}/>;
                break;
        }

        return (
            <ContentWrap title={this.props.title || "API Clients"} successMessage={successMsg} errorMessage={errMsg} form={currentForm}>
                <div className="search">
                    <TableSearch
                        onUserInput={ this.handleUserInput }
                        filterText={ this.state.filterText }
                    ></TableSearch>
                    <div className="pull-right form-inline">
                        <button className="btn btn-primary btn-sm searchBtn" title="Add API Client" onClick={ this.newApiClientClicked }>
                            {addNewApiClientTitle}
                        </button>
                    </div>
                </div>
                <div className="clearfix"></div>
                <DataTable
                    id="login_id"
                    columns={ this.getColumns() }
                    dataItems = { logins }
                    rowsPerPage={ this.state.rowsPerPage }
                    filterText={ this.state.filterText }
                    initialSortKey={ this.state.initialSortKey }
                    buttonIDPrefix={ "email" }
                    buttons={ buttonList }
                ></DataTable>
            </ContentWrap>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        apiClients: state.adminReducer.apiClients,
        currentForm: state.formStateReducer.currentForm
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, apiClientActions, formStateActions, attributeActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiClients)
