import * as types from '../actions/actionTypes';
/*
 * All reducers get two parameters passed in, state and action that occurred
 *   > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

let initialState = {
	sessiondata: {}
};

export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_SESSION_USER_DATA_SUCCESS:
			//console.log('reducer to update session user data', action);
			return Object.assign({}, state, {sessiondata: action.payload});
		default:
			return state;
	}
}
