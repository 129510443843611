import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as transformationActions  from '../../../actions/transformationActions';
import * as formStateActions  from '../../../actions/formStateActions';
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';
import ConfirmForm from '../../common/ConfirmForm';
import TransformationAccountForm from './TransformationAccountForm';
import TransformationMappingForm from './TransformationMappingForm';
import _ from 'lodash';

class ApiLinkTransformations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filterText: '',
			initialSortKey: 'name',
			errorMessage: null,
			successMessage: null,
			currentForm: null,
			currentPage: 1,
			rowsPerPage: 20
		};

		this.setFormState = this.setFormState.bind(this);
		this.takeDownForm = this.takeDownForm.bind(this);
		this.handleUserSearchInput = this.handleUserSearchInput.bind(this);
		this.newTransformationClicked = this.newTransformationClicked.bind(this);
		this.showTransformation = this.showTransformation.bind(this);
		this.editTransformation = this.editTransformation.bind(this);
		this.deleteTransformationCallback = this.deleteTransformationCallback.bind(this);
		this.clone = this.clone.bind(this);
		this.cancelForm = this.cancelForm.bind(this);
		this.saveForm = this.saveForm.bind(this);
	}

	getColumns() {
		return {
			name: { name: "Name", used: 1, width: 150, click_action: "View", required: true },
			description: { name: "Description", used: 1, width: 250, click_action: "View", required: false },
			in_use: { name: "In Use", used: 1, width: 50, type: "boolean", values: [1] },
			accounts_assigned: { name: "Accounts", used: 1, width: 80, required: false }
		};
	}

	getFieldIds() {
		return _.keys(this.getColumns());
	}

	componentDidMount() {
		this.props.actions.loadCustomTransformations().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the Transformations: " + msg);
		});
	}

	componentWillUnmount() {
	}

	setFormState(formType, errorMessage, successMessage, id) {
		if (this.props.actions.formChangeHandler)
			this.props.actions.formChangeHandler(formType, errorMessage, successMessage, id);
			this.setState({
				errorMessage: null,
				successMessage: null
			});
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			successMessage: null,
			errorMessage: errorMessage
		});
	}

	setFormSuccessMessage(successMessage) {
		this.setState({
			successMessage: successMessage,
			errorMessage: null
		});
	}

	takeDownForm(successMessage) {
		this.setFormState(null, null, successMessage, null);
		window.scrollTo(0, 0);
	}

	clearErrorMessage() {
		this.setState({
			errorMessage: null
		});
	}

	handleUserSearchInput(filterText) {
		this.setState({
			filterText: filterText
		});
	}

	newTransformationClicked() {
		this.setFormState("insert-transformation", null, null, null);
	}

	showTransformation(errorMessage, successMessage, api_link_transform_rule_id) {
		this.setFormState("show-transformation", errorMessage, successMessage, api_link_transform_rule_id);
	}

	editTransformation(errorMessage, successMessage, api_link_transform_rule_id) {
		this.setFormState("edit-transformation", errorMessage, successMessage, api_link_transform_rule_id);
	}

	deleteTransformationCallback(transformation) {
		this.props.actions
			.deleteCustomTransformation(transformation.api_link_transform_rule_id)
			.then(response => {
				this.takeDownForm(null);
				this.setFormSuccessMessage(
					`Successfully deleted the Transformation ${transformation.name}`);
				return true;
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred deleting the Transformation: " + msg);
				return false;
			}).then((reload) => reload ? this.props.actions.loadCustomTransformations(): null)
			.catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the Transformations: " + msg);
			});
	}

	clone(errorMessage, successMessage, id) {
		this.props.actions
			.cloneCustomTransformation(id)
			.then(response => {
				this.setFormSuccessMessage("Successfully cloned the Transformation");
				return true;
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred cloning the Transformation: " + msg);
				return false;
			}).then((reload) => reload ? this.props.actions.loadCustomTransformations(): null)
			.catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the Transformations: " + msg);
			});
	}

	cancelForm(reload) {
		this.takeDownForm(null);
		this.clearErrorMessage();
		if (reload) {
			this.props.actions.loadCustomTransformations().catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the Transformationss: " + msg);
			});
		}
	}

	saveForm(api_link_transform_rule_id, successMessage) {
		this.takeDownForm(successMessage);
		this.setFormSuccessMessage(successMessage);
		this.props.actions.loadCustomTransformations().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the Transformations: " + msg);
		});
		// this.editTransformation(null, successMessage, api_link_transform_rule_id);
	}

	render() {
		let currentForm;
		let object;
		let allCustomTransformations = this.props.customTransformations;
		if (!allCustomTransformations) {
			return null;
		}
		let errMsg = this.state.errorMessage;
		let successMsg = this.state.successMessage;

		let addNewTransformationTitle = "New Data Transformation";
		let buttonList = [
			{ label: "View", icon: "info", callback: this.showTransformation },
			{ label: "Edit", icon: "edit", callback: this.editTransformation },
			{ label: "Assign", icon: "plus", callback: _.wrap("assign-transformation", this.setFormState) },
			{ label: "Clone", icon: "clone", callback: this.clone },
			{ label: "Delete", icon: "trash", callback: _.wrap("delete-transformation", this.setFormState) }
		];

		switch (this.props.currentForm && this.props.currentForm.formType) {
			case "insert-transformation":
				currentForm = <TransformationMappingForm title="New Custom Data Transformation"
					api_link_transform_rule_id={0}
					readOnly={false}
					cancelCallback={this.cancelForm}
					onSave={this.saveForm} />;
				break;
			case "show-transformation":
				currentForm = <TransformationMappingForm title="Custom Data Transformation"
					api_link_transform_rule_id={this.props.currentForm.currentId}
					readOnly={true}
					cancelCallback={this.cancelForm} />;
				break;
			case "edit-transformation":
				currentForm = <TransformationMappingForm title="Edit Custom Data Transformation"
					api_link_transform_rule_id={this.props.currentForm.currentId}
					readOnly={false}
					cancelCallback={this.cancelForm}
					onSave={this.saveForm} />;
				break;
			case "assign-transformation":
				object = allCustomTransformations ?
					_.find(allCustomTransformations, {api_link_transform_rule_id: this.props.currentForm.currentId})
					: {};
				if (object) {
					currentForm = <TransformationAccountForm transformation={object}
						cancelCallback={this.cancelForm} />;
				}
				break;
			case "delete-transformation":
				object = allCustomTransformations ?
					_.find(allCustomTransformations, {api_link_transform_rule_id: this.props.currentForm.currentId})
					: {};
				currentForm = <ConfirmForm title="Delete Transformation"
					text={"Are you sure you want to permanently remove the transformation \"" + object.name + "\" ?"}
					args={object}
					errorMessage={errMsg}
					confirmCallback={this.deleteTransformationCallback}
					cancelCallback={this.cancelForm} />;
				break;
		}
		return (
			<ContentWrap title={this.props.title || "Custom Data Transformations"}
				successMessage={this.state.successMessage} errorMessage={errMsg}
				form={currentForm}>
				<div className="search">
					<TableSearch
						onUserInput={ this.handleUserSearchInput }
						filterText={ this.state.filterText }>
					</TableSearch>
					<div className="float-right form-inline">
						<button className="btn btn-tertiary btn-sm searchBtn" id="add_new_transformation" title="New Data Transformation" onClick={this.newTransformationClicked}>{addNewTransformationTitle}</button>
					</div>
				</div>
				<div className="clearfix"></div>
				<DataTable
					id="api_link_transform_rule_id"
					columns={ this.getColumns() }
					dataItems={ allCustomTransformations }
					rowsPerPage={ this.state.rowsPerPage }
					filterText={ this.state.filterText }
					initialSortKey={ this.state.initialSortKey }
					buttonIDPrefix={ "name" }
					buttons={ buttonList }>
				</DataTable>
			</ContentWrap>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		customTransformations: state.transformationReducer.customTransformations,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, transformationActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiLinkTransformations)
