import * as types from './actionTypes';

export function formChangeHandler(formType, errorMessage, successMessage, id) {
    //console.log('formChangeHandler formType', formType);
    return function(dispatch) {
        return  dispatch({type: types.FORMSTATE_CHANGED, payload: {
            formType: formType,
            errorMessage: errorMessage,
            successMessage: successMessage,
            lastMessageTime: new Date().getTime(),
            currentId: id
        }});
    };
}

export function setErrMsg( msg) {
    return function(dispatch) {
        return  dispatch({type: types.FORMSTATE_CHANGED, payload: { 'successMessage': null, 'errorMessage': msg }});
    };
}

export function setSuccessMsg( msg) {
    return function(dispatch) {
        return  dispatch({type: types.FORMSTATE_CHANGED, payload: { 'successMessage': msg, 'errorMessage': null }});
    };
}
