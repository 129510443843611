import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as vendorActions  from '../../../actions/vendorActions';
import * as shortcodeActions  from '../../../actions/shortcodeActions';
import * as formStateActions  from '../../../actions/formStateActions';
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';
import ConfirmForm from '../../common/ConfirmForm';
import ShortCodeForm from './ShortCodeForm';
import ShortCodeAccountForm from './ShortCodeAccountForm';
import _ from 'lodash';

class ShortCodes extends Component {
	constructor(props) {
		super(props);

		this.state = {
            filterText: '',
            initialSortKey: 'ShortCode',
            formError: null,
            formSuccess: null,
            currentForm: null,
            currentPage: 1,
			rowsPerPage: 50
        };

		this._isMounted = false;

		this.handleUserInput = this.handleUserInput.bind(this);
		this.setFormState = this.setFormState.bind(this);
		this.cancelForm = this.cancelForm.bind(this);
		this.takeDownForm = this.takeDownForm.bind(this);
		this.updateCallback  = this.updateCallback.bind(this);
		this.addShortCodeCallback = this.addShortCodeCallback.bind(this);
		this.deleteShortCodeCallback = this.deleteShortCodeCallback.bind(this);
		this.newShortcodeClicked = this.newShortcodeClicked.bind(this);
		this.setFormState("list-shortcode", null,null,null);
	}

	getColumns() {
		return {
			ShortCode:		{ name: "Short Code", used: 1, width: 100, click_action: "View", required: true },
			Throttle:		{ name: "Throttle", used: 1, width: 80, required: true },
			ShortCodeDesc:	{ name: "Description", used: 1, width: 300 ,required: true},
			AccountCnt:{ name: "Accounts", used: 1, width: 80},
			ProgramCnt:{ name: "Programs", used: 1, width: 80},
			EffectiveDateTime:	{ name: "EffectiveDT", used: 1, width: 170, type: "date"}
		};
	}

	getFieldIds() {
		return _.keys(this.getColumns());
	}

	getRequiredFieldIds() {
		return _.keys(_.pickBy(this.getColumns(), 'required'));
	}

	handleUserInput(filterText) {
		this.setState({
			filterText: filterText
		});
	}

	componentWillReceiveProps(nextProps) {
	    this.setState({
	    	currentForm: nextProps.currentForm
	    });
	}

	componentDidMount() {
		this.props.actions.loadShortCodes().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the shortcodes: " + msg);
		});
	}

	componentWillUnmount() {
		;//this.setFormState(null, null, null, null);
	}

	_onChange() {
		this.setState({
			formError: null
		});
	}

	addShortCodeCallback (fields) {
		this.update(fields, false);
	}

	updateCallback (fields) {
		this.update(fields, true);
	}

	isNormalInteger (str) {
		let n = Math.floor(Number(str));
		return String(n) == str && n >= 0;
	}

	newShortcodeClicked() {
		this.setFormState("insert-shortcode", null,null,null);
	}

	update(fields, updateShortCode) {
		let missingFields = _.difference(this.getRequiredFieldIds(), _.keys(fields));
		if (!_.isEmpty(missingFields)) {
			this.setFormErrorMessage("The following fields are required: " + missingFields.join(', '));
			return;
		}

		if(isNaN(fields.ShortCode) || fields.ShortCode.indexOf('e') > 0 || parseInt(fields.ShortCode) <= 0 ) {
			this.setFormErrorMessage("Short Code can only be a number.");
			return;
		}

		if (!fields.ShortCodeId && updateShortCode) {
			this.setFormErrorMessage("ID field is missing");
			return;
		}

		if(fields.ShortCode != null && fields.ShortCode.trim() =='') {
			this.setFormErrorMessage("Short Code cannot be empty.");
			return;
		}

		if(fields.ShortCode != null && fields.ShortCode.trim().length < 4) {
			this.setFormErrorMessage("Short Code cannot be less than 4 characters.");
			return;
		}

		let sThrottle= new String(fields.Throttle);
		if(isNaN(sThrottle) || sThrottle.indexOf('e') >= 0 || !this.isNormalInteger(sThrottle) ) {
			this.setFormErrorMessage("Short Code Throttle can only be a positive number.");
			return;
		}

		if(fields.ShortCodeDesc != null && fields.ShortCodeDesc.trim() =='') {
			this.setFormErrorMessage("Short Code Description cannot be empty.");
			return;
		}

		let allFields = _.extend(fields, {ModifierLoginId: fields.ModifierLoginId});
		let snakeCaseAllFields = {};

		_.forOwn(allFields, function(value, key) {
			snakeCaseAllFields[_.snakeCase(key)] = value;
		} );

		if (updateShortCode)
		{
			this.props.actions.updateShortCode(fields.ShortCodeId, fields.ShortCode, fields.Throttle, fields.ShortCodeDesc, fields.HelpMessage, fields.OptOutMessage).then(data => {
				this.props.actions.loadShortCodes().then(data => {
					this.takeDownForm(`Successfully updated shortcode ${fields.ShortCode}`);
				}).catch(errObject => {
					this.takeDownForm(null);
					let msg = errObject.message || errObject;
					this.setFormErrorMessage("An error occurred loading short codes: " + msg);
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred updating the short code: " + msg);
			});
		}
		else
		{
			this.props.actions.addShortCode(fields.ShortCode, fields.Throttle, fields.ShortCodeDesc, fields.HelpMessage, fields.OptOutMessage).then(data => {
				this.props.actions.loadShortCodes().then(data => {
					this.takeDownForm(`Successfully added shortcode ${fields.ShortCode}`);
				}).catch(errObject => {
					let msg = errObject.message || errObject;
					this.setFormErrorMessage("An error occurred loading short codes: " + msg);
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred adding the short code: " + msg);
			});
		}
	}

	deleteShortCodeCallback(obj) {
		var shortCodeId = obj.ShortCodeId;
		this.props.actions.removeShortCode(shortCodeId).then(data => {
			this.takeDownForm(`Shortcode deleted successfully`);
			this.props.actions.loadShortCodes().then(data => {
				;
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading short codes: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred adding the short code: " + msg);
		});
	}

	setFormState(formType, errorMessage, successMessage, id) {
		if (this.props.actions.formChangeHandler)
			this.props.actions.formChangeHandler(formType, errorMessage, successMessage, id);
	}

	setFormErrorMessage(errorMessage) {
		//$(document).scrollTop(0);
		this.setState({
			formError: errorMessage
		});
	}

	setFormSuccessMessage(successMessage) {
		//$(document).scrollTop(0);
		this.setState({
			formSuccess: successMessage
		});
	}

	takeDownForm(successMessage) {
		this.setFormState(null, null, successMessage, null);
		this.props.actions.loadShortCodes().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the shortcodes: " + msg);
		});
	}

	clearErrorMessage() {
		this.setState({
			formError: null
		});
	}

	cancelForm() {
		this.takeDownForm(null);
		this.clearErrorMessage();
	}

	render() {
		var currentForm, object;
		var allShortCodes = this.props.shortcodes;
		var addNewUserTitle = "Add new short code";
		var buttonList;
		var isSislUser = (this.props.usertype === "SISL");

		let errMsg = null;
		let successMsg= null;

		if (this.props.currentForm && this.props.currentForm.errorMessage)
			errMsg = this.props.currentForm.errorMessage;

		if (this.props.currentForm && this.props.currentForm.successMessage)
			successMsg = this.props.currentForm.successMessage;

		buttonList = [	{ label: "View", icon: "info", callback: _.wrap("show-shortcode", this.setFormState) },
						{ label: "Edit", icon: "edit", callback: _.wrap("edit-shortcode", this.setFormState) },
						{ label: "Assign", icon: "plus", callback: _.wrap("assign-shortcode", this.setFormState) },
						{ label: "Delete", icon: "trash", callback: _.wrap("delete-shortcode", this.setFormState) }
					 ];

		switch (this.props.currentForm && this.props.currentForm.formType) {
			case "insert-shortcode":
				object = {};
				currentForm = <ShortCodeForm title={addNewUserTitle} object={object} errorMessage={this.state.formError}
										showAccountGroupDropdown={ !isSislUser }
										hidepwd={ isSislUser }
										submitCallback={this.addShortCodeCallback} cancelCallback={this.cancelForm}/>;
				break;

			case "edit-shortcode":
				object = allShortCodes ? _.find(allShortCodes, {ShortCodeId: this.props.currentForm.currentId}) : {};

				currentForm = <ShortCodeForm title="Edit Short Code" hidepwd={true} object={object} errorMessage={this.state.formError}
										submitCallback={this.updateCallback} cancelCallback={this.cancelForm}/>;
				break;

			case "show-shortcode":
				object = allShortCodes ? _.find(allShortCodes, {ShortCodeId: this.props.currentForm.currentId}) : {};
				currentForm = <ShortCodeForm title="Short Code Details" readOnly={true} hidepwd={true} object={object} errorMessage={this.state.formError}
										showAccountGroupDropdown= { false } cancelCallback={this.cancelForm}/>;
				break;

			case "assign-shortcode":
				object = allShortCodes ? _.find(allShortCodes, {ShortCodeId: this.props.currentForm.currentId}) : {};
				if (object) {
					currentForm = <ShortCodeAccountForm title="Assign Account" readOnly={false} object={object}
														  errorMessage={this.state.errorMessage}
														  cancelCallback={this.takeDownForm}/>;
				}
				break;
			case "delete-shortcode":
				object = allShortCodes ? _.find(allShortCodes, {ShortCodeId: this.props.currentForm.currentId}) : {};
				currentForm = <ConfirmForm title="Delete Short Code" text={"Are you sure you want to permanently remove short code \"" + object.ShortCode + "\" ?"}
										   args={object} errorMessage={this.state.formError}
										   confirmCallback={this.deleteShortCodeCallback} cancelCallback={this.cancelForm}/>;
				break;
		}

		return (
			<ContentWrap title={this.props.title || "Short Codes"} successMessage={successMsg} errorMessage={errMsg} form={currentForm}>
				<div className="search">
					<TableSearch
						onUserInput={ this.handleUserInput }
						filterText={ this.state.filterText }>
					</TableSearch>
					<div className="pull-right form-inline">
						<button className="btn btn-primary btn-sm searchBtn" title="Add Short Code" onClick={this.newShortcodeClicked}>{addNewUserTitle}</button>
					</div>
				</div>
				<div className="clearfix"></div>
				<DataTable
					id="ShortCodeId"
					columns={ this.getColumns() }
					dataItems={ allShortCodes }
					rowsPerPage={ this.state.rowsPerPage }
					filterText={ this.state.filterText }
					initialSortKey={ this.state.initialSortKey }
					buttonIDPrefix={ "ShortCode" }
					buttons={ buttonList }>
				</DataTable>
			</ContentWrap>
		);
	}
}


function mapStateToProps(state, ownProps) {
	return {
		shortcodes: state.shortcodeReducer.shortcodes,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, shortcodeActions, vendorActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ShortCodes)
