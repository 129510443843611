import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as userActions  from '../../../actions/userActions';

import Form from '../../common/Form';
import FormField from '../../common/FormField'

class ChangePassword extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		var obj = this.props.object;
		if (obj && obj.login_id) {
			//AdminStore.loadUser(obj.login_id);
			this.props.actions.loadUser(obj.login_id)
		}
	}

	render() {
		var oldPasswordField = null;
		return (
			<div className="sl-rightside-container">
				<Form {...this.props} width="800px" height="580px" submitButtonLabel="Change Password">
					<FormField.Input dontModify={true} fieldid="email" type="email" width="5" label="Email" labelWidth="2"  />
					<FormField.Input dontModify={true} fieldid="first_name" width="3" label="First Name" labelWidth="2" />
					<FormField.Input dontModify={true} fieldid="last_name" width="5" label="Last Name" labelWidth="2" />
					<br />
					<br />
					<h4 style={{"color": "#763905"}}>Change Password</h4>
					<br />
					<div className="clearfix"></div>
					<span className="sl-formtext text-warning">Passwords must be 8 or more characters long and have at least one letter, digit, capital letter, and special character.</span>
					{ this.props.getOldPassword ? <FormField.Input fieldid="oldpwd" type="password" width="3" label="Current Password" labelWidth="3" required="true" /> : null }
					<FormField.Input fieldid="pwd1" type="password" width="3" label="Password" labelWidth="3" required="true" />
					<FormField.Input fieldid="pwd2" type="password" width="3" label="Retype Password" labelWidth="3" required="true" />
				</Form>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		user: state.adminReducer.user,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, userActions), dispatch)
	};

}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
