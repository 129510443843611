import * as types from '../actions/actionTypes';
/*
 * All reducers get two parameters passed in, state and action that occurred
 *   > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

var initialState = {
	apilinks: [],
	apilinkDetails: {},
	apilinkDatatypes: [],
	apilinkAuthmethods: [],
	accountApiLinks: {},
	apiLinkAttributes: {},
	apilinkInUse: {}
};

export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_APILINKS_SUCCESS:
			return Object.assign({}, state, {apilinks: action.payload});
		case types.GET_APILINK_DATA_TYPES_SUCCESS:
			return Object.assign({}, state, {apilinkDatatypes: action.payload});
		case types.GET_APILINK_AUTH_METHODS_SUCCESS:
			return Object.assign({}, state, {apilinkAuthmethods: action.payload});
		case types.RESET_APILINK_SUCCESS:
			delete state.apilinkDetails["lastid"];
			return Object.assign({}, state);
		case types.GET_APILINK_SUCCESS:
			/**************************
				This data gets the basic details of an API Link
			**************************/

			// If this Activate account has api links assigned to it
			if (action.payload.length > 0)
			{
				/**************************
					We're storing api links in a hash/associative array.
					The key is the api link ID.
					The value is the list of api links.
					This way, each incoming call doesn't overwrite data from previous calls.
				**************************/

				// FIREFOX BUG WORKAROUND
				// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
				// It doesn't like the number 1 as a computed key in an object, even once it's stringified
				let apiLinkID = action.id;
				return Object.assign({}, state, {apilinkDetails: {lastid: apiLinkID, ['ID_' + apiLinkID]: action.payload[0]}});
			}
			else // otherwise, return the current state
				return state;
		case types.GET_APILINK_INUSE:
			/**************************
				This data lets you tell the API Link configuration UI whether that API Link is being used in a call.
			**************************/

			if ("payload" in action)
			{
				/**************************
					We're storing api links in a hash/associative array.
					The key is the api link ID.
					The value is the list of api links.
					This way, each incoming call doesn't overwrite data from previous calls.
				**************************/

				// FIREFOX BUG WORKAROUND
				// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
				// It doesn't like the number 1 as a computed key in an object, even once it's stringified
				let apiLinkID = action.id;
				return Object.assign({}, state, {apilinkInUse: { ['ID_' + apiLinkID]: action.payload.in_use}});
			}
			else // otherwise, return the current state
				return state;
		case types.GET_APILINK_ACCOUNTS_SUCCESS:
			/**************************
				This data lets you assign api links to an account.
			**************************/

			/**************************
				We're storing api links in a hash/associative array.
				The key is the api link ID.
				The value is the list of api link accounts.
				This way, each incoming call doesn't overwrite data from previous calls.
			**************************/

			// FIREFOX BUG WORKAROUND
			// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
			// It doesn't like the number 1 as a computed key in an object, even once it's stringified
			let apiLinkID = action.id;
			return Object.assign({}, state, {accountApiLinks: { ['ID_' + apiLinkID]: action.payload}});
		case types.GET_APILINK_ATTRIBUTES_SUCCESS:
			/**************************
				This data gets API link attributes.
			**************************/

			// If this Activate account has api links assigned to it
			if ("payload" in action)
			{
				/**************************
					We're storing api links in a hash/associative array.
					The key is the api link ID.
					The value is the list of api links.
					This way, each incoming call doesn't overwrite data from previous calls.
				**************************/

				// FIREFOX BUG WORKAROUND
				// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
				// It doesn't like the number 1 as a computed key in an object, even once it's stringified
				let apiLinkID = action.id;
				return Object.assign({}, state, {apiLinkAttributes: { ['ID_' + apiLinkID]: action.payload}});
			}
			else // otherwise, return the current state
				return state;
	}
	return state;
}
