import React, {Component} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class CustomTab extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tabs: [],
		};
	}
	
	componentWillReceiveProps(newProps)
	{
		let tabArray = [];
		if ("allTabs" in newProps && newProps.allTabs)
			tabArray = newProps.allTabs;
		this.setState({ tabs: tabArray });
	}

	componentDidMount() {
	}

	add(name, content, isDisabled) {
		let newTab = {"name": name, "content": content, "disabled": isDisabled};
		this.setState({ tabs: [...this.state.tabs, newTab] });
	}

	render() {
		if (this.state)
		{
			const tabTitles = this.state.tabs.map((tabObj) =>
				<Tab disabled={tabObj.disabled ? true : false}>{tabObj.name}</Tab>
			);
			const tabPanels = this.state.tabs.map((tabObj) =>
				<TabPanel>{tabObj.content}</TabPanel>
			);
			return (
			  <Tabs>
				<TabList>
					{tabTitles}
				</TabList>
				{tabPanels}
			  </Tabs>
			);
		}
		else
			return (<div></div>);
	}
}

export default CustomTab;