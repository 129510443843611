import React, {Component} from 'react';
import {Route,Switch} from 'react-router-dom';
import SecuredRoute from '../common/SecuredRoute';
import SideBar from '../common/SideBar';
import MySettings from './Password';
import About from './About';

const menuitems = [
	{
		id: "homeAboutLink",
		displayName: 'About',
		linkname: '/home',
		icon: 'info-circle',
		needsRole: 'slink-sys-admin,slink-engmnt-manager,slink-apilink-manager'
	},
	{id: "homeSettingsLink", displayName: "My Settings", linkname: '/home/settings', icon: 'wrench', needsRole: 'slink-sys-admin,slink-engmnt-manager,slink-apilink-manager'},
	{id: "homeLogoutLink", displayName: "Logout", linkname: '/login', icon: 'sign-out-alt', isLogout: true}
];

class HomeSideBar extends Component {
	constructor(props) {
		super();
	}

	render() {
		return (
			<div>
				<SideBar menuitems={ menuitems }/>
				<div>
					<div id="page-content-wrapper" className="full-height">
						<div className="sl-rightside-container">
							<Switch>
								<SecuredRoute   path="/home/settings" component={MySettings}/>
								<SecuredRoute  component={About}/>
							</Switch>
						</div>
					</div>
				</div>
				{this.props.children}
			</div>
		);
	}
}


export default HomeSideBar;
