import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators}  from 'redux'
import * as userActions  from '../../actions/userActions';

class AboutAdmin extends Component {
    componentDidMount() {
		this.props.actions.loadUserData();
    }
    render(){
        let userData =  this.props.userSessionData;

        let welcomeMessage ="Welcome to Digital Channels";
        if (userData && userData.first_name) {
            welcomeMessage += ", " + userData.first_name;
        } else {
            return (<div>Loading...</div>);
        }
        return (
            <div>
                <div>
                    <div className="sl-rightside-container">
                        <h3 className="page-header">{ welcomeMessage }</h3>
                        <div className="clearfix"></div>
                        <div className="clearfix">The digital channel section allow you to manage short codes, email domains, token for SMS/Twilio and Email/Sendgrid, as well as set up unsubscribe groups.</div>
                    </div>
                </div>
            </div>
        );

    }
}

function mapStateToProps(state, ownProps) {
    return {
		userSessionData: state.sessionReducer.sessiondata,
		currentForm: state.formStateReducer.currentForm
    }
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, userActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutAdmin)