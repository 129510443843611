import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux'
import * as userActions  from '../../../actions/userActions';
import * as formStateActions  from '../../../actions/formStateActions';
import * as attributeActions from '../../../actions/attributeActions';
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';
import UserForm  from './UserForms';
import ChangePassword  from './ChangePassword';
import AssignUser  from './AssignUser';
import AssignAttribute from './AssignAttribute';
import FormField from '../../common/FormField';
import _ from 'lodash';
import ConfirmForm from '../../common/ConfirmForm';
const SlinkAuth = window.SlinkAuth;

class Users extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filterText: '',
			initialSortKey: 'email',
			formError: null,
			formSuccess: null,
			currentForm: null,
			currentPage: 1,
			rowsPerPage: 50
		};

		this.handleUserInput = this.handleUserInput.bind(this);
		this.addUserCallback = this.addUserCallback.bind(this);
		this.setFormState = this.setFormState.bind(this);
		this.cancelForm = this.cancelForm.bind(this);
		this.takeDownForm = this.takeDownForm.bind(this);
		this.deleteUserCallback = this.deleteUserCallback.bind(this);
		this.attributeAssignment = this.attributeAssignment.bind(this);
		this.userAssignment = this.userAssignment.bind(this);
		this.changePasswordCallback = this.changePasswordCallback.bind(this);
		this.updateUserCallback = this.updateUserCallback.bind(this);
		this.newUserClicked = this.newUserClicked.bind(this);
		this.setFormErrorMessage = this.setFormErrorMessage.bind(this);
		this.userAssignProperties = this.userAssignProperties.bind(this);
	}

	getColumns() {
		return {
			email: {name: "Email", used: 1, width: 320, click_action: "View", required: true},
			first_name: {name: "First Name", used: 1, width: 200, required: true},
			last_name: {name: "Last Name", used: 1, width: 180, required: true},
			enabled: {name: "Status", used: 1, width: 80, type: "boolean", valueLabels: ["Disabled", "Enabled"]}
		};
	}

	getFieldIds() {
		return _.keys(this.getColumns());
	}

	getRequiredFieldIds() {
		return _.keys(_.pickBy(this.getColumns(), 'required'));
	}

	handleUserInput(filterText) {
		this.setState({
			filterText: filterText
		});
	}

	componentWillMount() {
		this.setFormState(null, null, null, null);
	}

	componentDidMount() {
		this.props.actions.loadUsers().catch(errObject => {
			var msg = errObject.message || errObject;
			if (msg === "Conflict") {
				msg = "Check that a user with that name does not already exist."
			}
			this.setFormErrorMessage("An error occurred loading the user. " + msg);
		});
	}

	componentWillUnmount() {
		this.setFormState(null, null, null, null);
	}

	_onChange() {
		;
	}

	validateUserFields(fields) {
		var msg;

		if (!fields.email || fields.email.trim() === "") {
			msg = "Email address is required";
		} else if (!msg) {
			msg = FormField.ValidateType.prototype.validateType('email', fields.email);
		}

		if (!msg && (!fields.first_name || fields.first_name.trim() === "")) {
			msg = "First name is required";
		} else if (!msg) {
			msg = FormField.ValidateType.prototype.validateType('first_name', fields.first_name);
		}

		if (!msg && (!fields.last_name || fields.last_name.trim() === "")) {
			msg = "Last name is required";
		} else if (!msg) {
			msg = FormField.ValidateType.prototype.validateType('last_name', fields.last_name);
		}

		if (!msg && fields.zip) {
			msg = FormField.ValidateType.prototype.validateType('zip', fields.zip);
		}

		if (!msg && fields.state_code) {
			msg = FormField.ValidateType.prototype.validateType('state_code', fields.state_code);
		}

		if (!msg && fields.country_code) {
			msg = FormField.ValidateType.prototype.validateType('country_code', fields.country_code);
		}

		if (!msg && fields.city) {
			msg = /^[A-Za-z, ]*$/.test(fields.city) ? null : 'Not a valid city';
		}

		return msg;
	}

	addUserCallback(fields) {
		var missingFields = _.difference(this.getRequiredFieldIds(), _.keys(fields));
		if (!_.isEmpty(missingFields)) {
			this.setFormErrorMessage("The following fields are required: " + missingFields.join(', '));
			return;
		}

		var msg = this.validateUserFields(fields);
		if (msg) {
			this.setFormErrorMessage(msg);
			return;
		}

		if (this.props.usertype != "SISL") {
			if (!fields.pwd1 || !fields.pwd2 || (fields.pwd1 != fields.pwd2)) {
				this.setFormErrorMessage("The password fields are empty or do not match");
				return;
			}
		}

		var allFields = _.extend(fields, {
			password: fields.pwd1,
			deleted_ind: 0,
			creator_login_id: 1,
			change_pw_next_login_ind: 0,
			country_code: 'US'
		});

		var userData = SlinkAuth.getUserData() || {};

		if (this.props.usertype === "SISL") {
			var allFields = _.extend(fields, {
				account_group_id: userData.account_group_id_list ? userData.account_group_id_list[0] : null,
				app_code: 'workb',
				role_type_code: 'slink-sisl-user',
				password: ''
			});
			this.sislUserInsert(allFields);
		} else {
			if (!fields.account_group_id) {
				this.setFormErrorMessage("Please select an account group for the user.");
				return;
			}
			this.standardUserInsert(allFields);
		}
	}

	standardUserInsert(allFields) {
		this.props.actions.addUser(allFields).then(data => {
			var newUserId = data.id;
			// success - re-load the user record from scratch
			this.props.actions.loadUsers().then(response => {
				this.props.actions.formChangeHandler(null, null, `User ${allFields.first_name} ${allFields.last_name} added.`, null);
			}).catch(errObject => {
				this.props.actions.formChangeHandler(null, null, `User ${allFields.first_name} ${allFields.last_name} added.`, null);
			});
		}).catch(returnObject => {
			var msg = returnObject.message;
			if (msg && msg.indexOf('onflict') != -1) {
				msg = 'A login with that email may already exist';
			}
			this.setFormErrorMessage("An error occurred when creating the user. " + msg);
		});
	}

	sislUserInsert(allFields) {
		let thisObj = this;
		this.props.actions.addUser(allFields).then(data => {
			var newUserId = data.id;
			thisObj.userAssignProperties(newUserId, "sisl", "slink-sisl-user", null, null, function (err) {
				if (err) {
					thisObj.setFormErrorMessage("We were unable to give the user the proper permissions. Please see the system administrator. " + err);
					return;
				}
				// success - re-load the user record from scratch
				//thisObj.props.actions.loadUser(newUserId);
				thisObj.props.actions.loadUsers().then(response => {
					thisObj.props.actions.formChangeHandler(null, null, `SISLNext user ${allFields.first_name} ${allFields.last_name} added.`, null);
				}).catch(errObject => {
					thisObj.props.actions.formChangeHandler(null, null, `SISLNext user ${allFields.first_name} ${allFields.last_name} added.`, null);
				});
			});
		}).catch(returnObject => {
			var msg = returnObject.message;
			if (msg && msg.indexOf('onflict') != -1) {
				msg = 'A login with that email may already exist';
			}
			thisObj.setFormErrorMessage("An error occurred when creating the user. " + msg);
		});
	}

	userAssignProperties(userId, app_code, role_type_code, account_group_id, organization_type_code, callback) {
		var settings = {};
		if (app_code && role_type_code) {
			settings.app_code = app_code;
			settings.role_type_code = role_type_code;
		}
		if (account_group_id) {
			settings.account_group_id = account_group_id;
		}
		// TODO: Is this correct?
		if (account_group_id) {
			settings.organization_type_code = organization_type_code;
		}
		if (!_.isEmpty(settings)) {
			settings.action = "add";
			this.props.actions.updateUserRelationship(userId, settings).then(data => {
				callback(null, data);
			}).catch(errObject => {
				var msg = errObject.message;
				if (msg && msg.indexOf('cannot insert duplicate entry') != -1) {
					msg = 'The entry already exists.';
				}
				callback(msg);
			});
		}
	}

	updateUserCallback(fields) {
		// Need to set all null fields to empty quote for slip-api server
		for (let fieldName in fields)
		{
			if (fields[fieldName] === null)
				fields[fieldName] = "";
		}

		var missingFields = _.difference(this.getRequiredFieldIds(), _.keys(fields));
		if (!_.isEmpty(missingFields)) {
			this.setFormErrorMessage("The following fields are required: " + missingFields.join(', '));
			return;
		}

		var msg = this.validateUserFields(fields);
		if (msg) {
			this.setFormErrorMessage(msg);
			return;
		}

		if (!fields.login_id) {
			this.setFormErrorMessage("ID field is missing");
			return;
		}

		var allFields = _.extend(fields, {
			deleted_ind: 0,
			creator_login_id: 1,
			change_pw_next_login_ind: 0,
			country_code: 'US'
		});
		if (fields.pwd1) {
			_.extend(allFields, {password: fields.pwd1});
		}

		this.props.actions.updateUser(allFields).then(response => {
			this.props.actions.formChangeHandler(null, null, `User ${fields.first_name} ${fields.last_name} updated.`);
		}).catch(errObject => {
			var msg = errObject.message;
			if (msg === "Conflict") {
				msg = "Check that a user with that name does not already exist."
			}
			this.setFormErrorMessage(`An error occurred updating ${fields.first_name} ${fields.last_name}:  ${msg}`);
		});
	}

	changePasswordCallback(fields) {
		if (fields.pwd1 || fields.pwd2) {
			if (fields.pwd1 != fields.pwd2) {
				this.setFormErrorMessage("The password fields do not match");
				return;
			}
		} else {
			this.setFormErrorMessage("The password fields are required");
			return;
		}

		var allFields = _.extend(fields, {
			action: 'update',
			password: fields.pwd1,
			email: fields.email
		});

		this.props.actions.changePassword(allFields).then(data => {
			this.takeDownForm(`Password for user ${allFields.first_name} ${allFields.last_name} changed.`);
		}).catch(errObject => {
			var msg = errObject.message;
			this.setFormErrorMessage("An error occurred updating the user. " + msg);
		});
	}

	deleteUserCallback(userId) {
		this.props.actions.deleteUser({id: userId}).then(data => {
			this.props.actions.loadUsers().catch(errObject => {
				var msg = errObject.message || errObject;
				if (msg === "Conflict") {
					msg = "Check that a user with that name does not already exist."
				}
				this.setFormErrorMessage("An error occurred loading the user. " + msg);
			});
			this.takeDownForm("User deleted.");
		}).catch(errObject => {
			this.setFormErrorMessage("There was a problem deleting the user. " + errObject.message);
		});
	}

	attributeAssignment(fields) {
		var userID = fields.login_id;
		var attributeID = parseInt(fields.attribute_id);
		var attributeValue = fields.attribute_value;

		this.props.actions.addLoginAttributes(userID, attributeID, attributeValue).then(data => {
			this.props.actions.loadLoginAttributes(userID).then(data => {
				this.setFormSuccessMessage(`User ${fields.email} updated with attribute ${attributeValue}.`);
			});
		}).catch(errObject => {
			this.setFormErrorMessage("There was a problem assigning the attribute. " + errObject.message);
		});
	}

	userAssignment(fields) {
		var agId = fields.account_group_id;
		var appCode = fields.application_type_code;
		var roleCode = fields.role_type_code;

		if ((appCode && !roleCode) || (roleCode && !appCode)) {
			this.setFormErrorMessage("You must specify both an application and a role or neither");
			return;
		}

		this.props.actions.updateUserRelationship(fields.login_id, {
			action: "add",
			app_code: (appCode && roleCode ? appCode : undefined),
			role_type_code: (appCode && roleCode ? roleCode : undefined),
			account_group_id: agId
		}).then(data => {
			this.props.actions.loadUser(fields.login_id).then(data => {
				this.setFormSuccessMessage("User \"" + fields.email + "\" updated.");
			}).catch(errObject => {
				var msg = errObject.message || errObject;

				if (msg === "Conflict") {
					msg = "Check that a user with that name does not already exist."
				}
				this.setFormErrorMessage("An error occurred loading the user. " + msg);
			});
		}).catch(errObject => {
			this.setFormErrorMessage("An error occurred updating the user. " + errObject.message);
		});
	}

	setFormState(formType, errorMessage, successMessage, id) {
		if (this.props.actions.formChangeHandler) {
			this.props.actions.formChangeHandler(formType, errorMessage, successMessage, id);
		}
	}

	setFormErrorMessage(errorMessage) {
		window.scrollTo(0, 0);
		this.props.actions.setErrMsg(errorMessage);
	}

	setFormSuccessMessage(successMessage) {
		window.scrollTo(0, 0);
		this.props.actions.setSuccessMsg(successMessage);
	}

	takeDownForm(successMessage) {
		this.setFormState(null, null, successMessage, null);
		window.scrollTo(0, 0);
	}

	cancelForm() {
		this.takeDownForm(null);
	}

	newUserClicked() {
		this.setFormState("insert", null,null,null);
	}

	render() {
		let logins = this.props.users;
		let errMsg = null;
		let successMsg= null;

		if (!logins) {
			return null;
		}

		if (this.props.currentForm && this.props.currentForm.errorMessage)
			errMsg = this.props.currentForm.errorMessage;

		if (this.props.currentForm && this.props.currentForm.successMessage)
			successMsg = this.props.currentForm.successMessage;

		var currentForm, object;
		var addNewUserTitle;
		var buttonList;
		var isSislUser = (this.props.usertype === "SISL");

		if (isSislUser) {
			addNewUserTitle = "Add new SISLNext user";
			// omit the Assign button for SISL users -- users are automatically assigned to the Admin's account group
			buttonList = [{label: "View", icon: "info", callback: _.wrap("show", this.setFormState)},
						  {label: "Edit", icon: "edit", callback: _.wrap("edit", this.setFormState)},
						  {label: "Delete", icon: "trash", callback: _.wrap("delete", this.setFormState)}
			];
		} else {
			addNewUserTitle = "Add new User";
			buttonList = [{label: "View", icon: "info", callback: _.wrap("show", this.setFormState)},
						  {label: "Edit", icon: "edit", callback: _.wrap("edit", this.setFormState)},
						  {label: "Password", icon: "key", callback: _.wrap("changepassword", this.setFormState)},
						  {label: "Assign", icon: "plus", callback: _.wrap("assign", this.setFormState)},
						  {label: "Attributes", icon: "id-card", callback: _.wrap("attribute", this.setFormState)},
						  {label: "Delete", icon: "trash", callback: _.wrap("delete", this.setFormState)}
			];
		}

		switch (this.props.currentForm && this.props.currentForm.formType) {
			case "insert":
				object = {};
				currentForm = <UserForm title={addNewUserTitle} object={object} errorMessage={errMsg}
										showAccountGroupDropdown={ !isSislUser } hidepwd={ isSislUser }
										submitCallback={this.addUserCallback} cancelCallback={this.cancelForm}/>;
				break;

			case "edit":
				object = logins ? _.find(logins, {login_id: this.props.currentForm.currentId}) : {};
				currentForm =
					<UserForm title="Edit User" hidepwd={true} object={object} errorMessage={errMsg}
							submitCallback={this.updateUserCallback} cancelCallback={this.cancelForm}/>;
				break;

			case "show":
				object = logins ? _.find(logins, {login_id: this.props.currentForm.currentId}) : {};
				currentForm = <UserForm title="User Details" readOnly={true} hidepwd={true} object={object}
										errorMessage={errMsg} showAccountGroupDropdown={ !isSislUser }
										cancelCallback={this.cancelForm}/>;
				break;

			case "changepassword":
				object = logins ? _.find(logins, {login_id: this.props.currentForm.currentId}) : {};
				currentForm =
					<ChangePassword title="Change Password" object={object} errorMessage={errMsg}
									submitCallback={this.changePasswordCallback} cancelCallback={this.cancelForm}/>;
				break;

			case "assign":
				object = logins ? _.find(logins, {login_id: this.props.currentForm.currentId}) : {};
				//object = logins ? logins[this.props.currentId] : {};
				currentForm = <AssignUser title="User Assignment" object={object} errorMessage={errMsg} successMessage={successMsg}
										  submitCallback={this.userAssignment} cancelCallback={this.cancelForm}/>;
				break;

            case "attribute":
                object = logins ? _.find(logins, {login_id: this.props.currentForm.currentId}) : {};
                currentForm = <AssignAttribute title="Attribute Assignment" object={object} errorMessage={errMsg} successMessage={successMsg}
											  text="Assign attributes to this User"
											  submitCallback={this.attributeAssignment} cancelCallback={this.cancelForm}/>;
                break;

			case "delete":
				object = logins ? _.find(logins, {login_id: this.props.currentForm.currentId}) : {};
				currentForm = <ConfirmForm title="Delete User"
										text={"Are you sure you want to permanently remove User " + object.email + " ?"}
										args={this.props.currentForm.currentId}
										confirmCallback={this.deleteUserCallback} cancelCallback={this.cancelForm}/>;
				break;
		}

		return (
			<ContentWrap title={this.props.title || "Users"} successMessage={successMsg} errorMessage={errMsg} form={currentForm}>
				<div className="search">
					<TableSearch
						onUserInput={ this.handleUserInput }
						filterText={ this.state.filterText }
					></TableSearch>
					<div className="pull-right form-inline">
						<button className="btn btn-primary btn-sm searchBtn" title="Add User" onClick={this.newUserClicked}>
							{addNewUserTitle}
						</button>
					</div>
				</div>
				<div className="clearfix"></div>
				<DataTable
					id="login_id"
					columns={ this.getColumns() }
					dataItems={ logins }
					rowsPerPage={ this.state.rowsPerPage }
					filterText={ this.state.filterText }
					initialSortKey={ this.state.initialSortKey }
					buttonIDPrefix={ "email" }
					buttons={ buttonList }
				></DataTable>
			</ContentWrap>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		users: state.adminReducer.users,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, userActions, formStateActions, attributeActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
