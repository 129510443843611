import React from 'react';
import _ from 'lodash';
import APILinkConfigFormConverter from './APILinkConfigFormConverter';

const APILinkConfigAddRemoveRows = {
	getAuthFields: function(mainPageObj)
	{
		let authFieldsCopy;
		// First check whether you've made any changes to the form.
		// If not, copy form data from our own state from the server.
		// Otherwise, copy data from the form object which only sends us data once somebody changes a form field.
		if (_.isEmpty(mainPageObj.formObjectAttributeData))
			authFieldsCopy = _.cloneDeep(mainPageObj.state.authData);
		// Here we get all form fields. Then we use a converter function to extract all auth fields into an object.
		// We need to get current values before we update state with the new row.
		else
			authFieldsCopy = APILinkConfigFormConverter.authFormFieldsToObject(mainPageObj.formObjectAttributeData);
		return authFieldsCopy;
	},

	getAttributeFields: function(mainPageObj)
	{
		let attributeFieldsCopy;
		// First check whether you've made any changes to the form.
		// If not, copy form data from our own state from the server.
		// Otherwise, copy data from the form object which only sends us data once somebody changes a form field.
		if (_.isEmpty(mainPageObj.formObjectAttributeData))
			attributeFieldsCopy = _.cloneDeep(mainPageObj.state.attributeData);
		// Here we get all form fields. Then we use a converter function to extract all attribute fields into an array.
		// We need to get current values before we update state with the new row.
		else
			attributeFieldsCopy = APILinkConfigFormConverter.attributeFormFieldsToArray(mainPageObj.formObjectAttributeData, false);
		return attributeFieldsCopy;
	},

	addAuthRow: function(mainPageObj)
	{
		let authFieldsCopy = this.getAuthFields(mainPageObj);
		// We default to adding a new header.
		// There is no logic to this; it's only because we had to choose something as a default.
		if (!("headers" in authFieldsCopy))
			authFieldsCopy.headers = [];

		let key = "New_Field_";
		if (!mainPageObj.authFieldCount)
			mainPageObj.authFieldCount = authFieldsCopy.headers.length;
		mainPageObj.authFieldCount++;
		let keysSorted = Object.keys(mainPageObj.authDataObj).sort(function(a, b){return a - b});
		// Make the first auth number to be 1
		let highestIndex = keysSorted.length === 0 ? 0 : keysSorted[keysSorted.length - 1];
		highestIndex++;
		let newKeySuffix = highestIndex;
		if (newKeySuffix < 10)
			newKeySuffix = "0" + newKeySuffix;

		let newKey = key + (newKeySuffix);
		authFieldsCopy.headers.push({"name": newKey, value: ""});
		mainPageObj.authDataObj[highestIndex] = {"index": highestIndex, "fieldtype": "headers", "fieldname": newKey, "value": ""};

		// Get curent attribute fields in case the user added changes
		// Setting state here resets the form and could overwrite state... unless we copy it here
		mainPageObj.userEditedForm = true;
		let attributeFieldsCopy = this.getAttributeFields(mainPageObj);
		mainPageObj.setState({
			version: mainPageObj.state.version + 1,
			authData: authFieldsCopy,
			successMessage: null,
			formErrorMessage: null
		});
	},

	confirmRemoveAuthRow: function(mainPageObj, event)
	{
		if (event.target)
		{
			let rowID = event.target.getAttribute("id");
			let authFieldName = "";
			let authUIData = APILinkConfigFormConverter.authObjToUI(mainPageObj.state.authData, mainPageObj);
			for (let authRowIndex = 0, authDetailLen = authUIData.length; authRowIndex < authDetailLen; authRowIndex++)
			{
				if (authUIData[authRowIndex].index.toString() === rowID)
				{
					authFieldName = authUIData[authRowIndex].fieldname;
					break;
				}
			}

			let confirmMessage = `Are you sure you want to delete the authentication field ${authFieldName}? `;

			mainPageObj.setState({
				successMessage: null,
				formErrorMessage: null,
				formConfirmMessage: (<div>
					<span>{confirmMessage}</span>
					<button onClick={((e) => this.removeAuthRow(mainPageObj, rowID, authFieldName))}>Yes</button>
					<button onClick={mainPageObj.resetFormMessages}>No</button>
					</div>)
			});
		}
		else
		{
			mainPageObj.setState({
				successMessage: null,
				formErrorMessage: "I'm sorry, there was an error trying to delete the authentication field",
				formConfirmMessage: null
			});
		}
		window.scrollTo(0, 0);
	},

	removeAuthRow: function(mainPageObj, rowID, authFieldName)
	{
		let authFieldsCopy;
		// First check whether you've made any changes to the form.
		// If not, copy form data from our own state from the server.
		// Otherwise, copy data from the form object which only sends us data once somebody changes a form field.
		if (_.isEmpty(mainPageObj.formObjectAttributeData))
			authFieldsCopy = _.cloneDeep(mainPageObj.state.authData);
		// Here we get all form fields. Then we use a converter function to extract all auth fields into an object.
		// We need to get current values before we update state with the new row.
		else
			authFieldsCopy = APILinkConfigFormConverter.authFormFieldsToObject(mainPageObj.formObjectAttributeData);

		// Delete from UI auth array
		let authUIData = APILinkConfigFormConverter.authObjToUI(authFieldsCopy, mainPageObj);
		let fieldtype, fieldname, value;
		for (let authRowIndex = 0, authDetailLen = authUIData.length; authRowIndex < authDetailLen; authRowIndex++)
		{
			if (authUIData[authRowIndex].index.toString() === rowID)
			{
				fieldtype = authUIData[authRowIndex].fieldtype;
				fieldname = authUIData[authRowIndex].fieldname;
				value = authUIData[authRowIndex].value;
				break;
			}
		}

		for (let authField in authFieldsCopy)
		{
			if (authField === fieldtype)
			{
				if (fieldtype === "qs" || fieldtype === "headers" || fieldtype === "certs")
				{
					for (let subArrIndex = 0, subArrLen = authFieldsCopy[authField].length; subArrIndex < subArrLen; subArrIndex++)
					{
						let subItem = authFieldsCopy[authField][subArrIndex];
						if (subItem.name === fieldname && subItem.value === value)
						{
							authFieldsCopy[authField].splice(subArrIndex, 1);
							break;
						}
					}
				}
				else if (fieldtype === "request")
				{
					for (let requestItem in authFieldsCopy[authField])
					{
						if (requestItem === fieldname && authFieldsCopy[authField][requestItem] === value)
						{
							delete authFieldsCopy[authField][requestItem];
							break;
						}
					}
				}
				else if (fieldtype === "username" || fieldtype === "password")
				{
					delete authFieldsCopy[fieldtype];
				}
				break;
			}
		}

		// Delete from form fields object which is used by global page form
		let formObjectAttributeDataCopy = mainPageObj.formObjectAttributeData;
		if (!_.isEmpty(formObjectAttributeDataCopy))
		{
			delete formObjectAttributeDataCopy["fieldtype_" + rowID];
			delete formObjectAttributeDataCopy["fieldname_" + rowID];
			delete formObjectAttributeDataCopy["value_" + rowID];
		}

		// Delete from auth data store which connects auth JSON with UI array
		delete mainPageObj.authDataObj[rowID];

		mainPageObj.setState({
			version: mainPageObj.state.version + 1,
			authData: authFieldsCopy,
			successMessage: `Successfully removed the authentication row ${authFieldName}`,
			formErrorMessage: null,
			formConfirmMessage: null
		});
		mainPageObj.userEditedForm = true;
	},

	/**
		We allow up to 10 new rows at a time
	**/
	addAttributeRow: function(mainPageObj)
	{
		let key = "New_Field_";
		let newKey;
		for (let x = 1; x <= 10; x++)
		{
			let foundKey = false;
			for (let attrIndex = 0, attrLen = mainPageObj.state.attributeData.length; attrIndex < attrLen; attrIndex++)
			{
				if (mainPageObj.state.attributeData[attrIndex].api_link_attribute_id === (key + x))
				{
					foundKey = true;
					break;
				}
			}
			if (!foundKey)
			{
				newKey = (key + x)
				break;
			}
		}

		if (newKey)
		{
			let newRow = {api_data_type_id: 1,
				api_link_attribute_id: newKey,
				default_value: "",
				description: "",
				name: newKey,
				test_value: ""
			};

			let attributeFieldsCopy = this.getAttributeFields(mainPageObj);
			attributeFieldsCopy.push(newRow);

			// Get curent auth fields in case the user added changes
			// Setting state here resets the form and could overwrite state... unless we copy it here
			mainPageObj.userEditedForm = true;
			let authFieldsCopy = this.getAuthFields(mainPageObj);
			mainPageObj.setState({
				version: mainPageObj.state.version + 1,
				attributeData: attributeFieldsCopy,
				successMessage: null,
				formErrorMessage: null
			});
			window.scrollTo(0, document.body.scrollHeight + 85);
		}
		else
		{
			mainPageObj.setState({
				successMessage: null,
				formErrorMessage: "You can only add 10 new attribute rows at a time. Please save your data to the server."
			});
			window.scrollTo(0, 0);
		}
	},

	confirmRemoveAttributeRow: function(mainPageObj, event)
	{
		if (event.target)
		{
			let rowID = event.target.getAttribute("id");
			let attributeArray;
			if (_.isEmpty(mainPageObj.formObjectAttributeData))
				attributeArray = mainPageObj.state.attributeData;
			// Here we get all form fields. Then we use a converter function to extract all attribute fields into an array.
			// We need to get current values before we update state with the new row.
			else
				attributeArray = APILinkConfigFormConverter.attributeFormFieldsToArray(mainPageObj.formObjectAttributeData, false);

			let attributeIndex = _.findIndex(attributeArray, function(attr) { return attr.api_link_attribute_id.toString() === rowID; });
			let attrName = (attributeIndex !== -1) ? attributeArray[attributeIndex].name : "";

			let confirmMessage = `Are you sure you want to delete the api link attribute ${attrName}?`;

			mainPageObj.setState({
				successMessage: null,
				formErrorMessage: null,
				formConfirmMessage: (<div>
					<span>{confirmMessage}</span>
					<button onClick={((e) => this.removeAttributeRow(mainPageObj, rowID, attrName))}>Yes</button>
					<button onClick={mainPageObj.resetFormMessages}>No</button>
					</div>)
			});
		}
		else
		{
			mainPageObj.setState({
				successMessage: null,
				formErrorMessage: "I'm sorry, there was an error trying to delete the API link attribute",
				formConfirmMessage: null
			});
		}
		window.scrollTo(0, 0);
	},

	removeAttributeRow: function(mainPageObj, rowID, attrName)
	{
		let attributeFieldsCopy;
		if (_.isEmpty(mainPageObj.formObjectAttributeData))
			attributeFieldsCopy = _.cloneDeep(mainPageObj.state.attributeData);
		// Here we get all form fields. Then we use a converter function to extract all attribute fields into an array.
		// We need to get current values before we update state with the new row.
		else
			attributeFieldsCopy = APILinkConfigFormConverter.attributeFormFieldsToArray(mainPageObj.formObjectAttributeData, false);

		for (let attrIndex = 0, attrLen = attributeFieldsCopy.length; attrIndex < attrLen; attrIndex++)
		{
			if (attributeFieldsCopy[attrIndex].api_link_attribute_id.toString() === rowID)
			{
				attributeFieldsCopy.splice(attrIndex, 1)
				break;
			}
		}

		// Delete from form fields object which is used by global page form
		let formObjectAttributeDataCopy = mainPageObj.formObjectAttributeData;
		if (!_.isEmpty(formObjectAttributeDataCopy))
		{
			delete formObjectAttributeDataCopy["api_link_attribute_id_" + rowID];
			delete formObjectAttributeDataCopy["api_data_type_id_" + rowID];
			delete formObjectAttributeDataCopy["name_" + rowID];
			delete formObjectAttributeDataCopy["description_" + rowID];
			delete formObjectAttributeDataCopy["default_value_" + rowID];
		}

		mainPageObj.setState({
			version: mainPageObj.state.version + 1,
			attributeData: attributeFieldsCopy,
			successMessage: `Successfully removed the attribute row ${attrName}`,
			formErrorMessage: null,
			formConfirmMessage: null
		});
		mainPageObj.userEditedForm = true;
	}
};

export default APILinkConfigAddRemoveRows;
