import {combineReducers} from 'redux';
import AdminReducer from './reducer-admin';
import ActiveUserReducer from './reducer-active-user';
import AccountReducer from './reducer-account';
import ApiLinkReducer from './reducer-apilink';
import TransformationReducer from './reducer-transformation';
import DashboardReducer from './reducer-dashboard';
import CacheReducer from './reducer-cache';
import EmailDomainReducer from './reducer-emaildomain';
import SessionReducer from './reducer-session';
import ShortcodeReducer from './reducer-shortcode';
import EmailBEETemplateReducer from './reducer-emailbeetemplates';
import UnsubscribeGroupReducer from './reducer-unsubscribegroup';
import UserRedirect from './reducer-login-redirect';
import VendorReducer from './reducer-vendor';
import FormStateReducer from './reducer-formstate';
import AttributeReducer from './reducer-attribute'
import { connectRouter } from 'connected-react-router'


/*
 * We combine all reducers into a single object before updated data is dispatched (sent) to store
 * Your entire applications state (store) is just whatever gets returned from all your reducers
 * */

export default (history) => combineReducers({
	router: connectRouter(history),
	UserRedirect: UserRedirect,
	accountReducer: AccountReducer,
	activeUser: ActiveUserReducer,
	adminReducer: AdminReducer,
	apiLinkReducer: ApiLinkReducer,
	transformationReducer: TransformationReducer,
	cacheReducer: CacheReducer,
	emailDomainReducer: EmailDomainReducer,
	emailBEETemplateReducer: EmailBEETemplateReducer,
	unsubscribeGroupReducer: UnsubscribeGroupReducer,
	formStateReducer: FormStateReducer,
	dashboardReducer: DashboardReducer,
	sessionReducer: SessionReducer,
	shortcodeReducer: ShortcodeReducer,
	vendorReducer: VendorReducer,
	attributeReducer: AttributeReducer
});
