import React, { Component } from 'react';
import _ from 'lodash'
import { Redirect } from 'react-router-dom'

class SimpleForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tokenExpired: false
		};

		this.saveCallback = this.saveCallback.bind(this);
		this.fieldchangecallback = this.fieldchangecallback.bind(this);
		this.cancelButtonCallback = this.cancelButtonCallback.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.errorMessage && this.props.errorMessage.includes("The access token provided is expired, revoked, malformed")) {
			setTimeout( function() {
				this.setState({
					tokenExpired: true
				});
			}.bind(this), 3000);
		}
	}

	saveCallback(evt) {
		if (this.props.saveCallback) {
			this.props.saveCallback();
		}
	}

	fieldchangecallback(evt, objectChanges) {
		if (this.props.parentDataCallback)
			this.props.parentDataCallback(objectChanges);
	}

	cancelButtonCallback() {
		if (this.props.cancelCallback) {
			this.props.cancelCallback();
		}
	}

	render() {
		const cssStyle = { 'width': this.props.width || 500, 'minWidth': 500 };
		const cssHideTitle = { 'display': this.props.hideTitle ? "none" : "block" };
		let hasRequiredField = false;
		let message;
		let redirectBlock;

		if (this.state.tokenExpired == true)
			redirectBlock= <Redirect to='/login' />

		if (this.props.errorMessage) {
			message = <div className="alert alert-danger" id="statusMessage">{this.props.errorMessage}</div>;
		} else if (this.props.successMessage) {
			message = <div className="alert alert-success" id="statusMessage">{this.props.successMessage}</div>;
		} else if (this.props.confirmMessage) {
			message = <div className="alert alert-warning" id="statusMessage">{this.props.confirmMessage}</div>;
		} else {
			message = this.props.hideTitle ? '' : <br/>;
		}

		React.Children.forEach(this.props.children, function (child) {
			if (!child) return;
			if (child.props.required) {
				hasRequiredField = true;
			}
		});

		let requiredMessage;
		if (hasRequiredField && !this.props.readOnly) {
			requiredMessage = (
				<div style={{ float: "left", marginLeft: "10px" }}>
					<span className="required-mark" style={{ marginTop: "-4px" }}>*</span> indicates required field</div>
			);
		}

		var saveButton;
		var saveButtonLabel = this.props.saveButtonLabel || 'Save';
		if (this.props.saveCallback && ! this.props.readOnly) {
			saveButton = (
				<button id="formSaveButton" className="btn btn-primary btn-sm" onClick={this.saveCallback}>{ saveButtonLabel }</button>
			);
		}
		var cancelButton;
		var cancelButtonLabel = this.props.cancelButtonLabel || 'Cancel';
		if (this.props.cancelCallback) {
			cancelButton = (
				<button id="formCancelButton" className="btn btn-secondary btn-sm" onClick={this.cancelButtonCallback}>{ cancelButtonLabel }</button>
			);
		}
		return (
			<div className="sl-form" style={cssStyle}>
				<div className="form-horizontal">
					{this.props.title?<h4 className="page-header" style={cssHideTitle}>{this.props.title}</h4>:null }
					<div className="clearfix"></div>
					{message}
					{ this.props.children }
				</div>
				<br/>
				{ requiredMessage }
				{ cancelButton }
				{ saveButton }
				{ redirectBlock }
			</div>
		);
	}
}

export default SimpleForm;
