/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

// "state = null" is set so that we don't throw an error when app first boots up
import * as types from '../actions/actionTypes';
export default function (state = null, action) {

    switch (action.type) {
        case types.CURRENT_URL_SELECTED:
            //console.log('CURRENT_URL_SELECTED',action);
            var o =  Object.assign({}, state, {curUrl: action.payload });
            return o;

        case types.LOG_OUT:
            return  Object.assign({}, state, {
                isLoggedIn: false,
                logout: "called",
                logOutDateTime: action.payload.logOutDT,
                currentUrl: action.payload
            });
            break;
    }
    return Object.assign({}, state, {
        currentUrl: "/about",
        isLoggedIn: false,
        userData: null
    });
}
