const url = window.location.href;
const start = url.indexOf('.') + 1;
const end = url.indexOf('.', start);
const domain = url.substring(start, end);
const debug_redirect = false;

let apiDomain, env;
if (url.match('workbench.silverlink.com') !== null || url.match('login.silverlink.com') !== null)
{
	apiDomain = "silverlink.com";
	env = "production";
}
else if (domain.match('ssde') !== null)
{
	apiDomain = domain + ".dev.silverlink.com";
	env = domain;
}
else if (debug_redirect)
{
	apiDomain = domain + ".silverlink.com";
	apiDomain = apiDomain.replace(".silverlink.com",".silverlink.com:444")
	env = domain;
}
else
{
	apiDomain = domain + ".silverlink.com";
	env = domain;
}

export const baseURL = "https://api." + apiDomain;
export const environment = env;
