import * as types from '../actions/actionTypes';
/*
 * All reducers get two parameters passed in, state and action that occurred
 *   > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

var initialState = {
	dashboards: null, // set null here to help render() function to skip rendering the remaining UI
	dashboardsDetails: {accountBasedFilters: []},
	importMetrics: null,
	dashboardAccountPrograms: {},
	newDashboard : null
};

export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_DASHBOARD_SUCCESS:
			return Object.assign({}, state, {dashboards: action.payload});
		case types.GET_DASHBOARD_DETAILS_SUCCESS:
			return Object.assign({}, state, {dashboardsDetails: action.payload});
		case types.GET_DASHBOARD_METRICS_SUCCESS:
			return Object.assign({}, state, {dashboardMetrics: action.payload});
		case types.GET_DASHBOARD_ACCOUNT_PROGRAMS_SUCCESS:
			let newData = {};
			newData[action.payload.account_id] =  action.payload.programs;
			let newState = Object.assign({}, state.dashboardAccountPrograms, newData);
			return Object.assign({}, state, {dashboardAccountPrograms: newState});
		case types.SAVE_DASHBOARD_SUCCESS:
			return Object.assign({}, state, {newDashboard: action.payload});
		default:
			return state;
	}
	return state;
}
