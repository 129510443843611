import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as accountActions  from '../../../actions/accountActions';
import * as unsubscribegroupActions  from '../../../actions/unsubscribegroupActions';
import * as formStateActions  from '../../../actions/formStateActions';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import _ from 'lodash';

class UnsubscribeGroupAccountForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentAccountToAdd: {account_id: null}
		};
		this._isMounted = false;

		this.addUnsubscribeGroupAccount = this.addUnsubscribeGroupAccount.bind(this);
		this.removeUnsubscribeGroupAccount = this.removeUnsubscribeGroupAccount.bind(this);
		this.takeDownForm = this.takeDownForm.bind(this);
		this.accountChange = this.accountChange.bind(this);
		this.cancelButtonCallback = this.cancelButtonCallback.bind(this);
		this.setFormErrorMessage = this.setFormErrorMessage.bind(this);
		this.setFormSuccessMessage = this.setFormSuccessMessage.bind(this);
	}

	takeDownForm(successMessage) {
		window.scrollTo(0, 0);
	}

	componentDidMount() {
		let obj = this.props.object;
		if (obj && obj.email_unsubscribe_group_id) {
			this.props.actions.loadUnsubscribeGroupAccounts(obj.email_unsubscribe_group_id).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the unsubscribe group accounts: " + msg);
			});
		}
		this.props.actions.loadAccounts().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the activate accounts: " + msg);
		});
	}

	componentWillUnmount() {
		;
	}

	componentDidUpdate(prevProps) {
		window.scrollTo(0, 0)
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			errorMessage: errorMessage,
			successMessage: null
		});
	}

	setFormSuccessMessage(successMessage) {
		this.setState({
			errorMessage: null,
			successMessage: successMessage
		});
	}

	_onChange() {
		// repaint the form when data loads
		if (this._isMounted) {
			this.forceUpdate();
		}
	}

	extractJSONFields(text) {
		try {
			return JSON.parse(text);
		} catch(err) {
			return {};
		}
	}

	addUnsubscribeGroupAccount() {
		let email_unsubscribe_group_id = this.props.object.email_unsubscribe_group_id;
		let account_id = this.state.currentAccountToAdd.account_id;
		this.props.actions.addUnsubscribeGroupAccount(email_unsubscribe_group_id, account_id).then(data => {
			this.props.actions.loadUnsubscribeGroupAccounts(email_unsubscribe_group_id).then(data => {
				this.takeDownForm(null);
				this.setFormSuccessMessage(`Unsubscribe group account ${account_id} successfully added`);
			}).catch(errObject => {
				this.takeDownForm(null);
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the unsubscribe group accounts: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred adding the unsubscribe group account: " + msg);
		});
	}

	removeUnsubscribeGroupAccount(account_id) {
		let email_unsubscribe_group_id = this.props.object.email_unsubscribe_group_id;
		this.props.actions.removeUnsubscribeGroupAccount(email_unsubscribe_group_id, account_id).then(data => {
			this.props.actions.loadUnsubscribeGroupAccounts(email_unsubscribe_group_id).then(data => {
				this.takeDownForm(null);
				this.setFormSuccessMessage(`Unsubscribe group account ${account_id} successfully removed`);
			}).catch(errObject => {
				this.takeDownForm(null);
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the unsubscribe group accounts: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred removing the unsubscribe group: " + msg);
		});
	}

	cancelButtonCallback()
	{
		this.props.actions.loadUnsubscribeGroups().then(successObject => {
			if (this.props.cancelCallback)
				this.props.cancelCallback();
		}).catch(errObject => {
			if (this.props.cancelCallback)
				this.props.cancelCallback();
		});
	}

	accountChange(event, fieldChanges) {
		this.setState({
			currentAccountToAdd: _.extend(this.state.currentAccountToAdd, fieldChanges),
			applicationErrorMessage: null,
			formErrorMessage: null
		});
	}

	renderAvailable() {
		let accountTagTextFunction = function(obj) {
			return obj.account_name + ' (' + obj.account_id + ')';
		};
		let allAccounts = this.props.accounts;
		let addedAccounts = this.props.unsubscribeGroupAccounts;
		let aryAddAccounts = _.values(addedAccounts);

		let unSignedAccounts = _.filter(allAccounts, function (o) {
			let idx = _.findIndex(aryAddAccounts, {account_id: o.account_id});
			return idx === -1;
		}) ;

		let availableData = {
			label: "Account",
			fieldid: "account_id",
			fieldName: "account_name",
			optionid: "account_id",
			fieldvalue: this.state.currentAccountToAdd.account_id,
			options: unSignedAccounts,
			localFieldChangeCallback: this.accountChange,
			errorMessage: this.state.applicationErrorMessage,
			assinedCb: this.addUnsubscribeGroupAccount
		};

		return (
			<div className="form-group row">
				<label className="col-form-label col-md-2" style={{ lineHeight: "16px"}}>Accounts</label>
				<div className="form-control-plaintext col-md-10" >
					<FormField.AssignedTagList
						objects={ aryAddAccounts }
						keyField="account_id"
						tagText={ accountTagTextFunction }
						sortField = "account_name"
						readOnly = {this.props.readOnly}
						emptyMessage="No account assigned"
						removeCallback={ this.removeUnsubscribeGroupAccount }
						buttonIDPrefix={ "unsubscribe_group" }
						available = {availableData} >
					</FormField.AssignedTagList>
				</div>
			</div>
		);
	}

	render() {
		let object = this.props.object;
		let defaultUnsubscribeGroupValue = "group_name" in object ? object.group_name : "";
		return (
			<Form {...this.props} width="800" height="580" cancelCallback={this.cancelButtonCallback} cancelButtonLabel="Done" successMessage={ this.state.successMessage } errorMessage={ this.state.errorMessage }>
				<FormField.Input dontModify= {true} fieldid="group_name" fieldvalue= {defaultUnsubscribeGroupValue} width="2" label="Unsubscribe Group" labelWidth="2"  />

				{ this.renderAvailable()}
			</Form>
		);
	}
}

function mapStateToProps(state, ownProps) {
	// FIREFOX BUG WORKAROUND
	// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
	// It doesn't like the number 1 as a computed key in an object, even once it's stringified
	return {
		currentForm: state.formStateReducer.currentForm,
		unsubscribeGroupAccounts: state.unsubscribeGroupReducer.accountUnsubscribegroups['ID_' + ownProps.object.email_unsubscribe_group_id],
		accounts: state.accountReducer.accounts
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, accountActions, unsubscribegroupActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(UnsubscribeGroupAccountForm)
