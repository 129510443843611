import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as emaildomainActions  from '../../../actions/emaildomainActions';
import * as formStateActions  from '../../../actions/formStateActions';
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';
import ConfirmForm from '../../common/ConfirmForm';
import EmailDomainForm from './EmailDomainForm';
import EmailDomainAccountForm from './EmailDomainAccountForm';
import _ from 'lodash';

class EmailDomains extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filterText: '',
			initialSortKey: 'email_domain_url',
			currentForm: null,
			currentPage: 1,
			rowsPerPage: 50,
			errorMessage: '',
			successMessage: ''
		};
		this._isMounted = false;

		/*
			These binds are used to access the functions in EmailDomains.js from sub forms.
			For example:
			Suppose you add a domain. Now you want to relist the front page.
			Without the calls below, you couldn't access the takeDownForm function
			from the addDomainCallback with passing it along to the sub form.
			Here we just bind the parent form context to that function.
		*/
		this.handleUserInput = this.handleUserInput.bind(this);
		this.setFormState = this.setFormState.bind(this);
		this.cancelForm = this.cancelForm.bind(this);
		this.takeDownForm = this.takeDownForm.bind(this);
		this.updateCallback = this.updateCallback.bind(this);
		this.addDomainCallback = this.addDomainCallback.bind(this);
		this.deleteEmailDomainCallback = this.deleteEmailDomainCallback.bind(this);
		this.newEmaildomainClicked = this.newEmaildomainClicked.bind(this);
		this.setFormState("list-emaildomain", null,null,null);
	}

	getColumns() {
		return {
			email_domain_url:		{ name: "Domain URL", used: 1, width: 300, click_action: "View", required: true },
			accounts_assigned:		{ name: "Accounts", used: 1, width: 100, required: false }
		};
	}

	getFieldIds() {
		return _.keys(this.getColumns());
	}

	getRequiredFieldIds() {
		return _.keys(_.pickBy(this.getColumns(), 'required'));
	}

	handleUserInput(filterText) {
		this.setState({
			filterText: filterText
		});
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			currentForm: nextProps.currentForm
		});
	}

	componentDidMount() {
		this._isMounted = true;

		this.props.actions.loadEmailDomains().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the email domains: " + msg);
		});
	}

	componentWillUnmount() {
	}

	_onChange() {
		this.setState({
			errorMessage: null
		});
	}

	newEmaildomainClicked() {
		this.setFormState("insert-emaildomain", null,null,null);
	}

	addDomainCallback(fields) {
		this.props.actions.addEmailDomain(fields.email_domain_url).then(data => {
			this.props.actions.loadEmailDomains().then(data => {
				this.takeDownForm(`Successfully added email domain ${fields.email_domain_url}`);
			}).catch(errObject => {
				this.takeDownForm(null);
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the email domains: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred adding the email domain: " + msg);
		});
	}

	updateCallback(fields) {
		this.props.actions.updateEmailDomain(fields.email_domain_id, fields.email_domain_url).then(data => {
			this.props.actions.loadEmailDomains().then(data => {
				this.takeDownForm(`Successfully updated email domain ${fields.email_domain_url}`);
			}).catch(errObject => {
				this.takeDownForm(null);
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the email domains: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred updating the email domain: " + msg);
		});
	}

    deleteEmailDomainCallback(fields) {
        this.props.actions.deleteEmailDomain({id: fields.email_domain_id}).then(data => {
            this.props.actions.loadEmailDomains().catch(errObject => {
                var msg = errObject.message || errObject;
                if (msg === "Conflict") {
                    msg = "Check that an email domain with that name does not already exist."
                }
                this.setFormErrorMessage("An error occurred loading the api email domain. " + msg);
            });
            this.takeDownForm(`Successfully deleted email domain ${fields.email_domain_url}`);
        }).catch(errOject => {
            this.setFormErrorMessage("There was a problem deleting the email domain. " + errOject.message);
        })
    }

	setFormState(formType, errorMessage, successMessage, id) {
		if (this.props.actions.formChangeHandler)
			this.props.actions.formChangeHandler(formType, errorMessage, successMessage, id);
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			errorMessage: errorMessage
		});
	}

	setFormSuccessMessage(successMessage) {
		this.setState({
			successMessage: successMessage
		});
	}

	takeDownForm(successMessage) {
		this.setFormState(null, null, successMessage, null);
		window.scrollTo(0, 0);
	}

	clearErrorMessage() {
		this.setState({
			errorMessage: null
		});
	}

	cancelForm() {
		this.takeDownForm(null);
		this.clearErrorMessage();
	}

	render() {
		let currentForm, object;
		let allEmailDomains = this.props.emaildomains;
		if (!allEmailDomains) {
			return null;
		}

		let errMsg = null;
		let successMsg= null;

		if (this.state.currentForm && this.state.currentForm.errorMessage)
			errMsg = this.state.currentForm.errorMessage;

		if (this.state.currentForm && this.state.currentForm.successMessage)
			successMsg = this.state.currentForm.successMessage;

		let addNewDomainTitle;
		let buttonList;

		addNewDomainTitle = "Add new email domain";
		buttonList = [	{ label: "View", icon: "info", callback: _.wrap("show-emaildomain", this.setFormState) },
						{ label: "Edit", icon: "edit", callback: _.wrap("edit-emaildomain", this.setFormState) },
						{ label: "Assign", icon: "plus", callback: _.wrap("assign-emaildomain", this.setFormState) },
						{ label: "Delete", icon: "trash", callback: _.wrap("delete-emaildomain", this.setFormState) }
					];

		switch (this.props.currentForm && this.props.currentForm.formType) {
			case "insert-emaildomain":
				object = {};
				currentForm = <EmailDomainForm title={addNewDomainTitle} object={object} errorMessage={this.state.errorMessage}
										submitCallback={this.addDomainCallback} cancelCallback={this.cancelForm}/>;
				break;

			case "edit-emaildomain":
				object = allEmailDomains ? _.find(allEmailDomains, {email_domain_id: this.props.currentForm.currentId}) : {};
				currentForm = <EmailDomainForm title="Edit Email Domain" object={object} errorMessage={this.state.errorMessage}
										submitCallback={this.updateCallback} cancelCallback={this.cancelForm}/>;
				break;
			case "show-emaildomain":
				object = allEmailDomains ? _.find(allEmailDomains, {email_domain_id: this.props.currentForm.currentId}) : {};
				currentForm = <EmailDomainForm title="Email Domain Details" readOnly={true} hidepwd={true} object={object} errorMessage={this.state.errorMessage}
											 showAccountGroupDropdown= { false } cancelCallback={this.cancelForm}/>;
				break;

			case "assign-emaildomain":
				object = allEmailDomains ? _.find(allEmailDomains, {email_domain_id: this.props.currentForm.currentId}) : {};
				if (object) {
					currentForm = <EmailDomainAccountForm title="Assign Account" readOnly={false} object={object}
										errorMessage={this.state.errorMessage}
										cancelCallback={this.cancelForm}/>;
				}
				break;
			case "delete-emaildomain":
				object = allEmailDomains ? _.find(allEmailDomains, {email_domain_id: this.props.currentForm.currentId}) : {};
				currentForm = <ConfirmForm title="Delete Email Domain" text={"Are you sure you want to permanently remove email domain \"" + object.email_domain_url + "\" ?"}
										   args={object} errorMessage={this.state.errorMessage}
										   confirmCallback={this.deleteEmailDomainCallback} cancelCallback={this.cancelForm}/>;
				break;
		}

		return (
			<ContentWrap title={this.props.title || "Email Domains"} successMessage={successMsg} errorMessage={errMsg} form={currentForm}>
				<div className="search">
					<TableSearch
						onUserInput={ this.handleUserInput }
						filterText={ this.state.filterText }>
					</TableSearch>
					<div className="pull-right form-inline">
						<button className="btn btn-primary btn-sm searchBtn" title="Add Email Domain" onClick={this.newEmaildomainClicked}>{addNewDomainTitle}</button>
					</div>
				</div>
				<div className="clearfix"></div>
				<DataTable
					id="email_domain_id"
					columns={ this.getColumns() }
					dataItems={ allEmailDomains }
					rowsPerPage={ this.state.rowsPerPage }
					filterText={ this.state.filterText }
					initialSortKey={ this.state.initialSortKey }
					buttonIDPrefix={ "email_domain_url" }
					buttons={ buttonList }>
				</DataTable>
			</ContentWrap>

		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		emaildomains: state.emailDomainReducer.emaildomains,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, emaildomainActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailDomains)
