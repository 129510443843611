import * as types from './actionTypes';
import api from './api';

export function loadAccounts() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/auth/accounts'
		}).then(response => {
			dispatch({type: types.GET_ACCOUNTS_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadAccountGroups() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/auth/accountgroups',
		}).then(users => {
			dispatch({type: types.GET_ACCOUNTGROUPS_SUCCESS, payload: users});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadAccountGroupDetails(account_group_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/auth/accountgroups/${account_group_id}`
		}).then(accountGroupDetails => {
			dispatch({type: types.GET_ACCOUNTGROUP_DETAILS_SUCCESS, payload: accountGroupDetails, id: account_group_id});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addAccountGroup(account_group_name) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: '/auth/accountgroups',
			data: {
				account_group_name: account_group_name
			}
		}).then(users => {
			dispatch({type: types.ADD_ACCOUNTGROUPS_SUCCESS, payload: users});
		}).catch(error => {
			throw(error);
		});
	};
}

export function editAccountGroup(account_group_id, account_group_name) {
	return function(dispatch) {
		return api({
			method: 'put',
			url: `/auth/accountgroups/${account_group_id}`,
			data: {
				account_group_name: account_group_name
			}
		}).then(users => {
			dispatch({type: types.EDIT_ACCOUNTGROUPS_SUCCESS, payload: users});
		}).catch(error => {
			throw(error);
		});
	};
}

export function setDashboardAvailableAccounts(newState) {
	return function(dispatch) {
		dispatch({type: types.GET_ACCOUNTS_SUCCESS, payload: newState});
	};
}