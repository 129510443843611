import React, {Component} from 'react';
import {connect} from 'react-redux';
import validator from 'validator';
import PasswordFooter from './PasswordFooter';

const SlinkAuth = window.SlinkAuth;
const clientData = window.clientData;

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        var urlArgs = SlinkAuth.getUrlArgs(window.location.search);
        const clientId = urlArgs['client_id'] || urlArgs['clientid']  || '';
        let appName = null;
        if (!SlinkAuth.getAppName(clientId)) {
            // if SlinkAuth has AppName, the appName display will be handled in app.js
            // here it is for the case of no clientdata is available when calling from Activate
            appName = clientId.substring(0, clientId.indexOf(".")).toUpperCase();
        }

        this.state = {
            client_id: clientId,
            nonce: urlArgs['nonce'] || '',
            emailSentMessage: null,
            appName: appName
        }

        if(this.state.appName === null){
            var applicationName = window.SlinkAuth.getAppName(this.state.client_id);
            document.title = "Personify Health "+applicationName;
        }
        else{
            document.title = "Personify Health "+this.state.appName;
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.renderErrorBlock = this.renderErrorBlock.bind(this);
        this.textChange = this.textChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        //console.log('forgot password this.state.client_id', this.state.client_id);
    }

    renderErrorBlock() {
        var fieldErrorMessage = this.state.fieldMessage;
        if (!fieldErrorMessage) {
            return null;
        }
        return (
            <span className={"error-block"} style={{clear:"both", color: "#a94442"}}>{ fieldErrorMessage }</span>
        );
    }

    textChange(event) {
        var value = event.target.value;
        if(this.state.appName === 'CCD'){
            this.setState({
                username: value,
                fieldMessage: (value.trim().length > 150 || value.trim().toLowerCase().match(/(truncate|delete|exec|update|drop)/g) ? "Not a valid user name" : null )
            });
        }
        else{
            this.setState({
                username: value,
                fieldMessage: validator.isEmail(value.trim()) ? null : 'Not a valid email address'
            });
        }
    }

    handleKeyDown(event){
        if(event.key === "Enter"){
            event.preventDefault();
            event.stopPropagation();
            this.onSubmit();
        }
    }

    onSubmit() {
        var self = this;
        //var urlArgs = SlinkAuth.getAuthUrl(window.location.search);
        var urlArgs = SlinkAuth.getUrlArgs(window.location.search||' ');
        var userNameType = (this.state.appName === 'CCD' ? 'user name' : 'email address');

        //console.log('forgot password onsubmit url args clientid', self.state.client_id);
        //console.log('forgot password self.state.fieldMessage', self.state.fieldMessage);
        //console.log('forgot password self.state.username', self.state.username);

        if (self.state.fieldMessage || !self.state.username) {
            self.setState({
                fieldMessage: (self.state.fieldMessage || `Please supply a valid ${userNameType}`)
            });
        } else if (self.state.client_id) {
            self.setState({
                emailSentMessage: "Sending the message, please wait.."
            });

            //console.log('forgot password submit has reached here!!');
            SlinkAuth.requestPasswordEmail(self.state.client_id, self.state.username, function (err) {
                var msg;
                if (err) {
                    msg = `There was a problem (${err}) sending the password reset email; try again later.`;
                } else {
                    msg = "An email containing a password reset link has been sent.";
                }
                self.setState({
                    emailSentMessage: msg
                });
            });
        }
    }

    render() {

        //console.log('forgot password form: location pathname', this.props.router.location.pathname);
        //console.log('Forgot password form: ClientData', clientData);
        var clientIdString = window.location.search;
        var innerDiv, userNameType, SearchType;
        if(this.state.appName !== 'CCD'){
            userNameType = '(which is you email address)';
            SearchType = 'Email Address';
        }
        else{
            userNameType = '';
            SearchType = 'User Name';
        }
        if (this.state.emailSentMessage) {
            innerDiv = (
                <div id="updatemessage" style={{width: 500, marginRight: 'auto', marginLeft: 'auto', marginTop: 75}}>
                    <p style={{width: 500, marginTop: 24, fontWeight: 'bold'}}>
                        { this.state.emailSentMessage }
                    </p>
                </div>
            );
        } else {
            innerDiv = (
                <div id="passwordform" style={{width: 500, margin: '40px auto 24px auto'}}>
                    <form>
                        <label style={{fontSize: '20px', color: '#202020', margin: "20px auto 30px auto"}}>Request an email to reset your password</label>
                        <p>To request an email that contains a link to reset your password, <br/>enter your username {userNameType} below.</p>
                        <br/>
                        <div className="form-group row" style={{margin: '6px auto'}}>
                            <label htmlFor="username">{SearchType}</label>
                            <br/>
                            <input type="text" autoComplete="off" className="form-control"
                                   id="username" name="username"
                                   onChange={this.textChange}
                                   onKeyDown={this.handleKeyDown}
                                   placeholder="" autoFocus/>
                        </div>
                        { this.renderErrorBlock() }
                        <div style={{ paddingRight: '0px', marginTop: '24px' }}>
                            <button id="changesubmit" type="button" onClick={this.onSubmit }
                                    style={ {display: 'block', margin: '8px auto 0 auto', width: '120px'} } className='btn btn-primary'>Send Email</button>
                        </div>
                    </form>
                </div>
            );
        }
        return PasswordFooter(innerDiv, this.state.appName);
    }
}

function mapStateToProps(state, ownProps) {
    return {
        router: state.router
    }
}

// function mapDispatchToProps(dispatch) {
//     return {
//         auth: bindActionCreators(sessionActions, dispatch)
//     };
// }

export default connect(mapStateToProps, null) (ForgotPassword)
