import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as vendorActions  from '../../../actions/vendorActions';
import * as formStateActions  from '../../../actions/formStateActions';
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';
import VendorForm  from './VendorForm';
import VendorShortCodeForm  from './VendorShortcodeForm';
import VendorEmaildomainForm  from './VendorEmaildomainForm';
import ConfirmForm from '../../common/ConfirmForm';
import _ from 'lodash';

class Vendors extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filterText: '',
			initialSortKey: 'VendorName',
			formError: null,
			formSuccess: null,
			currentForm: null,
			currentPage: 1,
			rowsPerPage: 50
		};
		this._isMounted = false;

		this.handleUserInput  = this.handleUserInput.bind(this);
		this.setFormState     = this.setFormState.bind(this);
		this.newVendorClicked = this.newVendorClicked.bind(this);
		this.cancelForm       = this.cancelForm.bind(this);
		this.takeDownForm     = this.takeDownForm.bind(this);
		this.addUpdateVendorCallback = this.addUpdateVendorCallback.bind(this);
		this.deleteVendorAccountCallback = this.deleteVendorAccountCallback.bind(this);
		this.setFormErrorMessage = this.setFormErrorMessage.bind(this);
		this.setFormState("list-vendor", null,null,null);
	}

	getColumns(optionalVendorName) {
		return {
			VendorName:		{ name: "Vendor Name", used: 1, width: 120, click_action: "View", required: true },
			VendorTypeCode:	{ name: "Type", used: 1, width: 70, required: true},
			AccountName:	{ name: "Account Name", used: 1, width: 250, required: false},
			authToken:	{ name: "authToken", used: 0, width: 250, required: true},
			asid:	{ name: "asid", used: 0, width: 250, required: optionalVendorName == "SMS"},
			ShortCodeCount:	{ name: "Short Codes/Domains", used: 1, width: 100, required: false},
			ModifiedDateTime:{ name: "ModifiedDT", used: 1, width: 160, type: "date"},
		};
	}

	getFieldIds() {
		return _.keys(this.getColumns());
	}

	getRequiredFieldIds(optionalVendorName) {
		return _.keys(_.pickBy(this.getColumns(optionalVendorName), 'required'));
	}

	handleUserInput(filterText) {
		this.setState({
			filterText: filterText
		});
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			currentForm: nextProps.currentForm
		});
	}

	componentDidMount() {
		this._isMounted = true;

		this.props.actions.loadVendors(this.state.currentPage).catch(errObject => {
			var msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the vendors: " + msg);
		});
	}

	componentWillUnmount() {
	}

	_onChange() {
		if (this._isMounted) {
			this.setState({
				formError: null
			});
		}
	}

	addUpdateVendorCallback(fields) {
		let metadata_JSON;
		if ("MetadataJSON" in fields)
			metadata_JSON = JSON.parse(fields.MetadataJSON);
		else
			metadata_JSON = {};
		metadata_JSON.authToken = fields.authToken;
		if ("asid" in fields)
			metadata_JSON.asid = fields.asid;
		else
			metadata_JSON.asid = "";
		if ("qualtricsAccount" in fields)
			metadata_JSON.qualtricsAccount = fields.qualtricsAccount;
		else
			metadata_JSON.qualtricsAccount = "";
		if ("MessagingServiceSid" in fields)
			metadata_JSON.MessagingServiceSid = fields.MessagingServiceSid;
		else
			metadata_JSON.MessagingServiceSid = "";

		if ("VendorAccountID" in fields)
		{
			this.props.actions.updateVendor(fields.VendorAccountID, fields.VendorName, fields.AccountName, fields.VendorTypeCode, metadata_JSON).then(data => {
				this.props.actions.loadVendors().then(data => {
					this.takeDownForm(`Successfully updated vendor "${fields.VendorTypeCode}" for account "${fields.VendorName}".`);
				}).catch(errObject => {
					this.takeDownForm(null);
					let msg = errObject.message || errObject;
					this.setFormErrorMessage("An error occurred loading the email domains: " + msg);
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred adding the vendor: " + msg);
			});
		}
		else
		{
			this.props.actions.addVendor(fields.VendorName, fields.AccountName, fields.VendorTypeCode, metadata_JSON).then(data => {
				this.props.actions.loadVendors().then(data => {
					this.takeDownForm(`Successfully added vendor "${fields.VendorTypeCode}" for account "${fields.VendorName}".`);
				}).catch(errObject => {
					this.takeDownForm(null);
					let msg = errObject.message || errObject;
					this.setFormErrorMessage("An error occurred loading the vendors: " + msg);
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred updating the vendor: " + msg);
			});
		}
	}

	deleteVendorAccountCallback(fields) {
		var vendor_account_id = fields.VendorAccountID;
		this.props.actions.deleteVendor(fields.VendorAccountID).then(data => {
			this.takeDownForm(`Vendor ${fields.VendorName} Account ${fields.AccountName} deleted.`);
			this.props.actions.loadVendors().then(data => {
				;
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the vendors: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred deleting the vendor: " + msg);
		});
	}

	setFormState(formType, errorMessage, successMessage, id) {
		if (this.props.actions.formChangeHandler)
			this.props.actions.formChangeHandler(formType, errorMessage, successMessage, id);
	}

	setFormErrorMessage(errorMessage) {
		if (this._isMounted) {
			this.props.actions.setErrMsg(errorMessage);
		}
	}

	setFormSuccessMessage(successMessage) {
		if (this._isMounted) {
			this.setState({
				formSuccess: successMessage
			});
		}
	}

	takeDownForm(successMessage) {
		this.setFormState(null, null, successMessage, null);
		window.scrollTo(0, 0);
	}

	newVendorClicked() {
		this.setFormState("insert-vendor", null,null,null);
	}

	clearErrorMessage() {
		if (this._isMounted) {
			this.setState({
				formError: null
			});
		}
	}

	cancelForm() {
		this.takeDownForm(null);
		this.clearErrorMessage();
	}

	render() {
		var currentForm, object;
		var allVendors = this.props.vendors;

		//console.log('these are the vendors', allVendors);
		if (!allVendors) {
			return null;
		}

		let errMsg = null;
		let successMsg= null;

		if (this.state.currentForm && this.state.currentForm.errorMessage)
			errMsg = this.state.currentForm.errorMessage;

		if (this.state.currentForm && this.state.currentForm.successMessage)
			successMsg = this.state.currentForm.successMessage;

		var addNewUserTitle;
		var buttonList;
		var isSislUser = (this.props.usertype === "SISL");

		addNewUserTitle = "Add new vendor account";
		buttonList = [	{ label: "View", icon: "info", callback: _.wrap("show-vendor", this.setFormState) },
						{ label: "Edit", icon: "edit", callback: _.wrap("edit-vendor", this.setFormState) },
						{ label: "Assign", icon: "plus", callback: _.wrap("assign-vendor", this.setFormState) },
						{ label: "Delete", icon: "trash", callback: _.wrap("delete-vendor", this.setFormState) }
					  	];

		switch (this.props.currentForm && this.props.currentForm.formType) {
			case "insert-vendor":
				object = {};
				currentForm = <VendorForm title={addNewUserTitle} object={object} errorMessage={errMsg}
										showAccountGroupDropdown={ !isSislUser }
										hidepwd={ isSislUser }
										submitCallback={this.addUpdateVendorCallback} cancelCallback={this.cancelForm} />;
				break;

			case "edit-vendor":
				object = allVendors ? _.find(allVendors, {VendorAccountID: this.props.currentForm.currentId}) : {};

				currentForm = <VendorForm title="Edit Vendor Account" hidepwd={true} object={object} errorMessage={errMsg}
										submitCallback={this.addUpdateVendorCallback} cancelCallback={this.cancelForm} />;
				break;

			case "show-vendor":
				object = allVendors ? _.find(allVendors, {VendorAccountID: this.props.currentForm.currentId}) : {};
				currentForm = <VendorForm title="Vendor Account Details" readOnly={true} hidepwd={true} object={object}
										cancelCallback={this.cancelForm} errorMessage={errMsg} />;
				break;
			case "assign-vendor":
				object = allVendors ? _.find(allVendors, {VendorAccountID: this.props.currentForm.currentId}) : {};
				if (object) {
					if (object.VendorTypeCode.toUpperCase() == 'SMS')
					{
						currentForm = <VendorShortCodeForm title="Assign Short Codes" readOnly={false} object={object}
														errorMessage={this.state.errorMessage}
														cancelCallback={this.takeDownForm}/>;
					}
					else if (object.VendorTypeCode.toUpperCase() == 'EMAIL')
					{
						currentForm = <VendorEmaildomainForm title="Assign Email Domains" readOnly={false} object={object}
														errorMessage={this.state.errorMessage}
														cancelCallback={this.takeDownForm}/>;
					}
				}
				break;
			case "delete-vendor":
                object = allVendors ? _.find(allVendors, {VendorAccountID: this.props.currentForm.currentId}) : {};
                currentForm = <ConfirmForm title="Delete Vendor Account" text={"Are you sure you want to permanently remove " + object.VendorTypeCode + " vendor account \"" + object.VendorName + "\" ?"}
										   args={object}
										   confirmCallback={this.deleteVendorAccountCallback} cancelCallback={this.cancelForm}/>;

				break;
		}
		return (
			<ContentWrap title={this.props.title || "Vendor Accounts"} successMessage={successMsg} errorMessage={errMsg} form={currentForm}>
				<div className="search">
					<TableSearch
						onUserInput={ this.handleUserInput }
							filterText={ this.state.filterText }>
					</TableSearch>
					<div className="pull-right form-inline">
						<button className="btn btn-primary btn-sm searchBtn" title="Add Vendor Account" onClick={this.newVendorClicked}>{addNewUserTitle}</button>
					</div>
                	<div>
                	    {this.props.type}
                	</div>
				</div>
				<div className="clearfix"></div>
				<DataTable
					id="VendorAccountID"
					columns={ this.getColumns() }
					dataItems={ allVendors }
					rowsPerPage={ this.state.rowsPerPage }
					filterText={ this.state.filterText }
					initialSortKey={ this.state.initialSortKey }
					buttonIDPrefix={ "AccountName" }
					buttons={ buttonList }
				></DataTable>
			</ContentWrap>
		);
	}
}


function mapStateToProps(state, ownProps) {
	return {
		vendors: state.vendorReducer.vendors,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, vendorActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Vendors)
