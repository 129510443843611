import React, { Component } from 'react';
import _ from 'lodash';

class ConfirmForm extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	cons

    render() {
        var message =null;
        if (this.props.errorMessage) {
            message = <div className="alert alert-danger">{this.props.errorMessage}</div>;
        }
        return (
            <div className="sl-form" style={ { width: 500, paddingTop: 32 } }>
                <div className="form-horizontal">
                    <h4>{this.props.title}</h4>
                    <div className="clearfix"></div>
                    <div className="sl-confirmtext">
                        {message}
                        {this.props.text}
                    </div>
                </div>
                <button className="btn btn-primary btn-sm" onClick={_.wrap(this.props.args, this.props.confirmCallback)} id="confirmAction">OK</button>
                <button className="btn btn-secondary btn-sm" onClick={this.props.cancelCallback} id="cancelAction">Cancel</button>
            </div>
        );
	}
}

export default ConfirmForm;
