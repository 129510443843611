import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';
import { matchPath } from 'react-router';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import {createStore, compose, applyMiddleware} from 'redux';
import { createBrowserHistory }  from 'history'
import { routerMiddleware ,ConnectedRouter} from 'connected-react-router'
import configureStore, { history } from './configureStore'
import createRootReducer from './reducers'
import allReducers from './reducers';
import Header from './components/common/Header';
import HomeSideBar from './components/home/HomeSideBar';
import AdminSideBar from './components/admin/AdminSideBar';
import DashboardSideBar from './components/dashboard/DashboardSideBar';
import DigiChannelSideBar from './components/digichannel/DigiChannelSideBar';
import oAuthRedirectForm1 from './auth/oAuthRedirectForm1';
import SecuredRoute from './components/common/SecuredRoute';
import SharedLogin from './components/common/LoginForm';
import ForgotPasswordForm from './components/common/ForgotPasswordForm';
import ChangePasswordForm from './components/common/ChangePasswordForm';
import NavBar from "./components/common/NavBar";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const store = configureStore({});

const logger = createLogger();

const SlinkAuth = window.SlinkAuth;
const clientData = window.clientData;

//Make Material-UI font same as admin.css and sl-frameworks.css
const THEME = createMuiTheme({
	typography: {
		"fontFamily": "\"Arial\", sans-serif",
		"fontSize": 14,
		"lineHeight": 1.428,
		"fontWeightLight": 300,
		"fontWeightRegular": 400,
		"fontWeightMedium": 500
	}
});

class App extends Component {
	constructor(props) {
		super(props);

		var urlPath = window.location.pathname;
		var urlArgs = window.location.search || ' ';

		if (window.location.hash && window.location.hash.includes("access_token")) {
			sessionStorage.setItem('LOGIN_RESULT', window.location.hash);
		}

		var redirectTo;
		if (urlPath.match('/index.html') && urlArgs.match('op=forgotpassword')) {
			redirectTo = "/forgotpassword" + urlArgs + "#";
		} else if (urlPath.match('/index.html') && urlArgs.match('op=changepassword')) {
			redirectTo = "/changepassword" + urlArgs + "#";
		} else if (urlPath.match('/index.html') || urlPath.match('/sharedlogin')) {
			redirectTo = "/sharedlogin" + urlArgs + "#";
		}  else {
			redirectTo = urlPath;
		}



		this.state = {
			"mounted": false,
			"redirectTo": redirectTo
		};
	}

	componentDidMount() {
        let roles = SlinkAuth.getRoles();
		let redirectTo = window.location.pathname;

		if (redirectTo == "/") {
			if (roles == "slink-sys-admin")
				redirectTo = "/home";
			else if (roles == "slink-sisl-admin")
				redirectTo = "/admin/about";
			else if (roles == "slink-engmnt-manager")
				redirectTo = "/dashboard";
			else
				redirectTo = "/home";
		}

		this.setState({
            "mounted": true,
			"redirectTo": redirectTo
        });
	}

	render() {

		var atSharedLogin = window.location.pathname.match('/sharedlogin');
		var atIndex = window.location.pathname.match('/index.html');
		var	urlArgs = window.SlinkAuth.getUrlArgs(window.location.search||' ');
        var clientId = urlArgs ? (urlArgs.client_id || urlArgs.clientid) : '';
        var appName = window.SlinkAuth.getAppName(clientId);
        var atLogin = (atSharedLogin != null || atIndex != null);
		let loginStatus = sessionStorage.getItem('LOGIN_RESULT');

		return (
			<div>
				<MuiThemeProvider theme={THEME}>
				<Provider store={store}>
					<ConnectedRouter history={history}>
                            <div>
								{ atLogin ? <NavBar style={{top: '0px', left: '0px'}} noURL="true" fullbrand={ appName } shortbrand={ appName }/> :
											  <Switch><Route path="/" location={this.props.location} component={ Header }/></Switch>
								  }
                                <div id="belowMenuBar">
                                    <div className="sl-rightside-container" style={atLogin ? {marginLeft: 'auto', marginRight: 'auto'} : {}}>
                                        <Switch>
                                            <SecuredRoute path="/home" component={HomeSideBar} />
											<SecuredRoute path="/dashboard" component={DashboardSideBar} />
                                            <SecuredRoute path="/admin" component={AdminSideBar} />
                                            <SecuredRoute path="/digichannel" component={DigiChannelSideBar} />
                                            <Route exact path="/login"  component={oAuthRedirectForm1}/>
                                            <Route path="/sharedlogin" location={this.props.location} component={SharedLogin}/>} />
                                            <Route path="/forgotpassword"  component={ForgotPasswordForm}/>
											<Route path="/changepassword"  component={ChangePasswordForm}/>
                                            this.state.mounted ? (
                                            <Redirect to= { this.state.redirectTo }/>
                                            )
                                        </Switch>
                                    </div>
                                </div>
                            </div>
					</ConnectedRouter>
				</Provider>
				</MuiThemeProvider>
				{this.props.children}
			</div>
		);
	}
}

export default App;