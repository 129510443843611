import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as emailBEEActions  from '../../../actions/emailBEEActions';
import * as formStateActions  from '../../../actions/formStateActions';
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';
import ConfirmForm from '../../common/ConfirmForm';
import BEETemplateForm from './BEETemplateForm';
import BEETemplateAccountForm from './BEETemplateAccountForm';
import _ from 'lodash';

class EmailBEETemplates extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filterText: '',
			initialSortKey: 'email_bee_template_name',
			errorMessage: null,
			successMessage: null,
			currentForm: null,
			currentPage: 1,
			rowsPerPage: 50
		};

		/*
			These binds are used to access the functions in EmailBEETemplates.js from sub forms.
		*/
		this.handleUserInput = this.handleUserInput.bind(this);
		this.setFormState = this.setFormState.bind(this);
		this.cancelForm = this.cancelForm.bind(this);
		this.saveAndClose = this.saveAndClose.bind(this);
		this.takeDownForm = this.takeDownForm.bind(this);
		this.deleteBEETemplateCallback = this.deleteBEETemplateCallback.bind(this);
		this.newEmailBEETemplateClicked = this.newEmailBEETemplateClicked.bind(this);
		this.clone = this.clone.bind(this);
	}

	getColumns() {
		return {
			email_bee_template_name:		{ name: "Template Name", used: 1, width: 300, click_action: "View", required: true },
			accounts:		{ name: "Accounts", used: 1, width: 100, required: false }
		};
	}

	getFieldIds() {
		return _.keys(this.getColumns());
	}

	getRequiredFieldIds() {
		return _.keys(_.pickBy(this.getColumns(), 'required'));
	}

	handleUserInput(filterText) {
		this.setState({
			filterText: filterText
		});
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			currentForm: nextProps.currentForm
		});
	}

	componentDidMount() {
		this._isMounted = true;

		this.props.actions.loadEmailBEETemplates().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the email templates: " + msg);
		});
	}

	componentWillUnmount() {
	}

	_onChange() {
		this.setState({
			errorMessage: null
		});
	}

	newEmailBEETemplateClicked() {
		this.setFormState("insert-beetemplate", null,null,null);
	}

	deleteBEETemplateCallback(fields) {
		this.props.actions.deleteBEETemplate(fields.email_bee_template_id).then(data => {
			this.props.actions.loadEmailBEETemplates().then(data => {
				this.setFormSuccessMessage(`Successfully deleted email template ${fields.email_bee_template_name}`);
				this.takeDownForm(null);
			}).catch(errObject => {
				this.takeDownForm(null);
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the email templates: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred removing the email template: " + msg);
		});
	}

	setFormState(formType, errorMessage, successMessage, id) {
		if (this.props.actions.formChangeHandler)
			this.props.actions.formChangeHandler(formType, errorMessage, successMessage, id);
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			successMessage: null,
			errorMessage: errorMessage
		});
	}

	setFormSuccessMessage(successMessage) {
		this.setState({
			successMessage: successMessage,
			errorMessage: null
		});
	}

	takeDownForm(successMessage) {
		this.setFormState(null, null, successMessage, null);
		window.scrollTo(0, 0);
	}

	clearErrorMessage() {
		this.setState({
			successMessage: null,
			errorMessage: null
		});
	}

	saveAndClose(successMessage)
	{
		this.takeDownForm(null);
		this.setFormSuccessMessage(successMessage);
	}

	cancelForm() {
		this.takeDownForm(null);
		this.clearErrorMessage();
	}

	// These params are automatically added by the framework
	clone(errorMessage, successMessage, id)
	{
		this.props.actions.cloneBEETemplate(id).then(data => {
			this.props.actions.loadEmailBEETemplates().then(data => {
				this.setFormSuccessMessage('Successfully cloned email template');
				this.takeDownForm(null);
			}).catch(errObject => {
				this.takeDownForm(null);
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the email templates: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred cloning the email template: " + msg);
		});
	}

	render() {
		let currentForm, object;
		let allEmailBEETemplates = this.props.beetemplates;

		let errMsg = null;
		let successMsg= null;

		if (this.state.errorMessage)
			errMsg = this.state.errorMessage;

		if (this.state.successMessage)
			successMsg = this.state.successMessage;

		let addNewBEETemplateTitle;
		let buttonList;

		addNewBEETemplateTitle = "Add New Email Template";
		buttonList = [	{ label: "View", icon: "info", callback: _.wrap("show-beetemplate", this.setFormState) },
						{ label: "Edit", icon: "edit", callback: _.wrap("edit-beetemplate", this.setFormState) },
						{ label: "Assign", icon: "plus", callback: _.wrap("assign-beetemplate", this.setFormState) },
						{ label: "Clone", icon: "clone", callback: this.clone },
						{ label: "Delete", icon: "trash", callback: _.wrap("delete-beetemplate", this.setFormState) }
					];

		switch (this.props.currentForm && this.props.currentForm.formType) {
			case "insert-beetemplate":
				object = {};
				currentForm = <BEETemplateForm title={addNewBEETemplateTitle} object={object} errorMessage={this.state.errorMessage}
										submitCallback={this.saveAndClose} cancelCallback={this.cancelForm} />;
				break;

			case "edit-beetemplate":
				object = allEmailBEETemplates ? _.find(allEmailBEETemplates, {email_bee_template_id: this.props.currentForm.currentId}) : {};
				currentForm = <BEETemplateForm title="Edit Email Template" object={object} errorMessage={this.state.errorMessage}
										submitCallback={this.saveAndClose} cancelCallback={this.cancelForm}/>;
				break;
			case "show-beetemplate":
				object = allEmailBEETemplates ? _.find(allEmailBEETemplates, {email_bee_template_id: this.props.currentForm.currentId}) : {};
				currentForm = <BEETemplateForm title="Email Template Details" readOnly={true} object={object} errorMessage={this.state.errorMessage}
											 cancelCallback={this.cancelForm}/>;
				break;
			case "assign-beetemplate":
				object = allEmailBEETemplates ? _.find(allEmailBEETemplates, {email_bee_template_id: this.props.currentForm.currentId}) : {};
				if (object) {
					currentForm = <BEETemplateAccountForm title="Assign Account" readOnly={false} object={object}
										errorMessage={this.state.errorMessage}
										cancelCallback={this.cancelForm}/>;
				}
				break;
			case "delete-beetemplate":
				object = allEmailBEETemplates ? _.find(allEmailBEETemplates, {email_bee_template_id: this.props.currentForm.currentId}) : {};
				if (object && object.email_bee_template_name)
				{
					currentForm = <ConfirmForm title="Delete Email Template" text={"Are you sure you want to permanently remove email template \"" + object.email_bee_template_name + "\" ?"}
											   args={object} errorMessage={this.state.errorMessage}
											   confirmCallback={this.deleteBEETemplateCallback} cancelCallback={this.cancelForm}/>;
				}
				break;
		}

		return (
			<ContentWrap title={this.props.title || "Email Templates"} successMessage={successMsg} errorMessage={errMsg} form={currentForm}>
				<div className="search">
					<TableSearch
						onUserInput={ this.handleUserInput }
						filterText={ this.state.filterText }>
					</TableSearch>
					<div className="pull-right form-inline">
						<button className="btn btn-primary btn-sm searchBtn" id="add_new_email_template" onClick={this.newEmailBEETemplateClicked}>{addNewBEETemplateTitle}</button>
					</div>
				</div>
				<div className="clearfix"></div>
				<DataTable
					id="email_bee_template_id"
					columns={ this.getColumns() }
					dataItems={ allEmailBEETemplates }
					rowsPerPage={ this.state.rowsPerPage }
					filterText={ this.state.filterText }
					initialSortKey={ this.state.initialSortKey }
					buttonIDPrefix={ "email_bee_template_name" }
					buttons={ buttonList }>
				</DataTable>
			</ContentWrap>

		);
	}
}


function mapStateToProps(state, ownProps) {
	return {
		beetemplates: state.emailBEETemplateReducer.beetemplates,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, emailBEEActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailBEETemplates)
