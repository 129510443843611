import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as formStateActions  from '../../../actions/formStateActions';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import _ from 'lodash';

class AccountGroupNameForm extends Component {
	constructor(props)
	{
		super(props);

		this.state = {
			formErrorMessage: null
		};
	}

	render()
	{
		var self = this;
		var object = this.props.object;
		return (
			<Form {...this.props} width="800" height="580" errorMessage={ this.state.formErrorMessage }>
				<FormField.Input fieldid="account_group_name" width="5" label="Group Name" labelWidth="2" />
			</Form>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountGroupNameForm)
