import React, {Component} from 'react';
import {Route,Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import SideBar from '../common/SideBar';
import About from './About';
import AccountGroups from './accounts/AccountGroups';
import APIs from './apis/APIs';
import APIGroups from './users/APIGroups';
import APILinks from './apilinks/APILinks';
import APILinkTransformations from './transformations/APILinkTransformations';
import Cache from './cache/Cache';
import Roles from './users/Roles';
import Users from './users/Users';
import ApiClients from './users/ApiClients';
import SislUsers from './users/SislUsers';
import SecuredRoute from '../common/SecuredRoute';

const SlinkAuth = window.SlinkAuth;

const menuitems = [
	{id: "adminAboutLink", displayName: "About", linkname: '/admin/about', icon: 'info-circle', needsRole: 'slink-sisl-admin'},
	{id: "adminMainLink", displayName: 'Users', linkname: '/admin', icon: 'user', needsRole: 'slink-sys-admin'},
	{id: "adminAPIClientLink", displayName: 'API Clients', linkname: '/admin/apiclients', icon: 'id-badge', needsRole: 'slink-sys-admin'},
	{id: "adminAccountgroupLink", displayName: 'Account Groups', linkname: '/admin/accountgroups', icon: 'building', needsRole: 'slink-sys-admin'},
	{id: "adminRoleLink", displayName: "Roles", linkname: '/admin/roles', icon: 'user', needsRole: 'slink-sys-admin'},
	{id: "adminApiLink", displayName: "API", linkname: '/admin/apis', icon: 'cloud', needsRole: 'slink-sys-admin'},
	{id: "adminApigroupLink", displayName: "API Groups", linkname: '/admin/apigroups', icon: 'umbrella', needsRole: 'slink-sys-admin'},
	{id: "adminApilinkLink", displayName: "API Links", linkname: '/admin/apilinks', icon: 'link', needsRole: 'slink-sys-admin,slink-apilink-manager'},
	{id: "adminTransformations", displayName: "Transformations", linkname: '/admin/transformations', icon: 'retweet', needsRole: 'slink-sys-admin,slink-apilink-manager'},
	{id: "adminCacheLink", displayName: "Cache", linkname: '/admin/cache', icon: 'university', needsRole: 'slink-sys-admin'},
	{id: "adminSpacerLink", displayName: "--", linkname: '', needsRole: 'slink-sys-admin'},
	{id: "adminSISLNextLink", displayName: 'SISLNext Users', linkname: '/admin/sislusers', icon: 'user', needsRole: 'slink-sisl-admin'},
	{id: "adminLoginLink", displayName: "Logout", linkname: '/login', icon: 'sign-out-alt', isLogout: true}
];

class AdminSideBar extends Component {
	constructor(props) {
		super();
	}

	render() {
		let roles = SlinkAuth.getRoles();
		return (
			<div>
				<SideBar menuitems={ menuitems }/>
				<div>
					<div id="page-content-wrapper" className="full-height">
						<div className="sl-rightside-container">
							<Switch>
								<SecuredRoute  path="/admin/apiclients" component={ApiClients}/>
								<SecuredRoute  path="/admin/accountgroups" component={AccountGroups}/>
								<SecuredRoute  path="/admin/apis" component={APIs}/>
								<SecuredRoute  path="/admin/apigroups" component={APIGroups}/>
								<SecuredRoute  path="/admin/apilinks" component={APILinks}/>
								<SecuredRoute  path="/admin/transformations" component={APILinkTransformations}/>
								<SecuredRoute  path="/admin/roles" component={Roles}/>
								<SecuredRoute  path="/admin/sislusers" component={SislUsers}/>
								<SecuredRoute  path="/admin/cache" component={Cache}/>
								<SecuredRoute  path="/admin/about" component={About}/>
								{/*{ roles ==  "slink-sisl-admin" ? <Route  component={About}/>: <Route  component={Users}/> }*/}
								<SecuredRoute  component={Users}/>
							</Switch>
						</div>
					</div>
				</div>
				{this.props.children}
			</div>
		);
	}
}

export default AdminSideBar;
