import React, {Component} from 'react';
import {UpdateBlocker, Link, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux'
//import * as sessionActions from '../../actions/sessionActions';
import NavBar  from './NavBar'
import MyLink from './MyLink'
import * as constants from '../../constants';
/*
import '../../login/slinkauth';
import '../../login/cookies';
import '../../login/password';
import '../../login/slinkauth';
*/
var defaultConfigError = "Sorry, but there is a problem with your account configuration. Please contact your support team.";

class LoginForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
		    route: null
        };
	}

    messageError(err) {
        if (!err) {
            return null;
        }
        if (/unsupported IP address/i.test(err)) {
            return defaultConfigError + ' Error code: UnsupportedIP';
        }
        if (/requires an IP address restriction/i.test(err)) {
            return defaultConfigError + ' Error code: NoIPRestriction';
        }
        if (/OAuth2: ?/i.test(err)) {
            return err.replace('OAuth2: ','');
        }
        return err;
    }

    render() {
        var apiRoot = constants.baseURL;
        var postAction = apiRoot + "/oauth2/login";
        var urlArgs = window.SlinkAuth.getUrlArgs(window.location.search||' ');
        var clientId = urlArgs ? (urlArgs.client_id ||urlArgs.clientid) : '';
        var argsString = window.location.search;
        //var error = urlArgs.error ? urlArgs.error : '';
        var appName = window.SlinkAuth.getAppName(clientId);
        if(appName){
            document.title = "Personify Health "+appName;
        }
        var errDiv = urlArgs.error ? (<div className="alert alert-danger">{ this.messageError(urlArgs.error) }</div>) : null;

        return (
            <div>
                <div id="detail" className="full-height">
                    <div id="loginform" style={{width: 320, margin: "40px auto 24px auto"}}>
                        <label style={{ fontSize: "20px", color: "#202020", margin: "20px auto 30px auto" }}>Please Login</label>

                        <form method="post" action={ postAction }>
                            <input id="client_id" name="client_id" type="hidden" value={clientId}></input>
                                { errDiv }
                                <div className="form-group-row">
                                    <label htmlFor="username">Email Address</label>
                                    <input type="text" className="form-control" id="username" name="username" placeholder="" autoFocus />
                                </div>
                                <br/>
                                <div className="form-group-row">
                                   <label htmlFor="password">Password</label>
                                   <input type="password" className="form-control" id="password" name="password" autoComplete="off" placeholder="" />
                                   <div style={{paddingTop: '24px'}}>
                                       <MyLink to={"/forgotpassword" + argsString} id="ForgotPWDLink" linktext={`Forgot password or new user?`}
                                               route={this.props.router.location.pathname}>Forgot password or new user?</MyLink>
                                      {/*<a style={{ marginTop: 6, marginRight: 54, float: "right" }} href={ "/forgotpassword.html?client_id=" + clientId }>Forgot password or new user?</a>*/}
                                   </div>
                                </div>
                                <div style={{ paddingRight: 48, marginTop: 24 }}>
                                   <button id="loginsubmit" type="submit" style={{ display: "block", margin: "8px auto 0 auto", width: "120px" }} className="btn btn-primary">Login</button>
                                </div>
                        </form>
                    </div>
                    <br style={{ clear: "both" }} />
                    <div className="sl-footer">
                        <div style={{ float: "left", margin: "24px 16px 0 24px", fontSize: 12 }}>
                            Personify Health, All Rights Reserved<br/>
                            <a target="_blank" href="http://www.silverlink.com">www.personifyhealth.com</a> <br/>
                            <a target="_blank" href="http://www.silverlink.com/privacypolicy.html">Privacy Policy</a> | &nbsp;
                            <a target="_blank" href="http://www.silverlink.com/termsofuse.html">Terms of Use</a> | &nbsp;
                            <a target="_blank" href="http://www.silverlink.com/hipaa.html">HIPAA Compliance</a>
                        </div>
                        <div style={{ float: "right", margin: "8px 8px 0 16px" }}>
                            <img src="images/header_logo_personifyhealth.png" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//export default LoginForm;

// Grab a reference to the current URL. If this is a web app and you are
// using React Router, you can use `ownProps` to find the URL. Other
// platforms (Native) or routing libraries have similar ways to find
// the current position in the app.



function mapStateToProps(state, ownProps) {
    return {
        router: state.router
    }
}

// function mapDispatchToProps(dispatch) {
//
//     return {
//         auth: bindActionCreators(sessionActions, dispatch)
//     };
// }

export default connect(mapStateToProps, null)(LoginForm)
