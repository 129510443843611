import * as types from '../actions/actionTypes';
/*
 * All reducers get two parameters passed in, state and action that occurred
 *   > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

var initialState = {
	beeToken: {},
	beetemplates: [],
	beetemplateDetails: {},
	accountBEETemplates: {}
};

export default function (state = initialState, action) {
	switch (action.type) {
		case types.AUTHORIZE_BEE:
			//console.log('reducer to authorize BEE', action);
			return Object.assign({}, state, {beeToken: action.payload});
		case types.GET_BEETEMPLATES_SUCCESS:
			//console.log('reducer to load all bee template data', action);
			return Object.assign({}, state, {beetemplates: action.payload});
		case types.GET_BEETEMPLATE_SUCCESS:
			/**************************
				This data lets you assign bee templates to an account.
			**************************/

			/**************************
				We're storing api links in a hash/associative array.
				The key is the bee template ID.
				The value is the list of bee template accounts.
				This way, each incoming call doesn't overwrite data from previous calls.
			**************************/

			// FIREFOX BUG WORKAROUND
			// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
			// It doesn't like the number 1 as a computed key in an object, even once it's stringified
			let beeTemplateID = action.id;
			return Object.assign({}, state, {beetemplateDetails: { ['ID_' + beeTemplateID]: action.payload[0]}});
		case types.GET_BEETEMPLATE_ACCOUNTS_SUCCESS:
			/**************************
				This data lets you assign bee templates to an account.
			**************************/

			/**************************
				We're storing BEE template accounts in a hash/associative array.
				The key is the bee template ID.
				The value is the list of bee template accounts.
				This way, each incoming call doesn't overwrite data from previous calls.
			**************************/

			// FIREFOX BUG WORKAROUND
			// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
			// It doesn't like the number 1 as a computed key in an object, even once it's stringified
			let beeTemplateID_accounts = action.id;
			return Object.assign({}, state, {accountBEETemplates: { ['ID_' + beeTemplateID_accounts]: action.payload}});
		default:
			return state;
	}
	return state;
}
