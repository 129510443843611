import * as types from './actionTypes';
import api from './api';

export function loadVendors() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/text-service/smsvendors/',
		}).then(response => {
			dispatch({type: types.GET_VENDORS_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadVendorShortCodes(vendor_account_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/text-service/smsvendors/${vendor_account_id}/shortcodes`
		}).then(response => {
			dispatch({type: types.GET_VENDOR_SHORTCODES_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadVendorTypecodes() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/text-service/smsvendors/typecodes'
		}).then(response => {
			dispatch({type: types.GET_VENDOR_TYPECODES_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadVendorEmailDomains(vendor_account_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/email-service/emailvendors/${vendor_account_id}/emaildomains`
		}).then(response => {
			dispatch({type: types.GET_VENDOR_EMAILDOMAIN_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

// This should be moved in slip-api to text-service
export function addVendorEmailDomain(vendor_account_id, email_domain_id) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: `/email-service/emailvendors/${vendor_account_id}/emaildomain/${email_domain_id}`
		}).then(response => {
			dispatch({type: types.ADD_VENDOR_EMAILDOMAIN_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

// This should be moved in slip-api to text-service
export function removeVendorEmailDomain(vendor_account_id, email_domain_url) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/email-service/emailvendors/${vendor_account_id}/emaildomain/${email_domain_url}`
		}).then(response => {
			dispatch({type: types.REMOVE_VENDOR_EMAILDOMAIN_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addVendorShortCode(vendor_account_id, short_code_id) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: `/text-service/smsvendors/${vendor_account_id}/shortcode/${short_code_id}`
		}).then(response => {
			dispatch({type: types.ADD_VENDOR_SHORTCODE_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function removeVendorShortCode(vendor_account_id, short_code) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/text-service/smsvendors/${vendor_account_id}/shortcode/${short_code}`
		}).then(response => {
			dispatch({type: types.REMOVE_VENDOR_SHORTCODE_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addVendor(vendor_name, account_name, vendor_type_code, metadata_JSON) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: '/text-service/smsvendors/account',
			data: {
				VendorName: vendor_name,
				AccountName: account_name,
				VendorTypeCode: vendor_type_code,
				MetadataJSON: metadata_JSON
			}
		}).then(response => {
			dispatch({type: types.ADD_VENDOR_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function deleteVendor(vendor_account_id) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/text-service/smsvendors/${vendor_account_id}`
		}).then(response => {
			dispatch({type: types.DELETE_VENDOR_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function updateVendor(vendor_id, vendor_name, account_name, vendor_type_code, metadata_JSON) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: '/text-service/smsvendors/account',
			data: {
				VendorAccountID: vendor_id,
				VendorName: vendor_name,
				AccountName: account_name,
				VendorTypeCode: vendor_type_code,
				MetadataJSON: metadata_JSON
			}
		}).then(response => {
			dispatch({type: types.UPDATE_VENDOR_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}
