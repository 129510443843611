import React, { Component } from 'react';

class NavBar extends Component {
	render() {
		var url = this.props.noURL ? null : (this.props.url || "/");
		return (
			<div className="navbar navbar-inverse navbar-fixed-top">
				<div className="navbar-inner">
					<div>
						<a className="navbar-brand" href={ url }>
							<span className="fullBrand">{ this.props.fullbrand }</span>
							<span className="shortBrand"></span>
						</a>
					</div>
				</div>
				<div>
					{ this.props.children }
				</div>
			</div>
		)
	}
}

export default NavBar;
