import * as types from './actionTypes';
import api from './api';

export function loadUsers() {
    return function(dispatch) {
        return api({
            method: 'get',
            url: `/auth/users/`,
        }).then(users => {
            dispatch({type: types.GET_USERS_SUCCESS, payload: users});
        }).catch(error => {
            throw(error);
        });
    };
}

export function loadUser(userId) {
    return function(dispatch) {
        return api({
            method: 'get',
            url: `/auth/users/${userId}`
        }).then(response => {
            dispatch({type: types.GET_SINGLE_USER_SUCCESS, payload: response});
        }).catch(error => {
            throw(error);
        });
    };
}

export function updateUserRelationship(id, user) {
    return function(dispatch) {
        return api({
            method: 'post',
            url: `/auth/users/${id}/relationship`,
            data: user
        }).catch(error => {
            throw(error);
        });
    };
}

export function updateUser(user) {
    return function(dispatch) {
        return api({
            method: 'post',
            url: `/auth/users/${user.login_id}`,
            data: user
        }).then(response => {
            dispatch({type: types.UPDATE_USER, payload: user});
        }).catch(error => {
            throw(error);
        });
    };
}

export function addUser(user) {
    return function(dispatch) {
        return api({
            method: 'post',
            url: '/auth/users',
            data: user
        }).catch(error => {
            throw(error);
        });
    };
}

export function deleteUser(user) {
    return function(dispatch) {
        return api({
            method: 'delete',
            url: `/auth/users/${user.id}`,
            data: user
        }).catch(error => {
            throw(error);
        });
    };
}

export function changePassword(user) {
    return function(dispatch) {
        return api({
            method: 'post',
            url: '/auth/password',
            data: user
        }).catch(error => {
            throw(error);
        });
    };
}