import * as types from '../actions/actionTypes';

const initialState = {
	customTransformations: [],
	customTransformationDetails: {},
	customTransformationMappings: {},
	assignedAccounts: {}
};

export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_CUSTOM_TRANSFORMATIONS_SUCCESS:
			return Object.assign({}, state, {customTransformations: action.payload});
		case types.GET_CUSTOM_TRANSFORMATION_SUCCESS:
			if (action.payload.length > 0) {
				let api_link_transform_rule_id = action.id;
				let key = 'ID_' + api_link_transform_rule_id;
				return Object.assign(
					{},
					state,
					{ customTransformationDetails: { [key]: action.payload[0] } }
				);
			} else {
				return state;
			}
		case types.GET_CUSTOM_TRANSFORMATION_MAPPINGS_SUCCESS:
			if ("payload" in action) {
				let api_link_transform_rule_id = action.id;
				let key = 'ID_' + api_link_transform_rule_id;
				return Object.assign(
					{},
					state,
					{ customTransformationMappings: { [key]: action.payload } }
				);
			} else {
				return state;
			}
		case types.RESET_TRANSFORMATION_SUCCESS:
			return Object.assign(
				{},
				state,
				{ customTransformationDetails: {}, customTransformationMappings: {} }
			);
		case types.GET_TRANSFORMATION_ACCOUNTS_SUCCESS:
			let api_link_transform_rule_id = action.id;
			let key = 'ID_' + api_link_transform_rule_id;
			return Object.assign({}, state, { assignedAccounts: { [key]: action.payload } });
	}
	return state;
}
