import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import _ from 'lodash';
import {bindActionCreators} from "redux";
import * as sessionActions from "../../actions/sessionActions";
import {connect} from "react-redux";
const SlinkAuth = window.SlinkAuth;

class SideBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openMenuItems: [],
			selectedMenuItemId: null
		};
	}

	handleMenuClick(evt) {
		// special handling on logout
		var menuid = evt.target.dataset.menuid;
		var isLogoutLink = evt.target.dataset.logout;
		var newOpenMenuItemIds;

		if (isLogoutLink) {
			this.setState(this.getInitialState());
		} else {
			if (_.includes(this.state.openMenuItems, menuid)) {
				newOpenMenuItemIds = _.without(this.state.openMenuItems, menuid);
			} else {
				newOpenMenuItemIds = this.state.openMenuItems.concat([menuid]);
			}
			this.setState({
				openMenuItems: newOpenMenuItemIds,
				selectedMenuItemId: menuid
			});
		}
	}

	renderOneMenuItem(menuItemInfo, idx, leftMargin) {

		var idname = "navitem-" + menuItemInfo.id;
		var childmenus = null;
		var thisItemIsOpen =  _.includes(this.state.openMenuItems, menuItemInfo.id);
		var thisItemWasClicked = (menuItemInfo.linkname == this.props.router.location.pathname); // (menuItemInfo.id === this.state.selectedMenuItemId);

		var roles;
		var kids = [];
		if (menuItemInfo.kids && thisItemIsOpen) {
			_.each(menuItemInfo.kids, function(kid) {
				kids.push(this.renderOneMenuItem(kid, null, 20));
			}.bind(this));
			childmenus =
				(
					<ul id={idname + '-kids'} key={idname + '-sub'} className="sidebar-nav">
						{kids}
					</ul>
				);
		}

		if (menuItemInfo.needsRole) {
			roles = SlinkAuth.getRoles();
			if (!menuItemInfo.needsRole.includes(roles)) {
				return null;
			}
		}

		var menuItem, icon, menuNameClass;
		if (menuItemInfo.displayName === '--') {
			menuItem = <hr style={{width: "85%", marginLeft: 6, marginTop: 10, marginBottom: 10, borderBottom: 0}}></hr>;
		} else {
			icon = menuItemInfo.icon ? <i className={"fa fa-" + (thisItemIsOpen ? "caret-down" : menuItemInfo.icon)}></i> : "";
			menuNameClass = "navmenuname font-weight-normal"  + (thisItemWasClicked ? " navmenuname-selected" : "");
			if (menuItemInfo.linkname) {
				menuItem = (
					<span id={idname} key={idname} className="navitem">
						<Link className={"nav-link"} to={menuItemInfo.linkname} id={menuItemInfo.id}>
							<span className={'navbarIconBox'}>
								{icon}
								<span data-menuid={menuItemInfo.id} className={menuNameClass}>
									{menuItemInfo.displayName}
								</span>
							</span>
						</Link>
					</span>
				);
			} else {
				menuItem = (
					<span id={idname} key={idname} className="navitem navbarIconBox" onClick={this.handleMenuClick}>
						{icon}<span data-menuid={menuItemInfo.id} data-logout={menuItemInfo.isLogout||null} className="navmenuname">{menuItemInfo.displayName}</span>
					</span>
				);
			}
		}
		return (
			<li style={{marginLeft: leftMargin||4}} key={idname + '-list'} >
				{menuItem}
				{childmenus}
			</li>
		);
	}

	render() {
		var self = this;
		var renderItem = function(menuItemInfo, idx) {
			return self.renderOneMenuItem(menuItemInfo, idx);
		};
		return (
			<div id="sidebar-wrapper">
				<ul className="sidebar-nav">
					{_.map(this.props.menuitems, renderItem)}
				</ul>
			</div>
		);
	}
}

//export default SideBar;

// Grab a reference to the current URL. If this is a web app and you are
// using React Router, you can use `ownProps` to find the URL. Other
// platforms (Native) or routing libraries have similar ways to find
// the current position in the app.
function mapStateToProps(state, ownProps) {
	return {
		router: state.router
	}
}

function mapDispatchToProps(dispatch) {

	return {
		auth: bindActionCreators(sessionActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
