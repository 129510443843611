import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as formStateActions  from '../../../actions/formStateActions';
import * as shortcodeActions  from '../../../actions/shortcodeActions';
import * as vendorActions  from '../../../actions/vendorActions';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import _ from 'lodash';

class ShortCodeForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			formError: null,
			filterText: '',
			initialSortKey: 'account_name',
			rowsPerPage: 10000
		};

		this._isMounted = false;
		/*
			These binds are used to access the functions in EmailDomains.js from sub forms.
			For example:
			Suppose you add a domain. Now you want to relist the front page.
			Without the calls below, you couldn't access the takeDownForm function
			from the addDomainCallback with passing it along to the sub form.
			Here we just bind the parent form context to that function.
		*/
		this.handleUserInput = this.handleUserInput.bind(this);
	}

	getColumns() {
		return {
			CompanyName: { name: "Account Name", used: 1, width: 200,  required: true },
			ProgramName: { name: "Program Title", used: 1, width: 250, required: true },
			ChannelProgramStatusSDesc:	{ name: "Status", used: 1, width: 170 },
			Keyword:	{ name: "Keyword", used: 1, width: 80 }
		};
	}

	componentDidMount() {
		let object = this.props.object;
		this.props.actions.loadVendorTypecodes().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the vendor typecodes: " + msg);
		});
        if (this.props.readOnly) {
			this.props.actions.loadShortCodeAccounts(object.ShortCodeId).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the vendor typecodes: " + msg);
			});
			this.props.actions.loadShortCodePrograms(object.ShortCodeId).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the vendor typecodes: " + msg);
			});
        }
	}

	componentWillUnmount() {
		;
		/*
		SmsStore.removeChangeListener(this._onChange, 'shortcodeprograms');
		SmsStore.removeChangeListener(this._onChange, 'vendortypecodes');
		SmsStore.removeChangeListener(this._onChange, 'shortcodeaccounts');
		*/
	}

	_onChange() {
		// repaint the form when data loads
		if (this.isMounted()) {
			this.forceUpdate();
		}
	}

	handleUserInput(filterText) {
		this.setState({
			filterText: filterText
		});
	}
	renderShortCodeAccountsPanel() {
		let accountTagTextFunction = function(obj) {
			return obj.account_name + ' (' + obj.account_id + ')';
		};
		let assignedAccounts = this.props.shortcodeAccounts;
		let aryAssignedAccounts = _.values(assignedAccounts);
		return (
			<div className="form-group row">
				<label className="col-form-label col-md-2" style={{ lineHeight: "16px"}}>Accounts</label>
				<div className="form-control-plaintext col-md-10" >
					<FormField.AssignedTagList
						objects={ aryAssignedAccounts }
						keyField="account_id"
						tagText={ accountTagTextFunction }
						sortField = "account_name"
						readOnly = {this.props.readOnly}
						emptyMessage="No account assigned" />
				</div>
			</div>
		);
	}

	render() {
		let readOnlys = [];
		let donotModify = true;
		let UIList = [];
		let csStyle= {"paddingTop": "10px"};

		if (this.props.readOnly) {
			let programs = this.props.shortcodePrograms;
			UIList.push(this.renderShortCodeAccountsPanel());
			UIList.push(<div className="col-sm-6 form-control-plaintext" style={csStyle}>
					<b>Short Code Programs</b>
				</div>);
			UIList.push(
					<TableSearch
						onUserInput={this.handleUserInput}
						filterText={this.state.filterText}>
					</TableSearch>);

			UIList.push(
					<DataTable
						id="ChannelProgramId"
						columns={ this.getColumns() }
						filterText={this.state.filterText}
						initialSortKey={this.state.initialSortKey}
						dataItems={ programs }
						rowsPerPage={ this.state.rowsPerPage }
						caption="Short Code Programs">
					</DataTable>
				);
		} else  {
			donotModify = false;

			let DEFAULT_HELP = "Personify Health provides services on behalf of healthcare payers, programs and providers. Contact info@welltok.com or see http://welltok.com/terms-of-use.html";
			let DEFAULT_OPTOUT = "You have opted out. You will not receive additional messages. Usted ha optado por no participar. No recibirá mensajes adicionales. Contact info@welltok.com";

			this.props.object.HelpMessage = this.props.object.HelpMessage || DEFAULT_HELP;
			this.props.object.OptOutMessage = this.props.object.OptOutMessage || DEFAULT_OPTOUT;
		}
		return (
			<Form {...this.props} width="800" height="580" >
				<FormField.Input dontModify= {donotModify} fieldid="ShortCode" width="3" maxLength={15} label="Short Code" labelWidth="2" maxWidth = "20" required = {true} />
				<FormField.Input fieldid="Throttle" width="3" maxLength={15} label="Throttle" labelWidth="2" maxWidth = "10" required = {true} />
				<FormField.Input fieldid="ShortCodeDesc" width="9"  label="Description" labelWidth="2" required = {true} />
				<FormField.Input fieldid="HelpMessage" width="9" label="Help Message" labelWidth="2" countLetter="{true}"/>
				<FormField.Input fieldid="OptOutMessage" width="9" label="Optout Message" labelWidth="2" countLetter="{true}" />
				{ readOnlys }
				{ UIList }
			</Form>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		shortcodes: state.shortcodeReducer.shortcodes,
		shortcodeAccounts:  state.shortcodeReducer.shortcodeAccounts['ID_' + ownProps.object.ShortCodeId],
		shortcodePrograms:  state.shortcodeReducer.shortcodePrograms['ID_' + ownProps.object.ShortCodeId],
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, shortcodeActions, vendorActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ShortCodeForm)
