import * as types from './actionTypes';
import api from './api';

export function authorizeBEE(email_domain_id, account_id) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: '/email-service/bee/authorize'
		}).then(response => {
			dispatch({type: types.AUTHORIZE_BEE, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadEmailBEETemplates() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/email-service/emailbeetemplates',
		}).then(response => {
			dispatch({type: types.GET_BEETEMPLATES_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadEmailBEETemplate(email_bee_template_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/email-service/emailbeetemplates/${email_bee_template_id}`
		}).then(response => {
			dispatch({type: types.GET_BEETEMPLATE_SUCCESS, payload: response, id: email_bee_template_id});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addBEETemplate(email_bee_template_name, email_bee_template_json, email_bee_template_html) {
	if (typeof email_bee_template_json === "string")
	{
		try
		{
			email_bee_template_json = JSON.parse(email_bee_template_json);
		}
		catch(e)
		{
			return function(dispatch) {
				// Let calling page catch the error and display
				return (new Promise(function(resolve, reject) {
					let errMsg = (typeof e === "object" && "message" in e) ? e.message : e;
					reject(errMsg);
				}));
			};
		}
	}
	return function(dispatch) {
		return api({
			method: 'post',
			url: '/email-service/emailbeetemplates',
			data: {
				email_bee_template_name: email_bee_template_name,
				email_bee_template_json: email_bee_template_json,
				email_bee_template_html: email_bee_template_html
			}
		}).then(response => {
			dispatch({type: types.ADD_BEETEMPLATES_SUCCESS, payload: response});
			// Note that instead of dispatching, we return the response immediately to the page.
			// The response from this call is pertinent for the page since it will now know what its ID is for edit mode.
			// But this data isn't really important to the data store.
			// So we return the data to the calling page.
			// You could argue that this is anti-pattern, and we should have this go through the reducer.
			// However, it works really well, removes unnecessary complication, and is faster.
			return response;
		}).catch(error => {
			// Let calling page catch the error and display
			return (new Promise(function(resolve, reject) {
				let errMsg = (typeof error === "object" && "message" in error) ? error.message : error;
				reject(errMsg);
			}));
		});
	};
}

export function updateBEETemplate(email_bee_template_id, email_bee_template_name, email_bee_template_json, email_bee_template_html) {
	if (typeof email_bee_template_json === "string")
	{
		try
		{
			email_bee_template_json = JSON.parse(email_bee_template_json);
		}
		catch(e)
		{
			return function(dispatch) {
				// Let calling page catch the error and display
				return (new Promise(function(resolve, reject) {
					let errMsg = (typeof e === "object" && "message" in e) ? e.message : e;
					reject(errMsg);
				}));
			};
		}
	}
	return function(dispatch) {
		return api({
			method: 'put',
			url: `/email-service/emailbeetemplates/${email_bee_template_id}`,
			data: {
				email_bee_template_name: email_bee_template_name,
				email_bee_template_json: email_bee_template_json,
				email_bee_template_html: email_bee_template_html
			}
		}).then(response => {
			dispatch({type: types.UPDATE_BEETEMPLATE_SUCCESS, payload: response});
		}).catch(error => {
			// Let calling page catch the error and display
			return (new Promise(function(resolve, reject) {
				let errMsg = (typeof error === "object" && "message" in error) ? error.message : error;
				reject(errMsg);
			}));
		});
	};
}

export function deleteBEETemplate(email_bee_template_id) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/email-service/emailbeetemplates/${email_bee_template_id}`
		}).then(response => {
			dispatch({type: types.DELETE_BEETEMPLATE_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function cloneBEETemplate(email_bee_template_id) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: `/email-service/emailbeetemplates/clone/${email_bee_template_id}`
		}).then(response => {
			dispatch({type: types.CLONE_BEETEMPLATE_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadBEETemplateAccounts(email_bee_template_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/email-service/emailbeetemplates/${email_bee_template_id}/accounts`
		}).then(response => {
			dispatch({type: types.GET_BEETEMPLATE_ACCOUNTS_SUCCESS, payload: response, id: email_bee_template_id});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addBEETemplateAccount(email_bee_template_id, account_id) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: `/email-service/emailbeetemplates/${email_bee_template_id}/accounts/${account_id}`
		}).then(response => {
			dispatch({type: types.ADD_BEETEMPLATE_ACCOUNT_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function removeBEETemplateAccount(email_bee_template_id, account_id) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/email-service/emailbeetemplates/${email_bee_template_id}/accounts/${account_id}`
		}).then(response => {
			dispatch({type: types.REMOVE_BEETEMPLATE_ACCOUNT_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}
