import * as types from './actionTypes';
import api from './api';

export function loadShortCodes() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/text-service/shortcodes'
		}).then(response => {
			dispatch({type: types.GET_SHORTCODES_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadShortCodeAccounts(short_code_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/text-service/shortcodes/${short_code_id}/accounts`
		}).then(response => {
			dispatch({type: types.GET_SHORTCODE_ACCOUNTS_SUCCESS, payload: response, id: short_code_id});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadShortCodePrograms(short_code_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/text-service/shortcodes/${short_code_id}/programs`
		}).then(response => {
			dispatch({type: types.GET_SHORTCODE_PROGRAMS_SUCCESS, payload: response, id: short_code_id});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadAvailableShortCodes() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/text-service/smsvendors/freeshortcodes'
		}).then(response => {
			dispatch({type: types.GET_AVAILABLE_SHORTCODES_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addShortCodeAccount(short_code_id, account_id) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: `/text-service/shortcodes/${short_code_id}/accounts/${account_id}`
		}).then(response => {
			dispatch({type: types.ADD_SHORTCODE_ACCOUNT_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function removeShortCodeAccount(short_code_id, account_id) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/text-service/shortcodes/${short_code_id}/accounts/${account_id}`
		}).then(response => {
			dispatch({type: types.REMOVE_SHORTCODE_ACCOUNT_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addShortCode(ShortCode, Throttle, ShortCodeDesc, HelpMessage, OptOutMessage) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: '/text-service/shortcodes/shortcode',
			data: {
				short_code: ShortCode,
				throttle: Throttle,
				short_code_desc: ShortCodeDesc,
				help_message: HelpMessage,
				opt_out_message: OptOutMessage
			}
		}).then(response => {
			dispatch({type: types.ADD_SHORTCODE_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function updateShortCode(ShortCodeId, ShortCode, Throttle, ShortCodeDesc, HelpMessage, OptOutMessage) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: '/text-service/shortcodes/shortcode',
			data: {
				short_code_id: ShortCodeId,
				short_code: ShortCode,
				throttle: Throttle,
				short_code_desc: ShortCodeDesc,
				help_message: HelpMessage,
				opt_out_message: OptOutMessage
			}
		}).then(response => {
			dispatch({type: types.UPDATE_SHORTCODE_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function removeShortCode(short_code_id) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/text-service/shortcodes/${short_code_id}`
		}).then(response => {
			dispatch({type: types.REMOVE_SHORTCODE_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}
