import * as types from '../actions/actionTypes';
/*
 * All reducers get two parameters passed in, state and action that occurred
 *   > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

var initialState = {
	accountGroupAccounts: {},
	accountgroups: [],
	accounts: []
};

export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_ACCOUNTS_SUCCESS:
			//console.log('reducer to update account list',action);
			return Object.assign({}, state, {accounts: action.payload});
		case types.GET_ACCOUNTGROUPS_SUCCESS:
			//console.log('reducer to get account groups data',action);
			return Object.assign({}, state, {accountgroups: action.payload});
		case types.GET_ACCOUNTGROUP_DETAILS_SUCCESS:
			//console.log('reducer to get account group detail data', action);
			/**************************
				We're storing vendor shortcodes in a hash.
				The key is the vendor account ID.
				The value is the list of shortcodes.
				This way, each incoming call doesn't overwrite data from previous calls.
			**************************/

			// FIREFOX BUG WORKAROUND
			// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
			// It doesn't like the number 1 as a computed key in an object, even once it's stringified
			// Also: it's unfortunate that this API returns VendorAccountId ending with a lower-case d,
			// but in other places it ends with an upper-case D.
			let accountGroupID = action.id;
			return Object.assign({}, state, {accountGroupAccounts: { ['ID_' + accountGroupID]: action.payload}});
		default:
			return state;
	}
}
