import React, {Component} from 'react';
import {Route,Switch} from 'react-router-dom';

import SideBar from '../common/SideBar'
import EmailDomains from './emaildomains/EmailDomains';
import UnsubscribeGroups from './unsubscribegroups/UnsubscribeGroups';
import EmailBEETemplates from './emailbeetemplates/EmailBEETemplates';
import ShortCodes from './shortcodes/ShortCodes';
import Vendors from './vendors/Vendors';
import About from './About'
import SecuredRoute from '../common/SecuredRoute';

const menuitems = [
	{id: "digiShortcodeLink", displayName: 'Short Codes', linkname: '/digichannel', icon: 'comments', needsRole: 'slink-sys-admin'},
	{id: "digiVendorLink", displayName: 'Vendors', linkname: '/digichannel/vendors', icon: 'cogs', needsRole: 'slink-sys-admin'},
	{
		id: "digiEmaildomainLink",
		displayName: 'Email Domains',
		linkname: '/digichannel/emaildomains',
		icon: 'envelope',
		needsRole: 'slink-sys-admin'
	},
	{
		id: "digiUnsubscribeLink",
		displayName: 'Email Unsubscribe',
		linkname: '/digichannel/unsubscribegroups',
		icon: 'ban',
		needsRole: 'slink-sys-admin'
	},
	{
		id: "digiBEETemplateLink",
		displayName: 'Email Templates',
		linkname: '/digichannel/emailbeetemplates',
		icon: 'images',
		needsRole: 'slink-sys-admin'
	},
	{id: "digiEmptyLink", displayName: "--", linkname: '', needsRole: 'slink-sys-admin'},
	{id: "digiLogoutLink", displayName: "Logout", linkname: '/login', icon: 'sign-out-alt', isLogout: true}
];

class DigiChannelSideBar extends Component {
	constructor(props) {
		super();
	}

	render() {
		return (
			<div>
				<SideBar menuitems={ menuitems }/>
				<div>
					<div id="page-content-wrapper" className="full-height">
						<div className="sl-rightside-container">
							<Switch>
								<SecuredRoute  path="/digichannel/emaildomains" component={EmailDomains}/>
								<SecuredRoute  path="/digichannel/unsubscribegroups" component={UnsubscribeGroups}/>
								<SecuredRoute  path="/digichannel/emailbeetemplates" component={EmailBEETemplates}/>
								<SecuredRoute  path="/digichannel/vendors" component={Vendors}/>
								<SecuredRoute  component={ShortCodes}/>
							</Switch>
						</div>
					</div>
				</div>
				{this.props.children}
			</div>
		);
	}
}

export default DigiChannelSideBar;
