import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class MyLink extends Component {

    render () {
        const divProps = Object.assign({},  this.props, {"className": "nav-link"});
        delete divProps.linktext;
        delete divProps.route;
        return <NavLink  {...divProps}>{this.props.linktext}</NavLink>
    }
}

export default  MyLink;