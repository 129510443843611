import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as accountActions  from '../../../actions/accountActions';
import * as apilinkActions  from '../../../actions/apilinkActions';
import * as formStateActions  from '../../../actions/formStateActions';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import _ from 'lodash';

class ApiLinkAccountForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentAccountToAdd: {account_id: null}
		};
		this._isMounted = false;

		this.addApiLinkAccount = this.addApiLinkAccount.bind(this);
		this.removeApiLinkAccount = this.removeApiLinkAccount.bind(this);
		this.takeDownForm = this.takeDownForm.bind(this);
		this.accountChange = this.accountChange.bind(this);
		this.cancelButtonCallback = this.cancelButtonCallback.bind(this);
		this.setFormErrorMessage = this.setFormErrorMessage.bind(this);
		this.setFormSuccessMessage = this.setFormSuccessMessage.bind(this);
	}

	takeDownForm(successMessage) {
		window.scrollTo(0, 0);
	}

	componentDidMount() {
		let obj = this.props.object;
		if (obj && obj.api_link_id) {
			this.props.actions.loadApiLinkAccounts(obj.api_link_id).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage(`An error occurred loading the api link accounts: ${msg}`);
			});
		}
		this.props.actions.loadAccounts().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage(`An error occurred loading the activate accounts: ${msg}`);
		});
	}

	componentWillUnmount() {
		;
	}

	componentDidUpdate(prevProps) {
		window.scrollTo(0, 0)
	}

	_onChange() {
		// repaint the form when data loads
		if (this._isMounted) {
			this.forceUpdate();
		}
	}

	extractJSONFields(text) {
		try {
			return JSON.parse(text);
		} catch(err) {
			return {};
		}
	}

	addApiLinkAccount() {
		let api_link_id = this.props.object.api_link_id;
		let account_id = this.state.currentAccountToAdd.account_id;
		this.props.actions.addApiLinkAccount(api_link_id, account_id).then(data => {
			this.props.actions.loadApiLinkAccounts(api_link_id).then(data => {
				this.takeDownForm();
				this.setFormSuccessMessage(`Successfully added api link account ${account_id}`);
			}).catch(errObject => {
				this.takeDownForm(null);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred adding the api link account: " + msg);
		});
	}

	removeApiLinkAccount(account_id) {
		let api_link_id = this.props.object.api_link_id;
		this.props.actions.removeApiLinkAccount(api_link_id, account_id).then(data => {
			this.props.actions.loadApiLinkAccounts(api_link_id).then(data => {
				this.takeDownForm();
				this.setFormSuccessMessage(`Successfully removed api link account ${account_id}`);
			}).catch(errObject => {
				this.takeDownForm(null);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage(`An error occurred removing the api link account ${account_id}: ${msg}`);
		});
	}

	setFormSuccessMessage(successMessage) {
		this.setState({
			successMessage: successMessage,
			errorMessage: null
		});
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			successMessage: null,
			errorMessage: errorMessage
		});
	}

	cancelButtonCallback()
	{
		this.props.actions.loadApiLinks().then(successObject => {
			if (this.props.cancelCallback)
				this.props.cancelCallback();
		}).catch(errObject => {
			if (this.props.cancelCallback)
				this.props.cancelCallback();
		});
	}

	accountChange(event, fieldChanges) {
		this.setState({
			currentAccountToAdd: _.assignIn(this.state.currentAccountToAdd, fieldChanges),
			applicationErrorMessage: null,
			errorMessage: null
		});
	}

	renderAvailable() {
		let accountTagTextFunction = function(obj) {
			return obj.account_name + ' (' + obj.account_id + ')';
		};
		let allAccounts = this.props.accounts;
		let addedAccounts = this.props.apilinkAccounts;
		let aryAddAccounts = _.values(addedAccounts);

		let unSignedAccounts = _.filter(allAccounts, function (o) {
			let idx = _.findIndex(aryAddAccounts, {account_id: o.account_id});
			return idx === -1;
		});

		let availableData = {
			label: "Account",
			fieldid: "account_id",
			fieldName: "account_name",
			optionid: "account_id",
			fieldValue: this.state.currentAccountToAdd.account_id,
			options: unSignedAccounts,
			localFieldChangeCallback: this.accountChange,
			errorMessage: this.state.applicationErrorMessage,
			assinedCb: this.addApiLinkAccount
		};

		return (
			<div className="form-group">
				<label className="control-label col-md-2" style={{ lineHeight: "16px"}}>Accounts</label>
				<div className="form-control-static col-md-10" >
					<FormField.AssignedTagList
						objects={ aryAddAccounts }
						keyField="account_id"
						tagText={ accountTagTextFunction }
						sortField = "account_name"
						readOnly = {this.props.readOnly}
						emptyMessage="No account assigned"
						removeCallback={ this.removeApiLinkAccount }
						buttonIDPrefix={ "api_link" }
						available = {availableData} >
					</FormField.AssignedTagList>
				</div>
			</div>
		);
	}

	render() {
		let object = this.props.object;
		let defaultApiLinkNameValue = "name" in object ? object.name : "";
		return (
			<Form {...this.props} width="800" height="580" cancelCallback={this.cancelButtonCallback} cancelButtonLabel="Done" successMessage={ this.state.successMessage } errorMessage={ this.state.errorMessage }>
				<FormField.Input dontModify= {true} fieldid="name" fieldvalue= {defaultApiLinkNameValue} width="7" label="Api link" labelWidth="2"  />
				{ this.renderAvailable()}
			</Form>
		);
	}
}

function mapStateToProps(state, ownProps) {
	// FIREFOX BUG WORKAROUND
	// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
	// It doesn't like the number 1 as a computed key in an object, even once it's stringified
	return {
		currentForm: state.formStateReducer.currentForm,
		apilinkAccounts: state.apiLinkReducer.accountApiLinks['ID_' + ownProps.object.api_link_id],
		accounts: state.accountReducer.accounts
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, accountActions, apilinkActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiLinkAccountForm)
