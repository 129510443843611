import React, { Component } from 'react';
import _ from 'lodash';
import FormField from './FormField';
import DataTable  from './DataTable';

class DataTableForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: null
		};
		this.addRow = this.addRow.bind(this);
		this.removeRow = this.removeRow.bind(this);
	}

	addRow(event)
	{
		if (this.props.addRowCallback) {
			this.props.addRowCallback();
		}
	}

	removeRow(event)
	{
		if (this.props.removeRowCallback) {
			this.props.removeRowCallback(event);
		}
	}

	render() {
		let object = this.props.object;
		let fieldName = this.props.fieldName;
		let labelWidth = this.props.labelWidth || 3;
		let dataTag;

		let removeCallbackRow = {};
		if (this.props.removeRowCallback)
			removeCallbackRow["removeRowCallback"] = this.removeRow;

		// Crazy enough, the key field below really matters
		// If you change the data, you need to update the key
		// Otherwise, the DataTable might not recognize that data has changed
		return (
			<div>
				<DataTable
					key={this.props.key ? this.props.key : 1}
					fieldchangecallback={this.props.fieldchangecallback}
					{...removeCallbackRow}
					readOnly={this.props.readOnly}
					id={this.props.id}
					columns={ this.props.columns }
					dataItems={ this.props.dataItems }
					rowsPerPage={ 5000 }
					initialSortKey={ this.props.initialSortKey }
					disableSort={this.props.disableSort} >
				</DataTable>
				{ this.props.addRowCallback ?
					<div><button id={this.props.id + "_addRow"} style={{float: "none"}} onClick={this.addRow}>Add row</button></div>
					: null
				}
			</div>
		);
	}
}

export default {DataTableForm: DataTableForm};
