import React, {Component} from 'react';
import {Route,Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import SideBar from '../common/SideBar';
import DashboardListingPage from './report/DashboardListingPage';
import SecuredRoute from '../common/SecuredRoute';

const SlinkAuth = window.SlinkAuth;

const menuitems = [
	{id: "id2", displayName: 'Dashboards', linkname: '/dashboard/DashboardListingPage', icon: 'user', needsRole: 'slink-engmnt-manager'},
	{id: "id9", displayName: "--", linkname: '', needsRole: 'slink-engmnt-manager'},
	{id: "id12", displayName: "Logout", linkname: '/login', icon: 'sign-out-alt', isLogout: true}
];

class DashboardSideBar extends Component {
	constructor(props) {
		super();
	}

	render() {
		let roles = SlinkAuth.getRoles();
		return (
			<div>
				<SideBar menuitems={ menuitems }/>
				<div>
					<div id="page-content-wrapper" className="full-height">
						<div className="sl-rightside-container">
							<Switch>
								<SecuredRoute  path="/dashboard/DashboardListingPage" component={DashboardListingPage}/>
							</Switch>
						</div>
					</div>
				</div>
				{this.props.children}
			</div>
		);
	}
}

export default DashboardSideBar;
