import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import PasswordFooter from "./PasswordFooter";


const SlinkAuth = window.SlinkAuth;



class ChangePasswordForm extends Component {
    constructor(props)  {
        super(props);

        var urlArgs = SlinkAuth.getUrlArgs(window.location.search);
        this.state = {
            id: urlArgs['id'] || '',
            fieldMessage: null,
            passwordUpdateMessage: null,
            password1: null,
            password2: null,
            main_uri: null
        };

        document.title = "Personify Health Change Password";

        this.renderErrorBlock = this.renderErrorBlock.bind(this);
        this.textChange = this.textChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);


    }
    renderErrorBlock() {
        var fieldErrorMessage = this.state.fieldMessage;
        if (!fieldErrorMessage) {
            return null;
        }
        return (
            <span className={"error-block"} style={{clear:"both", color: "#a94442"}}>{fieldErrorMessage}</span>
        );
    }
    textChange(fieldId, event) {
        var value = event.target.value;
        var stateVals = {
            fieldMessage: window.getPasswordError(value)
        };
        stateVals[fieldId] = value;
        this.setState(stateVals);
    }
    onSubmit() {
        var self = this;

        if(!self.state.password1 && !self.state.password2){
            var stateVals = {
                fieldMessage: "Please provide password in both fields"
            };
            self.setState(stateVals);
            return;
        }

        if(!self.state.password1 || !self.state.password2 || self.state.password1 && self.state.password2 && self.state.password1 != self.state.password2){
            var stateVals = {
                fieldMessage: "The password fields must match"
            };
            self.setState(stateVals);
            return;
        }

        if (self.state.fieldMessage) {
            return;
        }

        SlinkAuth.resetPassword(this.state.id, this.state.password1, function (err, responseData) {
            var msg;
            if (err) {
                msg = `Your password could not be reset (${err}). The link may have expired. Request a new reset email, or contact your administrator.`;
            } else {
                msg = "Your password has been reset.";
            }
            self.setState({
                passwordUpdateMessage: msg,
                main_uri: responseData ? responseData.main_uri : null
            });
        });
    }
    render() {
        var innerDiv;
        var homeLink;
        if (this.state.passwordUpdateMessage) {
            if (this.state.main_uri) {
                homeLink = (<p style={{ width: 300, marginTop: 30 }}>
                    Click <a href={ this.state.main_uri }>here</a> to login.
                </p>);
            }
            innerDiv = (
                <div id="updatemessage" style={ {width: 300, marginRight: "auto", marginLeft: "auto", marginTop: 75 } }>
                    <p style={{ width: 400, marginTop: 24, fontWeight: "bold" }}>
                       { this.state.passwordUpdateMessage }
                    </p>
                    { homeLink }
                </div>
            );
        } else {
            innerDiv = (
                <div id="passwordform" style={ {width: 320, margin: "40px auto 24px auto" } }>
                    <form>
                        <label style={{ fontSize: "20px", color: "#f58220", margin: "20px auto 30px auto" }}>Enter your new password</label>

                        <div className="form-group">
                            <label htmlFor="password1">Password</label>
                            <input type="password" autoComplete="new-password" className="form-control"
                                    id="password1" name="password1"
                                    onChange={ _.wrap("password1", this.textChange) }
                                    placeholder="" autoFocus />
                        </div>
                        <div className="form-group">
                           <label htmlFor="password2">Re-enter Password</label>
                           <input type="password" autoComplete="new-password" className="form-control"
                                    id="password2" name="password2"
                                    onChange={ _.wrap("password2", this.textChange) }
                                    placeholder="" />
                        </div>
                        { this.renderErrorBlock() }

                        <div style={{ paddingRight: 72, marginTop: 48 }}>
                            <button id="changesubmit" type="button" onClick={ this.onSubmit }
                                    style={ {display: "block", margin: "8px auto 0 auto", width: "160px"} } className="btn btn-primary">Change Password</button>
                        </div>
                        <br/>
                        <p style={{ width: 500, fontStyle: "italic" }}>
                        Passwords should be at least 8 characters long, and contain at least one small letter, one capital letter, one digit and one special character such as $ ! . ; ? @ ( )
                        </p>
                    </form>
                </div>
            );
        }
        return PasswordFooter(innerDiv);
    }
};


function mapStateToProps(state, ownProps) {
    return {
        router: state.router
    }
}

// function mapDispatchToProps(dispatch) {
//     return {
//         auth: bindActionCreators(sessionActions, dispatch)
//     };
// }

export default connect(mapStateToProps, null) (ChangePasswordForm)
