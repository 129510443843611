import React, {Component}  from 'react';
import DataTableRow from './DataTableRow';
import _ from 'lodash';

class DataTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnSortKey: this.props.initialSortKey,
            ascending: this.props.ascending || true,
            filterText: '',
            rowsPerPage: this.props.rowsPerPage,
            previousPage: 1,
            currentPage: 1,
            //token: AuthStore.getAuthToken()
        }
        this._isMounted = false;

        this.headerClick = this.headerClick.bind(this);
        this.changePage = this.changePage.bind(this);
        this.changePageInput = this.changePageInput.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.filterText !== this.state.filterText) {
            this.setState({
                filterText: nextProps.filterText,
                currentPage: 1
            })
        }
    }

    headerClick(evt) {
        if (this.state.columnSortKey.length > 0) {
            var keyChanged = (evt.target.dataset.colkey !== this.state.columnSortKey);
            var newAscendingValue = keyChanged ? true : !this.state.ascending;
            this.setState({
                columnSortKey: evt.target.dataset.colkey,
                ascending: newAscendingValue
            })
        }
    }

    changePage(e, button, numPages) {
        var newPage;
        var currentPage = parseInt(this.state.currentPage, 10);
        var previousPage = parseInt(this.state.previousPage, 10);
        // 1 = first page, 2 = previous page, 3 = next page, 4 = final page
        if (button === 1) {
            newPage = 1;
        } else if (button === 2) {
            if (currentPage === 0) {
                if (previousPage === 1) {
                    newPage = previousPage;
                } else {
                    newPage = previousPage - 1;
                }
            } else {
                if (currentPage === 1) {
                    newPage = currentPage;
                } else {
                    newPage = currentPage - 1;
                }
            }
        } else if (button === 3) {
            if (currentPage === 0) {
                if (previousPage === numPages) {
                    newPage = previousPage;
                } else {
                    newPage = previousPage + 1;
                }
            } else {
                if (currentPage === numPages) {
                    newPage = currentPage;
                } else {
                    newPage = currentPage + 1;
                }
            }
        } else if (button === 4) {
            newPage = numPages;
        }
        this.setState({
            previousPage: currentPage,
            currentPage: newPage
        });
    }

    changePageInput(event, numPages) {
        var currentPage = parseInt(this.state.currentPage, 10);
        if (currentPage !== 0)  {
            this.setState({
                previousPage: currentPage
            })
        }

        var nextPage;
        if (!isNaN(parseInt(event.target.value, 10))) {
            nextPage = parseInt(event.target.value, 10);
        } else if (isNaN(parseInt(event.target.value))) {
            nextPage = 0;
        }
        if (nextPage <= numPages) {
            this.setState({
                currentPage: nextPage,
            });
        }
    }

    render() {
        var values = _.values(this.props.dataItems);
        var cols = [];
        var tempWidth = 0;

        // table headers
        _.each(this.props.columns, function(columnInfo, columnKey) {
            if (!columnInfo.used) {
                return;
            }
            var colStyle = {};
            if (columnInfo.width) {
                colStyle = { width: columnInfo.width };
            }
            var headerClass = (this.state.columnSortKey && (this.state.columnSortKey === columnKey)) ?
                (this.state.ascending ?
                    'headerSortUp' :
                    'headerSortDown') : null;
            cols.push(
                <th scope={"col"} key={"header-" + columnKey} data-colkey={columnKey}
                    style={colStyle} className={headerClass}
                    onClick={this.props.disableSort ? null : this.headerClick}
                    colSpan="1" rowSpan="2">
                    {columnInfo.name}
                </th>
            );
        }.bind(this));

        if (this.props.buttons) {
            tempWidth = ( 12 + 28 * this.props.buttons.length);
            cols.push(<th style={{ width: tempWidth }} key="_buttons">Actions</th>);
        }

        if (this.props.removeRowCallback && !this.props.readOnly) {
            cols.push(<th style={{ width: '20px' }} key="_delete">&nbsp;</th>);
        }

        // table body
        var textToFind = this.state.filterText ? this.state.filterText.toLowerCase() : null;
        var rowsToPaginate = [];
        var sortKey = this.state.columnSortKey;
        var sortFunction = function(obj) {
            var val = obj ? obj[sortKey] : null;
            if (val && _.isString(val)) {
                val = val.toLowerCase();
            }
            return val;
        };

        if (this.state.columnSortKey) {
            values = _.orderBy(values, sortFunction, [ (this.state.ascending ? 'asc' : 'desc') ]);
        }

        _.each(values, function(dataItem) {
            _.some(this.props.columns, function(columnInfo, columnKey) {
                var objid = dataItem['id'];
                if(!objid && this.props.id) {
                    objid = dataItem[this.props.id];
                }
                var columnValue = dataItem[columnKey];
                if (textToFind === null || (columnInfo.used && columnValue && ('' + columnValue).toLowerCase().indexOf(textToFind) !== -1)) {
                    rowsToPaginate.push(<DataTableRow key={"row-" + objid} objid={objid} buttonIDPrefix={ this.props.buttonIDPrefix } columns={this.props.columns} dataItem={dataItem} buttons={this.props.buttons} readOnly={this.props.readOnly} fieldchangecallback={this.props.fieldchangecallback} removeRowCallback={this.props.removeRowCallback} />);
                    return true;
                }
            }.bind(this));
        }.bind(this));

        if(values.length === 0) {
            rowsToPaginate.push(<tr key="row-no-record"><td colSpan= { String(cols.length) } >No Records</td></tr>);
        }

        // TODO: if current input string for page is not a number, use previous state, added in input function by setting previous state as variable

        var apiArray = Object.values(rowsToPaginate);
        var rowPages = _.chunk(apiArray, this.state.rowsPerPage);
        var rowNumPages = rowPages.length;
        var currentPage = this.state.currentPage;
        var previousPage = this.state.previousPage;
        var rows = (currentPage === 0) ? rowPages[previousPage - 1] : rowPages[currentPage - 1];

        var paginateStyle = {'display': (rowNumPages > 1) ? 'block' : 'none', 'marginBottom': '10px', 'height': '24px', 'width': '180px'};
        var inputStyle    = {'testAlign': 'center', 'width': '35px', 'height': '24', 'color': (currentPage === 0) ? 'FFFFFF' : '070707', 'backgroundColor': 'FFFFFF'};

        var pageButtons =
            <div className="pagination-container" style={ paginateStyle }>
                <ul className="pagination-pointed pagination text-center" role="navigation" aria-label="Pagination">
                    <li><button key="btn-first" title="First" className="pagination-button" onClick={((e) => this.changePage(e,1,rowNumPages))}><i className={"fa fa-angle-double-left"}/></button></li>
                    <li><button key="btn-previous" title="Previous" className="pagination-button" onClick={((e) => this.changePage(e,2,rowNumPages))}><i className={"fa fa-angle-left"}/></button></li>
                    <li><a className="corrected-margins"><input style={ inputStyle } type="integer" value={ currentPage } onChange={((e) => this.changePageInput(e, rowNumPages))}/> / {rowNumPages}</a></li>
                    <li><button key="btn-next" title="Next" className="pagination-button" onClick={((e) => this.changePage(e,3,rowNumPages))}><i className={"fa fa-angle-right"}/></button></li>
                    <li><button key="btn-last" title="Last" className="pagination-button" onClick={((e) => this.changePage(e,4,rowNumPages))}><i className={"fa fa-angle-double-right"}/></button></li>
                </ul>
            </div>;

        var pageButtonsUpper = <div style={{'marginTop': '-36px', 'marginLeft': '366px', 'height': '24px', 'width': '180px', 'marginBottom': '12px'}}>{ pageButtons }</div>;
        var pageButtonsLower = <div style={{'float': 'right', 'height': '24px', 'width': '180px'}}>{ pageButtons }</div>;

        return (
            <div>
                { pageButtonsUpper }
                <table className="table table-striped table-sm table-hover table-bordered sl-datatable">
                    <thead><tr>{cols}</tr></thead>
                    <tbody>
                    {rows}
                    </tbody>
                </table>
                { pageButtonsLower }
            </div>
        );
    }
}

export default DataTable
