import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Link, IndexRoute, browserHistory, Redirect, Switch} from 'react-router-dom';
import auth from './authenticator';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../actions/sessionActions';
//import clientData from '../login/clientdata';

const SlinkAuth = window.SlinkAuth;
const clientData = window.clientData;

class OAuthRedirectForm1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "success": false,
            "redirectTo": null
        };
    }
    componentWillMount(){
        let self = this;
        let success = false;

        let loginStatus = sessionStorage.getItem('LOGIN_RESULT');
        if (loginStatus != null) {
            SlinkAuth.extractResponse(loginStatus, function (err, returnUrl, is_reauth) {
                if (auth.loggedIn()) {
                    success = true;
                    //clear the result to indicate the user has login
                    sessionStorage.removeItem('LOGIN_RESULT');
                    let roles = SlinkAuth.getRoles();

                    let redirectTo;
                    if (roles == "slink-sys-admin")
                        redirectTo = "/home";
                    else if (roles == "slink-sisl-admin")
                        redirectTo = "/admin/about";
                    else
                        redirectTo = "/home";

                    self.setState({
                        "success": true,
                        "redirectTo": redirectTo
                    });
                }
            });
        }

        if (!success) {
            let token = sessionStorage.getItem('atk');
            //this.setState({position: 1});
            SlinkAuth.logout(token, function(err) {
                window.location = SlinkAuth.getAuthUrl(clientData);
            });
        }
    }

    render() {
        if (this.state.success) {
            return <Redirect to= { this.state.redirectTo }/>
        } else {
            return <div>Authorizing...</div>
        }
    };
}
// Grab a reference to the current URL. If this is a web app and you are
// using React Router, you can use `ownProps` to find the URL. Other
// platforms (Native) or routing libraries have similar ways to find
// the current position in the app.
function mapDispatchToProps(dispatch, ownProps) {
    return {
        currentURL: ownProps.location.pathname,
        auth: bindActionCreators(sessionActions, dispatch)
    };
}
export default connect(null,  mapDispatchToProps)(OAuthRedirectForm1);