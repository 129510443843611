import * as types from '../actions/actionTypes';
/*
 * All reducers get two parameters passed in, state and action that occurred
 *   > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

var initialState = {
};

export default function (state = initialState, action) {
	switch (action.type) {
		case types.REFRESH_CACHE_SUCCESS:
			//console.log('reducer to update cache',action);
			return state;
	}
	return state;
}
