import _ from 'lodash';
import React, { Component } from 'react';
import FormField from './FormField';

class DataTableRow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			"rowData": props.dataItem ? props.dataItem : [],
			"version": 0
		};
	}

	componentDidUpdate(prevProps) {
		if (_.isEmpty(prevProps.dataItem) && !_.isEmpty(this.props.dataItem))
		{
			this.setState({
				"rowData": _.clone(this.props.dataItem),
				"version": this.state.version + 1
			});
		}
	}

	formatDate(dateString) {
		return dateString.replace(/^(\d\d\d\d-\d\d-\d\d)T/,"$1 ").replace(/^([\d-]* [\d:.]*)Z$/,'$1');
	}

	buttonCallback(buttonInfo) {
		if (buttonInfo && buttonInfo.callback) {
			buttonInfo.callback(null, null, this.props.objid);
		}
	}

	renderButtons() {
		var self = this;
		let buttonIDSuffix = this.props.buttonIDPrefix ? ("_" + this.props.dataItem[this.props.buttonIDPrefix]) : "";
		var buttonList = null;
		_.each(this.props.buttons, function(buttonInfo, idx) {
			if (!buttonList) buttonList = [];
			if (buttonInfo.icon) {
				buttonList.push(<button key={"btn-"+idx} id={"btn-"+encodeURI(buttonInfo.label + buttonIDSuffix)} title={buttonInfo.label} className="sl-tablebutton" onClick={_.bind(self.buttonCallback,self,buttonInfo)}><i className={ "fa fa-" + buttonInfo.icon } /></button>);
			} else {
				buttonList.push(<button key={"btn-"+idx} title={buttonInfo.label} className="sl-tablebutton" onClick={_.wrap(buttonInfo,this.buttonCallback)}>{buttonInfo.label}</button>);
			}
		}.bind(this));
		return buttonList;
	}

	render() {
		var self = this;
		var buttons = this.renderButtons();
		var cols = [];
		var tempWidth = 0;
		var valueLabel;

		_.each(this.props.columns, function(columnInfo, columnKey) {
			if (!columnInfo.used) {
				return;
			}
			var columnValue = self.props.dataItem[columnKey];
			if (columnInfo.type === "date") {
				columnValue = self.formatDate(columnValue||'');
			} else if (columnInfo.type === "boolean") {
				// values is an optional array which lists potential values to be shown.
				// That way you can show a boolean column only if it's true
				// or only if it's false
				let hideVal = columnInfo.values ? !_.includes(columnInfo.values, columnValue) : false;
				if (!hideVal)
				{
					if (columnValue) {
						valueLabel = columnInfo.valueLabels ? columnInfo.valueLabels[1] : null;
						columnValue = ( <div style={{ fontSize: 12, paddingTop: 2 }}>
							<span className="fa fa-check"/> { valueLabel }
						</div>);
					} else {
						valueLabel = columnInfo.valueLabels ? columnInfo.valueLabels[0] : null;
						columnValue = ( <div style={{ fontSize: 12, paddingTop: 2, color: "#BE0000" }}>
							<span className="fa fa-times"/> { valueLabel }
						</div>);
					}
				}
				else
				{
					columnValue = ( <div style={{ fontSize: 12, paddingTop: 2 }}>
						<span />
					</div>);
				}
			} else if (columnInfo.type === "textInput" || columnInfo.type === "integer") {
				// Readonly can be set in 3 places:
				//		1) DataTable parameter
				//		2) Column definition attribute
				//		3) In the data sent to the DataTable
				let IDVal = self.props.dataItem[columnInfo.id];
				let currVal = columnValue;
				let rootTextInputId = `${columnKey}_${IDVal}`;
				let cssStyles = {};
				let textInputWidth = columnInfo.textInputWidth ? columnInfo.textInputWidth : 7;
				let type = columnInfo.type === "integer" ? "integer": "text";
				if (!isNaN(parseInt(columnInfo.width)))
					cssStyles["width"] = parseInt(columnInfo.width) - 10;
				columnValue = (
					<FormField.Input fieldid={rootTextInputId} fieldchangecallback={self.props.fieldchangecallback} type={type} defaultFieldValue={currVal} width={textInputWidth} noempty={columnInfo.noempty}
						maxWidth={(!isNaN(parseInt(columnInfo.width))) ? (parseInt(columnInfo.width) - 10) : null} readOnly={this.props.readOnly || columnInfo.readonly || self.props.dataItem.readonly}
						singleline={true} invalidChars={columnInfo.invalidChars} noaftertag={columnInfo.noaftertag} maxLength={columnInfo.maxLength} />
				);
			} else if (columnInfo.type === "textArea") {
				// Readonly can be set in 3 places:
				//		1) DataTable parameter
				//		2) Column definition attribute
				//		3) In the data sent to the DataTable
				let IDVal = self.props.dataItem[columnInfo.id];
				let currVal = columnValue;
				let rootTextInputId = `${columnKey}_${IDVal}`;
				columnValue = (
					<FormField.TextArea rows={columnInfo.rows} fieldchangecallback={self.props.fieldchangecallback} cols={columnInfo.cols} name={rootTextInputId} fieldid={rootTextInputId} defaultValue={currVal}
						fieldKey={IDVal + this.state.version} readOnly={this.props.readOnly || columnInfo.readonly || self.props.dataItem.readonly} singleline={true} noempty={columnInfo.noempty} noaftertag={columnInfo.noaftertag} />
				);
			} else if (columnInfo.type === "radio") {
				let IDVal = self.props.dataItem[columnInfo.id];
				let currVal = columnValue ? true : false;
				let radioClasses = ["form-check"];
				let rootRadioId = `${columnKey}_${IDVal}`;
				columnValue = (
					<FormField.Radio name={rootRadioId} fieldid={rootRadioId} fieldchangecallback={self.props.fieldchangecallback} value={columnValue}
						fieldvalue={columnValue} values={columnInfo.values} readOnly={this.props.readOnly || columnInfo.readonly} inline={columnInfo.inline} />
				);
			} else if (columnInfo.type === "select") {
				let IDVal = self.props.dataItem[columnInfo.id];
				let defaultVal = columnValue;
				let options = columnInfo.values.map((radioObj) =>(
					  <option value={radioObj.val}>{radioObj.name}</option>
				));
				let rootSelectId = `${columnKey}_${IDVal}`;
				columnValue =  (
					<FormField.Select width={columnInfo.selectWidth} defaultFieldValue={defaultVal}
						fieldKey={rootSelectId + this.state.version} fieldchangecallback={self.props.fieldchangecallback}
						fieldid={rootSelectId} optionid={columnInfo.option_id}
						fieldName={columnInfo.option_name} options={columnInfo.values}
						findReadOnlyValue={true} singleline={true} noempty={columnInfo.noempty}
						readOnly={this.props.readOnly || columnInfo.readonly} />
				);
			} else {
				columnValue =  (columnValue === '0' || columnValue === 0) ? '0' : columnValue || '';
			}

			if (columnInfo.click_action) {
				var buttonInfo = _.filter(this.props.buttons, { label: columnInfo.click_action});
				if (buttonInfo && buttonInfo.length > 0) {
					columnValue = (
						<span className="sl-link" onClick={_.bind(self.buttonCallback,self,buttonInfo[0])}>{ columnValue }</span>
					);
				}
			}
			cols.push(<td key={columnKey}>{columnValue}</td>);
		}.bind(this));

		if (buttons) {
			tempWidth = ( 12 + 28 * this.props.buttons.length);
			cols.push(<td key={"buttons-" + this.props.objid} className="sl-buttoncell" style={ { width: tempWidth } }>{buttons}</td>);
		}
		if (this.props.removeRowCallback && !this.props.readOnly) {
			cols.push(<td key={"delete-" + this.props.objid}
				id={this.props.objid}
				style={{ width: '20px', color: 'red', 'textAlign': 'center', cursor: 'pointer' }}
				onClick={this.props.removeRowCallback}
				key="_delete">x</td>);
		}
        //    <tr scope={"row"}>{cols}</tr>
		return (
			<tr>{cols}</tr>
		);
	}
}
export default DataTableRow
