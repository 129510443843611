import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as formStateActions  from '../../../actions/formStateActions';
import * as accountActions  from '../../../actions/accountActions';
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';
import ConfirmForm from '../../common/ConfirmForm';
import AccountGroupForm from './AccountGroupForm';
import AccountGroupNameForm from './AccountGroupNameForm';
import _ from 'lodash';

class AccountGroups extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filterText: '',
			initialSortKey: 'id',
			errorMessage: '',
			currentForm: null,
			currentPage: 1,
			rowsPerPage: 50
		};

		this.handleUserInput = this.handleUserInput.bind(this);
		this.setFormState = this.setFormState.bind(this);
		this.takeDownForm = this.takeDownForm.bind(this);
		this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
		this.setFormErrorMessage = this.setFormErrorMessage.bind(this);
		this.editAcctGroupCallback = this.editAcctGroupCallback.bind(this);
		this.createAcctGroupCallback = this.createAcctGroupCallback.bind(this);
	}

	getColumns() {
		return {
			id:					{ name: "ID", used: 1, width: 36 },
			account_group_name:	{ name: "Account Group Name", used: 1, width: 250, click_action: "View" },
			created_date_time:	{ name: "Created Date Time", used: 1, width: 150, type: "date" }
		};
	}

	handleUserInput(filterText) {
		this.setState({
			filterText: filterText
		});
	}

    componentWillMount() {
        this.setFormState(null, null, null, null);
    }

	componentDidMount() {
		this.props.actions.loadAccountGroups().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the account groups: " + msg);
		});
	}

	componentWillUnmount() {
		;//AdminStore.removeChangeListener(this._onChange, 'accountgroups');
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			currentForm: nextProps.currentForm
		});
	}

	_onChange() {
		if (this.isMounted()) {
			this.setState({
				errorMessage: ''
			});
		}
	}

	setFormState(formType, errorMessage, successMessage, id) {
		if (this.props.actions.formChangeHandler)
			this.props.actions.formChangeHandler(formType, errorMessage, successMessage, id);
	}

	setFormErrorMessage(errorMessage) {
		//$(document).scrollTop(0);
		this.setState({
			errorMessage: errorMessage
		});
	}

	createAcctGroupCallback(fields) {
		// validate fields
		var msg;
		if (!fields['account_group_name']) {
			msg = 'Missing group name';
		}

		if (msg) {
			this.setFormErrorMessage(msg);
		} else {
			this.props.actions.addAccountGroup(fields.account_group_name).then(data => {
				this.takeDownForm("Account group added successfully");
				this.props.actions.loadAccountGroups().then(data => {
					;
				}).catch(errObject => {
					let msg = errObject.message || errObject;
					this.setFormErrorMessage("An error occurred loading account groups: " + msg);
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred adding the account group: " + msg);
			});
		}
	}

	editAcctGroupCallback(fields) {
		// validate fields
		var msg;
		if (!fields['account_group_name']) {
			msg = 'Missing group name';
		}

		if (msg) {
			this.setFormErrorMessage(msg);
		} else {
			this.props.actions.editAccountGroup(fields.id, fields.account_group_name).then(data => {
				this.takeDownForm("Account group modified successfully");
				this.props.actions.loadAccountGroups().then(data => {
					;
				}).catch(errObject => {
					let msg = errObject.message || errObject;
					this.setFormErrorMessage("An error occurred loading account groups: " + msg);
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred modifying the account group: " + msg);
			});
		}
	}

	takeDownForm() {
		this.setFormState(null, null, null, null);
	}

	render() {
		var accountgroups = this.props.accountgroups;
		var errDiv = this.state.errorMessage ? <div className="alert">{this.state.errorMessage}</div> : null;
		var currentForm, object;

        switch (this.props.currentForm && this.props.currentForm.formType)
		{
			case "insert":
				object = {};
				currentForm = <AccountGroupNameForm title="Create Account Group" object={object} cancelCallback={this.takeDownForm} submitCallback={this.createAcctGroupCallback} />;
				break;

			case "edit":
				object = accountgroups ? _.find(accountgroups, {id: this.props.currentForm.currentId}) : {};
				currentForm = <AccountGroupNameForm title="Edit Account Group" object={object} cancelCallback={this.takeDownForm} submitCallback={this.editAcctGroupCallback} />;
				break;

			case "show":
				object = accountgroups ? _.find(accountgroups, {id: this.props.currentForm.currentId}) : {};
				if (object) {
					currentForm = <AccountGroupForm title="Account Group Details" readOnly={true} object={object} cancelCallback={this.takeDownForm}/>;
				}
				break;

			case "assign":
				object = accountgroups ? _.find(accountgroups, {id: this.props.currentForm.currentId}) : {};
				if (object) {
					currentForm = <AccountGroupForm title="Assign Account Group Properties" readOnly={false} object={object}
										errorMessage={this.state.errorMessage}
										cancelCallback={this.takeDownForm}/>;
				}
				break;
		}

		//console.log('account groups currentForm', this.props.currentForm);
		return (
			<ContentWrap title="Account Groups" errorMessage={this.state.errorMessage} form={currentForm}>
				<div className="search">
					<TableSearch
						onUserInput={this.handleUserInput}
						filterText={this.state.filterText}
					></TableSearch>
					<div className="pull-right form-inline">
						<button className="btn btn-primary btn-sm searchBtn" title="Add Account Group" onClick={_.wrap("insert", this.setFormState)}>Add Account Group</button>
					</div>
				</div>
				<div className="clearfix"></div>
				<DataTable
					columns={this.getColumns()}
					dataItems={accountgroups}
					rowsPerPage={ this.state.rowsPerPage }
					filterText={this.state.filterText}
					initialSortKey={this.state.initialSortKey}
					buttonIDPrefix={ "account_group_name" }
					buttons={[
						{ label: "View", icon: "info", callback: _.wrap("show", this.setFormState) },
						{ label: "Edit", icon: "edit", callback: _.wrap("edit", this.setFormState) },
						{ label: "Assign", icon: "plus", callback: _.wrap("assign", this.setFormState) }
					]}
				></DataTable>
			</ContentWrap>
		);
	}
}


function mapStateToProps(state, ownProps) {
	return {
		accountgroups: state.accountReducer.accountgroups,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, accountActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountGroups)
