import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators}  from 'redux'
import * as adminActions  from '../../../actions/adminActions';
import * as formStateActions  from '../../../actions/formStateActions';
import Form from '../../common/Form';
import FormField from '../../common/FormField';

class APIForm extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentWillMount() {
		this.props.actions.loadAPIs().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the shortcodes: " + msg);
		});
	}

	componentWillUnmount () {
		;
	}

	_onChange() {
		//console.log("_onChange");
		if (this.isMounted()) {
			this.setState({
				formError: null
			});
		}
	}

	render() {
		var httpMethods = [{ id: 'GET', http_method: 'GET'},
							{ id: 'POST', http_method: 'POST'},
							{ id: 'PUT', http_method: 'PUT'},
							{ id: 'DELETE', http_method: 'DELETE'}];
		return (
			<Form {...this.props} width="800" height="580" >
				<FormField.Select fieldid="http_method" width="5" label="HTTP Method" noChoice="== Please choose a value ==" labelWidth="2" options={httpMethods} required="true" fieldName="http_method" />
				<FormField.Input fieldid="url" type="URL" width="5" label="URL" labelWidth="2" required="true" help="URL path with arguments; for example, /auth/apis/:id" />
			</Form>
		);
	}
}



function mapStateToProps(state, ownProps) {
	return {
		apis: state.adminReducer.apis,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, formStateActions, adminActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(APIForm)
