import * as types from './actionTypes';
import api from './api';

export function loadCustomTransformations() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/api-link-service/transformations',
		}).then(response => {
			dispatch({type: types.GET_CUSTOM_TRANSFORMATIONS_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addCustomTransformation(details) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: '/api-link-service/transformations',
			data: {
				name: details.name,
				description: details.description,
				output_type: details.output_type
			}
		});
	};
}

export function updateCustomTransformation(details) {
	return function(dispatch) {
		return api({
			method: 'put',
			url: `/api-link-service/transformations/${details.api_link_transform_rule_id}`,
			data: {
				name: details.name,
				description: details.description,
				output_type: details.output_type
			}
		});
	};
}

export function updateCustomTransformationMappings(api_link_transform_rule_id, mappings) {
	return function(dispatch) {
		return api({
			method: 'put',
			url: `/api-link-service/transformations/${api_link_transform_rule_id}/mappings`,
			data: {
				mappings: mappings
			}
		});
	};
}

export function loadCustomTransformationDetails(api_link_transform_rule_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/api-link-service/transformations/${api_link_transform_rule_id}`
		}).then(response => {
			dispatch({type: types.GET_CUSTOM_TRANSFORMATION_SUCCESS, payload: response, id: api_link_transform_rule_id});
		});
	};
}

export function loadCustomTransformationMappings(api_link_transform_rule_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/api-link-service/transformations/${api_link_transform_rule_id}/mappings`
		}).then(response => {
			dispatch({type: types.GET_CUSTOM_TRANSFORMATION_MAPPINGS_SUCCESS, payload: response, id: api_link_transform_rule_id});
		});
	};
}

export function deleteCustomTransformation(api_link_transform_rule_id) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/api-link-service/transformations/${api_link_transform_rule_id}`
		});
	};
}

export function cloneCustomTransformation(api_link_transform_rule_id) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: `/api-link-service/transformations/${api_link_transform_rule_id}/clone`
		});
	};
}

export function resetTransformationDetails() {
	return function(dispatch) {
		dispatch({type: types.RESET_TRANSFORMATION_SUCCESS, payload: {}});
	};
}

export function loadTransformationAccounts(api_link_transform_rule_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/api-link-service/transformations/${api_link_transform_rule_id}/accounts`
		}).then(response => {
			dispatch({type: types.GET_TRANSFORMATION_ACCOUNTS_SUCCESS, payload: response, id: api_link_transform_rule_id});
		});
	};
}

export function addTransformationAccount(api_link_transform_rule_id, account_id) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: `/api-link-service/transformations/${api_link_transform_rule_id}/accounts/${account_id}`
		}).then(response => {
			dispatch({type: types.ADD_TRANSFORMATION_ACCOUNT_SUCCESS, payload: response});
		});
	};
}

export function removeTransformationAccount(api_link_transform_rule_id, account_id) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/api-link-service/transformations/${api_link_transform_rule_id}/accounts/${account_id}`
		}).then(response => {
			dispatch({type: types.REMOVE_TRANSFORMATION_ACCOUNT_SUCCESS, payload: response});
		});
	};
}
