import * as types from './actionTypes';
import React from 'react';
import { browserHistory } from 'react-router';

const SlinkAuth = window.SlinkAuth;
const clientData = window.clientData;

export function loadUserData() {
	return function(dispatch) {
		let response = SlinkAuth.getUserData();
		dispatch({type: types.GET_SESSION_USER_DATA_SUCCESS, payload: response});
	};
}
