import React, {Component} from 'react';
import ReactDOM  from 'react-dom'
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux'
import * as userActions  from '../../../actions/userActions';
import * as formStateActions  from '../../../actions/formStateActions';
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';
import UserForm  from './UserForms';
import ChangePassword  from './ChangePassword';
import AssignUser  from './AssignUser';
import FormField from '../../common/FormField';
import Users from './Users';
import _ from 'lodash';
import ConfirmForm from '../../common/ConfirmForm';

class SislUsers extends Component {
	render() {
		return (
			<Users {...this.props} title="SISLNext Users" usertype="SISL"/>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		users: state.adminReducer.users,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, userActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SislUsers);
