/*
 * The users reducer will always return an array of users no matter what
 * You need to return something, so if there are no users then just return an empty array
 * */
import * as types from '../actions/actionTypes';
import _ from 'lodash'

var initialState = {
	users: null,
	apiClients: null,
	usersError: null,
	apis: [],
	apigroups: [],
	applications: [],
	ipranges: [],
	roles: [],
	user: {},
	apiclient: {},
	accountgroups: {}
}
export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_USERS_SUCCESS:
			//console.log('reducer to get users data', action);
			return Object.assign({}, state, {users: action.payload});
		case types.GET_SINGLE_USER_SUCCESS:
			//console.log('reducer to get user data', action);
			return Object.assign({}, state, {user: action.payload});
		case types.GET_API_CLIENTS_SUCCESS:
			//console.log('reducer to get api clients data', action);
			return Object.assign({}, state, {apiClients: action.payload});
		case types.GET_SINGLE_API_CLIENT_SUCCESS:
			//console.log('reducer to get api client data', action);
			return Object.assign({}, state, {apiclient: action.payload});
		case types.GET_APPLICATIONS_SUCCESS:
			//console.log('reducer to get application data',action);
			return Object.assign({}, state, {applications: action.payload });
		case types.GET_ROLES_SUCCESS:
			//console.log('reducer to get role data',action);
			return Object.assign({}, state, {roles: action.payload});
		case types.GET_APIS_SUCCESS:
			//console.log('reducer to get api data',action);
			return Object.assign({}, state, {apis: action.payload});
		case types.GET_APIGROUPS_SUCCESS:
			//console.log('reducer to get api group data',action);
			return Object.assign({}, state, {apigroups: action.payload});
		case types.GET_IP_RANGES_SUCCESS:
			//console.log('reducer to get ip range data',action);
			return Object.assign({}, state, {ipranges: action.payload });
		case types.UPDATE_USER:
			//console.log('reducer to get user data',action);
			var updatedUser = _.find(state.users, {login_id: action.payload.login_id});
			Object.assign(updatedUser, action.payload );
			return Object.assign({}, state, {updated_user: updatedUser});
		case types.UPDATE_API_CLIENT:
			//console.log('reducer to get api client data', action);
			var updatedApiClient = _.find(state.apiClients, {login_id: action.payload.login_id});
			Object.assign(updatedApiClient, action.payload );
			return Object.assign({}, state, {updated_api_client: updatedApiClient});
		default:
			return state;
	}
}
