/*
 * The users reducer will always return an array of users no matter what
 * You need to return something, so if there are no users then just return an empty array
 * */
import * as types from '../actions/actionTypes';
var initialState = {
    currentForm: {
        formType: null,
        errorMessage: null,
        successMessage: null,
        lastMessageTime: new Date().getTime(),
        currentId: null
    }
}
export default function (state = initialState, action) {
    ////console.log(action);
    switch (action.type) {
        case types.FORMSTATE_CHANGED:
            var o = Object.assign({}, state.currentForm, action.payload);
             var v= {currentForm: o};
             return v;
            break;
        default:
            return state;
    }
}


