import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as unsubscribegroupActions  from '../../../actions/unsubscribegroupActions';
import * as formStateActions  from '../../../actions/formStateActions';
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';
import ConfirmForm from '../../common/ConfirmForm';
import UnsubscribeGroupForm from './UnsubscribeGroupForm';
import UnsubscribeGroupAccountForm from './UnsubscribeGroupAccountForm';
import _ from 'lodash';

class UnsubscribeGroups extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filterText: '',
			initialSortKey: 'email_unsubscribe_group_id',
			errorMessage: null,
			successMessage: null,
			currentForm: null,
			currentPage: 1,
			rowsPerPage: 50
		};

		/*
			These binds are used to access the functions in UnsubscribeGroups.js from sub forms.
			For example:
			Suppose you add an unsubscribe group. Now you want to relist the front page.
			Without the calls below, you couldn't access the takeDownForm function
			from the addUnsubscribeGroupCallback with passing it along to the sub form.
			Here we just bind the parent form context to that function.
		*/
		this.handleUserInput = this.handleUserInput.bind(this);
		this.setFormState = this.setFormState.bind(this);
		this.cancelForm = this.cancelForm.bind(this);
		this.takeDownForm = this.takeDownForm.bind(this);
		this.updateCallback = this.updateCallback.bind(this);
		this.addUnsubscribeGroupCallback = this.addUnsubscribeGroupCallback.bind(this);
		this.deleteUnsubscribeGroupCallback = this.deleteUnsubscribeGroupCallback.bind(this);
		this.newUnsubscribeGroupClicked = this.newUnsubscribeGroupClicked.bind(this);
		this.setFormState("list-unsubscribe", null,null,null);
	}

	getColumns() {
		return {
			group_name:		{ name: "Unsubscribe Group", used: 1, width: 300, click_action: "View", required: true },
			accounts:		{ name: "Accounts", used: 1, width: 100, required: false }
		};
	}

	getFieldIds() {
		return _.keys(this.getColumns());
	}

	getRequiredFieldIds() {
		return _.keys(_.pickBy(this.getColumns(), 'required'));
	}

	handleUserInput(filterText) {
		this.setState({
			filterText: filterText
		});
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			currentForm: nextProps.currentForm
		});
	}

	componentDidMount() {
		this._isMounted = true;

		this.props.actions.loadUnsubscribeGroups(this.state.currentPage).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the unsubscribe groups: " + msg);
		});
	}

	componentWillUnmount() {
	}

	_onChange() {
		this.setState({
			errorMessage: null
		});
	}

	newUnsubscribeGroupClicked() {
		this.setFormState("insert-unsubscribe", null,null,null);
	}

	addUnsubscribeGroupCallback(fields) {
		this.props.actions.addUnsubscribeGroup(fields.group_name).then(data => {
			this.props.actions.loadUnsubscribeGroups().then(data => {
				this.takeDownForm(`Successfully added unsubscribe group ${fields.group_name}`);
			}).catch(errObject => {
				this.takeDownForm(null);
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the unsubscribe groups: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred adding the unsubscribe group: " + msg);
		});
	}

	updateCallback(fields) {
		this.props.actions.updateUnsubscribeGroup(fields.email_unsubscribe_group_id, fields.group_name).then(data => {
			this.props.actions.loadUnsubscribeGroups().then(data => {
				this.takeDownForm(`Successfully updated unsubscribe group ${fields.group_name}`);
			}).catch(errObject => {
				this.takeDownForm(null);
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the unsubscribe groups: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred updating the unsubscribe group: " + msg);
		});
	}

	deleteUnsubscribeGroupCallback(fields) {
		this.props.actions.deleteUnsubscribeGroup(fields.email_unsubscribe_group_id).then(data => {
			this.props.actions.loadUnsubscribeGroups().then(data => {
				this.takeDownForm(`Successfully deleted unsubscribe group ${fields.group_name}`);
			}).catch(errObject => {
				this.takeDownForm(null);
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the unsubscribe groups: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred removing the unsubscribe group: " + msg);
		});
	}

	setFormState(formType, errorMessage, successMessage, id) {
		if (this.props.actions.formChangeHandler)
			this.props.actions.formChangeHandler(formType, errorMessage, successMessage, id);
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			errorMessage: errorMessage
		});
	}

	setFormSuccessMessage(successMessage) {
		this.setState({
			successMessage: successMessage
		});
	}

	takeDownForm(successMessage) {
		this.setFormState(null, null, successMessage, null);
		window.scrollTo(0, 0);
	}

	clearErrorMessage() {
		this.setState({
			errorMessage: null
		});
	}

	cancelForm() {
		this.takeDownForm(null);
		this.clearErrorMessage();
	}

	render() {
		let currentForm, object;
		let allUnsubscribeGroups = this.props.unsubscribegroups;
		if (!allUnsubscribeGroups) {
			return null;
		}

		let errMsg = null;
		let successMsg= null;

		if (this.state.currentForm && this.state.currentForm.errorMessage)
			errMsg = this.state.currentForm.errorMessage;

		if (this.state.currentForm && this.state.currentForm.successMessage)
			successMsg = this.state.currentForm.successMessage;

		let addNewUnsubscribeGroupTitle;
		let buttonList;

		addNewUnsubscribeGroupTitle = "Add new unsubscribe group";
		buttonList = [	{ label: "View", icon: "info", callback: _.wrap("show-unsubscribe", this.setFormState) },
						{ label: "Edit", icon: "edit", callback: _.wrap("edit-unsubscribe", this.setFormState) },
						{ label: "Assign", icon: "plus", callback: _.wrap("assign-unsubscribe", this.setFormState) },
						{ label: "Delete", icon: "trash", callback: _.wrap("delete-unsubscribe", this.setFormState) }
					];

		switch (this.props.currentForm && this.props.currentForm.formType) {
			case "insert-unsubscribe":
				object = {};
				currentForm = <UnsubscribeGroupForm title={addNewUnsubscribeGroupTitle} object={object} errorMessage={this.state.errorMessage}
										submitCallback={this.addUnsubscribeGroupCallback} cancelCallback={this.cancelForm}/>;
				break;

			case "edit-unsubscribe":
				object = allUnsubscribeGroups ? _.find(allUnsubscribeGroups, {email_unsubscribe_group_id: this.props.currentForm.currentId}) : {};
				currentForm = <UnsubscribeGroupForm title="Edit Unsubscribe Group" object={object} errorMessage={this.state.errorMessage}
										submitCallback={this.updateCallback} cancelCallback={this.cancelForm}/>;
				break;
			case "show-unsubscribe":
				object = allUnsubscribeGroups ? _.find(allUnsubscribeGroups, {email_unsubscribe_group_id: this.props.currentForm.currentId}) : {};
				currentForm = <UnsubscribeGroupForm title="Email Unsubscribe Group Details" readOnly={true} hidepwd={true} object={object} errorMessage={this.state.errorMessage}
											 showAccountGroupDropdown= { false } cancelCallback={this.cancelForm}/>;
				break;

			case "assign-unsubscribe":
				object = allUnsubscribeGroups ? _.find(allUnsubscribeGroups, {email_unsubscribe_group_id: this.props.currentForm.currentId}) : {};
				if (object) {
					currentForm = <UnsubscribeGroupAccountForm title="Assign Account" readOnly={false} object={object}
										errorMessage={this.state.errorMessage}
										cancelCallback={this.cancelForm}/>;
				}
				break;
			case "delete-unsubscribe":
				object = allUnsubscribeGroups ? _.find(allUnsubscribeGroups, {email_unsubscribe_group_id: this.props.currentForm.currentId}) : {};
				if (object && object.group_name)
				{
					currentForm = <ConfirmForm title="Delete Unsubscribe Group" text={"Are you sure you want to permanently remove unsubscribe group \"" + object.group_name + "\" ?"}
											   args={object} errorMessage={this.state.errorMessage}
											   confirmCallback={this.deleteUnsubscribeGroupCallback} cancelCallback={this.cancelForm}/>;
				}
				break;
		}

		return (
			<ContentWrap title={this.props.title || "Unsubscribe Groups"} successMessage={successMsg} errorMessage={errMsg} form={currentForm}>
				<div className="search">
					<TableSearch
						onUserInput={ this.handleUserInput }
						filterText={ this.state.filterText }>
					</TableSearch>
					<div className="pull-right form-inline">
						<button className="btn btn-primary btn-sm searchBtn" title="Add Unsubscribe Group" onClick={this.newUnsubscribeGroupClicked}>{addNewUnsubscribeGroupTitle}</button>
					</div>
				</div>
				<div className="clearfix"></div>
				<DataTable
					id="email_unsubscribe_group_id"
					columns={ this.getColumns() }
					dataItems={ allUnsubscribeGroups }
					rowsPerPage={ this.state.rowsPerPage }
					filterText={ this.state.filterText }
					initialSortKey={ this.state.initialSortKey }
					buttonIDPrefix={ "group_name" }
					buttons={ buttonList }>
				</DataTable>
			</ContentWrap>

		);
	}
}


function mapStateToProps(state, ownProps) {
	return {
		unsubscribegroups: state.unsubscribeGroupReducer.unsubscribegroups,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, unsubscribegroupActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(UnsubscribeGroups)
