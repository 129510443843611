import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as constants from '../../../constants';
import * as formStateActions  from '../../../actions/formStateActions';
import * as emailBEEActions  from '../../../actions/emailBEEActions';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import Bee from 'bee-plugin';
import {Modal} from 'react-bootstrap';
import emptyBEEJSON from './emptyBEEJSON';
import _ from 'lodash';

class BEETemplateForm extends Component {
	constructor(props) {
		super(props);

		let thisObj = this;
		this.bee = null;
		this.changed = false;
		this.saveAndClose = false;
		this.newlyAddedTemplateID = null;
		this.email_bee_template_name = null;
		// We give a different uid per environment.
		// This means that when you upload images to the BEE editor, the images will be stored
		// in the AWS store for that account. All images are publicly visible to everybody, but are stored in different AWS accounts.
		// When you go back into the BEE editor to upload new images, you'll see
		// all images you've previously uploaded to that environment.
		// Appending the environment to the uid segments uploaded images per environment.
		// It also means that each environment is a unique user in BEE's internal system.
		this.beeConfig = {
			uid: 'WorkbenchBEEClient_' + constants.environment,
			container: 'bee-plugin-container',
			language: 'en-US',
			forceSanitizeHTML: true,
			trackChanges: true,
			roleHash: 'WORKBENCHRole',
			onChange: function (jsonFile, response) {
				thisObj.changed = true;
			},
			onLoad: function(jsonFile) {
				if (thisObj.props.readOnly)
					thisObj.bee.preview();
			},
			onSave: function(jsonFile, htmlFile) {
				let obj = thisObj.props.object;
				// If you
				//  1) Added, saved with staying on the page, then added again
				//  2) you viewed the preview, closed preview, and then stayed on the page to edit
				//  3) you hit the standard edit button
				if (thisObj.newlyAddedTemplateID || thisObj.props.currentForm.formType === "edit-beetemplate" || thisObj.props.currentForm.formType === "show-beetemplate")
				{
					let bee_template_id = thisObj.newlyAddedTemplateID ? thisObj.newlyAddedTemplateID : obj.email_bee_template_id;
					thisObj.props.actions.updateBEETemplate(bee_template_id, thisObj.email_bee_template_name, jsonFile, htmlFile).then(data => {
						thisObj.setFormSuccessMessage(`Successfully updated email template ${thisObj.email_bee_template_name}`);
						thisObj.changed = false;
						// If you hit the Done button at the bottom, go back to the main page and show the appropriate banner
						if (thisObj.saveAndClose)
							thisObj.props.submitCallback(`Successfully updated email template ${thisObj.email_bee_template_name}`)
					}).catch(errObject => {
						let msg = errObject.message || errObject;
						thisObj.setFormErrorMessage("An error occurred updating the email template: " + msg);
					});
				}
				else // insert-beetemplate
				{
					thisObj.props.actions.addBEETemplate(thisObj.email_bee_template_name, jsonFile, htmlFile).then(data => {
						if (typeof data == "object" && "EmailBEETemplateId" in data)
						{
							thisObj.newlyAddedTemplateID = parseInt(data.EmailBEETemplateId);
							thisObj.setFormSuccessMessage(`Successfully added email template ${thisObj.email_bee_template_name}`);
							thisObj.changed = false;
							// If you hit the Done button at the bottom, go back to the main page and show the appropriate banner
							if (thisObj.saveAndClose)
								thisObj.props.submitCallback(`Successfully added email template ${thisObj.email_bee_template_name}`)
						}
					}).catch(errObject => {
						let msg = errObject.message || errObject;
						thisObj.setFormErrorMessage("An error occurred adding the email template: " + msg);
					});
				}
			}
		};

		this.state = {
			errorMessage: null,
			successMessage: null,
			currentForm: null,
		};

		this.setFormSuccessMessage = this.setFormSuccessMessage.bind(this);
		this.setFormErrorMessage = this.setFormErrorMessage.bind(this);
		this.setFormState = this.setFormState.bind(this);
		this.cancelForm = this.cancelForm.bind(this);
		this.promptCancel = this.promptCancel.bind(this);
		this.save = this.save.bind(this);
		this.resetFormMessages = this.resetFormMessages.bind(this);
		this.takeDownForm = this.takeDownForm.bind(this);
		this.templateNameChange = this.templateNameChange.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			currentForm: nextProps.currentForm
		});
	}

	componentDidMount() {
		if (this.props.object && this.props.object.email_bee_template_id)
		{
			this.props.actions.loadEmailBEETemplate(this.props.object.email_bee_template_id).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred getting email template details: " + msg);
			});
		}
		if (!this.bee)
		{
			this.props.actions.authorizeBEE().catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred authorizing email: " + msg);
			});
		}
	}

	componentWillUnmount() {
		this.newlyAddedTemplateID = null;
		this.changed = false;
		this.saveAndClose = false;
		// Refresh main page if we came here in add/edit mode
		if (this.props.currentForm.formType === "insert-beetemplate" || this.props.currentForm.formType === "edit-beetemplate")
		{
			this.props.actions.loadEmailBEETemplates().catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the email templates: " + msg);
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState)
	{
		let arePropsEqual = _.isEqual(this.props, nextProps);
		let isStateEqual = _.isEqual(this.state, nextState);
		return !arePropsEqual || !isStateEqual;
	}

	componentDidUpdate(prevProps) {
		if (!this.email_bee_template_name && this.props.beetemplateDetails)
			this.email_bee_template_name = this.props.beetemplateDetails.email_bee_template_name;

		let initializeBEE = false, beeJSON = {};
		// If we're adding a new template, the empty template JSON comes from a file.
		// So no need to wait for JSON to be downloaded from the database to initialize the BEE editor.
		if (this.props.currentForm.formType === "insert-beetemplate")
		{
			initializeBEE = true;
			beeJSON = emptyBEEJSON;
		}
		else // For edit-beetemplate and show-beetemplate, wait until we get the JSON from the database to initialize the BEE editor.
		{
			initializeBEE = this.props.beetemplateDetails && !_.isEmpty(this.props.beetemplateDetails);
			if (initializeBEE) // If we've downloaded the BEE template from the DB, initialize the BEE editor with that JSON
			{
				try
				{
					beeJSON = JSON.parse(this.props.beetemplateDetails.email_bee_template_json);
				}
				catch(e)
				{
					this.setFormErrorMessage("Error parsing BEE JSON: " + e);
				}
			}
		}

		if (!this.bee && !_.isEmpty(this.props.beeToken) && initializeBEE)
		{
			this.bee = new Bee(this.props.beeToken);
			this.bee.start(this.beeConfig, beeJSON);
		}
	}

	setFormState(formType, errorMessage, successMessage, id) {
		if (this.props.actions.formChangeHandler)
			this.props.actions.formChangeHandler(formType, errorMessage, successMessage, id);
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			successMessage: null,
			errorMessage: errorMessage
		});
	}

	setFormSuccessMessage(successMessage) {
		this.setState({
			successMessage: successMessage,
			errorMessage: null
		});
	}

	takeDownForm(successMessage) {
		this.setFormState(null, null, successMessage, null);
		window.scrollTo(0, 0);
	}

	clearErrorMessage() {
		this.setState({
			errorMessage: null
		});
	}

	cancelForm() {
		this.takeDownForm(null);
		this.clearErrorMessage();
	}

	templateNameChange(event, fieldChanges) {
		this.email_bee_template_name = fieldChanges.email_bee_template_name;
		this.changed = true;
	}

	promptCancel()
	{
		if (this.changed)
		{
			this.setState({
				successMessage: null,
				errorMessage: null,
				confirmMessage: (<div>
					<span>Are you sure you want to leave the email template editing page? You've made unsaved changes.</span>
					<button onClick={((e) => this.props.cancelCallback())}>Yes</button>
					<button onClick={this.resetFormMessages}>No</button>
					</div>)
			});
			window.scrollTo(0, 0);
		}
		else
			this.props.cancelCallback();
	}

	resetFormMessages()
	{
		this.setState({
			successMessage: null,
			errorMessage: null,
			confirmMessage: null
		});
	}

	save()
	{
		if (!this.email_bee_template_name)
			this.setFormErrorMessage("You must fill in the email template name");
		else
		{
			this.saveAndClose = true;
			if (this.bee)
				this.bee.save();
		}
	}

	render() {
		let errMsg = null;
		let successMsg= null;

		if (this.state.errorMessage)
			errMsg = this.state.errorMessage;

		if (this.state.successMessage)
			successMsg = this.state.successMessage;

		return (
			<Modal show={true} dialogClassName="mw-100 w-100 mh-100" backdrop="static" onHide={this.promptCancel}>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form hideTitle={true} submitCallback={this.save} cancelCallback={this.promptCancel}
						width="99%" height="90%" successMessage={successMsg} errorMessage={errMsg} confirmMessage={ this.state.confirmMessage }
						object={this.props.object} readOnly={this.props.readOnly}>
						<FormField.Input fieldid="email_bee_template_name" noAfterTag={true}
						fieldName="email_bee_template_name" fieldvalue={this.props.object.email_bee_template_name}
						localFieldChangeCallback={ this.templateNameChange }
						maxLength={255} width={6} label="Template name" labelWidth={2} noempty={true} />
						<div className="clearfix"></div>
						<div id="bee-plugin-container"></div>
					</Form>
				</Modal.Body>
			</Modal>
		);
	}
}


function mapStateToProps(state, ownProps) {
	return {
		beetemplateDetails: state.emailBEETemplateReducer.beetemplateDetails['ID_' + ownProps.object.email_bee_template_id],
		beeToken: state.emailBEETemplateReducer.beeToken,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, emailBEEActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BEETemplateForm)
