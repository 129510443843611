import * as types from './actionTypes';
import api from './api';

export function loadApplications() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/auth/applications'
		}).then(response => {
			dispatch({type: types.GET_APPLICATIONS_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadRoles() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/auth/roles',
		}).then(response => {
			dispatch({type: types.GET_ROLES_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadAPIGroups() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/auth/apigroups'
		}).then(response => {
			dispatch({type: types.GET_APIGROUPS_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadIPRanges() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/auth/ip-ranges'
		}).then(response => {
			dispatch({type: types.GET_IP_RANGES_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function removeIPRangeAssignment(IPRangeID) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/auth/ip-ranges/${IPRangeID}`
		}).then(response => {
			dispatch({type: types.DELETE_IP_RANGE_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addIPRangeAssignment(account_group_id, app_code, ip_range_start, ip_range_end) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: '/auth/ip-ranges',
			data: {
				account_group_id: account_group_id,
				app_code: app_code,
				ip_range_start: ip_range_start,
				ip_range_end: ip_range_end
			}
		}).then(response => {
			dispatch({type: types.ADD_IP_RANGE_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function getAccountGroupAssignments(accountId, accountGroupId) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/auth/accounts/${accountId}/accountgroups/${accountGroupId}`
		}).then(response => {
			dispatch({type: types.GET_ACCOUNT_GROUP_ASSIGNMENTS_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function removeAccountGroupAssignment(accountId, accountGroupId) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/auth/accounts/${accountId}/accountgroups/${accountGroupId}`
		}).then(response => {
			dispatch({type: types.DELETE_ACCOUNT_GROUP_ASSIGNMENT_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addAccountGroupAssignment(accountId, accountGroupId) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: `/auth/accounts/${accountId}/accountgroups/${accountGroupId}`
		}).then(response => {
			dispatch({type: types.ADD_ACCOUNT_GROUP_ASSIGNMENT_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadAPIs() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/auth/apis',
		}).then(response => {
			dispatch({type: types.GET_APIS_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addApi(newApiFields) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: '/auth/apis',
			data: newApiFields
		}).catch(error => {
			throw(error);
		});
	};
}

export function updateApi(apiId, newApiFields) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: `/auth/apis/${apiId}`,
			data: newApiFields
		}).catch(error => {
			throw(error);
		});
	};
}

export function deleteApi(apiId) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/auth/apis/${apiId}`
		}).catch(error => {
			throw(error);
		});
	};
}
