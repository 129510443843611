import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators}  from 'redux'
import * as adminActions  from '../../../actions/adminActions';
import * as formStateActions  from '../../../actions/formStateActions';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';
import ConfirmForm from '../../common/ConfirmForm';
import APIForm from './APIForm';
import _ from 'lodash'

class APIs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentAPIGroupId: null,
			filterText: '',
			initialSortKey: 'id',
			formError: null,
			currentForm: null,
			currentPage: 1,
            rowsPerPage: 50
		};

		this.cancelForm = this.cancelForm.bind(this);
		this.createAPICallback = this.createAPICallback.bind(this);
		this.updateAPICallback = this.updateAPICallback.bind(this);
		this.takeDownForm = this.takeDownForm.bind(this);
		this.handleUserInput = this.handleUserInput.bind(this);
		this.apiGroupChange = this.apiGroupChange.bind(this);
		this.setFormState = this.setFormState.bind(this);
		this.deleteAPICallback = this.deleteAPICallback.bind(this);
	}

	componentDidMount() {

		this.props.actions.loadAPIs().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the APIs: " + msg);
		});

		this.props.actions.loadAPIGroups().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the API groups: " + msg);
		});

	}

	getColumns() {
		return {
			id:					{ name: "ID", used: 1, width: 80 },
			http_method:		{ name: "HTTP Method", used: 1, width: 120 },
			url:				{ name: "URL", used: 1, width: 'auto', click_action: "View" }
		};
	}

	handleUserInput(filterText) {
		this.setState({
			filterText: filterText
		});
	}

    componentWillMount() {
        this.setFormState(null, null, null, null);
    }

	componentWillUnmount() {
		;
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			currentForm: nextProps.currentForm
		});
	}

	_onChange() {
		if (this.isMounted()) {
			this.setState({
				formError: null,
				currentForm: null
			});
		}
	}

	setFormState(formType, errorMessage, successMessage, id) {
		if (this.props.actions.formChangeHandler)
			this.props.actions.formChangeHandler(formType, errorMessage, successMessage, id);
	}

	setFormErrorMessage(errorMessage) {
		//$(document).scrollTop(0);
		this.setState({
			formError: errorMessage
		});
	}

	setErrorMessage(errorMessage) {
		this.setFormState(null, errorMessage);
		//$(document).scrollTop(0);
	}

	takeDownForm(successMessage) {
		this.setFormState(null, null, successMessage, null);
		//$(document).scrollTop(0);
	}

	cancelForm() {
		this.takeDownForm(null);
	}

	createAPICallback(fields) {
		// validate args
		let msg;
		if (!fields['http_method']) {
			msg = 'missing URL';
		}
		if (!msg && !fields['url']) {
			msg = 'missing URL';
		}

		if (msg) {
			this.setFormErrorMessage(msg);
		} else {
			this.props.actions.addApi(fields).then(data => {
				this.takeDownForm("API added successfully");
				this.props.actions.loadAPIs().then(data => {
					;
				}).catch(errObject => {
					let msg = errObject.message || errObject;
					this.setFormErrorMessage("An error occurred loading the APIs: " + msg);
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred updating the API: " + msg);
			});
		}
	}

	updateAPICallback(fields) {
		// validate args
		var msg;
		if (!fields['http_method']) {
			msg = 'missing URL';
		}
		if (!msg && !fields['url']) {
			msg = 'missing URL';
		}

		if (msg) {
			this.setFormErrorMessage(msg);
		} else {
			this.props.actions.updateApi(fields.id, fields).then(data => {
				this.takeDownForm("API updated successfully");
				this.props.actions.loadAPIs().then(data => {
					;
				}).catch(errObject => {
					let msg = errObject.message || errObject;
					this.setFormErrorMessage("An error occurred loading the APIs: " + msg);
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred updating the API: " + msg);
			});
		}
	}

	deleteAPICallback(apiID) {
		this.props.actions.deleteApi(apiID).then(data => {
			this.takeDownForm("API deleted successfully");
			this.props.actions.loadAPIs().then(data => {
				;
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading short codes: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred deleting the API: " + msg);
		});
	}

	apiGroupChange(dummy, objectChanges) {
		this.setState({
			currentAPIGroupId: objectChanges.id
		});
	}

	render() {
		let apis = this.props.apis;
		let apiGroups = this.props.apigroups;
		let currentAPIGroupId = this.state.currentAPIGroupId;
		let currentForm, object;
		let currentPage;

		switch (this.props.currentForm && this.props.currentForm.formType)
		{
			case "show":
				object = apis ? _.find(apis, {id: this.props.currentForm.currentId}) : {};
				currentForm = <APIForm title="API Details" readOnly={true} object={object} errorMessage={this.state.formError} cancelCallback={this.cancelForm}/>;
				break;

			case "insert":
				object = {};
				currentForm = <APIForm title="Create New API" object={object} errorMessage={this.state.formError} submitCallback={this.createAPICallback} cancelCallback={this.cancelForm}/>;
				break;

			case "edit":
				object = apis ? _.find(apis, {id: this.props.currentForm.currentId}) : {};
				currentForm = <APIForm title="Edit API" object={object} errorMessage={this.state.formError} submitCallback={this.updateAPICallback} cancelCallback={this.cancelForm}/>;
				break;

			case "delete":
				object = apis ? _.find(apis, {id: this.props.currentForm.currentId}) : {};
				currentForm = <ConfirmForm title="" text={"Delete API " + object.http_method + ':' + object.url + "?"} args={this.props.currentForm.currentId} confirmCallback={this.deleteAPICallback} errorMessage={this.state.formError} cancelCallback={this.cancelForm}/>;
				break;
		}
		let dataItems = _.cloneDeep(apis);
		if (currentAPIGroupId) {
			dataItems = _.filter(dataItems, function(api) {
				return _.find(api.api_groups, function(apiGroup) {
					return (apiGroup.api_group_id == currentAPIGroupId);
				});
			});
		}

		return (
			<ContentWrap title="APIs" successMessage={this.props.successMessage} errorMessage={this.props.errorMessage} form={currentForm} noInline={true}>
				<div style={ { width: "50%", marginLeft: 'auto', marginRight: 'auto' } } >
					<FormField.Select label="API&nbsp;Group" width="5" labelWidth="3" singleline="true" noChoice="== Show All ==" fieldid="id" fieldName="api_group_name" options={apiGroups} fieldvalue={currentAPIGroupId} fieldchangecallback={this.apiGroupChange} />
				</div>
				<div className="search">
					<TableSearch
						onUserInput={this.handleUserInput}
						filterText={this.state.filterText}
					></TableSearch>
					<div className="float-right form-inline">
						<button className="btn btn-primary btn-sm searchBtn" title="Add API" onClick={_.wrap("insert", this.setFormState)}>Add API</button>
					</div>
				</div>
				<div className="clearfix"></div>

				<DataTable
					columns={this.getColumns()}
					dataItems={dataItems}
					rowsPerPage={50}
					filterText={this.state.filterText}
					initialSortKey={this.state.initialSortKey}
					buttonIDPrefix={ "url" }
					buttons={[
						{ label: "View", icon: "info", callback: _.wrap("show", this.setFormState) },
						{ label: "Edit", icon: "edit", callback: _.wrap("edit", this.setFormState) },
						{ label: "Delete", icon: "trash", callback: _.wrap("delete", this.setFormState) }
					]}
				></DataTable>
			</ContentWrap>
		);
	}
}


function mapStateToProps(state, ownProps) {
	return {
		apis: state.adminReducer.apis,
		apigroups: state.adminReducer.apigroups,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, formStateActions, adminActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(APIs)
