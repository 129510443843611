import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as accountActions  from '../../../actions/accountActions';
import * as apilinkActions  from '../../../actions/apilinkActions';
import * as formStateActions  from '../../../actions/formStateActions';
import CustomTab from '../../common/CustomTab';
import DataTable  from '../../common/DataTable';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import FormFieldWrapper from '../../common/FormFieldWrapper';
import APILinkConfigFormConverter from './APILinkConfigFormConverter';
import ApiLinkConfigFormValidator from './ApiLinkConfigFormValidator';
import APILinkConfigAddRemoveRows from './APILinkConfigAddRemoveRows';
import _ from 'lodash';

class ApiLinkConfigForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			verb: "",
			securityType: "",
			authDetails: {},
			version: 0,
			authData: {},
			attributeData: []
		};
		this.isMounted2 = false;
		this.userEditedForm = false;

		this.formObjectAttributeData = {};
		this.authDataObj = {};
		this.authFieldCount = null;
		this.apiLinkAttributeColumns = {};

		this.componentWidth = 1000;
		this.componentHeight = 580;

		this.takeDownForm = this.takeDownForm.bind(this);
		this.cancelButtonCallback = this.cancelButtonCallback.bind(this);
		this.saveButtonCallback = this.saveButtonCallback.bind(this);
		this.parentDataCallback = this.parentDataCallback.bind(this);
		this.setFormSuccessMessage = this.setFormSuccessMessage.bind(this);
		this.setFormErrorMessage = this.setFormErrorMessage.bind(this);
		this.verbChange = this.verbChange.bind(this);
		this.securityChange = this.securityChange.bind(this);
		this.resetFormMessages = this.resetFormMessages.bind(this);
	}

	takeDownForm(successMessage) {
		if (successMessage)
			this.setFormSuccessMessage(successMessage);
	}

	componentDidMount() {
		this.props.actions.loadApiLinkDataTypes().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage(`An error occurred loading the api link data types: ${msg}`);
		});
		this.props.actions.loadApiLinkAuthMethods().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage(`An error occurred loading the api link auth methods: ${msg}`);
		});
		let obj = this.props.object;
		if (obj && obj.api_link_id) {
			this.props.actions.loadApiLinkInUse(obj.api_link_id).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage(`An error occurred checking whether the API Link is in use: ${msg}`);
			});
			this.props.actions.loadApiLinkDetails(obj.api_link_id).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage(`An error occurred loading the api link details: ${msg}`);
			});
			this.props.actions.loadApiLinkAttributes(obj.api_link_id).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage(`An error occurred loading the api link attributes for api link ID ${obj.api_link_id}: ${msg}`);
			});
		}
	}

	componentWillUnmount() {
		//isMounted is deprecated. rename it to be a different application variable
		this.isMounted2 = false;
		this.userEditedForm = false;
		this.formObjectAttributeData = {};
		this.authDataObj = {};
		this.authFieldCount = null;
		this.apiLinkAttributeColumns = {};
		this.setState({
			version: 0,
			authData: {},
			attributeData: []
		});

		this.props.actions.resetApiLinkDetails();
	}

	componentDidUpdate(prevProps) {
		let pageIsInAddMode = this.props.apilinkDetails === undefined && _.isEmpty(this.props.object);
		let unMounted = !this.isMounted2;

		let hasLoadedData = false;

		// Why check for the API Link being in use here?
		// Without this, there's a race condition with the in-use flag.
		// If in-use data returns before the API Link details, life was good.
		// If it came afterwards, the base authentication details would be written assuming API-Link isn't in use.
		// We add the check here to force a redraw of the base API Link information on loading the page and first getting API Link in-use info.
		let apilinkInUseUpdated = this.isMounted2 && prevProps.apilinkInUse === undefined && this.props.apilinkInUse === 1;
		if (!_.isEmpty(this.props.apilinkDetails) && (!_.isEqual(prevProps.apilinkDetails, this.props.apilinkDetails) || pageIsInAddMode || unMounted || apilinkInUseUpdated))
		{
			let authDetails = {};
			try {
				authDetails = JSON.parse(this.props.apilinkDetails.authentication_detail);
			} catch (e) {
				;
			} finally {
				;
			}

			// In case the API Link data comes after the API Link details,
			// make sure to update the authentication table.
			// That's how we make sure that anything but username and password is readonly.
			if (apilinkInUseUpdated || _.isEmpty(this.authDataObj))
				APILinkConfigFormConverter.initAuthObj(authDetails, this);
			hasLoadedData = true;
			this.setState({
				version: this.state.version + 1,
				authData: authDetails
			});
			this.userEditedForm = false;
		}

		if ((!_.isEmpty(this.props.apiLinkAttributes) || !_.isEmpty(prevProps.apiLinkAttributes)) && (!_.isEqual(prevProps.apiLinkAttributes, this.props.apiLinkAttributes) || pageIsInAddMode || unMounted))
		{
			hasLoadedData = true;
			let dataTypePrefix = "api_data_type_id";
			let attributeIDPrefix = "api_link_attribute_id";
			let defaultValuePrefix = "default_value";
			let descriptionPrefix = "description";
			let namePrefix = "name";
			let attributePrefixesArr = [dataTypePrefix, attributeIDPrefix, defaultValuePrefix, descriptionPrefix, namePrefix];

			// Delete all attribute values in the form Object.
			// This will be repopulated in the render function.
			// We need to do this in case somebody added a new attribute; in adding an attribute, we give it a fake ID until it's saved.
			// Since this code is only called on save, we should delete all the old fake IDs.
			// Otherwise, duplicate rows will appear in the attribute table.
			for (let fieldKey in this.formObjectAttributeData)
			{
				for (let attrPrefixIndex = 0, attrFieldLen = attributePrefixesArr.length; attrPrefixIndex < attrFieldLen; attrPrefixIndex++)
				{
					let attrFieldPrefixLen = attributePrefixesArr[attrPrefixIndex].length + '_'.length;
					let fullAttributePrefix = attributePrefixesArr[attrPrefixIndex] + "_";
					if (fieldKey.substring(0, fullAttributePrefix.length) === fullAttributePrefix)
						delete this.formObjectAttributeData[fieldKey];
				}
			}

			this.setState({
				version: this.state.version + 1,
				attributeData: this.props.apiLinkAttributes
			});
			this.userEditedForm = false;
		}

		// Let's say a user just added a new API Link.
		// This means that the page now needs to be updating from here on in instead of adding.
		// Make sure that the form object with all the form data is updated with the new api_link_id
		// because we use api_link_id when saving everything to the DB.
		if (prevProps.apilinkDetails === undefined && this.props.apilinkDetails !== undefined && !this.props.object.api_link_id)
			this.formObjectAttributeData.api_link_id = this.props.apilinkDetails.api_link_id;

		if (hasLoadedData && !this.isMounted2)
			this.isMounted2 = true;

		// In order to set the drop-down items in the attribute field type drop-down,
		// we need to get the apilinkDatatypes fro mthe server first.
		// Then we can set the table column definitions
		if (!_.isEmpty(this.props.apilinkDatatypes) && _.isEmpty(this.apiLinkAttributeColumns))
		{
			let dataTypeDropDownData = [];
			for (let x = 0; x < this.props.apilinkDatatypes.length; x++)
			{
				let newEntry = {"name": this.props.apilinkDatatypes[x].name, "val": this.props.apilinkDatatypes[x].api_data_type_id};
				dataTypeDropDownData.push(newEntry);
			}

			this.apiLinkAttributeInvalidChars = ["\\\\", "\""];
			this.apiLinkAttributeColumns = {
				api_data_type_id:	{ name: "Field Type", width: 160, selectWidth: 11, required: false, id: "api_link_attribute_id", type: "select", values: dataTypeDropDownData, option_id: "val", option_name: "name", used: 1 },
				name:		{ name: "Field Name", width: 200, type: "textInput", textInputWidth: 11, id: "api_link_attribute_id", required: true, noempty: true, invalidChars: this.apiLinkAttributeInvalidChars, noaftertag: true, used: 1 },
				description:		{ name: "Field Description", width: 300, type: "textArea", rows: 3, cols: 37, id: "api_link_attribute_id", required: false, noaftertag: true, used: 1 },
				default_value:		{ name: "Default value", width: 200, type: "textInput", textInputWidth: 11, id: "api_link_attribute_id", required: false, noaftertag: true, used: 1 }
			};

			// If the attribute data arrived first, reset the form
			if (!_.isEmpty(this.state.attributeData))
			{
				this.setState({
					version: this.state.version + 1
				});
			}
		}
	}

	_onChange() {
	}

	extractJSONFields(text) {
		try {
			return JSON.parse(text);
		} catch(err) {
			return {};
		}
	}

	setFormSuccessMessage(successMessage) {
		this.setState({
			formErrorMessage: null,
			successMessage: successMessage
		});
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			formErrorMessage: errorMessage,
			successMessage: null
		});
	}

	cancelButtonCallback(override)
	{
		if (override || !this.userEditedForm)
		{
			this.props.actions.loadApiLinks().then(successObject => {
				if (this.props.cancelCallback)
					this.props.cancelCallback();
			}).catch(errObject => {
				if (this.props.cancelCallback)
					this.props.cancelCallback();
			});
		}
		else
		{
			this.setState({
				successMessage: null,
				formErrorMessage: null,
				formConfirmMessage: (<div>
					<span>Are you sure you want to leave the API-link configuration page? You've made unsaved changes.</span>
					<button onClick={((e) => this.cancelButtonCallback(true))}>Yes</button>
					<button onClick={this.resetFormMessages}>No</button>
				</div>)
			});
			window.scrollTo(0, 0);
		}
	}

	verbChange(event, fieldChanges) {
		this.setState({
			verb: _.assignIn(this.state.verb, fieldChanges),
			successMessage: null,
			formErrorMessage: null,
			formConfirmMessage: null
		});
	}

	securityChange(event, fieldChanges) {
		this.setState({
			securityType: _.assignIn(this.state.securityType, fieldChanges),
			successMessage: null,
			formErrorMessage: null,
			formConfirmMessage: null
		});
	}

	resetFormMessages()
	{
		this.setState({
			successMessage: null,
			formErrorMessage: null,
			formConfirmMessage: null
		});
	}

	// Log whether or not changes were made to the form.
	// If yes, prompt before a user hits cancel.
	parentDataCallback(fields, formCallerFunction)
	{
		if (!_.isEqual(fields, this.formObjectAttributeData))
		{
			this.userEditedForm = (formCallerFunction === "fieldchange" || this.userEditedForm);
			this.formObjectAttributeData = fields;
		}
		let currentAuthObject = APILinkConfigFormConverter.authFormFieldsToObject(fields);
		if (!_.isEqual(currentAuthObject, this.state.authData) && formCallerFunction === "fieldchange")
		{
			APILinkConfigFormConverter.updateAuthObj(fields, this);
			this.setState({
				authData: currentAuthObject
			});
		}
		let currentAttributeArray = APILinkConfigFormConverter.attributeFormFieldsToArray(fields);
		if (!_.isEqual(currentAttributeArray, this.state.attributeData) && formCallerFunction === "fieldchange")
		{
			APILinkConfigFormConverter.updateAttributeObj(fields, this);
			this.setState({
				attributeData: currentAttributeArray
			});
		}
	}

	saveButtonCallback(fields)
	{
		let validation_result = ApiLinkConfigFormValidator.validateDetails(fields, this);
		if (validation_result)
			validation_result = ApiLinkConfigFormValidator.validateAttributes(fields, this);
		if (validation_result === false)
			return;

		let finalAuthObject = APILinkConfigFormConverter.authFormFieldsToObject(fields);

		if (this.props.apilinkDetails === undefined)
		{
			let authentication_method_id = isNaN(parseInt(fields.authentication_method_id)) ? fields.authentication_method_id : parseInt(fields.authentication_method_id);
			let api_link_enabled = fields.disabled_ind ? fields.disabled_ind : false;
			let description = fields.description ? fields.description : "";
			this.props.actions.addApiLink(fields.name, description, fields.url, fields.verb, api_link_enabled, authentication_method_id, finalAuthObject).then(data => {
				this.userEditedForm = false;
				let api_link_id = parseInt(data.ApiLinkId);
				fields.api_link_id = api_link_id;
				this.props.actions.loadApiLinkDetails(api_link_id).then(data => {
					this.setFormSuccessMessage("Successfully added API link details");
					this.saveApiLinkAttributes(fields);
				}).catch(errObject => {
					this.takeDownForm(null);
					let msg = errObject.message || errObject;
					this.setFormErrorMessage("An error occurred loading the api links: " + msg);
					window.scrollTo(0, 0);
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred adding the api link details: " + msg);
				window.scrollTo(0, 0);
			});
		}
		else
		{
			let authentication_method_id = isNaN(parseInt(fields.authentication_method_id)) ? fields.authentication_method_id : parseInt(fields.authentication_method_id);
			let api_link_enabled = fields.disabled_ind || (fields.disabled_ind === 1) ? true : false;
			let description = fields.description ? fields.description : "";
			this.props.actions.updateApiLink(fields.api_link_id, fields.name, description, fields.url, fields.verb, api_link_enabled, authentication_method_id, finalAuthObject).then(data => {
				this.userEditedForm = false;
				this.props.actions.loadApiLinkDetails(fields.api_link_id).then(data => {
					this.setFormSuccessMessage("Successfully saved API link details");
					this.saveApiLinkAttributes(fields);
					window.scrollTo(0, 0);
				}).catch(errObject => {
					this.takeDownForm(null);
					let msg = errObject.message || errObject;
					this.setFormErrorMessage("An error occurred loading the api links: " + msg);
					window.scrollTo(0, 0);
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred updating the api link details: " + msg);
				window.scrollTo(0, 0);
			});
		}
	}

	saveApiLinkAttributes(fields)
	{
		if (this.props.apilinkInUse === 1)
			return;
		let attributeArr = APILinkConfigFormConverter.attributeFormFieldsToArray(fields, true);

		this.props.actions.updateApiLinkAttributes(fields.api_link_id, attributeArr).then(data => {
			this.props.actions.loadApiLinkAttributes(fields.api_link_id).then(data => {
				this.setFormSuccessMessage("Successfully saved API link details and attributes");
				window.scrollTo(0, 0);
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the api link attributes: " + msg);
				window.scrollTo(0, 0);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred updating the api link attributes: " + msg);
			window.scrollTo(0, 0);
		});
	}

	render() {
		let self = this;
		let apiLinkInUse = (this.props.apilinkInUse === 1);
		let viewModeOrInUse = (this.props.readOnly || apiLinkInUse);
		let firstTimeOnPage = _.isEmpty(this.formObjectAttributeData);
		let object = firstTimeOnPage ? this.props.object : this.formObjectAttributeData;
		let defaultApiLinkValue = "name" in object ? object.name : "";
		let defaultApiLinkDescription = "description" in object ? object.description : "";
		let objToCopy = firstTimeOnPage ? this.props.apilinkDetails : this.formObjectAttributeData;
		let apiLinkVals = Object.assign({}, objToCopy);

		let URLVerbs = [{verb: "POST"}, {verb: "GET"}, {verb: "DELETE"}, {verb: "PUT"}];
		let inputWidth = 7;
		let labelWidth = 5;
		let authDetailsArr = APILinkConfigFormConverter.authObjToUI(this.state.authData, this);
		for (let x  = 0, authDetailLen = authDetailsArr.length; x < authDetailLen; x++)
		{
			let index = authDetailsArr[x].index;
			let fieldtype = authDetailsArr[x].fieldtype;
			let fieldname = authDetailsArr[x].fieldname;
			let value = authDetailsArr[x].value;
			apiLinkVals[`fieldtype_${index}`] = fieldtype;
			apiLinkVals[`fieldname_${index}`] = fieldname;
			apiLinkVals[`value_${index}`] = value;
		}
		if (this.state.attributeData)
		{
			for (let rowIndex = 0, apiLinkAttrLen = this.state.attributeData.length; rowIndex < apiLinkAttrLen; rowIndex++)
			{
				let rowObj = this.state.attributeData[rowIndex];
				let api_link_attribute_id = rowObj['api_link_attribute_id'];
				for (let attrName in rowObj)
				{
					let attrFormName = `${attrName}_${api_link_attribute_id}`;
					apiLinkVals[attrFormName] = rowObj[attrName];
				}
			}
		}

		let authFieldTypeData = [{"val": "certs", "name": "Cert Filename"}, {"val": "username", "name": "Username"}, {"val": "password", "name": "Password"}, {"val": "request", "name": "Request"}, {"val": "qs", "name": "Querystring"}, {"val": "headers", "name": "Header"}];
		let authColumns = {
			fieldtype:		{ name: "Field type", readonly: viewModeOrInUse, used: 1, width: 150, type: "select", selectWidth: 11, id: "index", values: authFieldTypeData, option_id: "val", option_name: "name", required: false },
			fieldname:		{ name: "Field", readonly: viewModeOrInUse, used: 1, width: 115, type: "textInput", textInputWidth: 11, id: "index", noempty: true },
			value:		{ name: "Value", used: 1, width: 220, type: "textArea", rows: 2, cols: 50, id: "index", noempty: true }
		};

		/*****
		 Note on the Security dropdown below:
		 The APILink table has a field AuthenticationMethodId.
		 The APIAuthenticationMethod table has a primary key of APIAuthenticationMethodId.
		 The foreign key and primary key are name differently.
		 This means that the dropdown below might look preplexing.
		 The fieldid is the id of the field in the form object. This comes from the APILink table, so it needs to be authentication_method_id.
		 The optionid is the name of the option listed in the options attribute whose ID will be the value in the select.
		 This has to be api_authentication_method_id to match the APIAuthenticationMethod table.
		 *****/

		return (
			<div>
				<Form title={this.props.title} currentForm={this.props.currentForm} object={apiLinkVals}
					  readOnly={this.props.readOnly} width={this.componentWidth} height={this.componentHeight}
					  successMessage={this.state.successMessage} errorMessage={ this.state.formErrorMessage }
					  confirmMessage={ this.state.formConfirmMessage } cancelCallback={this.cancelButtonCallback}
					  submitCallback={this.saveButtonCallback} parentDataCallback={this.parentDataCallback} submitButtonLabel="Save">
					{
						!apiLinkInUse ? null :
							<div class="alert alert-info" role="alert">
								This API Link is in use
							</div>
					}
					<FormField.Input fieldid="name" noAfterTag={true} fieldName="name" defaultFieldValue={apiLinkVals.name} maxLength={4000} width={inputWidth} label="API Link name" labelWidth={labelWidth} readOnly={apiLinkInUse} noempty={true} />
					<FormField.TextArea fieldid="description" fieldKey={this.state.version} defaultValue={apiLinkVals.description} width={7} rows={2} label="API Link Description" labelWidth={labelWidth} readOnly={apiLinkInUse} />
					<FormField.Input label="API URL Endpoint" noAfterTag={true} labelWidth={labelWidth} fieldid="url" maxLength={4000}  width={inputWidth} defaultFieldValue={apiLinkVals.url} readOnly={apiLinkInUse} noempty={true} />
					<FormField.Select label="Verb" width="2" labelWidth={labelWidth} readOnly={apiLinkInUse}
									  fieldid="verb"
									  fieldName="verb"
									  optionid="verb"
									  noempty={true}
									  noCaseChange={true}
									  fieldvalue={this.state.verb ? this.state.verb : apiLinkVals.verb}
									  options={ URLVerbs }
									  noChoice="Choose verb"
									  errorMessage={this.state.formError}
									  localFieldChangeCallback={ this.verbChange } />

					<FormField.Select label="Security type" width="2" labelWidth={labelWidth} readOnly={apiLinkInUse}
									  fieldid="authentication_method_id"
									  fieldName="name"
									  optionid="api_authentication_method_id"
									  noempty={true}
									  noCaseChange={true}
									  findReadOnlyValue={true}
									  fieldvalue={this.state.securityType ? this.state.securityType : apiLinkVals.authentication_method_id}
									  options={ this.props.apilinkAuthmethods }
									  noChoice="Choose security"
									  errorMessage={this.state.formError}
									  localFieldChangeCallback={ this.securityChange } />

					<FormField.Checkbox fieldid="disabled_ind" width="5" label="API Link enabled" labelWidth={labelWidth} valueLabels={ ["Disabled", "Enabled"] } defaultFieldValue={(apiLinkVals.disabled_ind === 0)} hideLabel={true} />

					<h5 className="page-header" style={{"display": "block"}}>Authentication details</h5>
					<FormFieldWrapper.DataTableForm
						readOnly={this.props.readOnly}
						id={"index"}
						columns={ authColumns }
						dataItems={ authDetailsArr }
						initialSortKey={"index"}
						addRowCallback={viewModeOrInUse ? null : ((e) => APILinkConfigAddRemoveRows.addAuthRow(this))}
						removeRowCallback={viewModeOrInUse ? null : ((e) => APILinkConfigAddRemoveRows.confirmRemoveAuthRow(this, e))}
						key={this.state.version}
					/>
					<br /><br />

					<h5 className="page-header" style={{"display": "block"}}>API Link Attributes</h5>
					<FormFieldWrapper.DataTableForm
						readOnly={this.props.readOnly || apiLinkInUse}
						id={"api_link_attribute_id"}
						columns={ this.apiLinkAttributeColumns }
						dataItems={ this.state.attributeData }
						addRowCallback={viewModeOrInUse ? null : ((e) => APILinkConfigAddRemoveRows.addAttributeRow(this))}
						removeRowCallback={((e) => APILinkConfigAddRemoveRows.confirmRemoveAttributeRow(this, e))}
						key={this.state.version}
					/>
				</Form>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	// This code is to handle when you add an API link and then edit it.
	// In that case, ownProps.object is empty.
	let apiLinkID;
	if (!_.isEmpty(ownProps.object))
		apiLinkID = ownProps.object.api_link_id;
	else if ("lastid" in state.apiLinkReducer.apilinkDetails)
		apiLinkID = state.apiLinkReducer.apilinkDetails.lastid;

	// FIREFOX BUG WORKAROUND
	// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
	// It doesn't like the number 1 as a computed key in an object, even once it's stringified
	return {
		currentForm: state.formStateReducer.currentForm,
		apilinkInUse: state.apiLinkReducer.apilinkInUse['ID_' + apiLinkID],
		apilinkDetails: state.apiLinkReducer.apilinkDetails['ID_' + apiLinkID],
		apiLinkAttributes: state.apiLinkReducer.apiLinkAttributes['ID_' + apiLinkID],
		apilinkDatatypes: state.apiLinkReducer.apilinkDatatypes,
		apilinkAuthmethods: state.apiLinkReducer.apilinkAuthmethods,
		accounts: state.accountReducer.accounts
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, accountActions, apilinkActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiLinkConfigForm)
