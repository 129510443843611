import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as formStateActions  from '../../../actions/formStateActions';
import * as adminActions  from '../../../actions/adminActions';
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';

class APIGroups extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filterText: '',
			initialSortKey: 'api_group_name',
			errorMessage: '',
			currentPage: 1,
			rowsPerPage: 50
		};
		this.handleUserInput = this.handleUserInput.bind(this);
		this.setFormErrorMessage = this.setFormErrorMessage.bind(this);
		this.setFormSuccessMessage = this.setFormSuccessMessage.bind(this);
	}

	getColumns() {
		return {
			api_group_name:		{ name: "Group Name", used: 1,  width: 125 },
			api_group_desc:		{ name: "Description", used: 1, width: 250 },
			application_name:	{ name: "Application", used: 1, width: 175 }
		};
	}

	handleUserInput(filterText) {
		this.setState({
			filterText: filterText
		});
	}

	componentDidMount() {
		this.props.actions.loadAPIGroups(this.state.currentPage, 1).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the API groups: " + msg);
		});
	}

	componentWillUnmount() {
	}

	_onChange() {
		if (this.isMounted()) {
			this.forceUpdate();
		}
	}

    setFormErrorMessage(errorMessage) {
        if (this._isMounted) {
            this.setState({
                formError: errorMessage
            });
        }
    }

    setFormSuccessMessage(successMessage) {
        if (this._isMounted) {
            this.setState({
                formSuccess: successMessage
            });
        }
    }

	render() {
		var apigroups = this.props.apigroups;
		return (
			<ContentWrap title="API Groups" errorMessage={this.state.errorMessage}>
				<div className="search">
					<TableSearch
						onUserInput={this.handleUserInput}
						filterText={this.state.filterText}
					></TableSearch>
				</div>
				<div className="clearfix"></div>
				<DataTable
					columns={this.getColumns()}
					dataItems={ apigroups }
					rowsPerPage={ this.state.rowsPerPage}
					filterText={this.state.filterText}
					initialSortKey={this.state.initialSortKey}
				></DataTable>
			</ContentWrap>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		apigroups: state.adminReducer.apigroups,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, adminActions, formStateActions), dispatch)
	};

}

export default connect(mapStateToProps, mapDispatchToProps)(APIGroups);
