import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as accountActions  from '../../../actions/accountActions';
import * as formStateActions  from '../../../actions/formStateActions';
import * as userActions  from '../../../actions/userActions';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import _ from 'lodash';

class UserForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentAppTypeCode: null
		};
	}

	componentDidMount() {
		var obj = this.props.object;
		if (obj && obj.login_id) {
			this.props.actions.loadUser(obj.login_id);
		}
		this.props.actions.loadAccountGroups();
	}

	componentWillUnmount() {
		;
	}

	componentDidUpdate(prevProps) {
		;
	}

	_onChange() {
		;
	}

	render() {
		var object = this.props.object;
		var accountgroups;
		var enabledCheckbox;
		var accountGroupItems = [];
		var accountGroupSection;
		var applicationRoleItems = [];
		var applicationRoleSection;
		let user = this.props.user;

		if (this.props.showAccountGroupDropdown) {
			accountgroups = this.props.accountgroups;

			accountGroupSection = (
				<FormField.Select label="Account Group" width="6" labelWidth="2" noChoice="=== Please choose a value ==="
								  required="true" fieldid="account_group_id" fieldName="account_group_name" options={ accountgroups } />
			);
		}

		if (object && object.login_id) {
			// only show the enabled checkbox for existing users
			enabledCheckbox = (
				<FormField.Checkbox fieldid="enabled" width="5" label="Status" labelWidth="2" valueLabels={ ["Disabled", "Enabled"] } />
			);
		}

		if (this.props.readOnly) {
			if (this.props.showAccountGroupDropdown) {
				if (user && user.account_group_name_list && user.account_group_name_list.length > 0) {
					_.each(user.account_group_name_list, function(acct_group_name) {
						accountGroupItems.push(<li key={"ag-" + _.snakeCase(acct_group_name)} className="sl-listitem">{acct_group_name}</li>);
					});
					accountGroupSection = (
						<div className="form-group row">
							<label className="col-form-label col-md-2">Account Groups</label>
							<div className="form-control-plaintext col-md-5">
								<ul style={{ paddingLeft: "15px"}}>
								{ accountGroupItems }
								</ul>
							</div>
						</div>
					);
				} else {
					accountGroupSection = (
						<div className="form-group row">
							<label className="col-form-label col-md-2">Account Groups</label>
							<div className="form-control-plaintext col-md-5">
								<p style={{ fontStyle: "italic" }}>No accounts assigned</p>
							</div>
						</div>
					);
				}
			}

			if (user && user.app_role_list && user.app_role_list.length > 0) {
				_.each(user.app_role_list, function(app_role) {
					var key = _.snakeCase( app_role.application_name + '_' + app_role.role_type_code);
					applicationRoleItems.push(<li key={"ar-" + key} className="sl-listitem"><b>{app_role.application_name}: </b> {app_role.role_name} ({app_role.role_type_code})</li>);
				});
				applicationRoleSection = (
					<div className="form-group row">
						<label className="col-form-label col-md-2">Application Roles</label>
						<div className="form-control-plaintext col-md-5">
							<ul style={{ paddingLeft: "15px"}}>
								{ applicationRoleItems }
							</ul>
						</div>
					 </div>
				);
			} else {
				applicationRoleSection = (
					<div className="form-group row">
						<label className="col-form-label col-md-2">Application Roles</label>
						<div className="form-control-plaintext col-md-5">
							<p style={{ fontStyle: "italic" }}>No roles assigned for any application</p>
						</div>
					 </div>
				);
			}
		}

		//Not working const {'formid', fieldvalue, fieldchangecallback, ...rest} = this.props;
		const divProps = Object.assign({},  this.props);
		// delete divProps.formid;
		// delete divProps.fieldvalue;
		// delete divProps.fieldchangecallback;
		//
		// if (!this.props.readOnly) {
		//	 delete divProps.value;
		// }

		return (
			<Form {...divProps} width="800px" height="580px" >
				<FormField.Input {...divProps}  fieldid="email" type="email" width="5" label="Email" labelWidth="2" required="true" help="Your email address will be your username" />
				<FormField.Input {...divProps}  fieldid="first_name" type="first_name" width="3" label="First Name" labelWidth="2" required="true" />
				<FormField.Input {...divProps}  fieldid="last_name" type="last_name" width="5" label="Last Name" labelWidth="2" required="true" />
				<FormField.Input fieldid="address" width="5" label="Address 1" labelWidth="2" />
				<FormField.Input fieldid="address2" width="5" label="Address 2" labelWidth="2" />
				<FormField.Input fieldid="city" type="city" width="4" label="City" labelWidth="2" />
				<FormField.Input fieldid="state_code" type="state_code" width="1" label="State" labelWidth="2" />
				<FormField.Input fieldid="zip" type="zip" width="3" label="Zip" labelWidth="2" />
				<FormField.Input fieldid="country_code" type="country_code" width="2" label="Country" labelWidth="2" />
				{ accountGroupSection }
				{ applicationRoleSection }
				<FormField.Box hide={this.props.readOnly || this.props.hidepwd}>
					<span className="sl-formtext text-warning">Passwords must be 8 or more characters long and have at least one letter, digit, capital letter, and special character.</span>
					<FormField.Input fieldid="pwd1" type="password" width="3" maxWidth="255" label="Password" labelWidth="3" required="true" />
					<FormField.Input fieldid="pwd2" type="password" width="3" maxWidth="255" label="Retype Password" labelWidth="3" required="true" />
				</FormField.Box>
				{ enabledCheckbox }
			</Form>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		user: state.adminReducer.user,
		accountgroups: state.accountReducer.accountgroups,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, accountActions, userActions, formStateActions), dispatch)
	};

}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
