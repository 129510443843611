import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as accountActions  from '../../../actions/accountActions';
import * as transformationActions  from '../../../actions/transformationActions';
import * as formStateActions  from '../../../actions/formStateActions';
import SimpleForm from '../../common/SimpleForm';
import FormField from '../../common/FormField';
import _ from 'lodash';

class TransformationAccountForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedAccount: {account_id: null},
			successMessage: props.currentForm.successMessage,
			errorMessage: props.currentForm.errorMessage
		};

		this.setFormSuccessMessage = this.setFormSuccessMessage.bind(this);
		this.setFormErrorMessage = this.setFormErrorMessage.bind(this);

		this.cancelCallback = this.cancelCallback.bind(this);
		this.accountChange = this.accountChange.bind(this);
		this.addTransformationAccount = this.addTransformationAccount.bind(this);
		this.removeTransformationAccount = this.removeTransformationAccount.bind(this);
	}

	setFormSuccessMessage(successMessage) {
		this.setState({
			successMessage: successMessage,
			errorMessage: null
		});
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			successMessage: null,
			errorMessage: errorMessage
		});
	}

	scrollToTop() {
		window.scrollTo(0, 0);
	}

	cancelCallback() {
		if (this.props.cancelCallback) {
			this.props.cancelCallback(true);
		}
	}

	accountChange(event, fieldChanges) {
		this.setState((state, props) => (
			{
				selectedAccount: _.assign({}, state.selectedAccount, fieldChanges),
				successMessage: null,
				errorMessage: null
			}
		));
	}

	addTransformationAccount() {
		let api_link_transform_rule_id = this.props.transformation.api_link_transform_rule_id;
		let account_id = this.state.selectedAccount.account_id;
		if (!account_id) {
			this.setFormErrorMessage("Please select an account to assign");
			return;
		}
		this.props.actions
			.addTransformationAccount(api_link_transform_rule_id, account_id)
			.then(response => {
				this.scrollToTop();
				this.setState({
					selectedAccount: {account_id: null},
					successMessage: `Successfully assigned the account ${account_id}`,
					errorMessage: null
				});
				return true;
			})
			.catch(errObject => {
				this.scrollToTop();
				let msg = errObject.message || errObject;
				this.setFormErrorMessage(`An error occurred adding the account ${account_id}: ${msg}`);
				return false;
			})
			.then(reload => reload ? this.props.actions.loadTransformationAccounts(api_link_transform_rule_id) : null)
			.catch(errObject => {
				this.scrollToTop();
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the assigned accounts for this transformation: " + msg);
			});
	}

	removeTransformationAccount(account_id) {
		let api_link_transform_rule_id = this.props.transformation.api_link_transform_rule_id;
		this.props.actions
			.removeTransformationAccount(api_link_transform_rule_id, account_id)
			.then(response => {
				this.scrollToTop();
				this.setFormSuccessMessage(`Successfully removed the account ${account_id}`);
				return true;
			})
			.catch(errObject => {
				this.scrollToTop();
				let msg = errObject.message || errObject;
				this.setFormErrorMessage(`An error occurred removing the account ${account_id}: ${msg}`);
				return false;
			})
			.then(reload => reload ? this.props.actions.loadTransformationAccounts(api_link_transform_rule_id) : null)
			.catch(errObject => {
				this.scrollToTop();
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the assigned accounts for this transformation: " + msg);
			});
	}

	componentDidMount() {
		let transformation = this.props.transformation;
		if (transformation && transformation.api_link_transform_rule_id) {
			this.props.actions
				.loadTransformationAccounts(transformation.api_link_transform_rule_id)
				.catch(errObject => {
					let msg = errObject.message || errObject;
					this.setFormErrorMessage("An error occurred loading the assigned accounts for this transformation: " + msg);
				});
		}
		this.props.actions
			.loadAccounts()
			.catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage(`An error occurred loading the activate accounts: ${msg}`);
			});
	}

	renderAvailable() {
		let accountTagTextFunction = function(obj) {
			return obj.account_name + ' (' + obj.account_id + ')';
		};
		let allAccounts = this.props.accounts;
		let assignedAccounts = this.props.assignedAccounts;
		let assignedAccountsArr = _.values(assignedAccounts);

		let unassignedAccounts = _.reject(allAccounts,
			itm => _.find(assignedAccountsArr, {account_id: itm.account_id}));

		let availableData = {
			label: "Account",
			fieldid: "account_id",
			fieldName: "account_name",
			optionid: "account_id",
			fieldValue: this.state.selectedAccount.account_id,
			options: unassignedAccounts,
			localFieldChangeCallback: this.accountChange,
			errorMessage: this.state.errorMessage,
			assinedCb: this.addTransformationAccount
		};

		return (
			<div className="form-group">
				<label className="control-label col-md-2" style={{ lineHeight: "16px"}}>Accounts</label>
				<div className="form-control-static col-md-10" >
					<FormField.AssignedTagList
						objects={ assignedAccountsArr }
						keyField="account_id"
						tagText={ accountTagTextFunction }
						sortField = "account_name"
						readOnly = {this.props.readOnly}
						emptyMessage="No account assigned"
						removeCallback={ this.removeTransformationAccount }
						buttonIDPrefix={ "transformation" }
						available = {availableData} >
					</FormField.AssignedTagList>
				</div>
			</div>
		);
	}

	render() {
		let transformation = this.props.transformation;
		let transformationName = transformation.name || "";
		return (
			<SimpleForm title="Assign Account"  currentForm={this.props.currentForm}
				width="800" height="580"
				successMessage={ this.state.successMessage }
				errorMessage={ this.state.errorMessage }
				cancelCallback={this.cancelCallback} cancelButtonLabel="Done">

				<FormField.Input fieldid="name" fieldvalue= {transformationName}
					width="7"
					label="Data Transformation" labelWidth="3"
					dontModify={true} />
				{ this.renderAvailable() }
			</SimpleForm>
		);
	}
}

function mapStateToProps(state, ownProps) {
	const transformRuleID = ownProps.transformation.api_link_transform_rule_id;
	const key = 'ID_' + transformRuleID;
	return {
		currentForm: state.formStateReducer.currentForm,
		assignedAccounts: state.transformationReducer.assignedAccounts[key],
		accounts: state.accountReducer.accounts
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, accountActions, transformationActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TransformationAccountForm)
