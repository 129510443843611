import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as apilinkActions  from '../../../actions/apilinkActions';
import * as formStateActions  from '../../../actions/formStateActions';
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';
import ConfirmForm from '../../common/ConfirmForm';
import ApiLinkConfigForm from './ApiLinkConfigForm';
import ApiLinkAccountForm from './ApiLinkAccountForm';
import _ from 'lodash';

class ApiLinks extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filterText: '',
			initialSortKey: 'name',
			errorMessage: null,
			successMessage: null,
			currentForm: null,
			currentPage: 1,
			rowsPerPage: 50
		};

		/*
			These binds are used to access the functions in ApiLinks.js from sub forms.
			For example:
			Suppose you add an API Link. Now you want to relist the front page.
			Without the calls below, you couldn't access the takeDownForm function
			from the addApiLinkCallback with passing it along to the sub form.
			Here we just bind the parent form context to that function.
		*/
		this.handleUserInput = this.handleUserInput.bind(this);
		this.setFormState = this.setFormState.bind(this);
		this.cancelForm = this.cancelForm.bind(this);
		this.takeDownForm = this.takeDownForm.bind(this);
		this.updateCallback = this.updateCallback.bind(this);
		this.addApiLinkCallback = this.addApiLinkCallback.bind(this);
		this.deleteApiLinkCallback = this.deleteApiLinkCallback.bind(this);
		this.newApilinkClicked = this.newApilinkClicked.bind(this);
		this.clone = this.clone.bind(this);
	}

	getColumns() {
		return {
			name:		{ name: "Api Link Name", used: 1, width: 300, click_action: "View", required: true },
			in_use:		{ name: "In Use", used: 1, width: 30, type: "boolean", values: [1] },
			accounts_assigned:		{ name: "Accounts", used: 1, width: 100, required: false }
		};
	}

	getFieldIds() {
		return _.keys(this.getColumns());
	}

	getRequiredFieldIds() {
		return _.keys(_.pickBy(this.getColumns(), 'required'));
	}

	handleUserInput(filterText) {
		this.setState({
			filterText: filterText
		});
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			currentForm: nextProps.currentForm
		});
	}

	componentDidMount() {
		this.props.actions.loadApiLinks().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the api links: " + msg);
		});
	}

	componentWillUnmount() {
	}

	_onChange() {
		this.setState({
			errorMessage: null
		});
	}

	newApilinkClicked() {
		this.setFormState("insert-apilink", null,null,null);
	}

	addApiLinkCallback(fields) {
		this.props.actions.addApiLink(fields.name).then(data => {
			this.props.actions.loadApiLinks().then(data => {
				this.takeDownForm(null);
			}).catch(errObject => {
				this.takeDownForm(null);
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the api links: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred adding the api link: " + msg);
		});
	}

	updateCallback(fields) {
		this.props.actions.updateApiLink(fields.api_link_id, fields.name).then(data => {
			this.props.actions.loadApiLinks().then(data => {
				this.takeDownForm(null);
			}).catch(errObject => {
				this.takeDownForm(null);
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the api links: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred updating the api link: " + msg);
		});
	}

	deleteApiLinkCallback(fields) {
		this.props.actions.deleteApiLink(fields.api_link_id).then(data => {
			this.takeDownForm(null);
			this.props.actions.loadApiLinks().then(data => {
				this.setFormSuccessMessage(`Successfully deleted API Link configuration ${fields.name}`);
			}).catch(errObject => {
				this.takeDownForm(null);
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the api links: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred removing the api link: " + msg);
		});
	}

	setFormState(formType, errorMessage, successMessage, id) {
		if (this.props.actions.formChangeHandler)
			this.props.actions.formChangeHandler(formType, errorMessage, successMessage, id);
			this.setState({
				errorMessage: null,
				successMessage: null
			});
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			successMessage: null,
			errorMessage: errorMessage
		});
	}

	setFormSuccessMessage(successMessage) {
		this.setState({
			successMessage: successMessage,
			errorMessage: null
		});
	}

	takeDownForm(successMessage) {
		this.setFormState(null, null, successMessage, null);
		window.scrollTo(0, 0);
	}

	clearErrorMessage() {
		this.setState({
			errorMessage: null
		});
	}

	cancelForm() {
		this.takeDownForm(null);
		this.clearErrorMessage();
	}

	clone(errorMessage, successMessage, id)
	{
		this.props.actions.cloneApiLink(id).then(data => {
			this.props.actions.loadApiLinks().then(data => {
				this.setFormSuccessMessage(`Successfully cloned the API Link configuration`);
			}).catch(errObject => {
				this.takeDownForm(null);
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the api links: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred cloning the api link: " + msg);
		});
	}

	render() {
		let currentForm, object;
		let allApiLinks = this.props.apilinks;
		if (!allApiLinks) {
			return null;
		}

		let errMsg = this.state.errorMessage;
		let successMsg = this.state.successMessage;

		let addNewApiLinkTitle;
		let buttonList;

		addNewApiLinkTitle = "Add new API Link";
		buttonList = [	{ label: "View", icon: "info", callback: _.wrap("show-apilink", this.setFormState) },
						{ label: "Edit", icon: "edit", callback: _.wrap("edit-apilink", this.setFormState) },
						{ label: "Assign", icon: "plus", callback: _.wrap("assign-apilink", this.setFormState) },
						{ label: "Clone", icon: "clone", callback: this.clone },
						{ label: "Delete", icon: "trash", callback: _.wrap("delete-apilink", this.setFormState) }
					];

		switch (this.props.currentForm && this.props.currentForm.formType) {
			case "insert-apilink":
				object = {};
				currentForm = <ApiLinkConfigForm title="API Link Configuration details" readOnly={false} object={object}
									errorMessage={errMsg}
									cancelCallback={this.cancelForm}/>;
				break;
			case "show-apilink":
				object = allApiLinks ? _.find(allApiLinks, {api_link_id: this.props.currentForm.currentId}) : {};
				if (object) {
					currentForm = <ApiLinkConfigForm title="API Link Configuration details" readOnly={true} object={object}
										errorMessage={errMsg}
										cancelCallback={this.cancelForm}/>;
				}
				break;
			case "edit-apilink":
				object = allApiLinks ? _.find(allApiLinks, {api_link_id: this.props.currentForm.currentId}) : {};
				if (object) {
					currentForm = <ApiLinkConfigForm title="API Link Configuration details" readOnly={false} object={object}
										errorMessage={errMsg}
										cancelCallback={this.cancelForm}/>;
				}
				break;
			case "assign-apilink":
				object = allApiLinks ? _.find(allApiLinks, {api_link_id: this.props.currentForm.currentId}) : {};
				if (object) {
					currentForm = <ApiLinkAccountForm title="Assign Account" readOnly={false} object={object}
										errorMessage={errMsg}
										cancelCallback={this.cancelForm}/>;
				}
				break;
			case "delete-apilink":
				object = allApiLinks ? _.find(allApiLinks, {api_link_id: this.props.currentForm.currentId}) : {};
				currentForm = <ConfirmForm title="Delete Api Link" text={"Are you sure you want to permanently the remove api link \"" + object.name + "\" ?"}
										   args={object} errorMessage={errMsg}
										   confirmCallback={this.deleteApiLinkCallback} cancelCallback={this.cancelForm}/>;
				break;
		}

		return (
			<ContentWrap title={this.props.title || "API Link Configuration"} successMessage={this.state.successMessage} errorMessage={this.state.errorMessage} form={currentForm}>
				<div className="search">
					<TableSearch
						onUserInput={ this.handleUserInput }
						filterText={ this.state.filterText }>
					</TableSearch>
					<div className="float-right form-inline">
						<button className="btn btn-primary btn-sm searchBtn" id="add_new_api_link" title="Add Api Link" onClick={this.newApilinkClicked}>{addNewApiLinkTitle}</button>
					</div>
				</div>
				<div className="clearfix"></div>
				<DataTable
					id="api_link_id"
					columns={ this.getColumns() }
					dataItems={ allApiLinks }
					rowsPerPage={ this.state.rowsPerPage }
					filterText={ this.state.filterText }
					initialSortKey={ this.state.initialSortKey }
					buttonIDPrefix={ "name" }
					buttons={ buttonList }>
				</DataTable>
			</ContentWrap>

		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		apilinks: state.apiLinkReducer.apilinks,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, apilinkActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiLinks)
