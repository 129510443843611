import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as vendorActions  from '../../../actions/vendorActions';
import * as shortcodeActions  from '../../../actions/shortcodeActions';
import * as formStateActions  from '../../../actions/formStateActions';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import _ from 'lodash';

class VendorShortCodeForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentVendorAccountToAdd: {ShortCodeId: null},
			applications: null,
			applicationErrorMessage: null,
			errorMessage: null,
			successMessage: null
		};

		this.accountChange         = this.accountChange.bind(this);
		this.removeVendorShortCode = this.removeVendorShortCode.bind(this);
		this.addVendorShortCode    = this.addVendorShortCode.bind(this);
		this.cancelButtonCallback = this.cancelButtonCallback.bind(this);
	}

	componentDidMount() {
		this.props.actions.loadVendorShortCodes(this.props.object.VendorAccountID).then(data => {
			;
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setState({
				applicationErrorMessage: null,
				errorMessage: "An error occurred loading short codes: " + msg,
				successMessage: null
			});
		});
		this.props.actions.loadAvailableShortCodes().then(data => {
			;
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setState({
				applicationErrorMessage: null,
				errorMessage: "An error occurred loading available short codes: " + msg,
				successMessage: null
			});
		});
	}

	componentWillUnmount() {
		;
	}

	_onChange() {
		this.setState({
			formError: null
		});
	}

	_onVendorAccountChange() {
		;
	}

	removeVendorShortCode(short_code) {
		let vendor_account_id = this.props.object.VendorAccountID;
		this.props.actions.removeVendorShortCode(vendor_account_id, short_code).then(data => {
			this.props.actions.loadAvailableShortCodes().then(data => {
				this.setState({
					currentVendorAccountToAdd: {
						ShortCodeId: null
					},
					applicationErrorMessage: null,
					errorMessage: null,
					successMessage: `Vendor shortcode successfully removed`
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setState({
					applicationErrorMessage: null,
					errorMessage: "An error occurred loading available short codes: " + msg
				});
			});
			this.props.actions.loadVendorShortCodes(this.props.object.VendorAccountID).then(data => {
				this.setState({
					currentVendorAccountToAdd: {
						ShortCodeId: null
					},
					applicationErrorMessage: null,
					errorMessage: null
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setState({
					applicationErrorMessage: null,
					errorMessage: "An error occurred loading vendor short codes: " + msg,
					successMessage: null
				});
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setState({
				applicationErrorMessage: null,
				errorMessage: "An error occurred deleting the vendor short code: " + msg,
				successMessage: null
			});
		});
	}

	addVendorShortCode(fields) {
		let vendor_account_id = this.props.object.VendorAccountID;
		let short_code_id = this.state.currentVendorAccountToAdd.ShortCodeId;
		this.props.actions.addVendorShortCode(vendor_account_id, short_code_id).then(data => {
			this.props.actions.loadVendorShortCodes(this.props.object.VendorAccountID).then(data => {
				this.setState({
					applicationErrorMessage: null,
					errorMessage: null,
					successMessage: `Vendor shortcode successfully added`
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setState({
					applicationErrorMessage: null,
					errorMessage: "An error occurred loading vendor short codes: " + msg,
					successMessage: null
				});
			});
			this.props.actions.loadAvailableShortCodes().then(data => {
				;
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setState({
					applicationErrorMessage: null,
					errorMessage: "An error occurred loading available short codes: " + msg,
					successMessage: null
				});
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setState({
				applicationErrorMessage: null,
				errorMessage: "An error occurred deleting the vendor short code: " + msg,
				successMessage: null
			});
		});

			this.setState({
				currentVendorAccountToAdd: {
					ShortCodeId: null
				},
				applicationErrorMessage: null,
				errorMessage: null,
				successMessage: null
			});
	}

	applyNameChange() {
		return true;
	}

	cancelButtonCallback()
	{
		this.props.actions.loadVendors().then(successObject => {
			if (this.props.cancelCallback)
				this.props.cancelCallback();
		}).catch(errObject => {
			if (this.props.cancelCallback)
				this.props.cancelCallback();
		});
	}

	accountChange(event, fieldChanges) {
		this.setState({
			currentVendorAccountToAdd: _.extend(this.state.currentVendorAccountToAdd, fieldChanges),
			applicationErrorMessage: null,
			errorMessage: null,
			successMessage: null
		});
	}

	renderAvailable() {
		var allShortCodes = this.props.availableshortcodes;
		var addedShortCodes = this.props.vendorShortcodes;
		var aryAddShortCodes = _.values(addedShortCodes);

		var tagTextFunction = function(obj) {
			return obj.ShortCode;
		};

		var availableVendorAccounts = _.filter(allShortCodes, function (o) {
			var idx = _.findIndex(aryAddShortCodes, {ShortCode: o.ShortCode});
			return idx === -1;
		}) ;

		var availableData = {
				label: "Short Code",
				fieldid: "ShortCodeId",
				fieldName: "ShortCode",
				optionid: "ShortCodeId",
				fieldvalue:  this.state.currentVendorAccountToAdd.ShortCodeId ,
				options:  availableVendorAccounts ,
				localFieldChangeCallback:  this.accountChange,
				errorMessage: this.state.applicationErrorMessage,
				assinedCb: this.addVendorShortCode
		};
		return (
		<div className="form-group row">
			<label className="col-form-label col-md-2" style={{ lineHeight: "16px"}}>Short Codes</label>
			<div className="form-control-plaintext col-md-10" >
				<FormField.AssignedTagList
					readOnly = { this.props.readOnly }
					keyField = "ShortCode"
					sortField ="ShortCode"
					tagText={ tagTextFunction }
					objects = { aryAddShortCodes}
					removeCallback={ this.removeVendorShortCode }
					emptyMessage="No short code assigned"
					buttonIDPrefix={ "vendor_shortcode" }
					available = {availableData}>
				</FormField.AssignedTagList>
			</div>
		</div>
		);
	}

	render() {
		return (
			<Form {...this.props} width="800" height="580" cancelCallback={this.cancelButtonCallback} cancelButtonLabel="Done" successMessage={ this.state.successMessage } errorMessage={ this.state.errorMessage }>
				<FormField.Input fieldid="VendorName" width="5" label="Vendor Name" labelWidth="2" dontModify="true" />
				<FormField.Input fieldid="VendorTypeCode" width="5" label="Type Code" labelWidth="2" dontModify="true" />
				<FormField.Input fieldid="AccountName" width="5" label="Account Name" labelWidth="2" dontModify="true" />
				{ this.renderAvailable() }
			</Form>
		);
	}
}


function mapStateToProps(state, ownProps) {
	// FIREFOX BUG WORKAROUND
	// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
	// It doesn't like the number 1 as a computed key in an object, even once it's stringified
	return {
		currentForm: state.formStateReducer.currentForm,
		shortcodes: state.shortcodeReducer.shortcodes,
		availableshortcodes: state.shortcodeReducer.availableshortcodes,
		vendorShortcodes: state.vendorReducer.vendorShortcodes['ID_' + ownProps.object.VendorAccountID]
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, vendorActions, shortcodeActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorShortCodeForm)
