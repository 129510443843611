import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators}  from 'redux'
import * as userActions  from '../../actions/userActions';
import * as sessionActions from '../../actions/sessionActions';
import * as formStateActions  from '../../actions/formStateActions';
import ChangePassword from '../admin/users/ChangePassword';
import Form from '../common/Form';
import FormField from '../common/FormField';
import _ from 'lodash';

class MySettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			formError: null,
			formSuccess: null,
			currentForm: null
		};

		this.changePasswordCallback = this.changePasswordCallback.bind(this);
		this.setFormSuccessMessage = this.setFormSuccessMessage.bind(this);
		this.setFormErrorMessage = this.setFormErrorMessage.bind(this);
	}

	componentDidMount() {
		this.props.actions.loadUserData();
	}

	changePasswordCallback(fields) {
		if (fields.pwd1 || fields.pwd2) {
			if (fields.pwd1 != fields.pwd2) {
				this.setFormErrorMessage("The password fields do not match");
				return;
			}
		} else {
			this.setFormErrorMessage("The password fields are required");
			return;
		}

		var allFields = _.extend(fields, {
			action: 'update',
			password: fields.pwd1,
			email: fields.email
		});

		this.props.actions.changePassword(allFields).then(data => {
			//console.log("Successfully changed password");
			this.setFormSuccessMessage("Successfully changed password");
		}).catch(errObject => {
			//console.log("An error occurred updating your password: ");
			//console.log(errObject);
			var msg = errObject.message;
			this.setFormErrorMessage("An error occurred updating your password: " + msg);
		});
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			formError: errorMessage,
			formSuccess: null
		});
	}

	setFormSuccessMessage(successMessage) {
		this.setState({
			formError: null,
			formSuccess: successMessage
		});
	}

	render(){
		let userData = this.props.userSessionData;

		var successMessage = null, errMsg = null;

		if (this.state.formSuccess)
			successMessage = this.state.formSuccess;

		if (this.state.formError)
			errMsg = this.state.formError;

		return (
			<div>
				<div className="sl-rightside-container">
					<div className="clearfix"></div>
					<ChangePassword title="Change Password" object={userData} errorMessage={errMsg} successMessage={successMessage}
									submitCallback={this.changePasswordCallback} />;
				</div>
			</div>
		);

	}
}

function mapStateToProps(state, ownProps) {
	return {
		userSessionData: state.sessionReducer.sessiondata,
		currentForm: state.formStateReducer.currentForm
	}
}
	
function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, sessionActions, userActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MySettings)