/*
 * The attributes reducer will always return an array of attributes no matter what
 * You need to return something, so if there are no users then just return an empty array
 * */

import * as types from '../actions/actionTypes';

var initialState = {
	loginattributes: [],
	attributes: [],
	//attribute: null
}

export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_LOGIN_ATTRIBUTES_SUCCESS:
			return Object.assign({}, state, {loginattributes: action.payload});
		case types.GET_ATTRIBUTES_SUCCESS:
			return Object.assign({}, state, {attributes: action.payload});
		default:
			return state;
	}
}
