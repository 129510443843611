import * as types from '../actions/actionTypes';
/*
 * All reducers get two parameters passed in, state and action that occurred
 *   > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

var initialState = {
	shortcodes: [],
	availableshortcodes: [],
	shortcodeAccounts: {},
	shortcodePrograms: {}
};

export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_SHORTCODES_SUCCESS:
			//console.log('reducer to update shortcode data', action);
			return Object.assign({}, state, {shortcodes: action.payload});
		case types.GET_AVAILABLE_SHORTCODES_SUCCESS:
			//console.log('reducer to get available shortcode data', action);
			return Object.assign({}, state, {availableshortcodes: action.payload});
		case types.GET_SHORTCODE_ACCOUNTS_SUCCESS:
			//console.log('reducer to get available shortcode data', action);
			// If this Activate account has email domains assigned to it
			/**************************
				We're storing vendor shortcodes in a hash.
				The key is the vendor account ID.
				The value is the list of shortcodes.
				This way, each incoming call doesn't overwrite data from previous calls.
			**************************/

			// FIREFOX BUG WORKAROUND
			// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
			// It doesn't like the number 1 as a computed key in an object, even once it's stringified
			// Also: it's unfortunate that this API returns VendorAccountId ending with a lower-case d,
			// but in other places it ends with an upper-case D.
			let shortCodeIDAccount = action.id;
			return Object.assign({}, state, {shortcodeAccounts: { ['ID_' + shortCodeIDAccount]: action.payload}});
		case types.GET_SHORTCODE_PROGRAMS_SUCCESS:
			//console.log('reducer to get available shortcode data', action);
			// If this Activate account has email domains assigned to it
			/**************************
				We're storing vendor shortcodes in a hash.
				The key is the vendor account ID.
				The value is the list of shortcodes.
				This way, each incoming call doesn't overwrite data from previous calls.
			**************************/

			// FIREFOX BUG WORKAROUND
			// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
			// It doesn't like the number 1 as a computed key in an object, even once it's stringified
			// Also: it's unfortunate that this API returns VendorAccountId ending with a lower-case d,
			// but in other places it ends with an upper-case D.
			let shortCodeIDProgram = action.id;
			return Object.assign({}, state, {shortcodePrograms: { ['ID_' + shortCodeIDProgram]: action.payload}});
	}
	return state;
}
