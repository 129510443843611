import React, {Component} from 'react';
import {Route, Redirect} from 'react-router-dom';
import auth from '../../auth/authenticator';

const SecuredRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        auth.loggedIn() ? (
            <Component {...props}/>
        ) : (
            <Redirect to={{
                pathname: '/login',
                state: {from: props.location}
            }}/>
        )
    )}/>
);

export default SecuredRoute;