import _ from 'lodash';

const ApiLinkConfigFormValidator = {
	validateDetails: function(fields, mainPageObj)
	{
		if (!fields.name)
		{
			mainPageObj.setFormErrorMessage("API Link name cannot be empty");
			document.getElementById("name").focus();
			return false;
		}
		if (!fields.url)
		{
			mainPageObj.setFormErrorMessage("API Link URL cannot be empty");
			document.getElementById("url").focus();
			return false;
		}
		if (!fields.verb)
		{
			mainPageObj.setFormErrorMessage("API Link verb cannot be empty");
			document.getElementById("verb").focus();
			return false;
		}
		if (!fields.authentication_method_id)
		{
			mainPageObj.setFormErrorMessage("API Link security type cannot be empty");
			document.getElementById("authentication_method_id").focus();
			return false;
		}

		let authFieldTypePrefix = "fieldtype_";
		let authFieldNamePrefix = "fieldname_";
		let authValuePrefix = "value_";
		let currentAuthTypesObj = {};
		let currentAuthVals = {};
		for (let key in fields)
		{
			let fieldValue = fields[key];
			// Trim leading and ending whitespace
			if (_.isString(fieldValue))
				fieldValue = fieldValue.trim();
			// Make sure auth text input fields aren't empty
			if ((key.substring(0, authFieldNamePrefix.length) === authFieldNamePrefix) || (key.substring(0, authValuePrefix.length) === authValuePrefix))
			{
				if (_.isEmpty(fieldValue))
				{
					mainPageObj.setFormErrorMessage("You cannot have authentication fields which are empty.");
					document.getElementById(key).focus();
					return false;
				}
			}
			// If this is the "Field type" dropdown
			if (key.substring(0, authFieldTypePrefix.length) === authFieldTypePrefix)
			{
				// Make sure we have a place to store headers, request, querystring, username, password, or cert filename
				if (!(fieldValue in currentAuthVals))
					currentAuthVals[fieldValue] = {};
				let rowID = key.substring(authFieldTypePrefix.length);
				let authName = fields[authFieldNamePrefix + rowID];
				// Make sure there are no duplicate entries for username and password field types
				if ((fieldValue === "username" || fieldValue === "password") && fieldValue in currentAuthTypesObj)
				{
					mainPageObj.setFormErrorMessage(`You cannot have more than 1 entry for ${fieldValue} in your auth details.`);
					window.scrollTo(0, 0);
					return false;
				}
				else if (fieldValue === "headers" && authName.indexOf(" ") !== -1)
				{
					mainPageObj.setFormErrorMessage(`The API-Link header authentication field ${authName} has spaces in it.`);
					window.scrollTo(0, 0);
					return false;
				}
				else if (authName in currentAuthVals[fieldValue])
				{
					mainPageObj.setFormErrorMessage(`The API-Link header authentication field ${authName} has been entered multiple times.`);
					window.scrollTo(0, 0);
					return false;
				}
				else // Store the value of the attribute name to make sure there aren't duplicates attribute name entries
				{
					currentAuthVals[fieldValue][authName] = 1;
					currentAuthTypesObj[fieldValue] = 1;
				}
			}
		}

		/****************** Signature Auth validation ******************/
		let authMethodName;
		for (let x = 0, authMethodLen = mainPageObj.props.apilinkAuthmethods.length; x < authMethodLen; x++)
		{
			if (mainPageObj.props.apilinkAuthmethods[x].api_authentication_method_id === parseInt(fields.authentication_method_id))
				authMethodName = mainPageObj.props.apilinkAuthmethods[x].name;
		}

		// SignatureAuth requires username and password; their values need to be accessKey and accessSecret
		if (authMethodName === "SignatureAuth")
		{
			if (!("username" in currentAuthVals))
			{
				mainPageObj.setFormErrorMessage('The SignatureAuth authentication method requires you to fill in a field of type username.');
				window.scrollTo(0, 0);
				return false;
			}
			if (!("password" in currentAuthVals))
			{
				mainPageObj.setFormErrorMessage('The SignatureAuth authentication method requires you to fill in a field of type password.');
				window.scrollTo(0, 0);
				return false;
			}
			if (!("headers" in currentAuthVals))
			{
				mainPageObj.setFormErrorMessage('The SignatureAuth authentication method requires you to fill in a field of type header, value accessKey.');
				window.scrollTo(0, 0);
				return false;
			}
			if (!("accessKey" in currentAuthVals["headers"]))
			{
				mainPageObj.setFormErrorMessage('The SignatureAuth authentication method requires you to fill in a field of type header, value accessKey.');
				window.scrollTo(0, 0);
				return false;
			}
		}
		/****************** End Signature Auth validation ******************/
		return true;
	},

	validateAttributes: function(fields, mainPageObj)
	{
		/***********************
			Attribute validation
		**********************/
		let currentAttributeNamesObj = {};
		let apilinkDatatypeNameObj = {};
		let headerID = 0;
		for (let x = 0; x < mainPageObj.props.apilinkDatatypes.length; x++)
		{
			let apiLinkDataType = mainPageObj.props.apilinkDatatypes[x];
			currentAttributeNamesObj[apiLinkDataType.api_data_type_id.toString()] = {};
			apilinkDatatypeNameObj[apiLinkDataType.api_data_type_id.toString()] = apiLinkDataType.name;
			if (apiLinkDataType.name.toLowerCase() === "header")
				headerID = apiLinkDataType.api_data_type_id.toString();
		}

		for (let key in fields)
		{
			// Trim leading and ending whitespace
			if (_.isString(fields[key]))
				fields[key] = fields[key].trim();

			let fieldValue = fields[key];
			/***** Attribute validation *****/
			// If this is the field name text input,
			// the field can't be empty
			let attrFieldNameDropdownPrefix = "name_";
			let errorMessage;
			if (key.substring(0, attrFieldNameDropdownPrefix.length) === attrFieldNameDropdownPrefix)
			{
				// Field name can't be empty
				// and you can't have slashes in field name
				// Stop validation at that point and put focus on the problematic input
				let hasInputError = false;
				if (!fieldValue)
				{
					hasInputError = true;
					errorMessage = "You have an empty attribute field name";
				}
				else
				{
					for (let charIndex = 0, invalidCharArrLen = mainPageObj.apiLinkAttributeInvalidChars.length; charIndex < invalidCharArrLen; charIndex++)
					{
						let regEx = new RegExp(mainPageObj.apiLinkAttributeInvalidChars[charIndex], 'g');
						if (fieldValue.match(regEx) !== null)
						{
							hasInputError = true;
							errorMessage = `Attribute field name ${fieldValue} has invalid characters`
							break;
						}
					}
				}

				if (hasInputError)
				{
					// Yes, this is anti-pattern for React. The React-way to do this would be to store a Ref via React.createRef() in the FormField.Input.
					// Then we'd need access to that here and we'd call focus on that ref.
					// While we could do that, calling document.getElementById is easier and involves less coding.
					// Using document.getElementById seems to cause the most problems when re-using components.
					// We'll never re-use the ApiLinkConfigForm class anywhere else.
					// See https://codepulse.blog/how-to-focus-element-in-react/ for more info.
					document.getElementById(key).focus();
					mainPageObj.setFormErrorMessage(errorMessage);
					return false;
				}

				let attributeRowID = key.substring(attrFieldNameDropdownPrefix.length);
				let fieldTypeKey = "api_data_type_id_" + attributeRowID;
				let fieldTypeVal = fields[fieldTypeKey];

				if (fieldTypeVal.toString() === headerID && fieldValue.indexOf(" ") !== -1)
				{
					mainPageObj.setFormErrorMessage(`The API-Link header attribute field "${fieldValue}" has spaces in it.`);
					window.scrollTo(0, 0);
					return false;
				}
				else if (fieldValue in currentAttributeNamesObj[fieldTypeVal])
				{
					mainPageObj.setFormErrorMessage(`The API-Link attribute ${fieldValue} of type ${apilinkDatatypeNameObj[fieldTypeVal]} has been entered multiple times.`);
					window.scrollTo(0, 0);
					return false;
				}
				else // Store the value of the attribute name to make sure there aren't duplicates attreibute name entries
					currentAttributeNamesObj[fieldTypeVal][fieldValue] = 1;
			}
		}
		return true;
	}
}

export default ApiLinkConfigFormValidator;
