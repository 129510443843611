import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as formStateActions  from '../../../actions/formStateActions';
import * as accountActions  from '../../../actions/accountActions';
import * as adminActions  from '../../../actions/adminActions';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import _ from 'lodash';

class AccountGroupForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			ipRanges: null,
			currentAccountToAdd: {account_id: null},
			ipRangesToRemove: [],
			ipRangesToAdd: [],
			currentIPRange: {
				application_type_code: null,
				rangestart: [0, 0, 0, 0],
				rangeend: [0, 0, 0, 0]
			},
			allAccounts: null,
			applications: null,
			applicationErrorMessage: null,
			formSuccessMessage: null,
			formErrorMessage: null
		};

		this.accountChange = this.accountChange.bind(this);
		this.setFormErrorMessage = this.setFormErrorMessage.bind(this);
		this.ipRangeChange = this.ipRangeChange.bind(this);
		this.addIPRangeAssignment = this.addIPRangeAssignment.bind(this);
		this.removeIPRangeAssignment = this.removeIPRangeAssignment.bind(this);
		this.addAccountGroupAssignment = this.addAccountGroupAssignment.bind(this);
		this.removeAccountGroupAssignment = this.removeAccountGroupAssignment.bind(this);
	}

	componentWillMount() {
		;
	}

	componentDidMount() {
		this.props.actions.loadAccounts().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the activate accounts: " + msg);
		});
		this.props.actions.loadApplications().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the appplications: " + msg);
		});
		this.props.actions.loadIPRanges().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the IP ranges: " + msg);
		});

		if (this.props.object.id)
		{
			this.props.actions.loadAccountGroupDetails(this.props.object.id).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the account group details: " + msg);
			});
		}
	}

	_onChange() {
		var ipRanges = this.props.ipranges;
		var ourRanges = ipRanges ? _.filter(_.values(ipRanges), { "account_group_id": this.props.object.id }) : null;
		this.setState({
			ipRanges: ourRanges
		});
	}

	componentWillUnmount() {
		;
	}


	ipNotOrdered(ipRangeStart, ipRangeEnd) {
		if (!ipRangeStart || !ipRangeEnd) {
			return true;
		}
		if (Number(ipRangeStart[0]) > Number(ipRangeEnd[0])) return true;
		if (Number(ipRangeStart[0]) < Number(ipRangeEnd[0])) return false;
		if (Number(ipRangeStart[1]) > Number(ipRangeEnd[1])) return true;
		if (Number(ipRangeStart[1]) < Number(ipRangeEnd[1])) return false;
		if (Number(ipRangeStart[2]) > Number(ipRangeEnd[2])) return true;
		if (Number(ipRangeStart[2]) < Number(ipRangeEnd[2])) return false;
		if (Number(ipRangeStart[3]) > Number(ipRangeEnd[3])) return true;
		return false;
	}

	removeAccountGroupAssignment(accountId)
	{
		var data = {};
		this.props.actions.removeAccountGroupAssignment(accountId, this.props.object.id).then(data => {
			this.props.actions.loadAccountGroupDetails(this.props.object.id).then(data => {
				this.setState({
					currentAccountToAdd: {
						account_id: null
					},
					applicationErrorMessage: null,
					formErrorMessage: null,
					formSuccessMessage: "Account group assignment removed successfully"
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the account group details: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage(`An error occurred removing the account group assignment: ${msg}`);
		});
	}

	addAccountGroupAssignment() {
		var data = {};
		var MAX_ASSIGNED_ACCOUNTS = 20;
		var addedAccounts = this.props.accountGroupAccounts ? this.props.accountGroupAccounts.accounts : [];

		if(addedAccounts && (addedAccounts.length+1) > MAX_ASSIGNED_ACCOUNTS) {
			this.setState({
				applicationErrorMessage: null,
				formErrorMessage: "You have reached the maximum number of accounts that can be assigned to a AccountGroup.",
				formSuccessMessage: null
			});
			return;
		}

		var accountId = this.state.currentAccountToAdd.account_id;
		if(!accountId) {
			this.setState({
				applicationErrorMessage: null,
				formErrorMessage: "Please select a valid account first.",
				formSuccessMessage: null
			});
			return;
		}
		this.props.actions.addAccountGroupAssignment(accountId, this.props.object.id).then(data => {
			this.props.actions.loadAccountGroupDetails(this.props.object.id).then(data => {
				this.setState({
					currentAccountToAdd: {
						account_id: null
					},
					applicationErrorMessage: null,
					formErrorMessage: null,
					formSuccessMessage: "Account group assignment added successfully"
				});
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading the account group details: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage(`An error occurred removing the account group assignment: ${msg}`);
		});
	}

	addIPRangeAssignment() {
		if (!this.state.currentIPRange.application_type_code) {
			this.setState({
				applicationErrorMessage: "Please specify an Application for the IP Range"
			});
			return;
		}

		if (this.ipNotOrdered(this.state.currentIPRange.rangestart, this.state.currentIPRange.rangeend)) {
			this.setState({
				applicationErrorMessage: "Error: The IP Start range is after the IP End range"
			});
			return;
		}

		if (this.state.applicationErrorMessage) {
			return;
		}

		var account_group_id = this.props.object.id,
			app_code = this.state.currentIPRange.application_type_code,
			ip_range_start = this.state.currentIPRange.rangestart.join('.'),
			ip_range_end = this.state.currentIPRange.rangeend.join('.');

		this.props.actions.addIPRangeAssignment(account_group_id, app_code, ip_range_start, ip_range_end).then(data => {
			this.setState({
				currentIPRange: {
					application_type_code: null,
					rangestart: [0, 0, 0, 0],
					rangeend: [0, 0, 0, 0]
				},
				applicationErrorMessage: null,
				formErrorMessage: null,
				formSuccessMessage: "IP range added successfully"
			});
			this.props.actions.loadIPRanges().then(data => {
				;
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading IP ranges: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage(`An error occurred adding the IP range ${ip_range_start} to ${ip_range_end}: ${msg}`);
		});
	}

	setFormErrorMessage(msg)
	{
		this.setState({
			applicationErrorMessage: null,
			formErrorMessage: "An error occurred: " + msg,
			formSuccessMessage: null
		});
	}

	removeIPRangeAssignment(ipRangeId) {
		this.props.actions.removeIPRangeAssignment(ipRangeId).then(data => {
			this.setState({
				currentIPRange: {
					application_type_code: null,
					rangestart: [0, 0, 0, 0],
					rangeend: [0, 0, 0, 0]
				},
				applicationErrorMessage: null,
				formErrorMessage: null,
				formSuccessMessage: "IP range removed successfully"
			});
			this.props.actions.loadIPRanges().then(data => {
				;
			}).catch(errObject => {
				let msg = errObject.message || errObject;
				this.setFormErrorMessage("An error occurred loading IP ranges: " + msg);
			});
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage(`An error occurred removing the IP range ${ipRangeId}: ${msg}`);
		});
	}

	applyNameChange() {
		return true;
	}

	ipRangeChange(event, fieldChanges) {
		this.setState({
			currentIPRange: _.extend(this.state.currentIPRange, fieldChanges),
			applicationErrorMessage: null,
			formErrorMessage: null,
			formSuccessMessage: null
		});
	}

	accountChange(event, fieldChanges) {
		this.setState({
			currentAccountToAdd: _.extend(this.state.currentAccountToAdd, fieldChanges),
			applicationErrorMessage: null,
			formErrorMessage: null,
			formSuccessMessage: null
		});
	}

	renderAccountList() {
		var addedAccounts = this.props.accountGroupAccounts ? this.props.accountGroupAccounts.accounts : [];

		var accountTagTextFunction = function(obj) {
			return obj.account_name + ' (' + obj.account_id + ')';
		};
		return (
			<FormField.TagList	objects={ addedAccounts }
								keyField="account_id"
								sortField="account_name"
								tagText={ accountTagTextFunction }
								readOnly = {this.props.readOnly}
								removeCallback={ this.removeAccountGroupAssignment }
								emptyMessage="No accounts assigned" />
		);
	}

	accountGroupAssignmentEdit() {
		if (this.props.readOnly) {
				return null;
		}
		var allAccounts = this.props.accounts;
		var addedAccounts = this.props.accountGroupAccounts ? this.props.accountGroupAccounts.accounts : [];
		var availableAccounts = _.filter(allAccounts, function (o) {
									var idx = _.findIndex(addedAccounts, {account_id: o.account_id});
									return idx === -1;
						}) ;

		return (
			<div className ="card-heading">
				<button className="btn btn-primary btn-sm" style={{ marginRight: 12 }} onClick={ this.addAccountGroupAssignment }>Add Account</button>
				<FormField.Select label="Account: " width="6" labelWidth="3" noChoice="== Please choose an account value =="
						fieldid="account_id"
						fieldName= "account_name"
						optionid="account_id"
						fieldvalue={this.state.currentAccountToAdd.account_id }
						options={availableAccounts}
						localFieldChangeCallback={ this.accountChange}
						errorMessage={this.state.applicationErrorMessage} />
			</div>
		)
	}

	renderIPRangeEdit() {
		if (this.props.readOnly) {
			return null;
		}
		var applications = this.props.applications;
		return (
			<div>
			<FormField.Box>
				<h4 style={{"color": "#763905", "margin": 15 }}>Assign IP Ranges for Applications</h4>
				<button className="btn btn-primary btn-sm" style={{ marginTop: 4, marginRight: 15 }} onClick={ this.addIPRangeAssignment }>Add Range</button>
				<FormField.Select label="Application" width="5" labelWidth="3" noChoice="== Please choose a value =="
								fieldid="application_type_code"
								fieldName="application_name"
								optionid="application_type_code"
								fieldvalue={ this.state.currentIPRange.application_type_code }
								options={ applications }
								localFieldChangeCallback={ this.ipRangeChange } errorMessage={this.state.applicationErrorMessage} />
				<div style={{"margin-left": "30px"}}>
                    <FormField.SingleLine>
                        <FormField.IPAddress fieldid="rangestart" label="IP Range Start" labelWidth="3" object={this.state.currentIPRange.rangestart} onChange={this.ipRangeChange} />
                    </FormField.SingleLine>
                    <FormField.SingleLine>
                        <FormField.IPAddress fieldid="rangeend" label="IP Range End" labelWidth="3" object={this.state.currentIPRange.rangeend} onChange={this.ipRangeChange} />
                    </FormField.SingleLine>
				</div>
			</FormField.Box>
			</div>
		);
	}

	render() {
		var self = this;
		var object = this.props.object;
		var ipRanges = this.props.ipranges;
		var ipRangesForThisGroup = ipRanges ? _.filter(_.values(ipRanges), { "account_group_id": object.id }) : [];
		var tagTextFunction;
		var accounts = this.props.accounts;
		var ipRangeEntries = [];
		var ipRangeList;
		var ipRangeEdit;

		var iprangeTagTextFunction = function(obj) {
			return obj.application_name + ': ' + obj.ip_range_start.join('.') + ' - ' + obj.ip_range_end.join('.');
		};

		_.each(ipRangesForThisGroup, function(ipRangeObject) {
			_.each(ipRangeObject.range_list, function(ipRangeDetail, idx) {
				ipRangeEntries.push({ id: ipRangeDetail.id,
									application_name: ipRangeObject.application_name,
									application_type_code: ipRangeObject.application_type_code,
									ip_range_start: ipRangeDetail.ip_range_start,
									ip_range_end: ipRangeDetail.ip_range_end });
			});
		});

		ipRangeList = (
			<FormField.TagList	objects={ ipRangeEntries }
								keyField="id"
								tagText={ iprangeTagTextFunction }
								readOnly={ this.props.readOnly }
								removeCallback={ self.removeIPRangeAssignment }
								emptyMessage="No IP Ranges assigned" />
		);

		var accountCountString = accounts.length > 0 ? ('(' + accounts.length + ')') : null;
		var iprangeCountString = ipRangeEntries.length > 0 ? ('(' + ipRangeEntries.length + ')') : null;
		return (
			<Form {...this.props} width="800" height="580" cancelButtonLabel="Done" successMessage={ this.state.formSuccessMessage } errorMessage={ this.state.formErrorMessage }>
				<FormField.Input fieldid="id" width="5" label="Group Id" labelWidth="2" dontModify="true" />
				<FormField.Input fieldid="account_group_name" width="5" label="Group Name" labelWidth="2" dontModify="true" />

				<div className="form-group row">
					<label className="col-form-label col-md-2" style={{ lineHeight: "16px"}}>Activate Accounts<br/>{ accountCountString }</label>

					<div className="form-control-plaintext col-md-10" >
						<div className = "card">
							{ this.accountGroupAssignmentEdit() }
							<div className ="card-body" style={{overflowY: "auto"}}>
								<div className="form-control-plaintext col-md-12" style={{ maxHeight: "120px", marginLeft: -4 }}>
								{ this.renderAccountList() }
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="form-group row" style={{ maxHeight: "120px", overflowY: "auto", width: "95%", marginLeft: -4 }}>
					<div className="row">
						<label className="col-form-label col-md-2" style={{ lineHeight: "16px"}}>IP Ranges<br/>{ iprangeCountString }</label>
						<div className="form-control-plaintext col-md-8" style={{marginLeft: "28px"}}>
						{ ipRangeList }
						</div>
					</div>
				</div>
				<br />
				{ this.renderIPRangeEdit() }
			</Form>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		accounts: state.accountReducer.accounts,
		accountgroups: state.accountReducer.accountgroups,
		applications: state.adminReducer.applications,
		currentForm: state.formStateReducer.currentForm,
		ipranges: state.adminReducer.ipranges,
		accountGroupAccounts:  state.accountReducer.accountGroupAccounts['ID_' + ownProps.object.id],
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, accountActions, adminActions, formStateActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountGroupForm)
