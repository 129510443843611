import * as types from './actionTypes';
import api from './api';

export function loadUnsubscribeGroups() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/email-service/unsubscribegroups',
		}).then(response => {
			dispatch({type: types.GET_UNSUBSCRIBEGROUPS_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadUnsubscribeGroupAccounts(unsubscribe_group_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/email-service/unsubscribegroups/${unsubscribe_group_id}/accounts`
		}).then(response => {
			dispatch({type: types.GET_UNSUBSCRIBEGROUP_ACCOUNTS_SUCCESS, payload: response, id: unsubscribe_group_id});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addUnsubscribeGroupAccount(unsubscribe_group_id, account_id) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: `/email-service/unsubscribegroups/${unsubscribe_group_id}/accounts/${account_id}`
		}).then(response => {
			dispatch({type: types.ADD_UNSUBSCRIBEGROUP_ACCOUNT, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function removeUnsubscribeGroupAccount(unsubscribe_group_id, account_id) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/email-service/unsubscribegroups/${unsubscribe_group_id}/accounts/${account_id}`
		}).then(response => {
			dispatch({type: types.REMOVE_UNSUBSCRIBEGROUP_ACCOUNT, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addUnsubscribeGroup(unsubscribe_group_name) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: '/email-service/unsubscribegroups',
			data: {
				group_name: unsubscribe_group_name
			}
		}).then(response => {
			dispatch({type: types.ADD_UNSUBSCRIBEGROUP, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function updateUnsubscribeGroup(unsubscribe_group_id, unsubscribe_group_name) {
	return function(dispatch) {
		return api({
			method: 'put',
			url: `/email-service/unsubscribegroups/${unsubscribe_group_id}`,
			data: {
				group_name: unsubscribe_group_name
			}
		}).then(response => {
			dispatch({type: types.UPDATE_UNSUBSCRIBEGROUP, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function deleteUnsubscribeGroup(unsubscribe_group_id) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/email-service/unsubscribegroups/${unsubscribe_group_id}`
		}).then(response => {
			dispatch({type: types.DELETE_UNSUBSCRIBEGROUP, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}
