import React, {useCallback} from 'react';
import {useDispatch, shallowEqual, useSelector} from 'react-redux';
import Form from '../../common/Form';
import * as DashboardActions from '../../../actions/dashboardActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import NewDashboardRow from './NewDashboardRow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import * as AccountActions from '../../../actions/accountActions';
import _ from 'lodash'
import { lighten, makeStyles } from '@material-ui/core/styles';
import FormField from '../../common/FormField';

const useStyles = makeStyles(theme => ({
    muTable: {
        tableBoarded: {
            minWidth: 750,
        },
        '& td': {
            color: '#202020',
            fontWeight: 'normal',
            fontSize: 14,
            lineHeight: 1.428
        },
        '& th': {
            color: '#ffffff',
            fontWeight: 'normal',
            fontSize: 14,
            lineHeight: 1.428,
            verticalAlign: 'middle'
        }
    },
    addAccount: {
        color: '#75390F',
        backgroundColor: "transparent",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "transparent"
        }
    }
}));

function NameDescription ({name, onNameChanged, description, onDescriptionChanged}) {
    return (
        <div width="100%">
            <FormField.Input  fieldid="name" width="5" label="Name" labelWidth="2" required={true}
                             fieldchangecallback={onNameChanged}
                             fieldvalue={name} help=""/>
            <FormField.Input fieldid="description" width="5" label="Description" labelWidth="2" required= {false}
                             fieldvalue={description}
                             fieldchangecallback={onDescriptionChanged}
                             help=""/>
        </div>
    )
}

export default function NewDashboard(props) {
    const classes = useStyles();
    const {object, cancelCallback} = props;
    const [errorMessage, setErrorMessage] = React.useState();
    const [successMessage, setSuccessMessage] = React.useState();
    const divProps = Object.assign({}, props);
    const [name, setName] = React.useState(object.engagement_dashboard_name);
    const [description, setDescription] = React.useState(object.description);
    
    // global states stored in Redux
    const {accounts, dashboardsDetails, newDashboard} = useSelector(state => ({
        accounts: state.accountReducer.accounts,
        dashboardsDetails: state.dashboardReducer.dashboardsDetails,
        newDashboard: state.dashboardReducer.newDashboard
    }), shallowEqual);

    // local state that holds the Dashboard structure changes
    const [dashboardList, setDashboardList] = React.useState();
    const dispatch = useDispatch();
    const sortedAccounts = accounts.sort((a, b) =>a.account_name.localeCompare(b.account_name));

    React.useEffect(() => {
        dispatch(DashboardActions.loadDashboardDetails(object.engagement_dashboard_id));
        dispatch(AccountActions.loadAccounts());
    }, []);

    const clearMessages = () => {
        setErrorMessage(null); //clear error message
        setSuccessMessage(null); //clear error message
    }

    const handleAccountChange = (oldAccount, newAccount, newSelect) => {
        clearMessages();

        if (oldAccount !== 0)
            removeAccountFilter(oldAccount.value);

        addAccountFilterObject(false, newAccount)
    };

    const getDashboardState = () => {
        if (dashboardList)
            return {...dashboardList};
        else if (dashboardsDetails)
            return {...dashboardsDetails};
        else
            return {};
    }

    const handleProgramChange = (accountId, newSelectedItems, evt) => {
        clearMessages(); //clear error message
        let newStateObject = getDashboardState();
        let modifiedAccount = _.find(newStateObject.accountBasedFilters, {account_id: accountId});
        if (modifiedAccount)
            modifiedAccount.account_based_filters = newSelectedItems;

        setDashboardList(newStateObject);
    };

    const addAccountFilter = () => {
        clearMessages(); //clear error message
        addAccountFilterObject(true,0)
    };

    const addAccountFilterObject = (isNew, account) => {
        let newStateObject = getDashboardState();
        let cnt = _.countBy(newStateObject.accountBasedFilters,(item)=>{
            return item.account_id === 0
        });

        if (cnt.true > 0) {
            setErrorMessage(`Please complete the current Account filter setup first.`);
            return;
        }
        if (isNew) {
            newStateObject.accountBasedFilters.push({
                is_new: true,
                account_id: account,
                disabled: false,
                account_name: 'Please select an account',
                account_based_filters: []
            });
        } else {
            newStateObject.accountBasedFilters.push({
                is_new: true,
                account_id: account.value,
                disabled: false,
                account_name: account.label,
                account_based_filters: []
            });
        }

        //update available list
        let availableAccounts = _.filter(accounts, (Item) => {
            return _.findIndex(newStateObject.accountBasedFilters, {account_id: Item.account_id}) === -1;
        });
        dispatch(AccountActions.setDashboardAvailableAccounts(availableAccounts));
        setDashboardList(newStateObject);
    };

    const removeAccountFilter = (accountId) => {
        clearMessages(); //clear error message
        let modifiedList = getDashboardState();
        const toBeRemoved = _.find(modifiedList.accountBasedFilters, {
            account_id: accountId
        });

        _.remove(modifiedList.accountBasedFilters, {
            account_id: accountId
        });
        setDashboardList(modifiedList);

        //update available list
        let availableAccounts = [...accounts, toBeRemoved];
        dispatch(AccountActions.setDashboardAvailableAccounts(availableAccounts));
    };

    const onNameChanged = (evt, object) => {
        if (!object)
            return;
        clearMessages(); //clear error message
        setName(object.name);
    };

    const onDescriptionChanged = (evt, object) => {
        if (!object)
            return;
        clearMessages(); //clear error message
        setDescription(object.description);
    };

    const saveDashboard = () => {

        let savedDashboard = getDashboardState();
        clearMessages(); //clear error message
        if (name == null || name.trim().length ==0) {
            setErrorMessage("Dashboard name cannot be blank");
            return;
        }

        if (savedDashboard.accountBasedFilters.length == 0) {
            setErrorMessage(`Must select at least one account for filter.`);
            return;
        }

        let foundError = false;
        _.forEach(savedDashboard.accountBasedFilters, (item )=> {
            if (item.account_based_filters.length == 0) {
                setErrorMessage(`Must select at least one program for filter - ${item.account_name} `);
                foundError = true
                return false;
            }
        })

        if (!foundError) {
            let dashboardId =  !object.engagement_dashboard_id? 0: object.engagement_dashboard_id
            if (newDashboard) {
                dashboardId = newDashboard.engagement_dashboard_id;
            }

            dispatch(DashboardActions.saveDashboard(dashboardId, name, description, JSON.stringify(savedDashboard)))
                .then(data => {
                    setSuccessMessage("The dashboard has been saved successfully");
                })
                .catch(err => {
                    setErrorMessage(`Failed to save the dashboard - ${err.message} `);
                });
        }
    }

    const localCancelCallback = () => {
        dispatch(DashboardActions.loadDashboards());
        dispatch(DashboardActions.resetDashboardId());
        cancelCallback();
    }

    return (
        <Form {...divProps} width="90%" height="580" readOnly={false}
              successMessage={successMessage}
              errorMessage={errorMessage}
              saveCallback={saveDashboard}
              cancelCallback={localCancelCallback}
        >
            <NameDescription
                name = {name}
                onNameChanged = {onNameChanged}
                description = {description}
                onDescriptionChanged = {onDescriptionChanged}
            />
            <br/>
            <TableContainer style={{overflow: 'visible'}}>
                <Table
                    className={`${classes.muTable} table table-striped table-sm table-hover table-bordered sl-datatable`}
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
                    style={{overflow: 'visible'}}
                >
                    <TableHead key={1}>
                        <TableRow key={1}>
                            <TableCell style={{width: '40%'}}>Accounts</TableCell>
                            <TableCell>Programs</TableCell>
                            <TableCell  align="center" style={{width: '10%', verticalAlign: 'top' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{overflow: 'visible'}}>
                        {dashboardsDetails.accountBasedFilters.map((row, index) => {
                            return (
                                <NewDashboardRow key={`${row.account_id}-${new Date().getTime()}`}
                                                 filters={row}
                                                 initAccounts={sortedAccounts}
                                                 disabledAccountSelect={row.disabled == null ? true : row.disabled}
                                                 initAccountObject={{
                                                     value: row.account_id,
                                                     label: (row.account_id === 0 || row.is_new) ? row.account_name : `${row.account_name} (${row.account_id})`,
                                                     intervention_mode_code: row.intervention_mode_code
                                                 }}
                                                 initProgramObjects={row.account_based_filters}
                                                 handleAccountChange={handleAccountChange}
                                                 handleProgramChange={handleProgramChange}
                                                 removeAccountFilter={removeAccountFilter}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid
                container
                item={true}
                xs={12}
                direction="row"
                justify="flex-start"
            >
                <IconButton className={classes.addAccount} onClick={addAccountFilter} color="primary" aria-label="Add an account filter">
                    <Add/> Add another account
                </IconButton>
            </Grid>
        </Form>
    )
}
