import * as types from './actionTypes';
import api from './api';
import _ from "lodash";


const convertToAccountBasedFilter = function (records) {
	let newObject = records;
	if (!newObject || _.isEmpty(newObject))
		return {accountBasedFilters: []};

	let accountBasedFilters = {};
	if (newObject.dashboard_filters == null) {
		return {accountBasedFilters: []};
	}
	let dashboardFilters = JSON.parse(newObject.dashboard_filters);
	accountBasedFilters = [];
	dashboardFilters.forEach(function (obj) {
		let elem = _.find(accountBasedFilters, {account_id: obj.account_id});
		if (!elem) {
			elem = {
				engagement_dashboard_id: obj.engagement_dashboard_id,
				account_id: obj.account_id,
				account_name: obj.account_name,
				account_based_filters: [obj]
			};
			accountBasedFilters.push(elem);
		} else {
			elem.account_based_filters.push(obj);
		}
	});

	return {accountBasedFilters: accountBasedFilters};
};


export function loadDashboards() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/dashboard-service/dashboards',
		}).then(response => {
			dispatch({type: types.GET_DASHBOARD_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadDashboardDetails(dashboard_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/dashboard-service/dashboards/${dashboard_id}`
		}).then(response => {
			dispatch({type: types.GET_DASHBOARD_DETAILS_SUCCESS, payload: convertToAccountBasedFilter(response[0])});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadDashboardMetrics(dashboard_id, type) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/dashboard-service/dashboards/${dashboard_id}/metrics/${type}`
		}).then(response => {
			dispatch({type: types.GET_DASHBOARD_METRICS_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadDashboardPrograms(account_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/dashboard-service/dashboards/accounts/${account_id}/programs`
		}).then(response => {
			dispatch({type: types.GET_DASHBOARD_ACCOUNT_PROGRAMS_SUCCESS, payload: {account_id: account_id, programs: response}});
		}).catch(error => {
			throw(error);
		});
	};
}

export function cloneDashboard(dashboard_id){
	return function(dispatch){
		return api({
			method: 'post',
			url: `/dashboard-service/dashboards/${dashboard_id}/clone`
		}).then(response => {
			dispatch({type: types.CLONE_DASHBOARD_SUCCESS, payload: response});
		}).catch(err =>{
			throw(err);
		})
	}
}

export function saveDashboard(dashboard_id, name, description, filters) {
	return function(dispatch) {
		if (!dashboard_id)
			dashboard_id = 0;

		let payload = {
			name: name,
			description: description,
			filters: filters
		}

		if (!description)
			delete payload.description;

		return api({
			method: 'post',
			url: `/dashboard-service/dashboards/${dashboard_id}`,
			data: payload
		}).then(response => {
			dispatch({type: types.SAVE_DASHBOARD_SUCCESS, payload: response[0]});
		}).catch(error => {
			throw(error);
		});
	};
}

export function resetDashboardId() {
	return function(dispatch) {
		dispatch({type: types.SAVE_DASHBOARD_SUCCESS, payload: null});
	};
}

export function deleteDashboard(dashboard_id) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/dashboard-service/dashboards/${dashboard_id}/delete`
		}).then(response => {
			dispatch({type: types.DELETE_DASHBOARD_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}
