import React, {Component} from 'react';
import NavBar from './NavBar';

function PasswordFooter (innerDiv, appName) {
    return (
        <div>
            <div id="detail" className="full-height">
                <NavBar noURL="true" fullbrand = {appName}/>
                {innerDiv}
                <br style={{clear: "both"}}/>
                <div className="sl-footer">
                    <div style={{ float: "left", margin: "24px 16px 0 24px", fontSize: 12 }}>
                        Personify Health., All Rights Reserved<br/>
                        <a target="_blank" href="http://www.welltok.com">www.personifyhealth.com</a><br/>
                        <a target="_blank" href="http://www.silverlink.com/privacypolicy.html">Privacy Policy</a> | &nbsp;
                        <a target="_blank" href="http://www.silverlink.com/termsofuse.html">Terms of Use</a> | &nbsp;
                        <a target="_blank" href="http://www.silverlink.com/hipaa.html">HIPAA Compliance</a>
                    </div>
                    <div style={{ float: "right", margin: "8px 8px 0 16px" }}>
                        <img src="images/header_logo_personifyhealth.png" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordFooter;
