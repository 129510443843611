import * as types from './actionTypes';
import api from './api';

export function loadEmailDomains() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/email-service/emaildomains',
		}).then(response => {
			dispatch({type: types.GET_EMAILDOMAINS_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadAvailableEmailDomains() {
	return function(dispatch) {
		return api({
			method: 'get',
			url: '/email-service/emailvendors/availableemaildomains'
		}).then(response => {
			dispatch({type: types.GET_AVAILABLE_EMAILDOMAIN_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function loadEmailDomainAccounts(email_domain_id) {
	return function(dispatch) {
		return api({
			method: 'get',
			url: `/email-service/emaildomains/${email_domain_id}/accounts`
		}).then(response => {
			dispatch({type: types.GET_EMAILDOMAIN_ACCOUNTS_SUCCESS, payload: response, id: email_domain_id});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addEmailDomainAccount(email_domain_id, account_id) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: `/email-service/emaildomains/${email_domain_id}/accounts/${account_id}`
		}).then(response => {
			dispatch({type: types.ADD_EMAILDOMAIN_ACCOUNT, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function removeEmailDomainAccount(email_domain_id, account_id) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/email-service/emaildomains/${email_domain_id}/accounts/${account_id}`
		}).then(response => {
			dispatch({type: types.REMOVE_EMAILDOMAIN_ACCOUNT, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function addEmailDomain(email_domain_url) {
	return function(dispatch) {
		return api({
			method: 'post',
			url: '/email-service/emaildomains',
			data: {
				email_domain_url: email_domain_url
			}
		}).then(response => {
			dispatch({type: types.ADD_EMAILDOMAIN, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function updateEmailDomain(email_domain_id, email_domain_url) {
	return function(dispatch) {
		return api({
			method: 'put',
			url: `/email-service/emaildomains/${email_domain_id}`,
			data: {
				email_domain_url: email_domain_url
			}
		}).then(response => {
			dispatch({type: types.UPDATE_EMAILDOMAIN, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}

export function deleteEmailDomain(email_domain) {
	return function(dispatch) {
		return api({
			method: 'delete',
			url: `/email-service/emaildomains/${email_domain.id}`
		}).then(response => {
			dispatch({type: types.DELETE_EMAILDOMAIN, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}
