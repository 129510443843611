import React, {Component} from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Link from '@material-ui/icons/Link';
import Select  from "react-select";
import { shallowEqual, useSelector , useDispatch} from "react-redux";
import * as DashboardActions from '../../../actions/dashboardActions';
import { lighten, makeStyles } from '@material-ui/core/styles';
import PlaylistAddCheckTwoTone  from '@material-ui/icons/PlaylistAddCheckTwoTone';
import Button from '@material-ui/core/Button';
import _ from "lodash";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    ActionsCell: {
        align: 'center'
    },
    NoHoverIcon: {
        color: '#75390F',
        float: 'none',
        backgroundColor: "transparent",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "transparent"
        }
    }
}));


//----------------Account Helper Functions-------------------------
const createAccountDropDownItem = function (row) {
    if (row.account_id == 0 || row.is_new)
        return {value: row.account_id, label: `${row.account_name}`}

    return {value: row.account_id, label: `${row.account_name} (${row.account_id})`}
};

const createAccountsDropdownData = function(data) {
    return data.map((row, index) => {
        return createAccountDropDownItem(row);
    })
};

//-----------------Program Helper Functions---------------------
const createProgramDropDownItem = function (row) {
    return {value: row.program_id,
            label: `${row.program_name} (${row.program_type}:${row.program_id})`,
            program_id: row.program_id,
            program_type: row.program_type,
            program_name: row.program_name,
            engagement_dashboard_filter_id: !row.engagement_dashboard_filter_id ? 0 : row.engagement_dashboard_filter_id}
};
const getAllProgramsDropdownData = function(accountId, data) {
    if (!data || !data[accountId])
        return;

    return data[accountId].map((row, index) => {
        return createProgramDropDownItem(row);
    })
};

const getDefaultProgramsDropdownData = function(data) {
    if (!data)
        return;

    return data.map((row, index) => {
        return createProgramDropDownItem(row);
    })
};


export default  function NewDashboardRow(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { initAccountObject,
            initProgramObjects,
            handleAccountChange,
            handleProgramChange,
            removeAccountFilter,
            disabledAccountSelect,
            initAccounts} = props;
    const [accounts, setAccounts] = React.useState(initAccounts);
    const [selectedAccountObj, setSelectedAccountObj] = React.useState(initAccountObject);
    const [selectedProgramObjects, setSelectedProgramObjects] = React.useState(initProgramObjects);

    // map state to local properties
    const {programs } = useSelector(state => ({
        programs: state.dashboardReducer.dashboardAccountPrograms
    }), shallowEqual);

    const handleLocalProgramChange = (newSelectedItems, evt: string[]) => {
        setSelectedProgramObjects(newSelectedItems);
        //notify parent container about change
        handleProgramChange(selectedAccountObj.value,newSelectedItems, evt);
    };

    const handleLocalAccountChange = (newSelectObj, evt) => {
        const oldAccountObj = {...selectedAccountObj};
        const newAccoutnId = newSelectObj.value;

        //load programs for the selected account
        dispatch(DashboardActions.loadDashboardPrograms(newAccoutnId)).then((response)=>{
            setSelectedAccountObj(newSelectObj);
        });

        //notify parent container about change
        handleAccountChange(oldAccountObj, newSelectObj);
    };

    const removeLocalAccountFilter = (removedObj, evt)=> {
        removeAccountFilter(selectedAccountObj.value)
    }

    const handleAddAllPrograms = function (evt) {
        if (!programs || !programs[selectedAccountObj.value])
            return;

        const allItems = programs[selectedAccountObj.value].map((row, index) => {
            return createProgramDropDownItem(row);
        });
        //notify parent container about change
        handleProgramChange(selectedAccountObj.value, allItems, evt);
    };

    // called only once to load initial data via API
    React.useEffect(() => {
        if (selectedAccountObj.value) {
            // the data used to populate available program list
            dispatch(DashboardActions.loadDashboardPrograms(selectedAccountObj.value));
        }
    }, []);

    const disabled = (programs !== null && programs !== undefined) && (programs[selectedAccountObj.value] !== null && programs[selectedAccountObj.value] !== undefined) && (selectedProgramObjects !== null) && (programs[selectedAccountObj.value].length === selectedProgramObjects.length);
    return (
        <TableRow
            hover
            role="checkbox"
            tabIndex={100}
            key={selectedAccountObj.value}
            style={{overflow:'visible'}}
        >
            <TableCell key={selectedAccountObj.value} style={{width: '40%',overflow: 'visible'}}>
                <Select
                    key={`account-${selectedAccountObj.value}`}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={disabledAccountSelect}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    name="account"
                    isMulti={false}
                    value = {selectedAccountObj}
                    options={createAccountsDropdownData(accounts)}
                    onChange ={handleLocalAccountChange}
                />
            </TableCell>
            <TableCell style={{overflow:'visible', width: '50%'}}>
                <Select
                    key={`programs-${selectedAccountObj.value}`}
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={getDefaultProgramsDropdownData( selectedProgramObjects)}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    name="programs"
                    isMulti={true}
                    isClearable = {true}
                    options={getAllProgramsDropdownData(selectedAccountObj.value, programs)}
                    onChange ={handleLocalProgramChange}
                />
                <IconButton className={classes.NoHoverIcon} disabled ={disabled} onClick={handleAddAllPrograms}  aria-label="add">
                    <PlaylistAddCheckTwoTone  fontSize="small" />
                    Add all
                </IconButton>
            </TableCell>
            <TableCell align="left" style={{textAlign:"center", width: '120px', verticalAlign: 'middle' }}>
                <button className={'sl-tablebutton'} onClick={removeLocalAccountFilter}   style={{float: 'none',textAlign:"center", width: '24px', minWidth: '24px'}}>
                    <i className="fa fa-trash"></i>
                </button>
            </TableCell>
        </TableRow>
    );
};
