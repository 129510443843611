import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {lighten, makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import _ from "lodash";
import ReactSelect from "react-select";
import {numberWithCommas} from './Utils'

/*
Two levels of Table Headers setup Example:

const headCells = [
    {id: 'name', align: "left", disablePadding: true, label: 'Dessert (100g serving)', width: "100px", rowSpan: 2},
    {id: null,  align: "center", disablePadding: true, label: 'Top Level', width: "200px", colSpan: 3, groupNumber: 1},
    {id: 'protein',  align: "right", disablePadding: false, label: 'Protein (g)', width: "100px", rowSpan: 2},
];

const headCells2 = [
    {id: 'calories',  align: "right", disablePadding: false, label: 'Calories', width: "100px", groupNumber: 1},
    {id: 'fat',  align: "right", disablePadding: false, label: 'Fat (g)', width: "100px", groupNumber: 1},
    {id: 'carbs',  align: "right", disablePadding: false, label: 'Carbs (g)', width: "100px", groupNumber: 1}
];

 */


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    muTable: {
        tableBoarded: {
            minWidth: 750,
        },
        '& td': {
            color: '#202020',
            fontWeight: 'normal',
            fontSize: 14,
            lineHeight: 1.428,
            fontFamily: "Arial, sans-serif"
        },
        '& th': {
            color: '#ffffff',
            fontWeight: 'normal',
            fontSize: 14,
            lineHeight: 1.428,
            fontFamily: "Arial, sans-serif",
            verticalAlign: 'middle'
        }
    },
    paginationUL: {
        marginTop: 0,
        '& button': {
            minWidth: 24,
            width: '27px',
            padding: 1,
            marginLeft: 0,
            fontSize: 14,
            lineHeight: 25,
            height: 27
        },
        '& td': {
            fontSize: 14
        }
    },
    paper: {
        marginBottom: theme.spacing(0),
    }
}));

function getDataRowsColumns(headCells, headCells2) {
    let newArray2 = [];
    headCells.forEach((hdr) => {
        if (hdr.id == null && headCells2) {
            let groupNum = hdr.groupNumber;
            headCells2.forEach(function (el) {
                if (el.groupNumber = groupNum)
                    newArray2.push(el);
            });
        } else {
            newArray2.push(hdr)
        }
    });
    return newArray2;
}

function descendingComparator(a, b, orderBy) {
    if (isNaN(b[orderBy]) || isNaN(a[orderBy])) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    } else {
        let an = parseFloat(a[orderBy], 10);
        let bn = parseFloat(b[orderBy], 10);
        if (bn < an)
            return -1;

        if (bn > an)
            return 1

        return 0;
    }
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    let sorted = stabilizedThis.map(el => el[0]);
    return sorted;
}

function createTableHead(props, headCells, headKey) {
    const {classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableRow key={headKey}>
            {headCells.map(headCell => (
                <TableCell
                    className={ orderBy === headCell.id && headCell.showSortIcon !== false ? (order === 'asc' ? "headerSortDown" : "headerSortUp") : ""}
                    key={headCell.id}
                    align={headCell.align ? headCell.align : "left"}
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCell.id ? order : false}
                    colSpan={headCell.colSpan ? headCell.colSpan : 1}
                    rowSpan={headCell.rowSpan ? headCell.rowSpan : 1}
                    style={{width: headCell.width, verticalAlign: 'middle'}}
                    onClick={createSortHandler(headCell.id)}
                >
                    {headCell.label}
                </TableCell>
            ))}
        </TableRow>

    );
}

function EnhancedTableHead(props) {
    const {classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, headCells2} = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    let headersConfig = headCells2 ? [headCells, headCells2] : [headCells];
    return (
        <TableHead key={1}>
            {headersConfig.map((head, index) => (createTableHead(props, head, index)))}
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    headCells: PropTypes.array.isRequired,
    headCells2: PropTypes.array
};

function EnhancedCell(props) {
    const {classes, hdr, row} = props;
    if (!hdr.id)
        return null;

    let cellContent = (hdr.type ==='number') ? numberWithCommas(row[hdr.id]) : row[hdr.id];

    let toolTip = row[hdr.tooltip];
    if (hdr.showAsTrafficLight) {
        toolTip = row[hdr.id];
        const foundColor = _.find(hdr.showAsTrafficLight, {value: row[hdr.id]});
        if (foundColor)
            cellContent = <i className="fas fa-circle" style={{fontSize: '12px', color: foundColor.color}}/>
    }
    return (<TableCell key={hdr.id} align={hdr.align} title={toolTip}>{cellContent}{hdr.showAfterValue}</TableCell>)
}

EnhancedCell.propTypes = {
    classes: PropTypes.object.isRequired,
    hdr: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired
};

function MyPagination(props) {
    const {
        classes, rowNumPages, currentPageState, rowsPerPage, location,
        previousPageState,
        handleCustomChangeRowsPerPage, changePage, changePageInput
    } = props;

    const changePageLocal = (e, button, numPages) => {
        var newPage;
        var currentPage = currentPageState;
        var previousPage = previousPageState;
        // 1 = first page, 2 = previous page, 3 = next page, 4 = final page
        if (button === 1) {
            newPage = 1;
        } else if (button === 2) {
            if (currentPage === 0) {
                if (previousPage === 1) {
                    newPage = previousPage;
                } else {
                    newPage = previousPage - 1;
                }
            } else {
                if (currentPage === 1) {
                    newPage = currentPage;
                } else {
                    newPage = currentPage - 1;
                }
            }
        } else if (button === 3) {
            if (currentPage === 0) {
                if (previousPage === numPages) {
                    newPage = previousPage;
                } else {
                    newPage = previousPage + 1;
                }
            } else {
                if (currentPage === numPages) {
                    newPage = currentPage;
                } else {
                    newPage = currentPage + 1;
                }
            }
        } else if (button === 4) {
            newPage = numPages;
        }

        changePage(currentPage, newPage)
    }

    const visibility = {'display': (rowNumPages > 1) ? 'block' : 'none'};
    const inputStyle = {
        marginLeft: '7px',
        'textAlign': 'center',
        'width': '32px',
        fontSize: '13px',
        'height': '25px',
        'color': (currentPageState === 0) ? 'FFFFFF' : '070707',
        'backgroundColor': 'FFFFFF'
    };
    const ROWS_PER_PAGE = [
        {value: 5, label: 5},
        {value: 10, label: 10},
        {value: 25, label: 25},
        {value: 50, label: 50}
    ]
    const pageButtons =
             <table className={`${classes.paginationUL}`} width="100%">
                 <tr>
                     <td width= '95%'>
                     </td>
                     <td>
                        <button style={visibility} key="btn-previous" title="Previous" className="pagination-button"
                                onClick={((e) => changePageLocal(e, 1, rowNumPages))}>
                            <i className={"fa fa-angle-double-left"}/>
                        </button>
                     </td>
                     <td>
                        <button style={visibility} key="btn-previous" title="Previous" className="pagination-button"
                                onClick={((e) => changePageLocal(e, 2, rowNumPages))}>
                            <i className={"fa fa-angle-left"}/>
                        </button>
                     </td>
                     <td>
                         <div style={visibility}>
                            <input style={inputStyle} type="integer" value={currentPageState}
                               onChange={((e) => changePageInput(e, rowNumPages))}
                            />
                         </div>
                     </td>
                     <td>
                         <div style={visibility}>
                             <div style={{width: '40px', verticalAlign: 'middle', paddingTop:'3px', fontSize: '15px'}}>
                                <a className="corrected-margins"> / {rowNumPages}</a>
                             </div>
                         </div>
                     </td>
                     <td>
                        <button style={visibility}  key="btn-next" title="Next" className="pagination-button"
                                onClick={((e) => changePageLocal(e, 3, rowNumPages))}>
                            <i className={"fa fa-angle-right"}/>
                        </button>
                     </td>
                     <td>
                        <button style={visibility} key="btn-last" title="Last" className="pagination-button"
                                onClick={((e) => changePageLocal(e, 4, rowNumPages))}>
                            <i className={"fa fa-angle-double-right"}/>
                        </button>
                     </td>
                     <td>
                         <div style={{fontFamily: 'Arial', textAlign: 'right', width: '120px' ,paddingTop: 2}}>
                             Rows Per Page:
                         </div>
                     </td>
                     <td>
                         <RowsPerPageSelector height={25} options={ROWS_PER_PAGE}
                                              selectedRowsPerPage={{value: rowsPerPage, label: rowsPerPage}}
                                              onChange={handleCustomChangeRowsPerPage}/>
                     </td>
                 </tr>
            </table>

    if (location === 'top')
        //not tested
        return <div style={{
            'marginTop': '-36px',
            'marginLeft': '366px',
            'height': '24px',
            'width': '183px',
            'marginBottom': '12px'
        }}>{pageButtons}</div>;
    else
        return (<div style={{paddingBottom: '10px'}}>{pageButtons}</div>);
}

function RowsPerPageSelector(props) {
    const {height, options, onChange, selectedRowsPerPage} = props;
    const targetHeight = height || 25;
    const customSelect2Styles = {
        control: base => ({
            ...base,
            width: 57,
            minHeight: '22px',
            marginRight: 5,
            fontFamily: "Arial",
            height: `${targetHeight + 2}px`,
            lineHeight: `${(targetHeight - 20 - 1 - 1) / 2}px`,
            fontSize: '10px'
        }),
        menu: base => ({
            ...base,
            fontFamily: "Arial",
            lineHeight: `${(targetHeight - 20 - 1 - 1) / 2}px`,
            fontSize: '10px'
        }),
        valueContainer: base => ({
            ...base,
            height: `${targetHeight + 2}px`,
            padding: '0 8px',
        }),
        clearIndicator: base => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
        indicatorSeparator: base => ({
            ...base,
            marginTop: 0,
            marginBottom: 0
        })
    };

    return (
        <ReactSelect
            key={`importsTypes`}
            className="basic-single"
            classNamePrefix="select"
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            name="importsTypes"
            isMulti={false}
            value={selectedRowsPerPage}
            options={options}
            onChange={onChange}
            styles={customSelect2Styles}
        />
    )
}

export default function MuTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [previousPageState, setPreviousPageState] = React.useState(0);
    const [currentPageState, setCurrentPageState] = React.useState(1);
    const {headCells, headCells2, rows} = props;
    let firstProp = 'name';

    let apiArray = Object.values(rows);
    let rowPages = _.chunk(apiArray, rowsPerPage);
    let rowNumPages = rowPages.length;

    for (var key in headCells) {
        if (headCells.hasOwnProperty(key)) {
            firstProp = headCells[key].id;
            break;
        }
    }
    const [orderBy, setOrderBy] = React.useState(firstProp);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleCustomChangeRowsPerPage = event => {
        const rowsCount = (event.value == 'All') ? rows.length : event.value;
        setRowsPerPage(parseInt(rowsCount, 10));
        setPreviousPageState(1);
        setCurrentPageState(1);
    };

    const changePage = (prevPage, newPage) => {
        setPreviousPageState(prevPage);
        setCurrentPageState(newPage);
    }

    const changePageInput = (event, numPages) => {
        var currentPage = parseInt(currentPageState, 10);
        if (currentPage !== 0) {
            setPreviousPageState(currentPage);
        }

        var nextPage;
        if (!isNaN(parseInt(event.target.value, 10))) {
            nextPage = parseInt(event.target.value, 10);
        } else if (isNaN(parseInt(event.target.value))) {
            nextPage = 0;
        }
        if (nextPage <= numPages) {
            setCurrentPageState(nextPage);
        }
    }

    const isSelected = name => selected.indexOf(name) !== -1;
    let emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - (currentPageState - 1) * rowsPerPage);
    emptyRows = (rows.length < rowsPerPage) ? 0 : emptyRows;
    const headerDataColumns = getDataRowsColumns(headCells, headCells2);

    return (
        <div className={classes.root}>
            <Paper variant="outlined" square>
                    <Table
                        className={`${classes.muTable}  table table-striped table-sm table-hover table-bordered sl-datatable `}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                        style={{width: '100%'}}
                    >
                        <EnhancedTableHead
                            headCells={headCells}
                            headCells2={headCells2}
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice((currentPageState - 1) * rowsPerPage, (currentPageState - 1) * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}
                                        >
                                            {headerDataColumns.map((hdr, index) => {
                                                return <EnhancedCell classes={classes} hdr={hdr} row={row}/>
                                            })}

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: 28 * emptyRows}}>
                                    <TableCell colSpan={headerDataColumns.length}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                    <MyPagination classes={classes}
                                  rowNumPages={rowNumPages}
                                  currentPageState={currentPageState}
                                  previousPageState={previousPageState}
                                  rowsPerPage={rowsPerPage}
                                  handleCustomChangeRowsPerPage={handleCustomChangeRowsPerPage}
                                  changePage={changePage}
                                  changePageInput={changePageInput}
                    />
            </Paper>
        </div>
    );
}

MuTable.propTypes = {
    headCells: PropTypes.array.isRequired,
    headCells2: PropTypes.array
};

