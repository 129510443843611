import * as types from '../actions/actionTypes';
/*
 * All reducers get two parameters passed in, state and action that occurred
 *   > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

var initialState = {
	unsubscribegroups: [],
	accountUnsubscribegroups: {}
};

export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_UNSUBSCRIBEGROUPS_SUCCESS:
			//console.log('reducer to load all unsubscribe group data', action);
			return Object.assign({}, state, {unsubscribegroups: action.payload});
		case types.GET_UNSUBSCRIBEGROUP_ACCOUNTS_SUCCESS:
			//console.log('reducer to load unsubscribe group account data', action);
			/**************************
				This data lets you assign unsubscribe groups to an account.
				This data populates the from domain drop-down in the activate email builder UI.
			**************************/

			/**************************
				We're storing unsubscribe groups in a hash/associative array.
				The key is the unsubscribe group ID.
				The value is the list of unsubscribe groups.
				This way, each incoming call doesn't overwrite data from previous calls.
			**************************/

			// FIREFOX BUG WORKAROUND
			// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
			// It doesn't like the number 1 as a computed key in an object, even once it's stringified
			let unsubscribeGroupID = action.id;
			return Object.assign({}, state, {accountUnsubscribegroups: { ['ID_' + unsubscribeGroupID]: action.payload}});
	}
	return state;
}
