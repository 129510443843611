import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux';
import * as formStateActions  from '../../../actions/formStateActions';
import * as adminActions  from '../../../actions/adminActions'
import ContentWrap from '../../common/ContentWrap';
import TableSearch from '../../common/TableSearch';
import DataTable  from '../../common/DataTable';
import _ from 'lodash';

class Roles extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filterText: '',
			initialSortKey: 'role_type_code',
			inserting: false,
			errorMessage: '',
			currentPage: 1,
			rowsPerPage: 50
		};
		this.handleUserInput = this.handleUserInput.bind(this);
	}

	getColumns() {
		return {
			role_type_code:	{ name: "Role Type Code", used: 1, width: 250},
			role_name:		{ name: "Role Name", used: 1, width: 250}
		};
	}

	handleUserInput(filterText) {
		this.setState({
			filterText: filterText
		});
	}

	componentDidMount() {
		this.props.actions.loadRoles(this.state.currentPage).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the roles: " + msg);
		});
	}

	setFormErrorMessage(errorMessage) {
		this.setState({
			formError: errorMessage
		});
	}

	componentWillUnmount() {

	}

	_onChange() {
		if (this.isMounted()) {
			this.forceUpdate();
		}
	}

	render() {
		var roles = this.props.roles;
		return (
			<ContentWrap title="Roles" errorMessage={this.state.errorMessage}>
				<div className="search">
					<TableSearch
						onUserInput={this.handleUserInput}
						filterText={this.state.filterText}
				 	></TableSearch>
				</div>
				<div className="clearfix"></div>
				<DataTable
					id="role_id"
					columns={this.getColumns()}
					dataItems={ roles }
					rowsPerPage={this.state.rowsPerPage}
					filterText={this.state.filterText}
					initialSortKey={this.state.initialSortKey}
				></DataTable>
			</ContentWrap>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		roles: state.adminReducer.roles,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, adminActions, formStateActions), dispatch)
	};

}

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
