import * as types from './actionTypes';
import api from './api';

export function loadLoginAttributes(loginID) {
    return function (dispatch) {
        return api({
            method: 'get',
            url: 'auth/attributes/' + loginID
        }).then(response => {
            dispatch({type: types.GET_LOGIN_ATTRIBUTES_SUCCESS, payload: response});
        }).catch(error => {
            throw(error);
        });
    }
}

export function addLoginAttributes(loginID, attributeID, attributeValue) {
    return function (dispatch) {
        return api({
            method: 'post',
            url: '/auth/attributes/' + loginID,
            data: {attribute_id: attributeID,
                     attribute_value: attributeValue}
        }).then(response => {
            dispatch({type: types.ASSIGN_LOGIN_ATTRIBUTE_SUCCESS, payload: response});
        }).catch(error => {
            throw(error);
        });
    }
}

export function deleteLoginAttributes(loginID, attributeID) {
    return function (dispatch) {
        return api({
            method: 'delete',
            url: 'auth/attributes/' + loginID + '/' + attributeID
        }).then(response => {
            dispatch({type: types.DELETE_LOGIN_ATTRIBUTE_SUCCESS, payload: response});
        })
    }
}

export function loadAttributes() {
    return function (dispatch) {
        return api({
            method: 'get',
            url: 'auth/attributes'
        }).then(response => {
            dispatch({type: types.GET_ATTRIBUTES_SUCCESS, payload: response});
        }).catch(error => {
            throw(error);
        });
    }
}
