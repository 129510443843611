import React, { Component } from 'react';
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
var nextFormId = 1;

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			_formId: nextFormId++,
			modified: false,
			currentObject: this.props.object ? _.clone(this.props.object) : {},
			tokenExpired: false
		};

		this.submitCallback = this.submitCallback.bind(this);
		this.saveCallback = this.saveCallback.bind(this);
		this.fieldchangecallback = this.fieldchangecallback.bind(this);
		this.cancelButtonCallback = this.cancelButtonCallback.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.errorMessage && this.props.errorMessage.includes("The access token provided is expired, revoked, malformed")) {
			setTimeout( function() {
				this.setState({
					tokenExpired: true
				});
			}.bind(this), 3000);
		}
		if (_.isEmpty(prevProps.object) && !_.isEmpty(this.props.object))
		{
			this.setState({
				modified: true,
				currentObject: _.clone(this.props.object)
			});
		}
		else
		{
			if (!_.isEqual(prevProps.object, this.props.object))
			{
				this.setState({
					modified: true,
					currentObject: _.clone(this.props.object)
				});
				if (this.props.parentDataCallback)
					this.props.parentDataCallback(_.clone(this.props.object), 'objectchange');
			}
		}
	}


	submitCallback(evt) {
		if (this.props.submitCallback) {
			this.props.submitCallback(this.state.currentObject);
		}
	}

	saveCallback(evt) {
		if (this.props.saveCallback) {
			this.props.saveCallback(this.state.currentObject);
		}
	}

	fieldchangecallback(evt, objectChanges) {
		this.setState(function (previousState) {
			return {
				modified: true,
				currentObject: _.extend(previousState.currentObject, objectChanges)
			}
		});
		if (this.props.parentDataCallback)
			this.props.parentDataCallback( _.extend(this.state.currentObject, objectChanges), 'fieldchange');
	}

	cancelButtonCallback() {
		if (this.props.cancelCallback) {
			this.props.cancelCallback();
		}

	}

	render() {
		var formid = this.state.formid;
		var cssStyle = { 'width': this.props.width || 500, 'minWidth': 500 };
		var cssHideTitle = { 'display': this.props.hideTitle ? "none" : "block" };
		var hasRequiredField = false;
		var message;
		var redirectBlock

		if (this.state.tokenExpired == true)
			redirectBlock= <Redirect to='/login' />

		if (this.props.errorMessage) {
			message = <div className="alert alert-danger" id="statusMessage">{this.props.errorMessage}</div>;
		} else if (this.props.successMessage) {
			message = <div className="alert alert-success" id="statusMessage">{this.props.successMessage}</div>;
		} else if (this.props.confirmMessage) {
			message = <div className="alert alert-warning" id="statusMessage">{this.props.confirmMessage}</div>;
		} else {
			message = this.props.hideTitle ? '' : <br/>;
		}

		var kids = React.Children.map(this.props.children, function (child, idx) {
			if (!child) return null;
			if (child.props.required) {
				hasRequiredField = true;
			}

			return React.cloneElement(child, {
				readOnly: this.props.readOnly || child.props.readOnly,
				key: 'key' + idx,
				formid: formid,
				fieldvalue: child.props.fieldid ? this.state.currentObject[child.props.fieldid] : this.state.currentObject,
				fieldchangecallback: this.fieldchangecallback
			});
			//return <div>{childrenWithProps}</div>

			// return React.addons.cloneWithProps(child, {
			//	 readOnly: this.props.readOnly,
			//	 key: 'key' + idx,
			//   formid: formid,
			//   fieldvalue: child.props.fieldid ? this.state.currentObject[child.props.fieldid] : this.state.currentObject,
			//   fieldchangecallback: this.fieldchangecallback
			// });
		}.bind(this));

		var requiredMessage;
		if (hasRequiredField && !this.props.readOnly) {
			requiredMessage = (
				<div style={{ float: "left", marginLeft: "130px" }}>
					<span className="required-mark" style={{ marginTop: "-4px" }}>*</span> indicates required field</div>
			);
		}

		var submitButton;
		var saveButton;
		var submitButtonLabel = this.props.submitButtonLabel || 'Done';
		var saveButtonLabel = this.props.saveButtonLabel || 'Save';
		if (this.props.submitCallback && ! this.props.readOnly) {
			submitButton = (
				<button id="formSubmitButton" className="btn btn-primary btn-sm" onClick={this.submitCallback}>{ submitButtonLabel }</button>
			);
		}
		if (this.props.saveCallback && ! this.props.readOnly) {
			saveButton = (
				<button id="formSaveButton" className="btn btn-primary btn-sm" onClick={this.saveCallback}>{ saveButtonLabel }</button>
			);
		}
		var cancelButton;
		var cancelButtonLabel = this.props.cancelButtonLabel || 'Cancel';
		if (this.props.cancelCallback) {
			cancelButton = (
				<button id="formCancelButton" className="btn btn-secondary btn-sm" onClick={this.cancelButtonCallback}>{ cancelButtonLabel }</button>
			);
		}
		return (
			<div className="sl-form" style={cssStyle}>
				<div className="form-horizontal">
					{this.props.title?<h4 className="page-header" style={cssHideTitle}>{this.props.title}</h4>:null }
					<div className="clearfix"></div>
					{message}
					{ kids }
				</div>
				<br/>
				{ requiredMessage }
				{ submitButton }
				{ cancelButton }
				{ saveButton }
				{ redirectBlock }
			</div>
		);
	}
}

export default Form;
