import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import _ from 'lodash';
import * as formStateActions from '../../../actions/formStateActions';
import * as attributeActions from  '../../../actions/attributeActions';
import * as adminActions from '../../../actions/adminActions';

class AssignAttribute extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentAttribute:  {
				attribute_id: null,
				attributeValue: null
			}
		};

		this.deleteLoginAttribute = this.deleteLoginAttribute.bind(this);
		this.setFormErrorMessage = this.setFormErrorMessage.bind(this);
		this.setFormSuccessMessage = this.setFormSuccessMessage.bind(this);
	}

	componentDidMount() {

	}

	componentWillMount() {
		this.props.actions.loadLoginAttributes(this.props.object.login_id).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the login attributes: " + msg);
		});
		this.props.actions.loadAttributes().catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred loading the user attributes: " + msg);
		});
	}

	componentWillUnmount() {

	}

	_onChange() {
		if (this._isMounted) {
			this.forceUpdate();
		}
	}

	deleteLoginAttribute(attributeID) {
		var userID = this.props.object.login_id;
		this.props.actions.deleteLoginAttributes(userID, attributeID).then(data => {
			this.props.actions.loadLoginAttributes(userID).then(data => {
				this.setFormSuccessMessage("Attribute deleted.");
			})
		}).catch(errObject => {
			let msg = errObject.message || errObject;
			this.setFormErrorMessage("An error occurred deleting this attribute:" + msg);
		})
	}

	setFormErrorMessage(errorMessage) {
		window.scrollTo(0, 0);
		this.props.actions.setErrMsg(errorMessage);
	}

	setFormSuccessMessage(successMessage) {
		window.scrollTo(0, 0);
		this.props.actions.setSuccessMsg(successMessage);
	}

	render() {
		var loginid = this.props.object.login_id;
		var object = this.props.object;
		var loginattributes = this.props.loginattributes;
		var attributes = this.props.attributes;
		var attributesLowercase = attributes;

		var buttonStyle = {
			'position': 'relative',
			'float': 'right',
			'cursor': 'pointer'
		};
		var formStyle = {
			'testAlign': 'left'
		};

		var attributeItems = [];
		var attributeList;

		if (loginattributes && loginattributes.length > 0) {
			var i;
			for (i = 0; i < loginattributes.length; i++) {
				var loginattribute = loginattributes[i];
				attributeItems.push(<li className={"sl-listitem"}>
										<p>
											<b>{loginattribute.attribute}: </b>
											{loginattribute.value}
											<b style={buttonStyle}
													onClick={() => this.deleteLoginAttribute(loginattribute.id)}>x</b>
					</p>
				</li>)
			}
			attributeList = (
				<div className="form-group row">
					<label className="col-form-label col-md-2">Current Attributes</label>
					<div className="form-control-plaintext col-md 6">
						<ul style={{ paddingLeft: "15px"}}>
							{attributeItems}
						</ul>
					</div>
				</div>
			)
		} else {
			attributeList = (
				<div className="form-group row">
					<label className="col-form-label col-md-2">Current Attributes</label>
					<div className="form-control-plaintext col-md-6">
						<p style={{fontStyle: "italic"}}>No attributes assigned to this user</p>
					</div>
				</div>
			);
		}

		return (
			<Form {...this.props} width="800" height="580" submitButtonLabel="Assign">
				<FormField.Input dontModify={true} fieldid="email" type="email" width="5" label="Email" labelWidth="2"  />
				<FormField.Input dontModify={true} fieldid="first_name" width="3" label="First Name" labelWidth="2" />
				<FormField.Input dontModify={true} fieldid="last_name" width="5" label="Last Name" labelWidth="2" />
				<h4 style={{"color": "#763905","marginTop": "30px"}}>Assign Attributes</h4>
				{ attributeList }
				<div className="clearfix"></div>
					<FormField.Select fieldid="attribute_id"
									  fieldName="attribute_name"
									  label="Attribute Type"
									  optionid="attribute_id"
									  width="6"
									  labelWidth="2"
									  noChoice="=== Please choose a value ==="
									  required="true"
									  options={attributes}/>
					<FormField.Input fieldid="attribute_value"
									 label="Attribute Value"
									 width="6"
									 labelWidth="2"
									 required="true"
									 placeholder="Enter the value for this login attribute"/>
			</Form>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		loginattributes: state.attributeReducer.loginattributes,
		attributes: state.attributeReducer.attributes,
		user: state.adminReducer.user,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators( Object.assign({}, adminActions, formStateActions, attributeActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignAttribute)
