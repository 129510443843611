import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators}  from 'redux'
import * as sessionActions from '../../actions/sessionActions';

const SlinkAuth = window.SlinkAuth;

class TopMenu extends Component {
    constructor(props) {
        super();
        this.logOutClick = this.logOutClick.bind(this);
    }

    logOutClick(event) {
        event.preventDefault();
        this.props.auth.dispLogOut(new Date());
        this.props.auth.dispLoginUser();
    }

    componentDidMount() {
        //this.props.auth.dispLoginUser();
    }

    render() {

        var loginText = "login";
        if(sessionStorage.getItem("itk")) {
            loginText ="logout";
        }
		let roles = SlinkAuth.getRoles();

		let homeNav, adminNav, digiChannelNav;
		if (roles === "slink-sys-admin" || roles === "slink-engmnt-manager" || roles === "slink-apilink-manager")
		{
			homeNav = (<li className="nav-item">
							<NavLink to="/home" className="nav-link" route={this.props.router.location.pathname} id="top_home">Home</NavLink>
						</li>);
		}
		if (roles === "slink-sys-admin" || roles === "slink-sisl-admin" || roles === "slink-engmnt-manager" || roles === "slink-apilink-manager")
		{
			let navTo, adminTabName;
			if (roles === "slink-sys-admin")
			{
				navTo = "/admin";
				adminTabName = "Admin";
			}
			else if (roles === "slink-sisl-admin")
			{
				navTo = "/admin/sislusers";
				adminTabName = "SISLNext users administrative console";
			}
			else if (roles === 'slink-engmnt-manager') {
				navTo = "/dashboard/DashboardListingPage";
				adminTabName = "Engagement";
			}
			else if (roles === 'slink-apilink-manager') {
				navTo = "/admin/apilinks";
				adminTabName = "API Links";
			}
			else
			{
				navTo = "/admin";
				adminTabName = "Admin";
			}
			adminNav = (<li className="nav-item">
							<NavLink to={navTo} className="nav-link" route={this.props.router.location.pathname} id="top_admin">{adminTabName}</NavLink>
						</li>);
		}
		if (roles === "slink-sys-admin")
		{
			digiChannelNav = (<li className="nav-item">
							<NavLink to="/digichannel" className="nav-link" route={this.props.router.location.pathname} id="top_digichannel">Digi Channel</NavLink>
						</li>);
		}
        return (
        	<div>
                <nav className="navbar navbar-fixed-top navbar-expand-lg navbar-dark bg-dark">
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
						<li className="nav-item navbar-brand">&nbsp;&nbsp;&nbsp;WorkBench&nbsp;&nbsp;&nbsp;</li>
                        <ul className="navbar-nav mr-auto">
                            {homeNav}
                            {adminNav}
                            {digiChannelNav}
                        </ul>
                    </div>
                </nav>
			</div>

        );
    }
}

/*
						<li class="nav-item dropdown">
							<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								Dropdown
							</a>
							<div class="dropdown-menu" aria-labelledby="navbarDropdown">
								<a class="dropdown-item" href="#">Action</a>
								<a class="dropdown-item" href="#">Another action</a>
								<div class="dropdown-divider"></div>
								<a class="dropdown-item" href="#">Something else here</a>
							</div>
						</li>
						<li class="nav-item">
							<a class="nav-link disabled" href="#">Disabled</a>
						</li>

					<form class="form-inline my-2 my-lg-0">
						<input class="form-group mr-sm-2 row" type="search" placeholder="Search" aria-label="Search" />
						<button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
					</form>
*/

// Grab a reference to the current URL. If this is a web app and you are
// using React Router, you can use `ownProps` to find the URL. Other
// platforms (Native) or routing libraries have similar ways to find
// the current position in the app.
function mapStateToProps(state, ownProps) {
    return {
        router: state.router
    }
}

function mapDispatchToProps(dispatch) {

    return {
        auth: bindActionCreators(sessionActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu)
