import React, { Component } from 'react';

class ContentWrap extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};
	}

	setFormState(newState) {
	}

    render() {
    	var message;
        if (this.props.errorMessage) {
            message = <div className="alert alert-danger" id="statusMessage">{this.props.errorMessage}</div>;
        } else if (this.props.successMessage) {
            message = <div className="alert alert-success" id="statusMessage">{this.props.successMessage}</div>;
        } else {
            message = null;
        }

    	if (this.props.form) {
    		return (
		        <div className="sl-rightside-container">
					{message}
					{this.props.form}
				</div>
    		);
    	}

        return (
	        <div className="sl-rightside-container">
				{message}
    			<div className={this.props.noInline === true ? "" : "form-inline"}>

						<h4>{this.props.title}</h4>

                    <div className="clearfix"></div>

					{this.props.children}
				</div>
			</div>
		);
    }
}

export default ContentWrap;
