import * as types from './actionTypes';
import api from './api';

export function refreshCache() {
	return function(dispatch) {
		return api({
			method: 'put',
			url: '/tools/auth/cache'
		}).then(response => {
			dispatch({type: types.REFRESH_CACHE_SUCCESS, payload: response});
		}).catch(error => {
			throw(error);
		});
	};
}
