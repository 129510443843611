import _ from 'lodash';

const APILinkConfigFormConverter = {
	authFormFieldsToObjectByID: function(fields)
	{
		let fieldtypePrefix = "fieldtype";
		let fieldnamePrefix = "fieldname";
		let valuePrefix = "value";
		let authDetailsObject = {};
		let authDetailsPrefixesArr = [fieldtypePrefix, fieldnamePrefix, valuePrefix];
		// Take in all authentication values from the form and put them in a single object
		for (let fieldKey in fields)
		{
			for (let authDetailIndex = 0, authDetailsFieldLen = authDetailsPrefixesArr.length; authDetailIndex < authDetailsFieldLen; authDetailIndex++)
			{
				let authDetailPrefixLen = authDetailsPrefixesArr[authDetailIndex].length + '_'.length;
				let fullAuthDetailPrefix = authDetailsPrefixesArr[authDetailIndex] + "_";
				if (fieldKey.substring(0, authDetailPrefixLen) === fullAuthDetailPrefix)
				{
					let authFakeID = fieldKey.substring(authDetailPrefixLen);
					if (!(authFakeID in authDetailsObject))
						authDetailsObject[authFakeID] = {};
					authDetailsObject[authFakeID][authDetailsPrefixesArr[authDetailIndex]] = fields[fieldKey];
					break;
				}
			}
		}
		return authDetailsObject;
	},

	authFormFieldsToObject: function(fields)
	{
		let authDetailsObject = this.authFormFieldsToObjectByID(fields);

		let finalAuthObject = {};
		// Take the object we just created and make it into authentication JSON fit for sending to the DB
		for (let fakeAuthFieldKey in authDetailsObject)
		{
			let authObj = authDetailsObject[fakeAuthFieldKey];
			if (authObj.fieldtype === "username" || authObj.fieldtype === "password")
				finalAuthObject[authObj.fieldtype] = {"name": authObj.fieldname, "value": authObj.value};
			else if (authObj.fieldtype === "headers" || authObj.fieldtype === "qs" || authObj.fieldtype === "certs")
			{
				if (!(authObj.fieldtype in finalAuthObject))
					finalAuthObject[authObj.fieldtype] = [];
				finalAuthObject[authObj.fieldtype].push({"name": authObj.fieldname, "value": authObj.value});
			}
			else if (authObj.fieldtype === "request")
			{
				if (!("request" in finalAuthObject))
					finalAuthObject["request"] = {};
				finalAuthObject["request"][authObj.fieldname] = authObj.value;
			}
		}
		return finalAuthObject;
	},

	attributeFormFieldsToArray: function(fields, convertAttrID)
	{
		let dataTypePrefix = "api_data_type_id";
		let attributeIDPrefix = "api_link_attribute_id";
		let defaultValuePrefix = "default_value";
		let descriptionPrefix = "description";
		let namePrefix = "name";
		let attributesObject = {};
		let attributePrefixesArr = [dataTypePrefix, attributeIDPrefix, defaultValuePrefix, descriptionPrefix, namePrefix];

		// First organize submitted form data into an object which stores all data by attribute ID
		for (let fieldKey in fields)
		{
			for (let attrPrefixIndex = 0, attrFieldLen = attributePrefixesArr.length; attrPrefixIndex < attrFieldLen; attrPrefixIndex++)
			{
				let attrFieldPrefixLen = attributePrefixesArr[attrPrefixIndex].length + '_'.length;
				let fullAttributePrefix = attributePrefixesArr[attrPrefixIndex] + "_";
				if (fieldKey.substring(0, attrFieldPrefixLen) === fullAttributePrefix)
				{
					let attrID = fieldKey.substring(attrFieldPrefixLen);
					if (!(attrID in attributesObject))
						attributesObject[attrID] = {};
					attributesObject[attrID][attributePrefixesArr[attrPrefixIndex]] = fields[fieldKey];
					break;
				}
			}
		}

		// Now let's take the JSON object which has everything separated by ID and make it into an array
		let attributeArr = [];
		for (let attrID in attributesObject)
		{
			// Potential scenarios here:
			// 1) We're editing an attribute which is in the DB, in which case the attribute could be a string version of a number such as "86"
			// 2) We're editing an attribute not in the DB
			//	a) We're saving to the DB-- in which case we want the ID to be 0 so the server will know to insert them.
			//	b) We're using this function within the form when adding/removing rows. In that case, retain the attribute ID as-is.
			let api_link_attribute_id;
			// If we're submitting to DB and we get to a new attribute, convert to 0 so the server knows to add it
			if (isNaN(parseInt(attrID)) && convertAttrID)
				api_link_attribute_id = 0;
			else
			{
				// If we're resetting state within the form, such as due to adding or removing rows, keep the current attribute ID
				if (isNaN(parseInt(attrID)))
					api_link_attribute_id = attrID;
				else // If we're sending data to the server, make sure all attribute IDs are numbers. When editing a row, they can change to strings.
					api_link_attribute_id = parseInt(attrID);
			}
			attributeArr.push({
				"api_link_attribute_id": api_link_attribute_id,
				"name": attributesObject[attrID]["name"],
				"description": attributesObject[attrID]["description"],
				"api_data_type_id": parseInt(attributesObject[attrID]["api_data_type_id"]),
				"default_value": attributesObject[attrID]["default_value"]
			});
		}
		return attributeArr;
	},

	initAuthObj: function(authDetails, mainPageObj)
	{
		let apiLinkInUse = mainPageObj.props.apilinkInUse;
		if (!_.isEmpty(authDetails))
		{
			let authFieldIndex = 0;
			if ("username" in authDetails)
			{
				let newAuthObj = {"index": authFieldIndex, "fieldtype": "username", "fieldname": authDetails.username.name, "value": authDetails.username.value};
				mainPageObj.authDataObj[authFieldIndex] = newAuthObj;
				authFieldIndex++;
			}
			if ("password" in authDetails)
			{
				let newAuthObj = {"index": authFieldIndex, "fieldtype": "password", "fieldname": authDetails.password.name, "value": authDetails.password.value};
				mainPageObj.authDataObj[authFieldIndex] = newAuthObj;
				authFieldIndex++;
			}
			if ("certs" in authDetails)
			{
				for (let certsIndex = 0, certsLen = authDetails.certs.length; certsIndex < certsLen; certsIndex++)
				{
					let newAuthObj = {"index": authFieldIndex, "fieldtype": "certs", "fieldname": authDetails.certs[certsIndex].name, "value": authDetails.certs[certsIndex].value, readonly: (apiLinkInUse === 1)};
					mainPageObj.authDataObj[authFieldIndex] = newAuthObj;
					authFieldIndex++;
				}
			}
			if ("headers" in authDetails)
			{
				for (let headerIndex = 0, headerLen = authDetails.headers.length; headerIndex < headerLen; headerIndex++)
				{
					let newAuthObj = {"index": authFieldIndex, "fieldtype": "headers", "fieldname": authDetails.headers[headerIndex].name, "value": authDetails.headers[headerIndex].value, readonly: (apiLinkInUse === 1)};
					mainPageObj.authDataObj[authFieldIndex] = newAuthObj;
					authFieldIndex++;
				}
			}
			if ("qs" in authDetails)
			{
				for (let qsIndex = 0, qsLen = authDetails.qs.length; qsIndex < qsLen; qsIndex++)
				{
					let newAuthObj = {"index": authFieldIndex, "fieldtype": "qs", "fieldname": authDetails.qs[qsIndex].name, "value": authDetails.qs[qsIndex].value, readonly: (apiLinkInUse === 1)};
					mainPageObj.authDataObj[authFieldIndex] = newAuthObj;
					authFieldIndex++;
				}
			}
			if ("request" in authDetails)
			{
				for (let requestProp in authDetails.request)
				{
					let newAuthObj = {"index": authFieldIndex, "fieldtype": "request", "fieldname": requestProp, "value": authDetails.request[requestProp], readonly: (apiLinkInUse === 1)};
					mainPageObj.authDataObj[authFieldIndex] = newAuthObj;
					authFieldIndex++;
				}
			}
		}
	},

	authObjToUI: function(authDetails, mainPageObj)
	{
		let authDetailsArr = [];
		if (!_.isEmpty(mainPageObj.authDataObj))
		{
			let keysSorted = Object.keys(mainPageObj.authDataObj).sort(function(a, b){return a - b});
			for (let x = 0, keysLen = keysSorted.length; x < keysLen; x++)
			{
				let key = keysSorted[x];
				authDetailsArr.push(mainPageObj.authDataObj[key]);
			}
		}
		return authDetailsArr;
	},

	// When a user changes any value in the auth data form,
	// update the main page auth data state object
	updateAuthObj: function(fields, mainPageObj)
	{
		let currentAuthObj = this.authFormFieldsToObjectByID(fields);
		for (let fakeAuthKey in mainPageObj.authDataObj)
		{
			let matchesFieldType = mainPageObj.authDataObj[fakeAuthKey].fieldtype === currentAuthObj[fakeAuthKey].fieldtype;
			let matchesFieldName = mainPageObj.authDataObj[fakeAuthKey].fieldname === currentAuthObj[fakeAuthKey].fieldname;
			let matchesValue = mainPageObj.authDataObj[fakeAuthKey].value === currentAuthObj[fakeAuthKey].value;
			let numMatches = 0;
			if (matchesFieldType)
				numMatches++;
			if (matchesFieldName)
				numMatches++;
			if (matchesValue)
				numMatches++;
			if (numMatches === 2) // If 2 out of 3 matched, this means that a value was changed to this row
			{
				mainPageObj.authDataObj[fakeAuthKey].fieldtype = currentAuthObj[fakeAuthKey].fieldtype;
				mainPageObj.authDataObj[fakeAuthKey].fieldname = currentAuthObj[fakeAuthKey].fieldname;
				mainPageObj.authDataObj[fakeAuthKey].value = currentAuthObj[fakeAuthKey].value;
			}
		}
	},

	// When a user changes any value in the auth data form,
	// update the main page auth data state object
	updateAttributeObj: function(fields, mainPageObj)
	{
		let attributeCopy = mainPageObj.state.attributeData;
		let attributeArrayFromForm = this.attributeFormFieldsToArray(fields, false);

		for (let mainAttrIndex = 0, mainAttrLen = attributeCopy.length; mainAttrIndex < mainAttrLen; mainAttrIndex++)
		{
			for (let fieldAttrIndex = 0, fieldAttrLen = attributeArrayFromForm.length; fieldAttrIndex < fieldAttrLen; fieldAttrIndex++)
			{
				let attrObjMainpage = attributeCopy[mainAttrIndex];
				let attrObjFromFields = attributeArrayFromForm[fieldAttrIndex];
				if (attrObjMainpage.api_link_attribute_id === attrObjFromFields.api_link_attribute_id)
				{
					let matchesFieldType = attrObjMainpage.api_data_type_id === attrObjFromFields.api_data_type_id;
					let matchesFieldName = attrObjMainpage.name === attrObjFromFields.name;
					let matchesDefaultValue = attrObjMainpage.default_value === attrObjFromFields.default_value;
					let matchesDescription = attrObjMainpage.description === attrObjFromFields.description;
					let numMatches = 0;
					if (matchesFieldType)
						numMatches++;
					if (matchesFieldName)
						numMatches++;
					if (matchesDefaultValue)
						numMatches++;
					if (matchesDescription)
						numMatches++;
					if (numMatches === 3) // If 3 out of 4 matched, this means that a value was changed to this row
					{
						attrObjMainpage.api_data_type_id = attrObjFromFields.api_data_type_id;
						attrObjMainpage.name = attrObjFromFields.name;
						attrObjMainpage.default_value = attrObjFromFields.default_value;
						attrObjMainpage.description = attrObjFromFields.description;
					}
				}
			}
		}
	}
};

export default APILinkConfigFormConverter;
