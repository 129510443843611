import * as types from '../actions/actionTypes';
/*
 * All reducers get two parameters passed in, state and action that occurred
 *   > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

var initialState = {
	vendors: [],
	vendorEmaildomains: [],
	vendorShortcodes: {},
	vendorTypecodes: []
};

export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_VENDOR_TYPECODES_SUCCESS:
			//console.log('reducer to update vendor typecode data',action);
			return Object.assign({}, state, {vendorTypecodes: action.payload});
		case types.GET_VENDOR_SHORTCODES_SUCCESS:
			//console.log('reducer to update vendor shortcode data',action);
			// If this vendor has shortcodes, save them
			/**************************
				We're storing vendor shortcodes in a hash.
				The key is the vendor account ID.
				The value is the list of shortcodes.
				This way, each incoming call doesn't overwrite data from previous calls.
			**************************/

			// FIREFOX BUG WORKAROUND
			// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
			// It doesn't like the number 1 as a computed key in an object, even once it's stringified
			// Also: it's unfortunate that this API returns VendorAccountId ending with a lower-case d,
			// but in other places it ends with an upper-case D.
			let vendorAccountIDShortcode = (action.payload.length > 0) ? action.payload[0].VendorAccountId : "";
			return Object.assign({}, state, {vendorShortcodes: { ['ID_' + vendorAccountIDShortcode]: action.payload}});
		case types.GET_VENDOR_EMAILDOMAIN_SUCCESS:
			//console.log('reducer to list email domains associated with an email vendor', action);
			/**************************
				In the sendgrid UI, we create a different subuser for every customer.
				Each subuser gets its own whitelisted email domains.
				We then add this data in workbench.
				We associate these email domains to the sendgrid vendor subuser account.
			**************************/

			// If this email vendor has domains assigned to it
			/**************************
				We're storing vendor shortcodes in a hash.
				The key is the vendor account ID.
				The value is the list of shortcodes.
				This way, each incoming call doesn't overwrite data from previous calls.
			**************************/

			// FIREFOX BUG WORKAROUND
			// We have to prefix the ID with 'ID_' due to a bug in the latest Firefox
			// It doesn't like the number 1 as a computed key in an object, even once it's stringified
			// Also: it's unfortunate that this API returns VendorAccountId ending with a lower-case d,
			// but in other places it ends with an upper-case D.
			let vendorAccountIDEmaildomain = (action.payload.length > 0) ? action.payload[0].VendorAccountId : "";
			return Object.assign({}, state, {vendorEmaildomains: { ['ID_' + vendorAccountIDEmaildomain]: action.payload}});
		case types.GET_VENDORS_SUCCESS:
			//console.log('reducer to update vendor data',action);
			return Object.assign({}, state, {vendors: action.payload});
	}
	return state;
}
