import React, {Component} from 'react';
const ReactDOM = require('react-dom');

class TableSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.props.onUserInput(
            //this.refs.filterTextInput.getDOMNode().value
            ReactDOM.findDOMNode(this.refs.filterTextInput).value
        );
    }

    render() {
        return (
            <div className="float-right form-inline">
                <input className="searchBox tableFilter"
                       ref="filterTextInput"
                       placeholder=" Type to Narrow Table"
                       onChange={this.handleChange}
                       value={this.props.filterText}
                       data-target="detail">
                </input>
            </div>
        );
    };
}

export default TableSearch;
