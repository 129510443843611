export const LOAD_CATS_SUCCESS = 'LOAD_CATS_SUCCESS';
export const LOAD_HOBBIES_SUCCESS = 'LOAD_HOBBIES_SUCCESS';
export const UPDATE_CAT_SUCCESS = 'UPDATE_CAT_SUCCESS';
export const CREATE_CAT_SUCCESS = 'CREATE_CAT_SUCCESS';
export const DELETE_CAT_SUCCESS = 'DELETE_CAT_SUCCESS';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const LOG_OUT = 'LOG_OUT';
export const LOAD_CARS_SUCCESS = 'LOAD_CARS_SUCCESS';

export const CURRENT_URL_SELECTED = 'CURRENT_URL_SELECTED';

export const GET_USERS_SUCCESS			= 'GET_USERS_SUCCESS';
export const GET_SINGLE_USER_SUCCESS	= 'GET_SINGLE_USER_SUCCESS';
export const UPDATE_USER				= 'UPDATE_USER';
export const GET_API_CLIENTS_SUCCESS       = 'GET_API_CLIENTS_SUCCESS';
export const GET_SINGLE_API_CLIENT_SUCCESS = 'GET_SINGLE_API_CLIENTS_SUCCESS';
export const UPDATE_API_CLIENT             = 'UPDATE_API_CLIENT';
export const CHANGE_PASSWORD			= 'CHANGE_PASSWORD';
export const FORMSTATE_CHANGED			= 'FORMSTATE_CHANGED';
export const GET_APPLICATIONS_SUCCESS	= 'GET_APPLICATIONS_SUCCESS';
export const GET_ROLES_SUCCESS			= 'GET_ROLES_SUCCESS';
export const GET_APIS_SUCCESS     		= 'GET_APIS_SUCCESS';
export const GET_IP_RANGES_SUCCESS		= 'GET_IP_RANGES_SUCCESS';
export const ADD_IP_RANGE_SUCCESS		= 'ADD_IP_RANGE_SUCCESS';
export const DELETE_IP_RANGE_SUCCESS	= 'DELETE_IP_RANGE_SUCCESS';
export const GET_APIGROUPS_SUCCESS      = 'GET_APIGROUPS_SUCCESS';
export const GET_ACCOUNTGROUPS_SUCCESS  = 'GET_ACCOUNTGROUPS_SUCCESS';
export const GET_ACCOUNTGROUP_DETAILS_SUCCESS  = 'GET_ACCOUNTGROUP_DETAILS_SUCCESS';
export const ADD_ACCOUNTGROUPS_SUCCESS  = 'ADD_ACCOUNTGROUPS_SUCCESS';
export const EDIT_ACCOUNTGROUPS_SUCCESS = 'EDIT_ACCOUNTGROUPS_SUCCESS';
export const GET_ACCOUNT_GROUP_ASSIGNMENTS_SUCCESS 		= 'GET_ACCOUNT_GROUP_ASSIGNMENTS_SUCCESS';
export const ADD_ACCOUNT_GROUP_ASSIGNMENT_SUCCESS 		= 'ADD_ACCOUNT_GROUP_ASSIGNMENT_SUCCESS';
export const DELETE_ACCOUNT_GROUP_ASSIGNMENT_SUCCESS	= 'DELETE_ACCOUNT_GROUP_ASSIGNMENT_SUCCESS';
export const GET_ATTRIBUTES_SUCCESS         = 'GET_ATTRIBUTES_SUCCESS';
export const GET_LOGIN_ATTRIBUTES_SUCCESS   = 'GET_LOGIN_ATTRIBUTES_SUCCESS';
export const ASSIGN_LOGIN_ATTRIBUTE_SUCCESS = 'ADD_LOGIN_ATTRIBUTES_SUCCESS';
export const EDIT_LOGIN_ATTRIBUTE_SUCCESS   = 'EDIT_LOGIN_ATTRIBUTES_SUCCESS';
export const DELETE_LOGIN_ATTRIBUTE_SUCCESS = 'DELETE_LOGIN_ATTRIBUTES_SUCCESS';

export const ADD_VENDOR_SUCCESS			= 'ADD_VENDOR_SUCCESS';
export const UPDATE_VENDOR_SUCCESS		= 'UPDATE_VENDOR_SUCCESS';
export const DELETE_VENDOR_SUCCESS		= 'DELETE_VENDOR_SUCCESS';
export const GET_VENDORS_SUCCESS		= 'GET_VENDORS_SUCCESS';
export const GET_VENDOR_TYPECODES_SUCCESS	= 'GET_VENDOR_TYPECODES_SUCCESS';
export const GET_VENDOR_SHORTCODES_SUCCESS	= 'GET_VENDOR_SHORTCODES_SUCCESS';
export const GET_VENDOR_EMAILDOMAIN_SUCCESS	= 'GET_VENDOR_EMAILDOMAIN_SUCCESS';
export const ADD_VENDOR_EMAILDOMAIN_SUCCESS	= 'ADD_VENDOR_EMAILDOMAIN_SUCCESS';
export const REMOVE_VENDOR_EMAILDOMAIN_SUCCESS	= 'REMOVE_VENDOR_EMAILDOMAIN_SUCCESS';
export const ADD_VENDOR_SHORTCODE_SUCCESS		= 'ADD_VENDOR_SHORTCODE_SUCCESS';
export const REMOVE_VENDOR_SHORTCODE_SUCCESS	= 'REMOVE_VENDOR_SHORTCODE_SUCCESS';

export const GET_EMAILDOMAINS_SUCCESS			= 'GET_EMAILDOMAINS_SUCCESS';
export const GET_AVAILABLE_EMAILDOMAIN_SUCCESS	= 'GET_AVAILABLE_EMAILDOMAIN_SUCCESS';
export const GET_EMAILDOMAIN_ACCOUNTS_SUCCESS	= 'GET_EMAILDOMAIN_ACCOUNTS_SUCCESS';
export const ADD_EMAILDOMAIN	= 'ADD_EMAILDOMAIN';
export const UPDATE_EMAILDOMAIN	= 'UPDATE_EMAILDOMAIN';
export const DELETE_EMAILDOMAIN	= 'DELETE_EMAILDOMAIN';
export const ADD_EMAILDOMAIN_ACCOUNT	= 'ADD_EMAILDOMAIN_ACCOUNT';
export const REMOVE_EMAILDOMAIN_ACCOUNT	= 'REMOVE_EMAILDOMAIN_ACCOUNT';

export const AUTHORIZE_BEE	= 'AUTHORIZE_BEE';
export const GET_BEETEMPLATES_SUCCESS	= 'GET_BEETEMPLATES_SUCCESS';
export const GET_BEETEMPLATE_SUCCESS	= 'GET_BEETEMPLATE_SUCCESS';
export const ADD_BEETEMPLATES_SUCCESS	= 'ADD_BEETEMPLATES_SUCCESS';
export const UPDATE_BEETEMPLATE_SUCCESS	= 'UPDATE_BEETEMPLATE_SUCCESS';
export const DELETE_BEETEMPLATE_SUCCESS	= 'DELETE_BEETEMPLATE_SUCCESS';
export const CLONE_BEETEMPLATE_SUCCESS	= 'CLONE_BEETEMPLATE_SUCCESS';
export const GET_BEETEMPLATE_ACCOUNTS_SUCCESS	= 'GET_BEETEMPLATE_ACCOUNTS_SUCCESS';
export const ADD_BEETEMPLATE_ACCOUNT_SUCCESS	= 'ADD_BEETEMPLATE_ACCOUNT_SUCCESS';
export const REMOVE_BEETEMPLATE_ACCOUNT_SUCCESS	= 'REMOVE_BEETEMPLATE_ACCOUNT_SUCCESS';

export const GET_UNSUBSCRIBEGROUPS_SUCCESS			= 'GET_UNSUBSCRIBEGROUPS_SUCCESS';
export const GET_UNSUBSCRIBEGROUP_ACCOUNTS_SUCCESS	= 'GET_UNSUBSCRIBEGROUP_ACCOUNTS_SUCCESS';
export const ADD_UNSUBSCRIBEGROUP		= 'ADD_UNSUBSCRIBEGROUP';
export const UPDATE_UNSUBSCRIBEGROUP	= 'UPDATE_UNSUBSCRIBEGROUP';
export const DELETE_UNSUBSCRIBEGROUP	= 'DELETE_UNSUBSCRIBEGROUP';
export const ADD_UNSUBSCRIBEGROUP_ACCOUNT		= 'ADD_UNSUBSCRIBEGROUP_ACCOUNT';
export const REMOVE_UNSUBSCRIBEGROUP_ACCOUNT	= 'REMOVE_UNSUBSCRIBEGROUP_ACCOUNT';

export const GET_SHORTCODES_SUCCESS = 'GET_SHORTCODES_SUCCESS';
export const GET_AVAILABLE_SHORTCODES_SUCCESS	= 'GET_AVAILABLE_SHORTCODES_SUCCESS';
export const GET_SHORTCODE_ACCOUNTS_SUCCESS		= 'GET_SHORTCODE_ACCOUNTS_SUCCESS';
export const GET_SHORTCODE_PROGRAMS_SUCCESS		= 'GET_SHORTCODE_PROGRAMS_SUCCESS';
export const ADD_SHORTCODE_ACCOUNT_SUCCESS		= 'ADD_SHORTCODE_ACCOUNT_SUCCESS';
export const REMOVE_SHORTCODE_ACCOUNT_SUCCESS	= 'REMOVE_SHORTCODE_ACCOUNT_SUCCESS';
export const ADD_SHORTCODE_SUCCESS		= 'ADD_SHORTCODE_SUCCESS';
export const UPDATE_SHORTCODE_SUCCESS	= 'UPDATE_SHORTCODE_SUCCESS';
export const REMOVE_SHORTCODE_SUCCESS	= 'REMOVE_SHORTCODE_SUCCESS';

export const GET_APILINK_SUCCESS			= 'GET_APILINK_SUCCESS';
export const GET_APILINKS_SUCCESS			= 'GET_APILINKS_SUCCESS';
export const RESET_APILINK_SUCCESS		= 'RESET_APILINK_SUCCESS';
export const GET_APILINK_INUSE				= 'GET_APILINK_INUSE';
export const GET_APILINK_ACCOUNTS_SUCCESS	= 'GET_APILINK_ACCOUNTS_SUCCESS';
export const ADD_APILINK_SUCCESS	= 'ADD_APILINK_SUCCESS';
export const CLONE_APILINK_SUCCESS	= 'CLONE_APILINK_SUCCESS';
export const UPDATE_APILINK_SUCCESS	= 'UPDATE_APILINK_SUCCESS';
export const DELETE_APILINK_SUCCESS	= 'DELETE_APILINK_SUCCESS';
export const ADD_APILINK_ACCOUNT_SUCCESS	= 'ADD_APILINK_ACCOUNT_SUCCESS';
export const REMOVE_APILINK_ACCOUNT_SUCCESS	= 'REMOVE_APILINK_ACCOUNT_SUCCESS';
export const GET_APILINK_ATTRIBUTES_SUCCESS	= 'GET_APILINK_ATTRIBUTES_SUCCESS';
export const GET_APILINK_DATA_TYPES_SUCCESS	= 'GET_APILINK_DATA_TYPES_SUCCESS';
export const GET_APILINK_AUTH_METHODS_SUCCESS	= 'GET_APILINK_AUTH_METHODS_SUCCESS';
export const UPDATE_APILINK_ATTRIBUTES_SUCCESS	= 'UPDATE_APILINK_ATTRIBUTES_SUCCESS';

export const GET_ACCOUNTS_SUCCESS	= 'GET_ACCOUNTS_SUCCESS';

export const REFRESH_CACHE_SUCCESS	= 'REFRESH_CACHE_SUCCESS';
export const GET_SESSION_USER_DATA_SUCCESS	= 'GET_SESSION_USER_DATA_SUCCESS';

export const GET_DASHBOARD_SUCCESS	= 'GET_DASHBOARD_SUCCESS';
export const CLONE_DASHBOARD_SUCCESS = 'CLONE_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_DETAILS_SUCCESS	= 'GET_DASHBOARD_DETAILS_SUCCESS';
export const GET_DASHBOARD_METRICS_SUCCESS	= 'GET_IMPORT_METRICS_SUCCESS';
export const GET_DASHBOARD_ACCOUNT_PROGRAMS_SUCCESS = 'GET_DASHBOARD_ACCOUNT_PROGRAMS_SUCCESS';
export const SAVE_DASHBOARD_SUCCESS = 'SAVE_DASHBOARD_SUCCESS';
export const DELETE_DASHBOARD_SUCCESS	= 'DELETE_DASHBOARD_SUCCESS';

export const GET_CUSTOM_TRANSFORMATIONS_SUCCESS = 'GET_CUSTOM_TRANSFORMATIONS_SUCCESS';
export const GET_CUSTOM_TRANSFORMATION_SUCCESS = 'GET_CUSTOM_TRANSFORMATION_SUCCESS';
export const GET_CUSTOM_TRANSFORMATION_MAPPINGS_SUCCESS = 'GET_CUSTOM_TRANSFORMATION_MAPPINGS_SUCCESS';
export const RESET_TRANSFORMATION_SUCCESS = 'RESET_TRANSFORMATION_SUCCESS';
export const GET_TRANSFORMATION_ACCOUNTS_SUCCESS = 'GET_TRANSFORMATION_ACCOUNTS_SUCCESS';
export const ADD_TRANSFORMATION_ACCOUNT_SUCCESS = 'ADD_TRANSFORMATION_ACCOUNT_SUCCESS';
export const REMOVE_TRANSFORMATION_ACCOUNT_SUCCESS = 'REMOVE_TRANSFORMATION_ACCOUNT_SUCCESS';
