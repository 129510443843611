import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators}  from 'redux'
import * as userActions  from '../../actions/userActions';
import * as sessionActions from '../../actions/sessionActions';
import {name, version, description} from '../../../package.json';

import _ from 'lodash'

class About extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		this.props.actions.loadUserData();
	}

	render(){
		let userData =  this.props.userSessionData;

		var displayObject = Object.assign({}, userData);
		if (displayObject.account_group_name_list && _.isArray(displayObject.account_group_name_list))
			displayObject.account_group_name_list = displayObject.account_group_name_list.join(', ');

		if (displayObject.organization_list && _.isArray(displayObject.organization_list))
			displayObject.organization_list = displayObject.organization_list.join(', ');

		if (displayObject.account_list && _.isArray(displayObject.account_list))
			displayObject.account_list = displayObject.account_list.join(', ');
		let firstnameText = "";
        if (userData && userData.first_name)
            firstnameText += ", " + userData.first_name;

		return (
			<div>
				{/*<SideBar menuitems={ menuitems }/>*/}
				<div>
				<div className="sl-rightside-container">
					<div>
						<h4 className="page-header pb-2 mt-4 mb-2">
							Welcome to {name} {version}{firstnameText}
						</h4>
						<div className="clearfix"></div>
						<div className="pb-2">{description}</div>
					</div>
					<table className="displayTable">
						<tr><th>Name</th><td>{displayObject.name}</td></tr>
						<tr><th>Email</th><td>{displayObject.email}</td></tr>
						<tr><th>Account Groups</th><td>{displayObject.account_group_name_list}</td></tr>
						<tr><th>EngageME Organizations</th><td>{displayObject.organization_list}</td></tr>
						<tr><th>Activate Accounts</th><td>{displayObject.account_list}</td></tr>
					</table>
				</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		userSessionData: state.sessionReducer.sessiondata,
		currentForm: state.formStateReducer.currentForm
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, sessionActions, userActions), dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(About)
