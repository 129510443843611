import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as accountActions from '../../../actions/accountActions';
import * as adminActions from '../../../actions/adminActions';
import * as formStateActions from '../../../actions/formStateActions';
import * as apiClientActions from '../../../actions/apiClientActions';
import Form from '../../common/Form';
import FormField from '../../common/FormField';
import _ from 'lodash';

class AssignApiClient extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentAppTypeCode: null
        };

        this.appChanged = this.appChanged.bind(this);
    }

    componentDidMount() {
        ;
    }

    componentWillMount() {
        this.props.actions.loadApplications();
        this.props.actions.loadRoles();
        this.props.actions.loadAccountGroups();
        this.props.actions.loadApiClient(this.props.object.login_id);
    }

    componentWillUnmount() {
        // TODO: specifics of apiclients need to be figured out
        // AdminStore.removeChangeListener(this._onChange, 'apiclients');
        // AdminStore.removeChangeListener(this._onChange, 'applications');
        // AdminStore.removeChangeListener(this._onChange, 'roles');
        // AdminStore.removeChangeListener(this._onChange, 'accountgroups');
    }

    _onChange() {
        // repaint the form when data loads
        if (this._isMounted) {
            this.forceUpdate();
        }
    }

    appChanged(evt, objChange) {
        if (!objChange) {
            return;
        }
        var appCode = objChange ? objChange.application_type_code : null;
        this.setState({
            currentAppTypeCode: ((!appCode || appCode === "") ? null : appCode.toLowerCase())
        })
    }

    render() {
        var object = this.props.object;
        var applications = this.props.applications;
        var roles = this.props.roles;
        var accountgroups = this.props.accountgroups;
        var apiclient = this.props.apiclient;


        var accountGroupItems = [];
        var accountGroupList;
        var applicationRoleItems = [];
        var applicationRoleList;

        if (apiclient && apiclient.account_group_name_list && apiclient.account_group_name_list.length > 0) {
            _.each(apiclient.account_group_name_list, function (acct_group_name) {
                accountGroupItems.push(<li key={"ag-" + _.snakeCase(acct_group_name)}
                                           className="sl-listitem">{acct_group_name}</li>);
            });
            accountGroupList = (
                <div className="form-group row">
                    <label className="col-form-label col-md-2">Current Account Groups</label>
                    <div className="form-control-plaintext col-md-6">
                        <ul style={{paddingLeft: "15px"}}>
                            {accountGroupItems}
                        </ul>
                    </div>
                </div>
            );
        } else {
            accountGroupList = (
                <div className="form-group row">
                    <label className="col-form-label col-md-2">Current Account Groups</label>
                    <div className="form-control-plaintext col-md-6">
                        <p style={{fontStyle: "italic"}}>No accounts assigned</p>
                    </div>
                </div>
            );
        }

        if (this.state.currentAppTypeCode &&
            this.state.currentAppTypeCode !== 'all' &&
            this.state.currentAppTypeCode !== 'workb') {
            roles = _.filter(roles, function (role) {
                if (role.role_type_code.toLowerCase() === 'slink-sys-admin') return true;
                if (role.application_type_code.toLowerCase() === this.state.currentAppTypeCode) return true;
            }.bind(this));
        }

        if (apiclient && apiclient.app_role_list && apiclient.app_role_list.length > 0) {
            _.each(apiclient.app_role_list, function (app_role) {
                var key = _.snakeCase(app_role.application_name + '_' + app_role.role_type_code);
                applicationRoleItems.push(<li key={"ar-" + key} className="sl-listitem">
                    <b>{app_role.application_name}: </b> {app_role.role_name} ({app_role.role_type_code})</li>);
            });
            applicationRoleList = (
                <div className="form-group row">
                    <label className="col-form-label col-md-2">Current Application Roles</label>
                    <div className="form-control-plaintext col-md-6">
                        <ul style={{paddingLeft: "15px"}}>
                            {applicationRoleItems}
                        </ul>
                    </div>
                </div>
            );
        } else {
            applicationRoleList = (
                <div className="form-group row">
                    <label className="col-form-label col-md-2">Current Application Roles</label>
                    <div className="form-control-plaintext col-md-6">
                        <p style={{fontStyle: "italic"}}>No roles assigned for any application</p>
                    </div>
                </div>
            );
        }

        const divProp = {};
        return (
            <Form {...this.props} width="800" height="580" submitButtonLabel="Assign">
                <FormField.Input dontModify={true} fieldid="email" type="email" width="5" label="Email" labelWidth="2"/>
                <FormField.Input dontModify={true} fieldid="first_name" width="3" label="First Name" labelWidth="2"/>
                <FormField.Input dontModify={true} fieldid="last_name" width="5" label="Last Name" labelWidth="2"/>
                <h4 style={{"color": "#763905", "marginTop": "30px"}}>Assign Account Groups</h4>
                {accountGroupList}
                <div className="clearfix"></div>
                <FormField.Select label="Account Group" width="6" labelWidth="2"
                                  noChoice="=== Please choose a value ===" fieldid="account_group_id"
                                  fieldName="account_group_name" options={accountgroups}/>
                <h4 style={{"color": "763905", "marginTop": "30px"}}>Assign Roles for Applications</h4>
                {applicationRoleList}
                <div className="clearfix"></div>
                <FormField.Select label="Application" optionid="application_type_code" width="6" labelWidth="2"
                                  noChoice="=== Please choose a value ===" fieldid="application_type_code"
                                  fieldName="application_name" localFieldChangeCallback={this.appChanged}
                                  options={applications}/>
                <FormField.Select label="Role" optionid="role_type_code" width="6" labelWidth="2"
                                  noChoice="=== Please choose a value ===" fieldid="role_type_code"
                                  fieldName="role_name" options={roles}/>
            </Form>
        );
    }

}

function mapStateToProps(state, ownProps) {
    return {
        applications: state.adminReducer.applications,
        roles: state.adminReducer.roles,
        accountgroups: state.adminReducer.accountgroups,
        apiclient: state.adminReducer.apiclient,
        currentForm: state.formStateReducer.currentForm

    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators( Object.assign({}, accountActions, adminActions, apiClientActions, formStateActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignApiClient)